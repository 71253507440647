/* eslint-disable react-hooks/exhaustive-deps */
import './index.css'
import { DesktopNavbar } from '../NavBar/Desktop/DesktopNavbar'
import {useState, useEffect, useRef} from 'react'
import "react-datepicker/dist/react-datepicker.css";
import calendar from '../../assets/calendar.svg'
// import makanan1 from '../../assets/makanan1.png'
import { useNavigate } from "react-router-dom";
import circleback from '../../assets/circleback.svg'
import down from '../../assets/down.svg'
import pin from '../../assets/pin.svg'
import time from '../../assets/time.svg'
import user from '../../assets/user.svg'
import search from '../../assets/search.svg' 
import plus from '../../assets/plus.svg'
import minus from '../../assets/minus.svg'
// import foto1 from '../../assets/foto4.png'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { PageLogin } from '../LoginPage/PageLogin'
import { CSSTransition } from 'react-transition-group';
import spinner from '../../assets/spinner.gif'
import axios from 'axios';
import HighlightedDaysPicker from './HighlightedDaysPicker';
import { baseUrl } from '../../lib/api';
import { useLocation } from 'react-router-dom';
import { FloatingWhatsAppCustom } from '../FloatingWhatsAppCustom/FloatingWhatsAppCustom';
import toRupiah from '@develoka/angka-rupiah-js';
import 'react-datepicker/dist/react-datepicker.css';

export const PageSetSchedule = () => {
    const [detail,setDetail] = useState()
    const [guest, setGuest] = useState(1)
    const [shownLocation, setShownLocation] = useState()
    const history = useNavigate()
    const { state } = useLocation()
    const [total, setTotal] = useState(0)
    const [showLocation, setShowLocation] = useState(false)
    const [showCalendar, setShowCalendar] = useState(false)
    const [showClock, setShowClock] = useState(false)
    const [selectedDate, setSelectedDate] = useState()
    const [selectedDateToSetBooking, setSelectedDateToSetBooking] = useState()
    const [dateShown, setDateShown] = useState('Select date')
    // const [globalFormatSelectedDate, setGlobalFormatSelectedDate] = useState('')
    const [clockSelected, setClockSelected] = useState('Select time')
    const [preparationTime, setPreparationTime] = useState()
    const [getListTime, setGetListTime] = useState()
    const [showStyle, setShowStyle] = useState(false)
    const [style, setStyle] = useState('Select serving style')
    const [styleId, setStyleId] = useState()
    const [servingStyle, setServingStyle] = useState()
    const [showLogin, setShowLogin] = useState(false)
    const [specialRequest, setSpecialRequest] = useState('')
    const [val1, setval1] = useState(false)
    const [val2, setval2] = useState(false)
    const [filterLocation, setFilterLocation] = useState('')
    const [allMonday, setAllMonday] = useState()
    const [val3, setval3] = useState(false)
    const [val4, setval4] = useState(false)
    const [val5, setval5] = useState(false)
    const [loading, setLoading] = useState(true)
    const firstUpdate2 = useRef(true);
    const [choosenLocation, setChoosenLocation] = useState('Pilih Daerah Lokasi Kamu')
    const [choosenLocationDetail, setChoosenLocationDetail] = useState('Pilih Daerah Lokasi Kamu')
    const firstUpdate = useRef(true);
    const [location, setLocation] = useState([])
    const firstUpdate3 = useRef(true);
    const firstUpdate4= useRef(true);
    const firstUpdate5 = useRef(true);
    const firstUpdate6 = useRef(true);
    const [showNavPopup, setShowNavPopup] = useState(false)
    const [availableDate, setAvailableDate] = useState()
    const [availableDateOnlyWorking, setAvailableDateOnlyWorking] = useState()
    const [isAvailableDate, setIsAvailableDate] = useState(false)
    const [voucher, setVoucher] = useState()
    const [foundLocation, setFoundLocation] = useState([])
    const [voucherAmount, setVoucherAmount] = useState(0) 
    const [totalAfter, setTotalAfter] = useState()
    const [selectedVoucher, setSelectedVoucher] = useState(0)
    const highlightedDates = [];

    const handleCloseAll = () => {
        // setShowCalendar(false)
        setShowNavPopup(false)
        setShowClock(false)
        // setShowLogin(false)
        setShowStyle(false)
    }

    const handleSelectDate = (e) => {
        setSelectedDate(e)
        let isAvail = false
        setIsAvailableDate(false)
        setClockSelected('Select time')
        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ]
        // setDateShown(`${e.getFullYear()}-${formatDate(e.getMonth()+1)}-${formatDate(e.getDate())}`)
        // callApigetTimeBooking(`${e.getFullYear()}-${formatDate(e.getMonth()+1)}-${formatDate(e.getDate())}`, 1, 6)
        // setDateShown(`${formatDate(e.getDate())}-${formatDate(e.getMonth()+1)}-${e.getFullYear()}`)
        setDateShown(`${formatDate(e.getDate())} ${months[e.getMonth()].slice(0, 3)} ${e.getFullYear()}`)
        setSelectedDateToSetBooking(`${formatDate(e.getFullYear())}-${formatDate(e.getMonth()+1)}-${formatDate(e.getDate())}`)
        // setGlobalFormatSelectedDate(`${formatDate(e.getFullYear())}-${formatDate(e.getMonth()+1)}-${e.getDate()}`)

        // for(var x in detail.booking_available){
        //     if(`${formatDate(e.getFullYear())}-${formatDate(e.getMonth()+1)}-${e.getDate()}` === detail.booking_available[x].booking_date){
        //         console.log("Available Date")
        //         setIsAvailableDate(true)
        //     } else {
        //         console.log("Chef is not Available")
        //     }
        // }
        
        for(var k in allMonday){
            // console.log("selected schedule date 1 => "+`${formatDate(allMonday[k].getFullYear())}-${formatDate(allMonday[k].getMonth()+1)}-${allMonday[k].getDate()}`)
            // console.log("selected schedule date 2 => "+`${formatDate(e.getFullYear())}-${formatDate(e.getMonth()+1)}-${e.getDate()}`)
            if(`${formatDate(allMonday[k].getFullYear())}-${formatDate(allMonday[k].getMonth()+1)}-${allMonday[k].getDate()}` === `${formatDate(e.getFullYear())}-${formatDate(e.getMonth()+1)}-${e.getDate()}`){
                console.log("Available Date Selected")
                setIsAvailableDate(true)
                isAvail = true
            } else {
                console.log("Chef is not Available")
            }
        }

        if(isAvail){
            for(let j in availableDateOnlyWorking){
                console.log("selected schedule date fuego => "+e.getDay())
                
                if(e.getDay() === 1 && availableDateOnlyWorking[j].day === "Monday"){
                    setGetListTime(parseAvailableTimeSelected(availableDateOnlyWorking[j].time))
                    break;
                } else if(e.getDay() === 2 && availableDateOnlyWorking[j].day === "Tuesday"){
                    setGetListTime(parseAvailableTimeSelected(availableDateOnlyWorking[j].time))
                    break;
                } else if(e.getDay() === 3 && availableDateOnlyWorking[j].day === "Wednesday"){
                    setGetListTime(parseAvailableTimeSelected(availableDateOnlyWorking[j].time))
                    break;
                } else if(e.getDay() === 4 && availableDateOnlyWorking[j].day === "Thursday"){
                    setGetListTime(parseAvailableTimeSelected(availableDateOnlyWorking[j].time))
                    break;
                } else if(e.getDay() === 5 && availableDateOnlyWorking[j].day === "Friday"){
                    setGetListTime(parseAvailableTimeSelected(availableDateOnlyWorking[j].time))
                    break;
                } else if(e.getDay() === 6 && availableDateOnlyWorking[j].day === "Saturday"){
                    setGetListTime(parseAvailableTimeSelected(availableDateOnlyWorking[j].time))
                    break;
                }else if(e.getDay() === 0 && availableDateOnlyWorking[j].day === "Sunday"){
                    setGetListTime(parseAvailableTimeSelected(availableDateOnlyWorking[j].time))
                    break;
                } else if(availableDateOnlyWorking[j].day === `${formatDate(e.getFullYear())}-${formatDate(e.getMonth()+1)}-${e.getDate()}`){
                    setGetListTime(parseAvailableTimeSelected(availableDateOnlyWorking[j].time))
                    break;
                } else {
                    console.log("selected schedule date chaks => Nothing")
                    setGetListTime([])
                }
            }
        }

        
        console.log("selected schedule date => "+`${formatDate(e.getDate())} ${months[e.getMonth()].slice(0, 3)} ${e.getFullYear()}`)
        console.log("selected schedule date => "+`${formatDate(e.getFullYear())}-${formatDate(e.getMonth()+1)}-${e.getDate()}`)
        // console.log("selected schedule date => "+detail.booking_available[3].booking_date)

        // callApigetTimeBooking(`${formatDate(e.getDate())}-${formatDate(e.getMonth()+1)}-${e.getFullYear()}`, 1, 6)
        setShowCalendar(false)
    }

    const handleClockSelect = (e) => {
        setClockSelected(e)
        var elem = document.getElementById("PSSclockshown");
        elem.className = "dropdown filled"
        setShowClock(false)
    }

    const handleSelectStyle = (e) => {
        setStyle(e.title)
        setStyleId(e.id_serving_style)
        var elem = document.getElementById("PSSstyleshown");
        elem.className = "tiny normal PSSpertanyaantext black"
        setShowStyle(false)
    }

    const formatDate = (data) =>{
        if(data<10){
          return `0${data}`
        }else{
          return data
        }
    }

    const handleGuest = (e) => {
        var elem3 = document.getElementById("PSSGuest");
        var elem = document.getElementById("PSSguestshown");
        elem.className = "tiny normal PSSpertanyaantext black"
        
        if(e === "minus"){
            if(guest === 1){
                return
            }else{
                setGuest(guest-1)
            }
        }else{
            if(guest === detail.pricelist[detail.pricelist.length-1].max){
                return
            }else{
                setGuest(guest+1)
                console.log("ini guest nya => "+(guest+1)+" ini min pax nya => "+detail.pricelist[0].min)
                if((guest+1) === detail.pricelist[0].min){
                    elem3.className = "PSSpertanyaan flex";
                    setval5(false)
                }
                
            }
        }
    }

    useEffect(() =>{
        const { details } = state
        window.scrollTo(0, 0)
        setLoading(true)
        let isBookingChefStillProggress = localStorage.getItem("isBookingChefStillProggress")
        if(isBookingChefStillProggress === "true"){
            // console.log("ini data ada cok")
            setChoosenLocation(localStorage.getItem("location") !== null ? localStorage.getItem("location"):'Pilih Daerah Lokasi Kamu')
            setDateShown(localStorage.getItem("dateShown") !== null ? localStorage.getItem("dateShown"):'Select date')
            setSelectedDateToSetBooking(localStorage.getItem("tanggal") !== null ? localStorage.getItem("tanggal"):'Select date')
            setIsAvailableDate(localStorage.getItem("tanggal") !== null ? true : false)
            setClockSelected(localStorage.getItem("jam") !== null ? localStorage.getItem("jam"):'Select time')
            setDetail(localStorage.getItem("tanggal") !== null ? JSON.parse(localStorage.getItem("detailBooking")):null)
            setTotal(localStorage.getItem("tanggal") !== null ? localStorage.getItem("total"):detail.pricelist[0].amount)
            setTotalAfter(localStorage.getItem("tanggal") !== null ? localStorage.getItem("totalAfter"):detail.pricelist[0].amount)
            setStyle(localStorage.getItem("style") !== null ? localStorage.getItem("style"):'Select serving style')
            setStyleId(localStorage.getItem("styleId") !== null ? localStorage.getItem("styleId"):'')
            setGuest(parseInt(localStorage.getItem("pax")) !== null ? parseInt(localStorage.getItem("pax")):1)
            setVoucher(localStorage.getItem("voucherName") !== null ? localStorage.getItem("voucherName"):undefined)
            setSpecialRequest(localStorage.getItem("specialRequest") !== null ? localStorage.getItem("specialRequest"):'')
            setVoucherAmount(localStorage.getItem("voucherName") !== null ?localStorage.getItem("voucherDisc") !== null ? localStorage.getItem("voucherDisc"):0:0)
        } else {
            console.log("data not found")
        }
        console.log("data image service => "+total)
        console.log("data image service => "+totalAfter)
        console.log("data image service => "+details.service_image_url)
        console.log("data image service => "+JSON.stringify(detail))
        // console.log("ini data ada cok => "+specialRequest)
        const menuDetailPriceFromDBV2 = async (dataPreparationTime) => {
            try {
                const data = parseSetScheduleData(details,dataPreparationTime)
                console.log("Error menuDetail => "+JSON.stringify(data))
                // console.log("List data booking available => "+JSON.stringify(data.booking_available[0].booking_date))
                availableDateFromDB(data.id,dataPreparationTime)
                setDetail(data)
            } catch (error) {
                console.log("error happened")
                console.log(error)
            }
        }
        

        console.log("data detail chef => "+details)
        const locationSearchFromDB = async () => {
            try {
                const data = await callApiLocationSearch("tes")
                // console.log("set location")
                setLocation(data)
            
            } catch (error) {
                console.log("Error locationSearchFromDB")
                console.log(error)
            }
        }

        const availableDateFromDB = async (idChef,dataPreparationTime) => {
            try {
                let availableDates = []
                const data = await callApiAvailableDate(idChef)
                const parsedAvailableDateOnlyWorking = parseAvailableDateOnlyWorking(data)
                
                parsedAvailableDateOnlyWorking.forEach(element => {
                    if(element.day !== null && element.working !== null){
                        // console.log(element.nama)
                        availableDates = [...availableDates,
                            {
                                day: element.day,
                                type_schedule: element.type_schedule,
                                working: element.working,
                                time: parseAvailableTime(element.time),
                            }
                        ]
                    }
                });
                console.log("set available date => "+JSON.stringify(availableDates))
                getAllMondayFromDB(availableDates,dataPreparationTime,data)
                setAvailableDateOnlyWorking(availableDates)
                setAvailableDate(data)
            
            } catch (error) {
                console.log("Error locationSearchFromDB")
                console.log(error)
            }
        }

        const servingStyleFromDB = async () => {
            try {
                const data = await callApiServingStyle()
                // console.log("set location")
                setServingStyle(data)
            
            } catch (error) {
                console.log("Error locationSearchFromDB")
                console.log(error)
            }
        }

        const preparationTimeFromDB = async () => {
            try {
                const data = await callApiPreparationTime(details)
                console.log("set preparation time => "+ JSON.stringify(data))
                // setPreparationTime(data)
                const dataPrice = await menuDetailPriceFromDBV2(data)
                console.log("set data menu => "+ JSON.stringify(dataPrice))
            } catch (error) {
                console.log("Error locationSearchFromDB")
                console.log(error)
            }
        }

        const isHighlightedDay = (date) => {
            console.log("get available date 2 => "+date)
            const day = date.getDay();
            console.log("get available date 3 => " + day)
            return day === 1 || day === 2 || day === 5; // Monday, Tuesday, and Friday
          };

          const getAllMondayFromDB = async (availableDates,dataPreparationTime,dataAvailDate) => {
            try {
                const data = await getMondaysOfYear(availableDates,dataPreparationTime,dataAvailDate)
                // console.log("get available date 4 => " + data)
                setAllMonday(data)
            } catch (error) {
                console.log("Error locationSearchFromDB")
                console.log(error)
            }
        }

        // const menuDetailPriceFromDB = async () => {
        //     try {
        //         const data = await callApigetMenuDetailPrice()
        //         // Create parser before set to state
        //         // setDetail(data)
        //     } catch (error) {
        //         console.log("Error menuDetailPriceFromDB")
        //     }
        // }
        // Turn on to use it
        
        
        isHighlightedDay(new Date('2024-02-19'))
        // console.log("data booking list 5 => "+JSON.stringify(details.booking_available))
        // menuDetailPriceFromDB("finish")
        servingStyleFromDB()
        preparationTimeFromDB()
        // menuDetailPriceFromDBV2()
        locationSearchFromDB()
        // setDetail({chef:"Fauzan Pradana",foto:foto1, jenis:"Asian",fotoMakanan:makanan1,menu:["Beef bulgogi", "Yaki gyoza"],pricelist:[{min:1,max:4,harga:200},{min:5,max:6,harga:150}], jam:["18:00 - 19:00","19:00 - 20:00", "20:00 - 21:00"] })
        localStorage.setItem('TaplakBookingDetail',JSON.stringify(details))
        localStorage.setItem('nav', 'fnd')
    },[])

    const parseBookingAvailable = (booking_available,dataPreparationTime) => {
        const currentDate = new Date();
        const futureDate = new Date(currentDate);
        futureDate.setDate(currentDate.getDate() + dataPreparationTime.preparation_time);

        booking_available = booking_available.map(el => {
            if(new Date(el.booking_date) > futureDate){
                // if(new Date(el.booking_date) > (currentDate + preparationTime)){
                    return {
                        booking_date:el.booking_date
                    }
                // } else {
                //     return {
                //         booking_date:null
                //     }
                // }
                
            } else {
                return {
                    booking_date:null
                }
            }
            
        })

        return booking_available
    }

    const parsePreparationTime = (preparationData) => {
        preparationData = preparationData.map((el) => {
            return {
                preparation_time:el.preparation_time,
                max_pax:el.max_pax,
                min_pax:el.min_pax
            }
        })

        return preparationData
    }

    const parseServingData = (servingDatas) =>{
        servingDatas = servingDatas.map((el)=>{
            return {
                id_serving_style:el.id_serving_style,
                title:el.title,
                deskripsi:el.deskripsi
            }
        })
        return servingDatas
    }

    const callApiPreparationTime = async (detail_order) => {
        try {
            const { data: res } = await axios({
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                url: `${baseUrl}preparation-package/${detail_order.package_menu_id}`,
                // params
            })
            const preparationData = res.data
            setPreparationTime(preparationData)
            // console.log("callApi preparation Data => "+ JSON.stringify(preparationData) )
            // const parsedPreparationData = parsePreparationTime(preparationData)
            // console.log("callApi preparation Data 2 => "+ JSON.stringify(parsedPreparationData) )
            return preparationData
        } catch (error) {
            console.log("error callApiLocationSearch")
            // Create error handler (pop-up or something)
        }
    }

    const callApiServingStyle = async () => {
        try {
            const { data : res} = await axios({
                headers:{
                    'Content-Type': 'application/json'
                },
                method: 'get',
                url: `${baseUrl}servingStyle?terms=`
            })

            console.log("error call api serving style => "+res.data.data)
            console.log("error call api serving style => "+res.data)
            const servingData = res.data

            const parsedServingData = parseServingData(servingData)
            return parsedServingData
        } catch (error) {
            console.log(error)
            console.log("error call api serving style")
        }
    }

    const callApiAvailableDate = async (idChef) => {
        try {
            const { data: res } = await axios({
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'get',
                url: `${baseUrl}chef/dateAvilable/${idChef}`,
                // params
            })
            const availableDateData = res
            console.log("Available Date 1 =>"+JSON.stringify(availableDateData))
            console.log("Available Date 2 =>"+JSON.stringify(res))
            const parsedAvailableDate = parseAvailableDate(availableDateData)
            
            return parsedAvailableDate
        } catch (error) {
            console.log("error callApiLocationSearch")
            // Create error handler (pop-up or something)
        }
    }

    const callApiLocationSearch = async () => {
        try {
            const { data: res } = await axios({
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'get',
                url: `${baseUrl}location?terms=`,
                // params
            })
            const eventData = res.data
            
            const parsedEvent = parseLocationSearch(eventData)
            return parsedEvent
        } catch (error) {
            console.log("error callApiLocationSearch")
            // Create error handler (pop-up or something)
        }
    }

    const parseAvailableDate = (availableDate) => {
        availableDate = availableDate.map((el) => {
            return {
                day: el.day,
                type_schedule: el.type_schedule,
                working: el.working,
                time: parseAvailableTime(el.time),
            }
        })
        return availableDate
    }
    
    const parseAvailableDateOnlyWorking = (availableDate) => {
        availableDate = availableDate.map((el) => {
            if(el.working === true){
                return {
                    day: el.day,
                    type_schedule: el.type_schedule,
                    working: el.working,
                    time: parseAvailableTime(el.time),
                }
            } else {
                return {
                    day: null,
                    type_schedule: null,
                    working: null,
                    time: null,
                }
            }
                
        })
        return availableDate
    }

    const parseAvailableTime = (availableTime) => {
        availableTime = availableTime.map((el) => {
            return {
                start_time: el.start_time,
                end_time: el.end_time,
            }
        })
        return availableTime
    }

    const parseAvailableTimeSelected = (availableTime) => {
        let timeList = []

        const timeLists = [];

        const startTime = new Date();
        
        const endTime = new Date();

        const { min, max } = availableTime.reduce((acc, obj) => {
            return {
              min: obj.start_time < acc.min ? obj.start_time : acc.min,
              max: obj.end_time > acc.max ? obj.end_time : acc.max
            };
          }, { min: availableTime[0].start_time, max: availableTime[0].end_time });

        availableTime = availableTime.map((el) => {
            // console.log("tes substring => "+el.start_time)
            startTime.setHours(min.substring(0,2), min.substring(3,5), min.substring(6,8)); // Set start time to 08:00:00
            endTime.setHours(max.substring(0,2), max.substring(3,5), max.substring(6,8)); // Set end time to 12:00:00
            timeList = [...timeList, `${el.start_time.slice(0, -3)} - ${el.end_time.slice(0, -3)}`]
        })
            // Loop from start time to end time
        for (let time = new Date(startTime); time <= endTime; time.setMinutes(time.getMinutes() + 60)) {
            // Format the time to display as HH:mm
            const formattedTime = time.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12:false});
            // Push the formatted time to the list
            timeLists.push(formattedTime);
            // setTimeList(timeLists)
        }

        

        return timeLists
    }

    const parseLocationSearch = (eventData) => {
        eventData = eventData.map((el) => {
            return {
                id: el.id,
                name: el.name,
                lat: el.lat,
                lng: el.lng,
                country_id: el.country_id,
                timezone_id: el.timezone_id,
                status: el.status,
                created_at: el.created_at,
                updated_at: el.updated_at
            }
        })
        return eventData
    }

    useEffect(() => {
        // console.log("masuk sini kok")
        // console.log(detail)
        if (firstUpdate2.current) {
            firstUpdate2.current = false;
        }else{
            let isBookingChefStillProggress = localStorage.getItem("isBookingChefStillProggress")
            if(isBookingChefStillProggress === "true"){
                console.log("gagals")
            } else {
                setTotal(detail.pricelist[0].amount)
            }
            setLoading(false)
            console.log("selesai")
            console.log(detail)
            
        }
    },[detail])

    useEffect(() => {
        let isBookingChefStillProggress = localStorage.getItem("isBookingChefStillProggress")
        console.log("is booking chef still progress => "+isBookingChefStillProggress)
        if(isBookingChefStillProggress === "true"){
            console.log("gagals")
        } else {
            console.log(total)
            setTotalAfter(total)
            console.log(totalAfter)
            
        }
        
    },[total,totalAfter])

    useEffect(() => {
        const discount = (total*voucherAmount)/100
        // console.log("discount nyas")
        // console.log(discount)
        // console.log(total)
        // console.log(voucherAmount)
        if(voucherAmount === 0){
            console.log("voucher kosong")
            setTotalAfter(total)
        } else {
            setTotalAfter(total-discount)
        }
        // console.log(totalAfter)
    },[()=>handleAddVoucher()])

    useEffect(()=>{
        if (firstUpdate.current) {
            firstUpdate.current = false;
        }else{
            var elem2 = document.getElementById("PSSdatedown");
            elem2.style.transition = "transform 0.5s"
            if(showCalendar===true){
                elem2.style.transform = "rotate(180deg)"
            }else{
                elem2.style.transform = "rotate(0)"
            }
        }
    },[showCalendar])

    useEffect(()=>{
        if (firstUpdate3.current) {
            firstUpdate3.current = false;
        }else{
            var elem2 = document.getElementById("PSSclockdown");
            elem2.style.transition = "transform 0.5s"
            if(showClock===true){
                elem2.style.transform = "rotate(180deg)"
            }else{
                elem2.style.transform = "rotate(0)"
            }
        }
    },[showClock])

    useEffect(()=>{
        if (firstUpdate4.current) {
            firstUpdate4.current = false;
        }else{
            var elem2 = document.getElementById("PSSstyledown");
            elem2.style.transition = "transform 0.5s"
            if(showStyle===true){
                elem2.style.transform = "rotate(180deg)"
            }else{
                elem2.style.transform = "rotate(0)"
            }
        }
    },[showStyle])

    useEffect(()=>{
        if(firstUpdate6.current){
            firstUpdate6.current = false;
        }
        else{
            var elem2 = document.getElementById("HPiconloc");
            elem2.style.transition = "transform 0.5s"
            if(showLocation===true){
                elem2.style.transform = "rotate(180deg)"
            }
            else{
                elem2.style.transform = "rotate(0)"
            }
        }
    },[showLocation])

    const handleOpenPopUp = (e) => {
        setFoundLocation([]);
        let isError=false
        var elem = document.getElementById("PSSdate");
        var elem2 = document.getElementById("PSSLocation");
        if(e === "date"){
            if(choosenLocation === 'Pilih Daerah Lokasi Kamu'){
                elem2.className = "dropdown PSSred shake";
                isError=true
                setval4(true)
            } else {
                // elem2.className = "dropdown";
                setval4(false)
                setShowClock(false)
                setShowStyle(false)
                setShowCalendar(true)
            }
            
        }else if(e === "clock"){
            if(dateShown === 'Select date'){
                elem.className = "dropdown PSSred shake";
                isError=true
                setval1(true)
            }else{
                // elem.className = "dropdown";
                setval1(false)
                setShowClock(true)
                setShowStyle(false)
                setShowCalendar(false)
            }
            
        } else if(e === "location"){
            for (let x in location){
                foundLocation.push(location[x])
            }
            setShownLocation(foundLocation)
            setShowLocation(true)
            setShowCalendar(false)
        }
        else{
            setShowClock(false)
            setShowStyle(true)
            setShowLocation(false)
            setShowCalendar(false)
        }
    }

    const convertToRupiah = (angka) => {
        var rupiah = '';		
        var angkarev = angka.toString().split('').reverse().join('');
        for(var i = 0; i < angkarev.length; i++) if(i%3 === 0) rupiah += angkarev.substr(i,3)+'.';
        return rupiah.split('',rupiah.length-1).reverse().join('');
    }

    useEffect(()=>{
        console.log("data detail booking => "+detail)
        console.log("data detail booking => "+JSON.stringify(detail))
        if (firstUpdate5.current) {
            console.log("first update 5")
            firstUpdate5.current = false;
        }else{
            console.log("Tidak first update 5")
            for (let x in detail.pricelist){
                if(guest <= detail.pricelist[x].max && guest >= detail.pricelist[x].min){
                    setTotal(detail.pricelist[x].amount * guest)
                } 
                // else if(guest < detail.pricelist[x].min){
                //     setTotal(detail.pricelist[0].amount * guest)
                // }
            }
        }
    },[guest])

    useEffect(() => {
        console.log(voucherAmount)
    },[voucherAmount])

    const handleSubmit = () => {
        let isError=false
        var elem = document.getElementById("PSSdate");
        var elem2 = document.getElementById("PSSclock");
        var elem3 = document.getElementById("PSSstyle");
        var elem4 = document.getElementById("PSSLocation");
        var elem5 = document.getElementById("PSSGuest");
        if(dateShown === 'Select date'){
            elem.className = "PSSpertanyaan flex PSSred";
            isError=true
            setval1(true)
        }else{
            elem.className = "PSSpertanyaan flex";
            setval1(false)
        }if(clockSelected === 'Select time'){
            elem2.className = "PSSpertanyaan flex PSSred";
            isError=true
            setval2(true)
        }else{
            elem2.className = "PSSpertanyaan flex";
            setval2(false)
        }if(style==='Select serving style'){
            elem3.className = "PSSpertanyaan flex PSSred";
            isError=true
            setval3(true)
        }else{
            elem3.className = "PSSpertanyaan flex";
            setval3(false)
        }

        if(choosenLocation==='Pilih Daerah Lokasi Kamu'){
            elem4.className = "PSSpertanyaan flex PSSred";
            isError=true
            setval4(true)
        }else{
            elem4.className = "PSSpertanyaan flex";
            setval4(false)
        }

        if(guest < detail.pricelist[0].min){
            elem5.className = "PSSpertanyaan flex PSSred";
            isError=true
            setval5(true)
        } else {
            elem5.className = "PSSpertanyaan flex";
            setval5(false)
        }

        if(isAvailableDate){
          console.log("available date")
          
        } else {
            pushInfoAvailableDate()
            isError=true
        }

        if(isError){
            return
        }else{
            localStorage.setItem('tanggal',selectedDateToSetBooking)
            localStorage.setItem('dateShown',dateShown)
                localStorage.setItem('jam',clockSelected)
                localStorage.setItem('style', style)
                localStorage.setItem('styleId', styleId)
                localStorage.setItem('specialRequest', specialRequest)
                localStorage.setItem('location', choosenLocation)
                localStorage.setItem('pax', guest)
                localStorage.setItem('detailBooking', JSON.stringify(detail))
                localStorage.setItem('total',total)
                localStorage.setItem('totalAfter',totalAfter)
                localStorage.setItem('voucherDisc',selectedVoucher === 0 ? "": selectedVoucher.discount)
                localStorage.setItem('voucherId',selectedVoucher === 0 ? "":selectedVoucher.id)
                localStorage.setItem('voucherName',selectedVoucher === 0 ? "":selectedVoucher.name)
                localStorage.setItem('chefName',detail.chef)
                localStorage.setItem('chefNameDraftFlow',detail.chef)
                localStorage.setItem('chefIdDraftFlow',detail.id)
                localStorage.setItem('chefFoto',detail.foto)
                localStorage.setItem('package_menu_id',detail.package_menu_id)
                localStorage.setItem('chefEquipment',JSON.stringify(detail.equipmentData))
                localStorage.setItem('chefContain',JSON.stringify(detail.containData))
                localStorage.setItem('isBookingChefStillProggress',true)
                localStorage.setItem('chefFotoMakanan',detail.fotoMakanan)
                // localStorage.setItem('userName',response.data.message.user_order.name)
                localStorage.setItem('chefService',detail.jenis)
            callAPIbookingEvent()
            // setstandby(false)
            // localStorage.setItem('tanggal',dateShown)
            // localStorage.setItem('jam',clockSelected)
            // localStorage.setItem('style', style)
            // localStorage.setItem('specialRequest', specialRequest)
            // localStorage.setItem('pax', guest)
            // localStorage.setItem('total',total)
            // localStorage.setItem('chefName',detail.chef)
            // localStorage.setItem('chefService',detail.jenis)
        }
    }

    const callAPIbookingEvent = () => {
        // var axios = require('axios');
        // var FormData = require('form-data');
        // const formDataLogin = new FormData();
        // formDataLogin.append("menu_id", localStorage.getItem("menuId"))
        // formDataLogin.append("date",dateShown)
        // formDataLogin.append("times",clockSelected)
        // formDataLogin.append("guest",guest)
        // formDataLogin.append("amount",totalAfter)
        // formDataLogin.append("voucher_id",selectedVoucher === 0 ? "":selectedVoucher.id)
        // axios({
        //     method: 'POST',
        //     url: baseUrl + 'chef/setBooking',
        //     data : formDataLogin,
        //     headers: {
        //         'Authorization': `Bearer ${localStorage.getItem('taplakToken')}` 
        //     },
        //     withCredentials:false
        // })
        // .then(function (response) {
            
        //     if(response.data.status === true){
        //         console.log("Berhasil")
        //         console.log(response.data.message)
        //         console.log(response.data.booking_id)
        //         localStorage.setItem('bookingId',response.data.message.booking_id)
                // localStorage.setItem('tanggal',dateShown)
                // localStorage.setItem('jam',clockSelected)
                // localStorage.setItem('style', style)
                // localStorage.setItem('specialRequest', specialRequest)
                // localStorage.setItem('pax', guest)
                // localStorage.setItem('total',total)
                // localStorage.setItem('totalAfter',totalAfter)
                // localStorage.setItem('voucherDisc',selectedVoucher === 0 ? "": selectedVoucher.discount)
                // localStorage.setItem('voucherId',selectedVoucher === 0 ? "":selectedVoucher.id)
                // localStorage.setItem('chefName',detail.chef)
                // localStorage.setItem('chefFoto',detail.foto)
                // localStorage.setItem('chefFotoMakanan',detail.fotoMakanan)
                // localStorage.setItem('userName',response.data.message.user_order.name)
                // localStorage.setItem('chefService',detail.jenis)
                setTimeout(function() {
                    setstandby(false)
                }, 500);    
        //     } else {
        //         console.log("Gagal booking event")
        //         alert(response.data.message);
        //     }
        // })
        //     .catch(function (error) {
        //     console.log(error.response.data.message);
        //     alert(error.response.data.message);
        // });
    }

    const [standby, setstandby] = useState(true)
    useEffect(()=>{
        
        if(standby===false){
            setTimeout(function() {
                history('/category/chefList/detailChef/detailMenu/setSchedule/setVenue', { state: {menus: detail.menu}});
           }, 500);
        }
    },[standby])

    const callApigetTimeBooking = async (date,locId,chefId) => {
        // console.log("time  => "+timeBooking)
            // console.log("time  => "+res.data)
        try {
            const { data: res } = await axios({
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'get',
                url: `${baseUrl}time_booking?date=${date}&location_id=${choosenLocationDetail.id}&chef_id=${detail.id}`,
            })
            const timeBooking = res.data
            // console.log("time  => "+timeBooking)
            // console.log("time  => "+res.data)
            const parsedTimeBooking = parseTimeBooking(timeBooking)
            // console.log("time  => "+parsedTimeBooking)
            setGetListTime(parsedTimeBooking)
        } catch (error) {
            console.log("error getMenuDetailPriceFromDB")
            // Create error handler (pop-up or something)
        }
    }

    const parseTimeBooking = (timeBooking) => {
        timeBooking = timeBooking.map((el) => {
            return {
                time: el
            }
        })

        return timeBooking
    }

    // API Calling
    // const callApigetMenuDetailPrice = async (type) => {
    //     try {
    //         let params = null
    //         if (type) {
    //             params = { type }
    //         }
    //         const { data: res } = await axios({
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             },
    //             method: 'get',
    //             url: `${baseUrl}event/anniversary-pik`,
    //             params
    //         })
    //         const menuDetailPrice = res.data
    //         const parsedMenuDetailPrice = parseAllMenuDetailPrice(menuDetailPrice)
    //         return parsedMenuDetailPrice
    //     } catch (error) {
    //         console.log("error getMenuDetailPriceFromDB")
    //         // Create error handler (pop-up or something)
    //     }
    // }

    // Helpers
    const parseSetScheduleData = (setScheduleData,dataPreparationTime) => {
        setScheduleData = {
            id: setScheduleData.id,
            chef: setScheduleData.chef,
            foto: setScheduleData.foto,
            jenis: setScheduleData.nama,
            package_menu_id: setScheduleData.package_menu_id,
            // booking_available:parseBookingAvailable(setScheduleData.booking_available,dataPreparationTime),
            fotoMakanan: setScheduleData.service_image_url.replace(/ /g, '%20'),
            menu: setScheduleData.menu.map(el => el.name),
            voucher:parseVoucher(setScheduleData.voucher),
            pricelist: setScheduleData.prices,
            equipmentData: parseEquipment(setScheduleData.equipmentData !== "N/A" ? setScheduleData.equipmentData:null),
            containData: parseContain(setScheduleData.containData !== "N/A" ? setScheduleData.containData:null),
            jam:["18:00 - 19:00","19:00 - 20:00", "20:00 - 21:00"]

        }

        return setScheduleData
    }

    const parseEquipment = (equipment) => {
        let eqipments = []
        if(!equipment || equipment.length < 1 || equipment === "N/A"){
            return null
        }

        equipment = equipment.map(el => {
            if(el !== null){
                console.log("equipment yang tidak kosong => "+el.deskripsi)
                eqipments.push({
                    equipmentId: el["id_equipment"],
                    deskripsi: el["deskripsi"],
                    icon: el["icon"],
                    file_url: el["file_url"],
                })
            } else {
                console.log("equipment yang kosong => ")
                return {
                    equipmentId: null,
                    deskripsi: null,
                    icon: null,
                    file_url: null
                }
            }
            
        })

        return eqipments
    }

    const parseContain = (contain) => {
        let contains = []
        if(!contain || contain < 1 || contain === "N/A"){
            return "N/A"
        }

        contain = contain.map(el => {
            if(el !== null){
                console.log("equipment yang tidak kosong => "+el.deskripsi)
                contains.push({
                    containId: el["id_dietary"],
                    deskripsi: el["deskripsi"],
                    icon: el["icon"],
                    file_url: el["file_url"]
                })
            } else {
                console.log("equipment yang kosong => ")
                return {
                    containId: null,
                    deskripsi: null,
                    icon: null,
                    file_url: null
                }
            }
            
        })

        return contains
    }

    const parseVoucher = (vouchers) => {
        if(vouchers === "N/A"){
            return "N/A"
        }

        vouchers = vouchers.map(el => {
            return {
                id: el["id"],
                name: el["name"],
                discount: el["discount"],
                quota: el["quota"]
            }
        })

        return vouchers
    }

    const handleSearchLocation = (e) => {
        setFilterLocation(e);
        setFoundLocation([]);
        for (var x in location){
          if (location[x].name.toLowerCase().includes(e.toLowerCase())){
            foundLocation.push(location[x])
          }
        }
        setShownLocation(foundLocation);
    }

    const handleSelectLocation = (e) => {
        var elem2 = document.getElementById("PSSLocation");
        elem2.className = "dropdown filled";
        setval4(false)
        setDateShown('Select date')
        setClockSelected('Select time')
        setChoosenLocation(e.name)
        setChoosenLocationDetail(e)
        setShowLocation(false)
    }

    const handleAddVoucher = (voucherCode,detail) => {
        // console.log("ini code vouchernya")
        // console.log(voucherCode)
        // console.log(detail)

        if(detail.voucher.length !== 0){
            // console.log("ada vouchernya")
            detail.voucher.forEach(element => {
                // console.log(element.name)
                if(voucherCode === element.name){
                    if(element.quota !== 0){
                        setVoucherAmount(detail.voucher[0].discount)
                        const discount = (total*voucherAmount)/100
                        setTotalAfter(total-discount)
                        const detailSelectedVoucher = {
                            id: element.id,
                            name: element.name,
                            discount: element.discount,
                            quota: element.quota
                        }
                        setSelectedVoucher(detailSelectedVoucher)
                    } else {
                        setVoucherAmount(0)
                        setTotalAfter(total)
                        setSelectedVoucher(0)
                    }
                } else {
                    setVoucherAmount(0)
                    setTotalAfter(total)
                    setSelectedVoucher(0)
                }
            });

            // if(voucherCode === )
            // setVoucherAmount(detail.voucher[0].discount)
            // const discount = (total*voucherAmount)/100
            // setTotalAfter(total-discount)
            // console.log("hasil nya")
            // console.log(total)
            // console.log(discount)
            // console.log(voucherAmount)
            // console.log(totalAfter)

        }
    }

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );
    const [animated, setAnimated] = useState(false);
    const [visible, setVisible] = useState(false);

    const [animatedAvailableDate, setAnimatedAvailableDate] = useState(false);
    const [visibleAvailableDate, setVisibleAvailableDate] = useState(false);

    const pushInfoAvailableDate = async () => {
        setVisibleAvailableDate(true);
        setAnimatedAvailableDate(true);
        document.querySelector("body").style.overflow='hidden';
    };

    const closeInfoAvailableDate = async () =>{
        setAnimatedAvailableDate(false);
        await delay(500);
        setVisibleAvailableDate(false);
        document.querySelector("body").style.overflow='auto';
    }

    const pushInfo = async () => {
        setVisible(true);
        setAnimated(true);
        document.querySelector("body").style.overflow='hidden';
    };

    const closeInfo = async () =>{
        setAnimated(false);
        await delay(500);
        setVisible(false);
        document.querySelector("body").style.overflow='auto';
    }

    const isHighlightedDay = (date) => {
        console.log("get available date 2")
        const day = date.getDay();
        console.log("get available date 3")
        return day === 1 || day === 2 || day === 5; // Monday, Tuesday, and Friday
      };
    
      // Function to highlight Mondays, Tuesdays, and Fridays
      const highlightDays = (date) => {
        console.log("get available date 1")
        return isHighlightedDay(date) ? 'highlight-day' : null;
      };

      // Array of dates to highlight (in this case, just one date)
    const highlightedDatess = [new Date('2024-02-19')];

    // Function to check if a date should be highlighted
    const highlightDate = (date) => {
        console.log("get available date 4")
        return highlightedDatess.some((highlightedDate) =>
        new Date(date.toDateString()) === new Date(highlightedDate.toDateString())
        );
    };

     // Array of specific dates to highlight (in string format, e.g., '2023-12-25')
  const highlightedDatesss = ['2024-02-25', '2024-02-31'];

  // Function to highlight specific dates
  const highlightSpecificDates = (date) => {
    console.log("get available date 5")
    const formattedDate = date.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
    return highlightedDatesss.includes(formattedDate) ? 'highlight-day' : null;
  };

  const getMondaysOfYear = async (availableDates,dataPreparationTime,dataAvailDate) => {
    const startDate = new Date(); // January 1st of the given year
    const endDate = new Date(2024, 11, 31); // December 31st of the given year
    const futureDate = new Date(startDate);
    futureDate.setDate(startDate.getDate() + dataPreparationTime.preparation_time);
    let availableDate = [];
    let mondays = [];
    const finalAvailableDate = [];
    // const days = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
    availableDates.forEach(element => {
        if(element.day === "Sunday" ){
            // console.log(element.nama)
            availableDate = [...availableDate,
                {
                    id : 0,
                    day: element.day,
                    type_schedule: element.type_schedule,
                    working: element.working,
                    time: parseAvailableTime(element.time),
                }
            ]
        } else if(element.day === "Monday" ){
            // console.log(element.nama)
            availableDate = [...availableDate,
                {
                    id : 1,
                    day: element.day,
                    type_schedule: element.type_schedule,
                    working: element.working,
                    time: parseAvailableTime(element.time),
                }
            ]
        } else if(element.day === "Tuesday" ){
            // console.log(element.nama)
            availableDate = [...availableDate,
                {
                    id : 2,
                    day: element.day,
                    type_schedule: element.type_schedule,
                    working: element.working,
                    time: parseAvailableTime(element.time),
                }
            ]
        } else if(element.day === "Wednesday" ){
            // console.log(element.nama)
            availableDate = [...availableDate,
                {
                    id : 3,
                    day: element.day,
                    type_schedule: element.type_schedule,
                    working: element.working,
                    time: parseAvailableTime(element.time),
                }
            ]
        } else if(element.day === "Thursday" ){
            // console.log(element.nama)
            availableDate = [...availableDate,
                {
                    id : 4,
                    day: element.day,
                    type_schedule: element.type_schedule,
                    working: element.working,
                    time: parseAvailableTime(element.time),
                }
            ]
        }else if(element.day === "Friday" ){
            // console.log(element.nama)
            availableDate = [...availableDate,
                {
                    id : 5,
                    day: element.day,
                    type_schedule: element.type_schedule,
                    working: element.working,
                    time: parseAvailableTime(element.time),
                }
            ]
        } else {
            availableDate = [...availableDate,
                {
                    id : 6,
                    day: element.day,
                    type_schedule: element.type_schedule,
                    working: element.working,
                    time: parseAvailableTime(element.time),
                }
            ]
        }
    });
    let currentDate = startDate;

    console.log("aval date => "+JSON.stringify(availableDate))
    
    while (currentDate <= endDate) {
        for(let x in availableDate){
            if(new Date(currentDate) > futureDate){
                if (currentDate.getDay() === availableDate[x].id) { // Monday has index 1 in JavaScript
                    mondays.push(new Date(`${formatDate(currentDate.getFullYear())}-${formatDate(currentDate.getMonth()+1)}-${currentDate.getDate()}`));
                }
            }
            
        }
      currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
    }

    dataAvailDate.forEach(element => {
        if(element.type_schedule === "day_off"){
            if(element.working === false){
                for(let k in mondays){
                    if(mondays[k]){

                    
                    console.log("day off => -"+element.day)
                    console.log("day available => -"+`${formatDate(mondays[k].getFullYear())}-${formatDate(mondays[k].getMonth()+1)}-${mondays[k].getDate()}`)
                    
                        // console.log(element.nama)
                        if(element.day === `${formatDate(mondays[k].getFullYear())}-${formatDate(mondays[k].getMonth()+1)}-${mondays[k].getDate()}`){
                            const filteredArray = mondays.filter(date => !isSameDate(date,new Date(element.day)));
                            mondays = filteredArray;
                            console.log("ada tanggal yang sama dari day off => ")
                            console.log("ada tanggal yang sama dari day off => "+filteredArray)
                        }
                    }
                }
            } else {
                for(let k in mondays){
                    if(mondays[k]){
                    console.log("day off => -"+element.day)
                    console.log("day available => -"+`${formatDate(mondays[k].getFullYear())}-${formatDate(mondays[k].getMonth()+1)}-${mondays[k].getDate()}`)
                    
                        // console.log(element.nama)
                        if(element.day !== `${formatDate(mondays[k].getFullYear())}-${formatDate(mondays[k].getMonth()+1)}-${mondays[k].getDate()}`){
                            console.log("ada tanggal yang sama ")
                            mondays.push(new Date(element.day));
                        }
                    }
                }
            }
        }
        
    });

    dataAvailDate.forEach(element => {
        if(element.type_schedule === "recuring_schedule"){
            if(element.working === false){
                for(let k in mondays){
                    if(mondays[k]){

                    
                    console.log("day off => -"+element.day)
                    console.log("day available => -"+`${formatDate(mondays[k].getFullYear())}-${formatDate(mondays[k].getMonth()+1)}-${mondays[k].getDate()}`)
                    
                        // console.log(element.nama)
                        if(element.day === `${formatDate(mondays[k].getFullYear())}-${formatDate(mondays[k].getMonth()+1)}-${mondays[k].getDate()}`){
                            console.log("ada tanggal yang sama dari day off => "+element.day)
                        }
                    }
                }
            } else {
                for(let k in mondays){
                    if(mondays[k]){
                    console.log("day off => -"+element.day)
                    console.log("day available => -"+`${formatDate(mondays[k].getFullYear())}-${formatDate(mondays[k].getMonth()+1)}-${mondays[k].getDate()}`)
                    
                        // console.log(element.nama)
                        if(element.day !== `${formatDate(mondays[k].getFullYear())}-${formatDate(mondays[k].getMonth()+1)}-${mondays[k].getDate()}`){
                            console.log("ada tanggal yang sama ")
                            mondays.push(new Date(element.day));
                        }
                    }
                }
            }
        }
        
    });

    // for(let n in mondays){

    // }
    

    return mondays;
  };

  function isSameDate(date1, date2) {
      console.log("Dates 1 => "+date1.getDate()+" "+(date1.getMonth()+1)+" "+date1.getFullYear())
      console.log("Dates 2 => "+date2.getDate()+" "+(date2.getMonth()+1)+" "+date2.getFullYear())
    return date1.getDate() === date2.getDate() &&
            date1.getMonth()+1 === date2.getMonth()+1 &&
           date1.getFullYear() === date2.getFullYear();
  }

    // const parseAllMenuDetailPrice = (menuDetailPrice) => {
    //     menuDetailPrice = menuDetailPrice.map((el) => {
    //         return {
    //             chef_image_url: el.chef_image_url,
    //             chef_name: el.chef_name,
    //             image_url: el.image_url,
    //             name: el.name,
    //             menu_names: el.menu_names,
    //             contains: el.contains,
    //             pricings:el.pricings
    //         }
    //     })
    //     return menuDetailPrice[0]
    // }

    return (
        <div onClick={e => {e.stopPropagation();handleCloseAll()}} id="maincontainer" style={{minWidth:"100%", minHeight:"100vh", backgroundColor:"#111111", position:"relative", overflow:"unset"}}>
            <DesktopNavbar showPopup={showNavPopup} setShowPopup={setShowNavPopup}></DesktopNavbar>
            <FloatingWhatsAppCustom></FloatingWhatsAppCustom>
            <div className='detailMenuNavMobile'>
                <img src={circleback} draggable="false" className='pointer' onClick={e => {e.preventDefault();e.stopPropagation();history(-1)}}></img>
            </div>
            {
                loading?
                <img alt='loading' className='taplakLoading' src={spinner}></img>
                :
                <CSSTransition in={standby} timeout={{appear:1000,enter:2000,exit:1000}} mountOnEnter={true} appear={true} classNames="PSStrans">
                <div>
                    {
                        visible ?
                        <div className={`informasiWrapper ${animated ? 'informasiIn' : 'informasiOut'}`}>
                            <div className='informasiHead'>
                                <i class='bx bx-info-circle'></i>
                                <h1>Information</h1>
                            </div>
                            <div className='informasiBody'>
                                <p><span>Serving Style : </span>How the talent will serve their menu. It can be multiple courses, family style, buffet and more.</p>
                            </div>
                            <div className='informasiFooter'>
                                <button onClick={closeInfo}>Close</button>
                            </div>
                        </div>
                        :
                        null
                    }

                    {
                        visibleAvailableDate ?
                        <div className={`informasiWrapper ${animatedAvailableDate ? 'informasiIn' : 'informasiOut'}`}>
                            <div className='informasiHead'>
                                <i class='bx bx-info-circle'></i>
                                <h1>Information</h1>
                            </div>
                            <div className='informasiBody'>
                                <p><span> Chef is not available on the selected date.</span></p>
                            </div>
                            <div className='informasiFooter'>
                                <button onClick={closeInfoAvailableDate}>Close</button>
                            </div>
                        </div>
                        :
                        null
                    }
                    
                    <div className={`backdrop ${visible ||visibleAvailableDate ? 'block' : 'hidden'}`} onClick={closeInfo}></div>
                    <div className='container separating plan'>
                        <div className='left__parts'>
                            <p className='big bold white'>Set Schedule</p>
                            <p className='semibig white PSSmargin'>Your Chef</p>
                            <div className='flex' style={{marginTop:"18px"}}>
                                <div className='PSSimg' style={{backgroundImage:`url(${detail.foto})`}}></div>
                                <p className='PSSimg2 normal medium white'>{detail.chef}</p>
                            </div>

                            <p className="semibig white PSSmargin">Your Menu</p>
                            <div className='flex flex_wrap' style={{marginTop:"18px"}}>
                                <div className='PSSimg' style={{backgroundImage:`url(${detail.fotoMakanan})`}}></div>
                                <div className='PSSimg2 flex' style={{flexDirection:"column", marginTop:"18px"}}>
                                    <p className='bold white'>{detail.jenis} Food</p>
                                    <p className='small grey-text medium' style={{marginTop:"8px"}}>
                                        {
                                            detail.menu.map((post) => {
                                                if(post === detail.menu[0]){
                                                    return(<span>{post}</span>)

                                                }else{
                                                    return(<span>, {post}</span>)
                                                }
                                            })
                                        }
                                    </p>
                                </div>
                            </div>

                            <p className="semibig white PSSmargin">Special Request</p>
                            <p className="tiny" style={{color:"#AAAAAA", marginTop:"18px"}}>*Any requests made will be reviewed by the Taplak Partner before confirming the event</p>
                            <textarea onClick={e => {e.preventDefault();e.stopPropagation();handleCloseAll()}} value={specialRequest} onChange={e => setSpecialRequest(e.target.value)} className='PSStextarea' rows="10" placeholder="*Any requests made will be reviewed by the Taplak Partner before confirming the event"></textarea>
                        </div>
                        <div className='right__parts'>
                            <div className='containerWrap'>
                                <div className='inputGroup'>
                                    <div className='headerGroup'>                                    
                                        <h1>Your Booking</h1>
                                    </div>
                                    {
                                        val4 && (
                                            <div className='alert alert-fail'>
                                                <i className='bx bx-info-circle'></i>
                                                <p>Please select the Location!</p>
                                            </div>
                                        )
                                    }
                                    {
                                        val1 && (
                                            <div className='alert alert-fail'>
                                                <i className='bx bx-info-circle'></i>
                                                <p>Please select the date!</p>
                                            </div>
                                        )
                                    }
                                    {
                                        val2 && (
                                            <div className='alert alert-fail'>
                                                <i className='bx bx-info-circle'></i>
                                                <p>Please select the time!</p>
                                            </div>
                                        )
                                    }
                                    {
                                        val3 && (
                                            <div className='alert alert-fail'>
                                                <i className='bx bx-info-circle'></i>
                                                <p>Please select the style!</p>
                                            </div>
                                        )
                                    }

                                    <div className="inputText">
                                        <h4>Location</h4>
                                        <div id="PSSLocation" onClick={e => {e.preventDefault();e.stopPropagation();showLocation? setShowLocation(false):handleOpenPopUp('location')}} className="dropdown">
                                            <div className='text'>
                                                <i className='bx bx-map-pin'></i>
                                                <p id="homeloc">{choosenLocation}</p>
                                            </div>
                                            <img id="HPiconloc" src={down} alt="icon"></img>
                                            {
                                                showLocation === true && (
                                                    <div className="dropdown_drop">
                                                            <div onClick={e => {e.preventDefault();e.stopPropagation();}} className="dropdown_search">
                                                                <i className='bx bx-search-alt'></i>
                                                                <input value={filterLocation} onChange={e => handleSearchLocation(e.target.value)} type='text' placeholder='Search Location'/>
                                                            </div>
                                                            <div className='border-top'>
                                                            {
                                                                shownLocation.map((post) => (
                                                                    <div className='location_pick'><p onClick={e => {e.preventDefault();e.stopPropagation();handleSelectLocation(post)}}>{post.name}</p></div>
                                                                ))
                                                            }
                                                            </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>

                                    <div className='inputFlex'>
                                        <div className='inputText'>
                                            <h4>Date</h4>
                                            <div onClick={e => {e.preventDefault();e.stopPropagation();showCalendar? setShowCalendar(false):handleOpenPopUp("date")}} id="PSSdate" className='dropdown'>
                                                <div className='text'>
                                                    <i className='bx bx-calendar-week'></i>
                                                    <p id="PSSdateshown">{dateShown}</p>
                                                </div>
                                                <img id="PSSdatedown" alt='icon' src={down}></img>
                                                {
                                                    showCalendar === true && (
                                                        <div className="dropdown_drop">
                                                            <DatePicker
                                                                id="dateStartEnd"
                                                                dateFormat="yyyy/mm/dd"
                                                                // className={'form-control form-control-sm'}
                                                                calendarClassName="custom-calendar"
                                                                autoFocus={true}
                                                                shouldCloseOnSelect={false}
                                                                selected={selectedDate}
                                                                onChange={(date) => handleSelectDate(date)}
                                                                monthsShown={1}
                                                                // highlightDates={detail.booking_available.map(item => new Date(item.booking_date))}
                                                                // highlightDates={highlightedDatess}
                                                                highlightDates={allMonday}
                                                                inline
                                                                minDate={new Date()}

                                                                style={{width:"100%"}}
                                                            />
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className='inputText'>
                                            <h4>Time</h4>
                                            <div onClick={e => {e.preventDefault();e.stopPropagation();showClock? setShowClock(false):handleOpenPopUp("clock")}} id="PSSclock" className='dropdown'>
                                                <div className='text'>
                                                    <i className='bx bx-time-five'></i>
                                                    <p id="PSSclockshown">{clockSelected}</p>
                                                </div>
                                                <img id="PSSclockdown" alt='icon' src={down}></img>
                                                {
                                                    showClock === true && (
                                                        <div className="dropdown_drop">
                                                            {
                                                                isAvailableDate ? getListTime !== undefined ? getListTime.length !== 0?
                                                                getListTime.map((post) => (
                                                                    <p onClick={e => {e.preventDefault();e.stopPropagation();handleClockSelect(post)}}>{post}</p>
                                                                )) : <p className='small normal'>Data Not Found</p>
                                                                :<div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>: <p className='small normal'>Data Not Found</p>
                                                            }
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className='PSSformgroup'>
                                        <div className='PSSpertanyaanflex'>
                                            <div className='infoPush'>
                                                <p className='small normal PSSpertanyaantext'>Serving style</p>
                                                <div onClick={e => pushInfo()} className='button'>
                                                    i
                                                </div>
                                            </div>
                                            <div onClick={e => {e.preventDefault();e.stopPropagation();showStyle? setShowStyle(false):handleOpenPopUp("style")}} id="PSSstyle" className='PSSpertanyaan flex'>
                                                <img className='PSSiconkiri' alt='icon' src={user}></img>
                                                <p id="PSSstyleshown" className='verytiny normal PSSpertanyaantext'>{style}</p>
                                                <img id="PSSstyledown" className='PSSiconkanan' alt='icon' src={down}></img>
                                            </div>
                                        </div>
                                        {
                                            showStyle === true && (
                                                <div className="PSSpopupkontainer" style={{right:"0", width:"100%", marginTop:"10px"}}>
                                                    <div style={{postion:"relative", height:"100%", width:"auto", padding:"25px"}}>
                                                        {
                                                            servingStyle.map((post) =>(
                                                                <div onClick={e => {e.preventDefault();e.stopPropagation();handleSelectStyle(post)}} style={{marginBottom:"24px"}}>
                                                                    <p className='tiny bold' style={{marginBottom:"8px"}}>{post.title}</p>
                                                                    <p className='verytiny normal'>{post.deskripsi}</p>
                                                                </div>
                                                            ))
                                                        }
                                                        {/* <div onClick={e => {e.preventDefault();e.stopPropagation();handleSelectStyle('Individual Plating')}} style={{marginBottom:"24px"}}>
                                                            <p className='tiny bold' style={{marginBottom:"8px"}}>Individual Plating</p>
                                                            <p className='verytiny normal'>Plating individual dish to every person</p>
                                                        </div>
                                                        <div onClick={e => {e.preventDefault();e.stopPropagation();handleSelectStyle('Family-Style')}} style={{marginBottom:"24px"}}>
                                                            <p className='tiny bold' style={{marginBottom:"8px"}}>Family-Style</p>
                                                            <p className='verytiny normal'>Dish is served on large platters meant for sharing, reather as individual plates, and being placed on the center of the table</p>
                                                        </div>
                                                        <div onClick={e => {e.preventDefault();e.stopPropagation();handleSelectStyle('Standart Buffet Style')}}>
                                                            <p className='tiny bold' style={{marginBottom:"8px"}}>Standart Buffet Style</p>
                                                            <p className='verytiny normal'>A self-service style where Penyantap serve themselves</p>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>

                                    <div className='PSSformgroup'>
                                        <div className='PSSpertanyaanflex'>
                                            <p className='small normal PSSpertanyaantext'>Guest</p>
                                            <div className='PSSpertanyaan flex' id='PSSGuest'>
                                                <img className='PSSiconkiri' alt='icon' src={user}></img>
                                                <p id="PSSguestshown" className='verytiny normal PSSpertanyaantext'>{guest} guest</p>
                                                <img onClick={e => {e.preventDefault();e.stopPropagation();handleCloseAll();handleGuest("minus")}} className='PSSiconkanan' alt='icon' src={minus}></img>
                                                <img onClick={e => {e.preventDefault();e.stopPropagation();handleCloseAll();handleGuest("plus")}} className='PSSiconkanan' alt='icon' style={{marginLeft:"24px"}} src={plus}></img>
                                            </div>
                                            {
                                            val5 && (
                                                <p className='bold verytiny' style={{color:"#FA591D", marginTop:"8px", marginBottom:"8px", textAlign:"right"}}>Minimum pax is {detail.pricelist[0].min}</p>
                                            )
                                        }
                                        </div>
                                    </div>

                                    <p className='small SBPmargin grey' style={{marginTop:"26px"}}>Voucher Code</p>
                                    <div className='SBPmargin2 flex'>
                                        <input type='text' value={voucher} onChange={e => setVoucher(e.target.value)} placeholder='Enter voucher code' className='SBPform'></input>
                                        {/* <button onClick={() => handleAddVoucher(voucher,detail)}className='SBPbutton tiny bold'>Apply</button> */}
                                        <button onClick={() => handleAddVoucher(voucher,detail)}className='SBPbutton tiny bold'>Apply</button>
                                    </div>

                                    <p className='PSSpertanyaantext tiny normal' style={{marginTop:"16px"}}>Set menu price(s)</p>
                                    
                                        {
                                            detail.pricelist.map((post) => (
                                                <div className='flex' style={{marginTop:"8px"}}>
                                                    <p className='PSSpertanyaantext tiny bold'>{post.min} - {post.max} guest</p>
                                                    <p className='PSSpertanyaantext tiny bold' style={{marginLeft:"auto"}}>{toRupiah(post.amount, {formal: false, symbol: 'IDR'}).slice(0, -3)}/Pax</p>
                                                </div>
                                            ))
                                        }
                                    <div className='flex' style={{marginTop:"32px"}}>
                                        <p className='small'>Price</p>
                                        <p className='small' style={{marginLeft:"auto"}}>{toRupiah(total,{formal: false, symbol: 'IDR'}).slice(0, -3)}</p>
                                    </div>

                                    <div className='flex' style={{marginTop:"4px"}}>
                                        <p className='small'>Voucher Amount</p>
                                        <p className='small' style={{marginLeft:"auto"}}>{voucherAmount === 0 ? "-":voucherAmount+"%"}</p>
                                    </div>

                                    <div className='flex' style={{marginTop:"32px"}}>
                                        <p className='bold medium'>Total</p>
                                        {/* <p className='small' style={{marginLeft:"auto"}}>IDR {convertToRupiah(total)}</p> */}
                                        <p className='bold medium' style={{marginLeft:"auto"}}>{toRupiah(totalAfter,{formal: false, symbol: 'IDR'}).slice(0, -3)}</p>
                                    </div>
                                    
                                </div>
                                {
                                    localStorage.getItem('TaplakLoggedIn')==='true' ?

                                <div className='containerWrap' style={{marginTop:"32px"}}>
                                    <p className='medium bold'>Set Venue</p>
                                    <button onClick={e => {e.preventDefault();e.stopPropagation();handleSubmit()}}  className='PSSbutton bold'>Continue to set venue</button>
                                </div>
                                :
                                <div className='containerWrap' style={{marginTop:"32px"}}>
                                    <p className='medium bold'>Login or register to book</p>
                                    <button onClick={e => {e.preventDefault();e.stopPropagation();setShowLogin(true)}} className='PSSbutton bold'>Login/Register</button>
                                </div>
                                }
                            </div>  
                        </div>  
                    </div>
                </div>
                </CSSTransition>
            }
            {
                showLogin && (
                <PageLogin fungsi={() => setShowLogin(false)}></PageLogin>
                )
            }
            
        </div>
    )
  }