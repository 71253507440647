/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import './index.css'
import { DesktopNavbar } from '../NavBar/Desktop/DesktopNavbar'
import {useState, useEffect} from 'react'
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { MobileNavbar } from '../NavBar/Mobile/MobileNavbar';
import { PageLogin } from '../LoginPage/PageLogin'
import notFoundBg from '../../assets/notfoundbg.jpg'

export const PageNotFound = () => {
    const [showNavPopup, setShowNavPopup] = useState(false)
    const [showLogin, setShowLogin] = useState(false)
    
    const handleCloseAll = () => {
        setShowNavPopup(false)
    }

    useEffect(() =>{
        window.scrollTo(0, 0)
        localStorage.setItem('nav', 'x')
    },[])


    const [standby, setstandby] = useState(true)


    const history = useNavigate()



    useEffect(()=>{
        
        if(standby===false){
            setTimeout(function() {
                history('/')
           }, 500);
        }
    },[standby])

    
    return (
        <div onClick={e => {e.stopPropagation();handleCloseAll()}} id="maincontainer" style={{minWidth:"100%", minHeight:"100vh", backgroundColor:"#111111", position:"relative", overflow:"unset"}}>
            <div className='PNPbg' style={{backgroundImage:`url(${notFoundBg})`}}></div>
            <DesktopNavbar showPopup={showNavPopup} setShowPopup={setShowNavPopup}></DesktopNavbar>
            <MobileNavbar></MobileNavbar>
            <CSSTransition in={standby} timeout={{appear:1000,enter:2000,exit:1000}} mountOnEnter={true} appear={true} classNames="MBPtrans">
            <div className='PNPkontainer'>
                <div className='PNPkonten'>
                    <p className='bold PNP404'>404</p>
                    <p className='big bold white'>We're Sorry</p>
                    <p className='white semibig PNPmargin'>We couldn't find the page you are looking for</p>
                    <button onClick={()=>setstandby(false)} className='bold small PUSmargin PFPbutton'>Go Back Home</button>
                </div>
            </div>
            </CSSTransition>

            {
                showLogin && (
                <PageLogin fungsi={() => setShowLogin(false)}></PageLogin>
                )
            }
        </div>
    )
  }