import more from '../../../assets/more.svg'
import more2 from '../../../assets/more-vertical.svg'
import calendar from '../../../assets/calendar.svg'
import location from '../../../assets/pin.svg'
import toRupiah from '@develoka/angka-rupiah-js';
import React from 'react';
import PaidStamp from "../../../assets/paid.png";

export const MBPcard = ({number, post, fungsi, fungsi2, fungsi3, handlePayment, showOpsi, setShowOpsi, section}) => {

    // const convertToRupiah = (angka) => {
    //     let rupiah = '';		
    //     let angkarev = angka.toString().split('').reverse().join('');
    //     for(let i = 0; i < angkarev.length; i++) if(i%3 === 0) rupiah += angkarev.substr(i,3)+'.';
    //     return rupiah.split('',rupiah.length-1).reverse().join('');
    // }

    return (
        <div className='MBPkonten flex'>
            <div className={`MBPStamp ${post.done==="approved" && post.status === "completed" ? '' : 'hidden'}`}>
                <img src={PaidStamp}></img>
            </div>
            <div className='MBPkontenkiri'>
                <div className='flex' style={{alignItems:"center", position:"relative"}}>
                    <p className='small'>Booking Code: <span className='bold'>{post.bookingCode}</span></p>
                    {
                        post.status==="In Review" && (
                            <p className='MBPstatus1 tiny bold'>{post.status}</p>
                        )
                    }
                    {/* tambahan baru */}
                    {
                        post.status==="PENDING" && (
                            <p className='MBPstatus1 tiny bold'>{post.status}</p>
                        )
                    }
                    {
                        (post.status==="Approve" || post.status==="Success") && (
                            <p className='MBPstatus2 tiny bold'>{post.status}</p>
                        )
                    }

                    {
                        post.status==="Rejected" && (
                            <p className='MBPstatus3 tiny bold'>{post.status}</p>
                        )
                    }
                    <img onClick={e => {e.preventDefault();e.stopPropagation();;showOpsi===number?setShowOpsi():setShowOpsi(number)}} alt='icon' className='MBPmoremobile' src={more2}></img>
                    {showOpsi===number && (
                        <div className="MBPpopup1 mobile">
                            <p className="tiny" disabled style={{marginBottom:"16px",color: "#808080"}}>QR For Chef</p>
                            <p className="tiny" disabled style={{color: "#808080"}}>Ask for Help</p>
                            {/* <p className="tiny" onClick={e => {e.preventDefault();e.stopPropagation();fungsi([post.namaChef,post.qr])}} style={{marginBottom:"16px",color: "#808080"}}>QR For Chef</p>
                            <p className="tiny" onClick={e => {e.preventDefault();e.stopPropagation();fungsi2(post.bookingCode)}} style={{color: "#808080"}}>Ask for Help</p> */}
                        </div>
                    )}
                </div>
                <p className='small MBPmargin1'><span className='bold' style={{marginRight:"24px"}}>{post.nama}</span>{post.prefix} {post.phoneNumber}</p>
                <div className='flex MBPmargin2'>
                <img 
                                    src={`${post.foto}`}
                                    alt="new"
                                    className="MBPimage"
                                />
                    {/* <div className='MBPimage' style={{backgroundImage:`url(${post.foto})`}}/> */}
                    <div>
                        <p className='small'>{post.namaChef}</p>
                        {/* <p className='small' style={{marginTop:"4px"}}>{post.Type} Food</p> */}
                        {/* <p className='small' style={{marginTop:"4px"}}>{post.Type }</p> */}
                        <p className='small' style={{marginTop:"4px"}}>{React.createElement("div", { dangerouslySetInnerHTML: { __html: post.Type } })}</p>
                    </div>
                </div>
                <div className='flex MBPmargin2'>
                    <img style={{marginRight:"16px"}} alt="icon" className='MBPicon' src={location} />
                    <p className='small'>{post.address}</p>
                </div>
                <div className='flex MBPmargin2' style={{alignItems:"center"}}>
                    <img style={{marginRight:"16px"}} alt="icon" className='MBPicon' src={calendar} />
                    <p className='small'>{post.tanggal}</p>
                </div>
            </div>
            <div className='MBPkontenkanan'>
                <div className='MBPmobileline' />
                {
                    section === 0 ?
                    <div className='MBPkananwrapper'>
                    <div className='MBPharga flex' style={{marginBottom:"18px"}}>
                        <p className='small'>Total</p>
                        {/* <p className='small bold' style={{marginLeft:"auto"}}>IDR {convertToRupiah(post.total)}</p> */}
                        <p className='small bold' style={{marginLeft:"auto"}}>{toRupiah(post.total, {formal: false, symbol: 'IDR'}).slice(0, -3)}</p>
                    </div>
                    {
                        post.status==="In Review" && (
                            <button disabled className='MBPbutton2 small bold'>Proceed to Payment</button>  
                        )
                    }
                    {/* tambahan baru */}
                    {
                        post.status==="PENDING" && (
                            // <button disabled className='MBPbutton2 small bold'>Proceed to Payment</button>  
                            <button onClick={e => {e.preventDefault();e.stopPropagation();handlePayment(post.paymentLink)}} className='MBPbutton1 small bold'>Proceed to Payment</button>  
                        )
                    }
                    {
                        post.status==="Approve" && (
                            <button onClick={e => {e.preventDefault();e.stopPropagation();handlePayment(post.paymentLink)}} className='MBPbutton1 small bold'>Proceed to Payment</button>  
                        )
                    }

                    {
                        (post.status==="Success" && post.done === true ) && (
                            <button onClick={e => {e.preventDefault();e.stopPropagation();fungsi3(post.bookingCode)}} className='MBPbutton1 small bold'>Rate Us</button>  
                        )
                    }

                    
                </div>
                :
                <div className='MBPkananwrapper'>
                    <div className='MBPharga flex' style={{marginBottom:"18px"}}>
                        <p className='small'>Total</p>
                        {/* <p className='small bold' style={{marginLeft:"auto"}}>IDR {convertToRupiah(post.total)}</p> */}
                        <p className='small bold' style={{marginLeft:"auto"}}>{toRupiah(post.total, {formal: false, symbol: 'IDR'}).slice(0, -3)}</p>
                    </div>

                     {/* <button onClick={e => {e.preventDefault();e.stopPropagation();fungsi3(post.bookingCode)}} className='MBPbutton1 small bold'>Rate Us</button>   */}
                     <button className='MBPbutton1 small bold'>Rate Us</button>  
                    
                    
                </div>
                }
                
                <div onClick={e => {e.preventDefault();e.stopPropagation();;showOpsi===number?setShowOpsi():setShowOpsi(number);}} className='MBPbuttonmore'>
                    <div style={{position:"relative", height:"100%", width:"100%"}}>
                        <img alt='icon' className='MBPiconbuttonmore' src={more}></img>
                        {showOpsi===number && (
                            <div className="MBPpopup1 desktop">
                                <p className="tiny" disabled style={{marginBottom:"16px",color: "#808080"}}>QR For Chef</p>
                                <p className="tiny" disabled style={{color: "#808080"}}>Ask for Help</p>
                                {/* <p className="tiny" onClick={e => {e.preventDefault();e.stopPropagation();fungsi([post.namaChef,post.qr])}} style={{marginBottom:"16px",color: "#808080"}}>QR For Chef</p>
                                <p className="tiny" onClick={e => {e.preventDefault();e.stopPropagation();fungsi2(post.bookingCode)}} style={{color: "#808080"}}>Ask for Help</p> */}
                            </div>
                        )}
                        
                    </div>
                </div>
            </div>
        </div>
    )
  }