/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import './index.css'
import { DesktopNavbar } from '../NavBar/Desktop/DesktopNavbar'
import {useState, useEffect, useRef} from 'react'
import "react-datepicker/dist/react-datepicker.css";
import beef from '../../assets/beef.svg'
// import makanan1 from '../../assets/makanan1.png'
import calendar from '../../assets/calendar-1.svg'
import { useLocation, useNavigate } from "react-router-dom";
import pork from '../../assets/pork.svg'
import time from '../../assets/time2.svg'
import stove from '../../assets/stove.svg'
import microwave from '../../assets/microwave.svg'
import circleback from '../../assets/circleback.svg'
import oven from '../../assets/oven.svg'
import location from '../../assets/pin-1.svg'
// import foto1 from '../../assets/foto4.png'
import { CSSTransition } from 'react-transition-group';
import spinner from '../../assets/spinner.gif'
import { baseUrl } from '../../lib/api';
import toRupiah from '@develoka/angka-rupiah-js';
import { FloatingWhatsAppCustom } from '../FloatingWhatsAppCustom/FloatingWhatsAppCustom';

export const PageSubmitBooking = () => {
    const { state } = useLocation()
    const [detail, setDetail] = useState()
    const [loading, setLoading] = useState(true)
    const [image1, setImage1] = useState()
    const [image2, setImage2] = useState()
    const [image3, setImage3] = useState()
    // const [total, setTotal] = useState()
    const firstUpdate = useRef(true)
    const [showNavPopup, setShowNavPopup] = useState(false)

    const handleCloseAll = () => {
        setShowNavPopup(false)
    }
    useEffect(() =>{
        const { menus,image1,image2,image3 } = state
        window.scrollTo(0, 0)
        // console.log("ini adwd"+localStorage.getItem("addressDetail"))
        // console.log("ini adwd"+localStorage.getItem("isAddressChoosen"))
        // console.log("ini adwd chuaks => "+JSON.parse(localStorage.getItem("chefEquipment")))
        const storedDataEquipment = localStorage.getItem('chefEquipment');
        const storedContain = localStorage.getItem('chefContain');
        setImage1(image1)
        setImage2(image2)
        setImage3(image3)
        // Convert the JSON string back to an object
        const parsedDataEquipment = JSON.parse(storedDataEquipment);
        const parsedDataContain = JSON.parse(storedContain);
        

        console.log("ini adwd chuaks => "+parsedDataEquipment)
        console.log("ini adwd chuaks => "+parsedDataContain)


        setLoading(true)
        setDetail({nama:localStorage.getItem("chefName"), foto:localStorage.getItem("chefFoto"),Type:localStorage.getItem("chefService"),Menu:menus,bahan:parsedDataContain === "N/A" ? null:parsedDataContain,alat:parsedDataEquipment === "N/A" ? null: parsedDataEquipment,fotoMakanan:localStorage.getItem("chefFotoMakanan"),tanggal:localStorage.getItem("dateShown"), jam:localStorage.getItem("jam"),style:localStorage.getItem('style'),specialRequest:localStorage.getItem('specialRequest'),pax:localStorage.getItem('pax'), total:localStorage.getItem('total'),address:localStorage.getItem('address'),totalAfter:localStorage.getItem('totalAfter')})
        localStorage.setItem('nav', 'fnd')
    },[])

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
        }else{
            setLoading(false)
            // setTotal(parseInt(detail.total))
        }
    },[detail])

    // const [voucher, setVoucher] = useState('') 
    const [standby, setstandby] = useState(true)
    ;
    const history = useNavigate()

    const convertToRupiah = (angka) => {
        var rupiah = '';		
        var angkarev = angka.toString().split('').reverse().join('');
        for(var i = 0; i < angkarev.length; i++) if(i%3 === 0) rupiah += angkarev.substr(i,3)+'.';
        return rupiah.split('',rupiah.length-1).reverse().join('');
    }

    useEffect(()=>{
        
        if(standby===false){
            setTimeout(function() {
                // localStorage.setItem('totalAfter',total)
                history('/category/chefList/detailChef/detailMenu/setSchedule/setVenue/submitBooking/Review')
           }, 500);
        }
    },[standby])

    const callAPIbookingEvent = async (isAggreAddNewAddress) => {
        var axios = require('axios');
        var FormData = require('form-data');
        const formDataLogin = new FormData();
        formDataLogin.append("menu_id", localStorage.getItem("menuId"))
        formDataLogin.append("package_menu_id", localStorage.getItem("package_menu_id"))
        formDataLogin.append("date",localStorage.getItem("tanggal"))
        formDataLogin.append("times",localStorage.getItem("jam"))
        formDataLogin.append("guest",localStorage.getItem("pax"))
        formDataLogin.append("amount",localStorage.getItem("totalAfter"))
        formDataLogin.append("voucher_id",localStorage.getItem("voucherId"))
        formDataLogin.append("id_veneu_user",localStorage.getItem('addressId'))
        formDataLogin.append("special_request",localStorage.getItem('specialRequest'))
        formDataLogin.append("serving_style_id",localStorage.getItem('styleId'))
        axios({
            method: 'POST',
            url: baseUrl + 'chef/setBooking',
            data : formDataLogin,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('taplakToken')}` 
            },
            withCredentials:false
        })
        .then(function (response) {
            
            if(response.data.status === true){
                // console.log("Berhasil")
                // console.log(response.data.message)
                // console.log(response.data.booking_id)
                localStorage.setItem('bookingId',response.data.message.booking_id)
                localStorage.setItem('userName',response.data.message.user_order.name)
                localStorage.setItem('serving_style',response.data.message.serving_style)
                localStorage.setItem('menu_name',response.data.message.menu_order.name)
                localStorage.setItem('isBookingChefStillProggress',false)
                localStorage.setItem('isBookingChefStillProggressVenue',false)
                localStorage.removeItem('chefNameDraftFlow')
                localStorage.removeItem('chefIdDraftFlow')

                console.log("is Aggree Add New Address => "+isAggreAddNewAddress)
            
                if(isAggreAddNewAddress === "true"){
                    callSetVenue(response.data.message.booking_id)
                } else {
                    setstandby(false)
                }

                // setstandby(false)
            } else {
                // console.log("Gagal booking event")
                alert("Session Login anda sudah Expired, Silahkan Login ulang");
            }
        })
            .catch(function (error) {
            console.log(error.response.data.message);
            alert(error.response.data.message);
        });
    }

    const callSetVenue = async (booking_id,isAddressHasChoosen) => {
        var axios = require('axios');
        var FormData = require('form-data');
        const formDataSetvenue = new FormData();
        formDataSetvenue.append("booking_id", localStorage.getItem("bookingId"))
        formDataSetvenue.append("phone_number",localStorage.getItem("phoneNumber"))
        formDataSetvenue.append("veneu_profile",localStorage.getItem("type"))
        formDataSetvenue.append("address",localStorage.getItem("address"))
        formDataSetvenue.append("address_details",localStorage.getItem("addressDetail"))
        if(image1){
            formDataSetvenue.append("venue_image[]",image1)
        }
        if(image2){
            formDataSetvenue.append("venue_image[]",image2)
        }
        if(image3){
            formDataSetvenue.append("venue_image[]",image3)
        }
        // if(photoVenue === ""){
        //     formDataSetvenue.append("venue_image[]",localStorage.getItem("photoVenue"))
        // } else {
            
        //     for(var photo in photoVenue){
        //         formDataSetvenue.append("venue_image[]",photo)
        //     }
            
        // }
        
        // formDataSetvenue.append("venue_image",value)
        axios({
            method: 'POST',
            url: baseUrl + 'chef/setVeneuBooking',
            data : formDataSetvenue,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('taplakToken')}` 
            },
            withCredentials:false
        })
        .then(function (response) {
            
            // console.log("response set book")
            // console.log(response.data.status)
            if(response.data.status === true){
                // console.log("Berhasil set vennue")
                
                
                setstandby(false)
            } 
            // console.log(response.data.access_token)
            // localStorage.setItem('TaplakLoggedIn', 'true')
            // localStorage.setItem('username',capitalize(response.data.user.name))
            // localStorage.setItem('taplakToken',response.data.access_token)
            // window.location.reload()
            // fungsi()
            // console.log(JSON.stringify(response.data));
        })
            .catch(function (error) {
            console.log(error);
            // setValLog3(false)
        });
    }
    
    return (
        <div onClick={e => {e.stopPropagation();handleCloseAll()}} id="maincontainer" style={{minWidth:"100%", minHeight:"100vh", backgroundColor:"#111111", position:"relative", overflow:"unset"}}>
            <DesktopNavbar showPopup={showNavPopup} setShowPopup={setShowNavPopup}></DesktopNavbar>
            <FloatingWhatsAppCustom></FloatingWhatsAppCustom>
            <img onClick={() => history(-1)} className='backmobile' alt="back" src={circleback}></img>
            {
                loading?
                <img alt='loading' className='taplakLoading' src={spinner}></img>
                :
                <CSSTransition in={standby} timeout={{appear:1000,enter:2000,exit:1000}} mountOnEnter={true} appear={true} classNames="SBPtrans">
                <div className='SBPkontainer'>
                    <p className='big bold white SBPtittle'>Submit Booking</p>
                    <div className='SBPkonten flex'>
                        <div className='SBPkontainerkiri'>
                            <p className='medium white'>Your booking details</p>
                            <p className='white tiny SBPmargin'>Chef</p>
                            <div className='flex SBPmargin2'>
                                <div className='SBPimage' style={{backgroundImage:`url(${detail.foto})`}}></div>
                                <p className='white small'>{detail.nama}</p>
                            </div>
                            <div className='SBPline SBPmargin'></div>
                            <p className='white tiny SBPmargin'>Menu</p>
                            <div className='flex SBPmargin2'>
                                <div className='SBPimage' style={{backgroundImage:`url(${detail.fotoMakanan})`}}></div>
                                <div>
                                    <p className='white small'>{detail.Type} Food</p>
                                    <p className='white tiny bold SBPmargin3'>{
                                        detail.Menu.map((post) => {
                                            if(post===detail.Menu[0]){
                                                return(
                                                    <span>{post}</span>
                                                )
                                            }else{
                                                return(
                                                    <span>, {post}</span>
                                                )
                                            }
                                        })
                                    }</p>
                                    <div className='flex SBPmargin3'>
                                        {  detail.bahan ?
                                            detail.bahan.map((post) => {
                                                (
                                                        <img alt="icon" className='SBPicon' src={post.file_url}></img>
                                                    )
                                            })
                                            :<div></div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='SBPline SBPmargin'></div>
                            <p className='white tiny SBPmargin'>Tools Needed</p>
                            <div className='flex SBPmargin2'>
                                {
                                    detail.alat ?
                                    detail.alat.map((post) => 
                                        (
                                                <div className='SBPtools'>
                                                    <img alt="icon" className='SBPicon2' src={post.file_url}></img>
                                                    <p className='white tiny'>{post.deskripsi}</p>
                                                </div>
                                            )
                                    )
                                    :
                                    <div></div>
                                }
                            </div>
                            <div className='SBPline SBPmargin'></div>
                            <div className='SBPmargin flex' style={{alignItems:"center"}}>
                                <img alt="icon" className='SBPicon' src={calendar}></img>
                                <p className='tiny white' style={{marginRight:"32px"}}>{detail.tanggal}</p>
                                <img alt="icon" className='SBPicon' src={time}></img>
                                <p className='tiny white' >{detail.jam}</p>
                            </div>
                            <div className='SBPmargin flex' style={{alignItems:"center"}}>
                                <img alt="icon" className='SBPicon' src={location}></img>
                                <p className='tiny white' >{detail.address}</p>
                            </div>
                            <div className='SBPline SBPmargin'></div>
                            <p className='SBPmargin tiny white'>Serving style: <span className='bold'>{detail.style}</span></p>
                            <div className='SBPline SBPmargin'></div>
                            <p className='SBPmargin tiny white'>Special Request</p>
                            <p className='SBPmargin2 tiny white bold'>{detail.specialRequest}</p>
                        </div>

                        <div className='SBPkontainerkanan'>
                            <div className='SBPkontenputih'>
                                <p className='bold medium'>Invoice Preview</p>
                                <div className='SBPmargin4 flex'>
                                    <p className='small'>{detail.pax} pax</p>
                                    <p className='small' style={{marginLeft:"auto"}}>{toRupiah(detail.total,{formal: false, symbol: 'IDR'}).slice(0, -3)}</p>
                                </div>
                                {/* <div className='SBPline SBPmargin'></div> */}
                                {/* <div className='SBPmargin flex'>
                                    <p className='small'>Service Fee</p>
                                    <p className='small' style={{marginLeft:"auto"}}>-</p>
                                </div> */}
                                {/* <div className='SBPline SBPmargin'></div>
                                <p className='small SBPmargin'>Voucher Code</p>
                                <div className='SBPmargin2 flex'>
                                    <input type='text' value={voucher} onChange={e => setVoucher(e.target.value)} placeholder='Enter voucher code' className='SBPform'></input>
                                    <button className='SBPbutton tiny bold'>Apply</button>
                                </div> */}
                                <div className='SBPline SBPmargin'></div>
                                <div className='SBPmargin flex'>
                                    <p className='small'>Voucher Amount</p>
                                    <p className='small' style={{marginLeft:"auto"}}>{localStorage.getItem('voucherDisc') === "" ? "-" : localStorage.getItem('voucherDisc')+"%"}</p>
                                </div>
                                <div className='SBPmargin4 flex'>
                                    <p className='medium bold'>Total</p>
                                    <p className='medium bold' style={{marginLeft:"auto"}}>{toRupiah(localStorage.getItem('totalAfter'),{formal: false, symbol: 'IDR'}).slice(0, -3)}</p>
                                </div>
                            </div>

                            <div className='SBPkontenputih SBPmargin4'>
                                <p className='bold medium'>Submit Your Booking</p>
                                {/* <p className='SBPmargin2 small'>After submitting your order we will review it first</p> */}
                                <p className='SBPmargin2 small'>Your booking will be reviewed by Taplak Talents, please wait for the confirmation in your email address or whatsapp number</p>
                                {/* <button onClick={() => setstandby(false)} className='SBPbutton2 small bold SBPmargin4'>Continue to Submit</button> */}
                                <button onClick={() => callAPIbookingEvent(localStorage.getItem('isAggreAddNewAddress'))} className='SBPbutton2 small bold SBPmargin4'>Submit Now</button>
                            </div>
                        </div>
                    </div>
                </div>
                </CSSTransition>
            }
        </div>
    )
  }