import React from 'react';
import Modal from 'react-modal';
import close from '../../assets/close.svg'
import './index.css'

const PaymentModal = ({ isOpen, onClose ,xenditPaymentLink}) => {
  const xenditUrl = xenditPaymentLink;

  const closingModal = (isOpen) => {
    // console.log("is Modal Open => "+isOpen)
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <button onClick={() => closingModal(isOpen)} className='small bold SBEPmargin'>Close</button>
      {/* <button onClick={() => closingModal(isOpen)}>Close</button> */}
      {/* <img onClick={() => onClose} className="menu" alt='logoTaplak' src={close}></img> */}
      <iframe src={xenditUrl} title="Xendit Payment" width="100%" height="500" />
    </Modal>
  );
};

export default PaymentModal;