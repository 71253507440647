import './index.css';
import chefImg from '../../assets/chef_bg.avif';
import star from '../../assets/star.svg';
import microwave from '../../assets/microwave.svg';
import {useState, useEffect, useCallback, useRef, useLayoutEffect} from 'react';
import { MenuCards } from '../Card/MenuListNew/MenuCard';
import React from 'react';
import { DesktopNavbar } from '../NavBar/Desktop/DesktopNavbar';
import { useLocation, useNavigate } from "react-router-dom";
import { ChefBanner } from '../Banner/chefBanner';
import { MenuBanner } from "../Banner/menuBanner";
import circleback from '../../assets/circleback.svg';
import spinner from '../../assets/spinner.gif'
import forkspoonWhite from '../../assets/forkspoon2.svg'
import carleft from '../../assets/carleft.svg';
import carright from '../../assets/carright.svg';
import pattern from '../../assets/assets/bycategory/chef-cricle.png';

export const ChefDetail = () =>{
    const { state } = useLocation()
    const iconwidth = "16px";
    const iconwidthNavigasi = "24px";
    const iconwidthtools = "48px";
    const [detail, setDetail] = useState()
    const [loading, setLoading] = useState(true)
    const [isFromMenu, setIsFromMenu] = useState(false)
    const [scrollButton, setScrollButton] = useState(false);
    const [showNavPopup, setShowNavPopup] = useState(false);
    const [y, setY] = useState(window.scrollY);
    const firstUpdate2 = useRef(true);
    const history = useNavigate()

    useEffect(() =>{
        const { dataChef,isFromMenu } = state

        // console.log(dataMenu)
        window.scrollTo(0, 0)
        setLoading(true)
        setIsFromMenu(isFromMenu)
        // get menu detail version 2
        const chefDetail = async () => {
            try {
                const data = await callApigetChefDetail(dataChef)
                // console.log("ini data menu nya => 2 "+JSON.stringify(data))
                setDetail(data)
            } catch (error) {
                console.log("Error menuDetailFromDB")
            }
        }
        console.log("data booking list 2 => "+JSON.stringify(dataChef.booking_available))
        // console.log("ini data menu nya => 3 "+JSON.stringify(dataChef))
        chefDetail()
        // menuDetailFromDB()
        localStorage.setItem('nav', 'fnd')
        // localStorage.setItem('chefId', chefId)
        // localStorage.setItem('menuId', dataMenu.food[0].bussines_id)
    },[])

    useEffect(() => {
        // Insert Hotjar tracking code into the head section
        const script = document.createElement('script');
        script.innerHTML = `
            (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:5070309,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `;
        document.head.appendChild(script);

        return () => {
            // Cleanup if necessary
            // document.head.removeChild(script);
        };
    }, []);

    useEffect(() => {
        if (firstUpdate2.current) {
            firstUpdate2.current = false;
        }else{
            setLoading(false)
        }
    },[detail])

    const callApigetChefDetail = async (dataMenu) => {
        try {
            const parsedMenuDetail = parseChefDetail([dataMenu])
            // console.log("ini data menu nya => "+JSON.stringify(parsedMenuDetail))
            return parsedMenuDetail
        } catch (error) {
            console.log(error)
            console.log("error getMenuDetailFromDB")
            // Create error handler (pop-up or something)
        }
    }

    // Helpers
    const parseChefDetail = (detailChef) => {
        
        const parseEduOrExp = (arrInput) => {
            // console.log("work edu => "+JSON.stringify(arrInput))
            arrInput = arrInput.map((el) => {
                
                // console.log(el.date)
                return {
                    institusi: el.name,
                    tahun: formatDateV2(el.date)
                }
            })
            return arrInput
        }

        
        // const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
        detailChef = detailChef.map((el) => {
            console.log("data speciality 123 => "+ el.speciality_chef)
            return {
                id: el["id"],
                nama: el["nama"],
                // foto: el["cover_image"],
                gambar: el["gambar"],
                rating: el["rating"],
                service : parseService(el["service"]),
                speciality_chef : parseChefSpeciality(el.speciality_chef),
                booking_available:parseBookingAvailable(el.booking_available),
                // menu: [
                //     {id: 1, jenis: "Asian", max: 6,harga: 150, 
                //     food:[{nama: "Beef bulgogi", gambar: makanan1},{nama: "Yaki gyoza", gambar: makanan2}]},
                //     {id: 2, jenis: "Western", max: 3, harga: 200, 
                //     food:[{nama: "Beef bulgogi", gambar: makanan2},{nama: "Yaki gyoza", gambar: makanan1}]}
                // ],
                deskripsi:el.deskripsi ? el.deskripsi : el["biography"] ? el["biography"] : "N/A",
                bahasa: el.bahasa ? el.bahasa :el["languages"],
                instagram: el.instagram ? el.instagram: el["chefCompany"]["instagram"] ? el["chefCompany"]["instagram"] : "N/A",
                facebook: el.facebook ? el.facebook: el["chefCompany"]["facebook"] ? el["chefCompany"] : "N/A",
                linkedin: el.linkedin ? el.linkedin: el["chefCompany"]["linkedin"] ? el["chefCompany"]["linkedin"] : "N/A",
                // education: [...parseEduOrExp(el.educations), ...parseEduOrExp(el.experiences)]
                education: el.education ? el.education : parseEduOrExp(el["chefCompany"])
            }
        })

        return detailChef[0]
    }

    const parseBookingAvailable = (booking_available) => {
        booking_available = booking_available.map(el => {
            return {
                booking_date:el.booking_date
            }
        })

        return booking_available
    }

    const parseChefSpeciality = (speciality) =>{
        let specialities = []
        let speciality_convert = ""
        if (speciality.length === 0 || speciality === "N/A") {
            return "N/A"
        }

        if(typeof speciality ==="string"){
            return speciality
        }
        
        speciality = speciality.map((el) =>{
            specialities.push(el.name_speciality)
        })

        // console.log("ini proficient => "+ proficientList)
        specialities = specialities.join(', ').replace(/, ([^,]*)$/, ' and $1')
        return specialities
    }

    function formatDateV2(string){
        var options = { year: 'numeric', day: 'numeric', month: 'long' };
        return new Date(string).toLocaleDateString([],options);
    }
    
    const parseService = (service) => {
        service = service.map((el) => {
            return {
                id: el.id,
                nama: el.nama,
                service_image_url: el.service_image_url,
                max_pax:el.max_pax,
                converted_discounted_price: el.converted_discounted_price,
                package_menu_id: el.package_menu_id,
                prices: el.prices,
                voucher:parseVoucher(el.voucher),
                menu: parseFood(el.menu),
                equipmentData: parseEquipment(el.equipmentData),
                containData: parseContain(el.containData),
            }
        })
        return service 
    }

    const parseVoucher = (vouchers) => {
        if(vouchers === "N/A"){
            return "N/A"
        }

        vouchers = vouchers.map(el => {
            return {
                id: el["id"],
                name: el["name"],
                discount: el["discount"],
                quota: el["quota"]
            }
        })

        return vouchers
    }

    const parseFood = (menus) =>{
        menus = menus.map((el) => {
            return {
                id: el.id,
                bussines_id : el.bussines_id,
                name: el.name,
                description : el.description,
                image: el.image,
                category_name:el.category_name
            }
        })
        return menus
    }

    const parseEquipment = (equipment) => {
        if(!equipment || equipment.length < 1 || equipment === "N/A"){
            return "N/A"
        }

        equipment = equipment.map(el => {
            if(el !== null){
                return {
                    equipmentId: el ? el["equipmentId"] : "",
                    deskripsi: el ? el["deskripsi"] : "",
                    icon: el ? el["icon"] : "",
                    file_url: el ? el["file_url"] :""
                }
            }
            
        })

        return equipment
    }

    const parseContain = (contain) => {
        if(!contain || contain.length < 1 || contain === "N/A"){
            return "N/A"
        }

        contain = contain.map(el => {
            if(el !== null){
                return {
                    containId: el ? el["containId"] : "",
                    deskripsi: el ? el["deskripsi"] : "",
                    icon: el ? el["icon"] : "",
                    file_url: el ? el["file_url"] :""
                }
            }
            
        })

        return contain
    }

    const handleNavigation = useCallback(
        e => {
            const window = e.currentTarget;
            setY(window.scrollY);
            if(y<=50){
                setScrollButton(false);
                var elem = document.getElementById("penggantidiubah");
                if(elem){
                    elem.style.transition = "background-color 0.5s";
                    elem.style.backgroundColor = "transparent";
                }
            }
            else{
                setScrollButton(true);
                var elem2 = document.getElementById("penggantidiubah");
                if(elem2){
                    elem2.style.transition = "background-color 0.5s";
                    elem2.style.backgroundColor = "rgba(0, 0, 0, 1)";
                }
            }
        }, [y]
    );

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);
        
        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);

    const [MoreText, setMoreText] = useState("Read More");
    const [ReadMoreText, setReadMoreText] = useState(true);
    
    const handleReadMore = () =>{
        if(ReadMoreText){
            setReadMoreText(false);
            setMoreText("Read Less");
        }else{
            setReadMoreText(true);
            setMoreText("Read More");
        }
    }
    return(
        <div onClick={e => {e.stopPropagation();}} id="maincontainer">
            <DesktopNavbar showPopup={showNavPopup} setShowPopup={setShowNavPopup}></DesktopNavbar>
            <div className='detailMenuNavMobile' onClick={e => {e.preventDefault();e.stopPropagation();history(-1)}}>
                <img src={circleback} draggable="false" className='pointer'></img>
            </div>
            {
                loading?
                <img alt='loading' className='taplakLoading' src={spinner}></img>
                :
            <div className='detailMenuWrapper chef'>
                <div className='detailMenuRight'>
                    <div className='detailMenuImage'>
                        <img src={detail.gambar} draggable="false"></img>
                    </div>
                    <div className='detailMenuContents'>
                        <div className='head flex justify-between align-center'>
                            <h1>{detail.nama}</h1>
                            <div className='flex gap-5'>
                                <img src={star} width={iconwidth}></img>
                                <p className='ratingDetail'>{detail.rating}</p>
                            </div>
                        </div>
                        <div className='body'>
                            <div className='bodyText desc' onClick={()=>{handleReadMore()}}>
                            {/* {detail.deskripsi === "N/A"? "" : <p className='grey-text medium PDEstyle'>{React.createElement("div", { dangerouslySetInnerHTML: { __html: detail.deskripsi } })}</p>} */}
                            {detail.deskripsi === "N/A"? null : <div className={`${ReadMoreText ? 'line-clamps-3' : 'no-line'}`}>{React.createElement("div", { dangerouslySetInnerHTML: { __html: detail.deskripsi } })}</div>}
                                <button>{MoreText}</button>
                            </div>
                            <div className='bodyText'>
                                <p>Type Of Cuisine</p>
                                <h1>{detail.speciality_chef}</h1>
                            </div>
                            <div className='bodyText'>
                                <p>Proficient In</p>
                                <h1>{detail.bahasa}</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='detailMenuLeft'>
                    <div className='cardListHead flex justify-between align-center'>
                        <h1>Pilihan Menu</h1>
                    </div>
                    <div className='cardList'>
                        {
                            detail.service.length!==0 && (
                                detail.service.map((post) => (
                                    // <MenuCards/>
                                    <MenuCards  data={post} detailChef={detail} isFromMenu={isFromMenu}></MenuCards>
                                ))
                            ) 
                        }
                        {/* <MenuCards></MenuCards>
                        <MenuCards></MenuCards>
                        <MenuCards></MenuCards>
                        <MenuCards></MenuCards> */}
                    </div>
                    <div className='line-separator'>
                        <div className='line-img'>
                            <img src={forkspoonWhite} alt="icon" draggable="false"></img>
                        </div>
                    </div>
                    <div className='bannerDouble'>
                        <ChefBanner></ChefBanner>
                        <div className='orline-separator'>
                            or
                        </div>
                        <MenuBanner></MenuBanner>
                    </div>
                </div>
            </div>
        }
        </div>

    )
}