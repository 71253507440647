/* eslint-disable react-hooks/exhaustive-deps */
import './index.css'
import { DesktopNavbar } from '../NavBar/Desktop/DesktopNavbar'
import {useState, useEffect, useRef} from 'react'
import "react-datepicker/dist/react-datepicker.css";
// import makanan1 from '../../assets/makanan1.png'
import { useNavigate } from "react-router-dom";
import circleback from '../../assets/circleback.svg'
import down from '../../assets/down.svg'
// import foto1 from '../../assets/foto4.png'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { PageLogin } from '../LoginPage/PageLogin'
import { CSSTransition } from 'react-transition-group';
import spinner from '../../assets/spinner.gif'
import axios from 'axios';
// import HighlightedDaysPicker from './HighlightedDaysPicker';
import { baseUrl } from '../../lib/api';
import { useLocation } from 'react-router-dom';
import { FloatingWhatsAppCustom } from '../FloatingWhatsAppCustom/FloatingWhatsAppCustom';
import toRupiah from '@develoka/angka-rupiah-js';
import 'react-datepicker/dist/react-datepicker.css';
import breadcrumbs from '../../assets/event/breadcrumbs2.png';
import check from '../../assets/event/check.svg';
import current from '../../assets/event/current.svg';
import checurrent from '../../assets/event/checurrent.svg';
import ongoing from '../../assets/event/ongoing.svg';
import logoBlack from '../../assets/icontaplak.png';
import useDraggableScroll from 'use-draggable-scroll';

export const StartPlanningYourEvent = () => {
    const [detail,setDetail] = useState()
    const [guest, setGuest] = useState(1)
    const [shownLocation, setShownLocation] = useState()
    const history = useNavigate()
    const { state } = useLocation()
    const [total, setTotal] = useState(0)
    const [showLocation, setShowLocation] = useState(false)
    const [showCalendar, setShowCalendar] = useState(false)
    const [showClock, setShowClock] = useState(false)
    const [showDaterange, setShowDaterange] = useState(false)
    const [selectedDate, setSelectedDate] = useState()
    const [selectedDateToSetBooking, setSelectedDateToSetBooking] = useState()
    const [dateShown, setDateShown] = useState('Select date')
    // const [globalFormatSelectedDate, setGlobalFormatSelectedDate] = useState('')
    const [clockSelected, setClockSelected] = useState('Select time')
    const [tbcSelected, settbcSelected] = useState('Get date range')
    const [jmlpax, setJmlpax] = useState('');
    const [openEventArea, setopenEventArea] = useState('');
    const [preparationTime, setPreparationTime] = useState()
    const [getListTime, setGetListTime] = useState()
    const [showStyle, setShowStyle] = useState(false)
    const [privacyPolicy, setPrivacyPolicy] = useState(false)
    const [foodService, setFoodService] = useState(false)
    const [BeverageService, setBeverageService] = useState(false)
    const [ManpowerService, setManPowerService] = useState(false)
    const [CultleriesService, setCultleriesService] = useState(false)
    const [DecorService, setDecorService] = useState(false)
    const [foodCheckService, setFoodCheckService] = useState(false)
    const [BeverageCheckService, setBeverageCheckService] = useState(false)
    const [ManpowerCheckService, setManPowerCheckService] = useState(false)
    const [style, setStyle] = useState('Select serving style')
    const [styleId, setStyleId] = useState()
    const [servingStyle, setServingStyle] = useState()
    const [showLogin, setShowLogin] = useState(false)
    const [specialRequest, setSpecialRequest] = useState('')
    const [val1, setval1] = useState(false)
    const [val2, setval2] = useState(false)
    const [filterLocation, setFilterLocation] = useState('')
    const [val3, setval3] = useState(false)
    const [val4, setval4] = useState(false)
    const [val5, setval5] = useState(false)
    const [val6, setval6] = useState(false)
    const [val7, setval7] = useState(false)
    const [val8, setval8] = useState(false)
    const [val9, setval9] = useState(false)
    const [val10, setval10] = useState(false)
    const [loading, setLoading] = useState(true)
    const firstUpdate2 = useRef(true);
    const [choosenLocation, setChoosenLocation] = useState('Select your event area')
    const [choosenLocationDetail, setChoosenLocationDetail] = useState('Select your event area')
    const firstUpdate = useRef(true);
    const [location, setLocation] = useState([])
    const firstUpdate3 = useRef(true);
    const firstUpdate4= useRef(true);
    const firstUpdate5 = useRef(true);
    const firstUpdate6 = useRef(true);
    const firstUpdate7 = useRef(true);
    const [showNavPopup, setShowNavPopup] = useState(false)
    const [availableDate, setAvailableDate] = useState()
    const [availableDateOnlyWorking, setAvailableDateOnlyWorking] = useState()
    const [isAvailableDate, setIsAvailableDate] = useState(false)
    const [voucher, setVoucher] = useState('')
    const [foundLocation, setFoundLocation] = useState([])
    const [voucherAmount, setVoucherAmount] = useState(0) 
    const [totalAfter, setTotalAfter] = useState()
    const [selectedVoucher, setSelectedVoucher] = useState(0)
    const [yourConcept, setyourConcept] = useState('');
    const [yourBudget, setyourBudget] = useState('');
    const [firstName, setfirstName] = useState('');
    const [lastName, setlastName] = useState('');
    const [phoneNumber, setphoneNumber] = useState('');
    const [selectedTime, setselectedTime] = useState(new Date());
    const [standby, setstandby] = useState(true)
    const [questionerSubmit, setquestionerSubmit] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const [successed, setSuccessed] = useState(false);
    const [backdrop, setBackdrop] = useState(false);
    const [disablestate, setDisabledstate] = useState(false);
    const [selectedstate, setSelectedstate] = useState(false);
    const locationTop = useRef(null);
    const topDivRef = useRef(null);
    const dateTop = useRef(null);
    const clockTop = useRef(null);
    const daterangeTop = useRef(null);
    const highlightedDates = [];

    useEffect(()=>{    
        if(standby===false){
            setTimeout(function() {
                history('/category/chefList/detailChef/detailMenu/setSchedule/setVenue', { state: {menus: detail.menu}});
           }, 500);
        }
    },[standby])

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );
    
    const handleCloseAll = () => {
        // setShowCalendar(false)
        setShowNavPopup(false)
        setShowClock(false)
        // setShowLogin(false)
        setShowStyle(false)
    }

    const isWeekday = (date) =>{
        const day = date.getDay();
        return day !== 0;
    }

    const openDropdown = (type) =>{
        if(type === 'date'){
            if(!showCalendar){
                setShowCalendar(true);
                setShowLocation(false);
                setShowClock(false);
                setShowDaterange(false);
            }
            else{
                setShowCalendar(false);
            }
        }
        else if(type === 'area'){
            if(!showLocation){
                setShowCalendar(false);
                setShowLocation(true);
                setShowClock(false);
                setShowDaterange(false);
            }
            else{
                setShowLocation(false);
            }
        }
        else if(type === 'clock'){
            if(!showCalendar){
                var PSSdate = document.getElementById("PSSdate");
                if(dateShown === "Select date"){
                    PSSdate.className = "dropdown PSSred shake";

                    // removing animation 
                    setTimeout(function(){
                        PSSdate.className = "dropdown PSSred";
                    },500)
                    setval10(true);
                }
                else{
                    if(!showClock){
                        setShowCalendar(false);
                        setShowLocation(false);
                        setShowClock(true);
                        setShowDaterange(false);
                    }
                    else{
                        setShowClock(false);
                    }
                }
            }
        }
        else{
            if(!showDaterange){
                setShowCalendar(false);
                setShowLocation(false);
                setShowClock(false);
                setShowDaterange(true);
            }
            else{
                setShowDaterange(false);
            }
        }
    }

    const handleSelectDate = (e) => {
        if(val10){
            setval10(false);
        }

        setShowCalendar(false)
        setSelectedDate(e)
        let isAvail = false
        setIsAvailableDate(false)
        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ]
        setDateShown(`${formatDate(e.getDate())} ${months[e.getMonth()].slice()} ${e.getFullYear()}`)
        setSelectedDateToSetBooking(`${formatDate(e.getFullYear())}-${formatDate(e.getMonth()+1)}-${formatDate(e.getDate())}`)
        // topDivRef.current.scrollIntoView({behavior: "smooth", block: "start", inline: 'nearest'})

        // callApigetTimeBooking(`${formatDate(e.getDate())}-${formatDate(e.getMonth()+1)}-${e.getFullYear()}`, 1, 6)
    }

    const handleSelectTime = (e) =>{
        setShowClock(false);
        setClockSelected(`${formatDate(e.getHours())}:${formatDate(e.getMinutes())}`);
    }

    const formatDate = (data) =>{
        if(data<10){
          return `0${data}`
        }else{
          return data
        }
    }

    useEffect(() =>{
        // const { details } = state
        window.scrollTo(0, 0)
        setLoading(false)

        const serving = [
            {
                id_serving_style:1,
                title:"Kurang Dari 30 Pax",
                deskripsi:"Contoh Serving Style 1"
            },
            {
                id_serving_style:2,
                title:"30 - 60 Pax",
                deskripsi:"Contoh Serving Style 2"
            },
        ]
        setServingStyle(serving)
        
        localStorage.setItem('nav', 'e')
    },[])

    useEffect(()=>{
        if (firstUpdate.current) {
            firstUpdate.current = false;
        }
        else{
            var elem = document.getElementById("PSSdatedown");
            elem.style.transition = "transform 0.5s"
            if(showCalendar===true){
                elem.style.transform = "rotate(180deg)"
                dateTop.current.scrollIntoView({behavior: "smooth", block: "center", inline: 'nearest'})
            }else{
                elem.style.transform = "rotate(0)"
            }
        }
    },[showCalendar])

    useEffect(()=>{
        if(firstUpdate6.current){
            firstUpdate6.current = false;
        }
        else{
            var elem = document.getElementById("PSSdaterangedown");
            elem.style.transition = "transform 0.5s";
            if(showDaterange === true){
                elem.style.transform = "rotate(180deg)";
                daterangeTop.current.scrollIntoView({behavior:"smooth", block:"center", inline:'nearest'})
            }
            else{
                elem.style.transform = "rotate(0)";
            }
        }
    },[showDaterange])
    
    useEffect(()=>{
        if (firstUpdate5.current){
            firstUpdate5.current = false;
        }
        else{
            var elem2 = document.getElementById("HPiconloc");
            elem2.style.transition = "transform 0.5s";
            if(showLocation === true ){
                elem2.style.transform = "rotate(180deg)";
                locationTop.current.scrollIntoView({behavior: "smooth", block: "center", inline: 'nearest'})
            }
            else{
                elem2.style.transform = "rotate(0)";
            }
        }
    },[showLocation])

    useEffect(()=>{
        if (firstUpdate3.current) {
            firstUpdate3.current = false;
        }
        else{
            var elem2 = document.getElementById("PSSclockdown");
            elem2.style.transition = "transform 0.5s"
            if(showClock===true){
                elem2.style.transform = "rotate(180deg)"
                clockTop.current.scrollIntoView({behavior: "smooth", block: "center", inline: 'nearest'})
            }
            else{
                elem2.style.transform = "rotate(0)"
            }
        }
    },[showClock])

    useEffect(()=>{
        if (firstUpdate4.current) {
            firstUpdate4.current = false;
        }else{
            var elem2 = document.getElementById("PSSstyledown");
            elem2.style.transition = "transform 0.5s"
            if(showStyle===true){
                elem2.style.transform = "rotate(180deg)"
            }else{
                elem2.style.transform = "rotate(0)"
            }
        }
    },[showStyle])

    const handleSubmit = () => {
        let isError=false
        var elem = document.getElementById("PSSdate");
        var elem2 = document.getElementById("PSSclock");
        var elem3 = document.getElementById("PSSstyle");
        var elem4 = document.getElementById("PSSLocation");
        var elem5 = document.getElementById("PSSGuest");
        if(dateShown === 'Select date'){
            elem.className = "PSSpertanyaanEvent flex PSSred";
            isError=true
            setval1(true)
        }else{
            elem.className = "PSSpertanyaanEvent flex";
            setval1(false)
        }if(clockSelected === 'Select time'){
            elem2.className = "PSSpertanyaanEvent flex PSSred";
            isError=true
            setval2(true)
        }else{
            elem2.className = "PSSpertanyaanEvent flex";
            setval2(false)
        }if(style==='Select serving style'){
            elem3.className = "PSSpertanyaanEvent flex PSSred";
            isError=true
            setval3(true)
        }else{
            elem3.className = "PSSpertanyaanEvent flex";
            setval3(false)
        }

        if(choosenLocation==='Select your event area'){
            elem4.className = "PSSpertanyaanEvent flex PSSred";
            isError=true
            setval4(true)
        }else{
            elem4.className = "PSSpertanyaanEvent flex";
            setval4(false)
        }

        if(guest < detail.pricelist[0].min){
            elem5.className = "PSSpertanyaanEvent flex PSSred";
            isError=true
            setval5(true)
        } else {
            elem5.className = "PSSpertanyaanEvent flex";
            setval5(false)
        }

        if(isAvailableDate){
          console.log("available date")
          
        } else {
            pushInfoAvailableDate()
            isError=true
        }

        if(isError){
            return
        }else{
            localStorage.setItem('tanggal',selectedDateToSetBooking)
            localStorage.setItem('dateShown',dateShown)
                localStorage.setItem('jam',clockSelected)
                localStorage.setItem('style', style)
                localStorage.setItem('styleId', styleId)
                localStorage.setItem('specialRequest', specialRequest)
                localStorage.setItem('location', choosenLocation)
                localStorage.setItem('pax', guest)
                localStorage.setItem('detailBooking', JSON.stringify(detail))
                localStorage.setItem('total',total)
                localStorage.setItem('totalAfter',totalAfter)
                localStorage.setItem('voucherDisc',selectedVoucher === 0 ? "": selectedVoucher.discount)
                localStorage.setItem('voucherId',selectedVoucher === 0 ? "":selectedVoucher.id)
                localStorage.setItem('voucherName',selectedVoucher === 0 ? "":selectedVoucher.name)
                localStorage.setItem('chefName',detail.chef)
                localStorage.setItem('chefNameDraftFlow',detail.chef)
                localStorage.setItem('chefIdDraftFlow',detail.id)
                localStorage.setItem('chefFoto',detail.foto)
                localStorage.setItem('package_menu_id',detail.package_menu_id)
                localStorage.setItem('chefEquipment',JSON.stringify(detail.equipmentData))
                localStorage.setItem('chefContain',JSON.stringify(detail.containData))
                localStorage.setItem('isBookingChefStillProggress',true)
                localStorage.setItem('chefFotoMakanan',detail.fotoMakanan)
                localStorage.setItem('chefService',detail.jenis)
            callAPIbookingEvent()
        }
    }

    const callAPIbookingEvent = () => {       
        setTimeout(function() {
            setstandby(false)
        }, 500);    
    }

    const handleSearchLocation = (e) => {
        setFilterLocation(e);
        setFoundLocation([]);
        for (var x in location){
          if (location[x].name.toLowerCase().includes(e.toLowerCase())){
            foundLocation.push(location[x])
          }
        }
        setShownLocation(foundLocation);
    }

    const handleSelectLocation = (e) => {
        var elem2 = document.getElementById("PSSLocation");
        elem2.className = "PSSpertanyaanEvent flex";
        setval4(false)
        setDateShown('Select date')
        setClockSelected('Select time')
        setChoosenLocation(e.name)
        setChoosenLocationDetail(e)
        setShowLocation(false)
        var elem = document.getElementById("homeloc");
        elem.className = "inputquestion verytiny black";
    }

    const handleLocation = (e) =>{
        setChoosenLocation(e.name)
        setShowLocation(false)
    }

    const [animated, setAnimated] = useState(false);
    const [visible, setVisible] = useState(false);

    const [animatedAvailableDate, setAnimatedAvailableDate] = useState(false);
    const [visibleAvailableDate, setVisibleAvailableDate] = useState(false);

    const pushInfoAvailableDate = async () => {
        setVisibleAvailableDate(true);
        setAnimatedAvailableDate(true);
        document.querySelector("body").style.overflow='hidden';
    };

    const closeInfoAvailableDate = async () =>{
        setAnimatedAvailableDate(false);
        await delay(500);
        setVisibleAvailableDate(false);
        document.querySelector("body").style.overflow='auto';
    }

    // Set Animating State 
    const [animatedIn, setanimatedIn] = useState();
    const [animatedOut, setanimatedOut] = useState();

    const [eventarea, setEventarea] = useState([
        { name: 'Jakarta' },
        { name: 'Bali' },
        { name: 'Sulawesi' },
        { name: 'Kalimantan' },
    ])

    const [wizard, setWizard] = useState([
        { name: 'Event Schedule', status: false, current: true},
        { name: 'Dietary & Request', status: false, current: false},
        { name: 'Budget & Services', status: false, current:  false},
        { name: 'Contact', status: false, current: false}
    ]);

    const NextWizard = async (no) =>{
        let isError = false;

        // Form 1 
        var PSSdate = document.getElementById("PSSdate");
        var PSSclock = document.getElementById("PSSclock");
        var PSSdaterange = document.getElementById("PSSdaterange");
        var PSSopenLocation = document.getElementById("eventArea");
        var PSSpax = document.getElementById("PSSpax");
        // var PSSLocation = document.getElementById("PSSLocation");

        // Form 2 
        var DietaryInput = document.getElementById("dietaryRest");
        var SpecialInput = document.getElementById("specialReq");
        var ConceptInput = document.getElementById("ownConcept");

        // Form 3 
        var FoodCheck = document.getElementById("foodCheck");
        var BeverageCheck = document.getElementById("beverageCheck");
        var ManpowerCheck = document.getElementById("manpowerCheck");
        var CuteleriesCheck = document.getElementById("cutleriesCheck");
        var DecorCheck = document.getElementById("decorCheck");
        var BudgetCheck = document.getElementById("budgetCheck");

        // Form 4 
        var firstCheck = document.getElementById("firstName");
        var lastCheck = document.getElementById("lastName");
        var numberCheck = document.getElementById("numberPhone");

        // Cek isian form ke-1 
        if(no === 1){
            if(!disablestate){
                // Cek apakah select date diisi
                if(dateShown === "Select date"){
                    isError = true;
                    PSSdate.className = "dropdown PSSred shake";
    
                    // removing animation 
                    setTimeout(function(){
                        PSSdate.className = "dropdown PSSred";
                    },500)
                }

                // Cek apakah time diisi 
                if(clockSelected === "Select time"){
                    isError = true;
                    PSSclock.className = "dropdown PSSred shake";
    
                    // removing animation 
                    setTimeout(function(){
                        PSSclock.className = "dropdown PSSred";
                    },500)
                }

                // Cek apakah date range diisi
                if(tbcSelected === 'Get date range'){
                    isError = true;
                    PSSdaterange.className = "dropdown PSSred shake";
                    
                    // removing animation 
                    setTimeout(function(){
                        PSSdaterange.className = "dropdown PSSred";
                    },500)
                }
            }

            // Cek apakah location diisi 
            // if(choosenLocation === 'Select your event area'){
            //     isError = true;
            //     PSSLocation.className = "dropdown PSSred shake";

            //     // removing animation 
            //     setTimeout(function(){
            //         PSSLocation.className = "dropdown PSSred";
            //     },500)
            // }

            if(openEventArea === ''){
                isError = true;
                PSSopenLocation.className = "input PSSred shake";

                setTimeout(() => {
                    PSSopenLocation.className = "input PSSred";
                }, 500);
            }

            // Cek apakah Pax diisi 
            if(jmlpax === ''){
                isError = true;
                PSSpax.className = "input PSSred shake";

                 // removing animation 
                 setTimeout(function(){
                    PSSpax.className = "input PSSred";
                },500)
            }

            // cek apakah ada error dalam form 
            if(!isError){
                setval6(false);
                setWizard(prevWizard =>
                    prevWizard.map((item, index) => {
                        if (index === no - 1) {
                            return { ...item, status: true, current: false };
                        } else if (index === no) {
                            return { ...item, current: true };
                        }
                        return item;
                    })
                )
                topDivRef.current.scrollIntoView({behavior: "smooth", block: "start", inline: 'nearest'})
            }
            else{
                setval6(true);
                topDivRef.current.scrollIntoView({behavior: "smooth", block:"start", inline:"nearest"})
            }
        }

        // Cek isian form ke-2
        else if(no === 2){
            // Cek apakah dietary diisi 
            if(!tagsDiet.length >= 1){
                isError = true;
                DietaryInput.className = 'input PSSred shake';

                // removing animation 
                setTimeout(function(){
                    DietaryInput.className = 'input PSSred';
                },500)
            }

            // Cek apakah request diisi
            if(!tagsReq.length >= 1){
                isError = true;
                SpecialInput.className = 'input PSSred shake';

                // removing animation 
                setTimeout(function(){
                    SpecialInput.className = 'input PSSred';
                },500)
            }

            // Cek apakah concept diisi 
            if(yourConcept === ''){
                isError = true;
                ConceptInput.className = "input PSSred shake";

                // removing animation 
                setTimeout(function(){
                    ConceptInput.className = 'input PSSred';
                },500)
            }

            // cek apakah ada error dalam form 
            if(!isError){
                setval7(false);
                setWizard(prevWizard =>
                    prevWizard.map((item, index) => {
                        if (index === no - 1) {
                            return { ...item, status: true, current: false };
                        } 
                        else if (index === no) {
                            return { ...item, current: true };
                        }
                        return item;
                    })
                )
                topDivRef.current.scrollIntoView({behavior: "smooth", block: "start", inline: 'nearest'})
            }
            else{
                setval7(true);
                topDivRef.current.scrollIntoView({behavior: "smooth"});
            }
        }

        // Cek isian form ke-3 
        else if(no === 3){
            // Cek apakah food service dicentang
            if(!selectedstate){
                if(!foodService){
                    isError = true;
                    FoodCheck.className = 'checkGroup PSSred shake';

                    // removing animation 
                    setTimeout(function(){
                        FoodCheck.className = 'checkGroup PSSred';
                    },500)
                }

                // Cek apakah food beverage dicentang 
                if(!BeverageService){
                    isError = true;
                    BeverageCheck.className = 'checkGroup PSSred shake';

                    // removing animation 
                    setTimeout(function(){
                        BeverageCheck.className = 'checkGroup PSSred';
                    },500)
                }

                // Cek apakah manpower dicentang 
                if(!ManpowerService){
                    isError = true;
                    ManpowerCheck.className = 'checkGroup PSSred shake';

                    // removing animation 
                    setTimeout(function(){
                        ManpowerCheck.className = 'checkGroup PSSred';
                    },500)
                }

                // Cek apakah cutleries dicentang 
                if(!CultleriesService){
                    isError = true;
                    CuteleriesCheck.className = 'checkGroup PSSred shake';

                    // removing animation 
                    setTimeout(function(){
                        CuteleriesCheck.className = 'checkGroup PSSred';
                    },500)
                }

                // Cek apakah decor dicentang 
                if(!DecorService){
                    isError = true;
                    DecorCheck.className = 'checkGroup PSSred shake';

                    // removing animation 
                    setTimeout(function(){
                        DecorCheck.className = 'checkGroup PSSred';
                    },500)
                }
            }

            // Cek apakah budget diisi 
            if(yourBudget === ''){
                isError = true;
                BudgetCheck.className = 'input PSSred shake';

                // removing animation 
                setTimeout(function(){
                    BudgetCheck.className = 'input PSSred';
                },500)
            }

            // Cek apakah ada yang belum diisi dari form 
            if(!isError){
                setWizard(prevWizard =>
                    prevWizard.map((item, index) => {
                        if (index === no - 1) {
                            return { ...item, status: true, current: false };
                        } 
                        else if (index === no) {
                            return { ...item, current: true };
                        }
                        return item;
                    })
                )
                topDivRef.current.scrollIntoView({behavior: "smooth", block: "start", inline: 'nearest'})
            }
            else{
                setval8(true);
                topDivRef.current.scrollIntoView({behavior: "smooth"});
            }
        }

        // Cek isian form ke 4 
        else{
            // Cek apakah firstname diisi 
            if(firstName === ''){
                isError = true;
                firstCheck.className = 'input PSSred shake';

                // removing animation 
                setTimeout(function(){
                    firstCheck.className = 'input PSSred';
                },500)
            }

            // Cek apakah lastname diisi 
            if(lastName === ''){
                isError = true;
                lastCheck.className = 'input PSSred shake';

                // removing animation 
                setTimeout(function(){
                    lastCheck.className = 'input PSSred';
                },500)
            }

            // Cek apakah nomor telfon diisi 
            if(phoneNumber === ''){
                isError = true;
                numberCheck.className = 'input PSSred shake';

                // removing animation 
                setTimeout(function(){
                    numberCheck.className = 'input PSSred';
                },500)
            }

            // Cek apakah ada yang belum diisi dari form 
            if(!isError){                
                document.querySelector("body").style.overflow='hidden';
                setBackdrop(true);
                await delay(300);
                setConfirmation(true);
            }
            else{
                setval9(true);
                topDivRef.current.scrollIntoView({behavior: "smooth"});
            }
        }
    }

    const BackWizard = async (no) =>{
        topDivRef.current.scrollIntoView({behavior: "smooth"});
        setWizard(prevWizard =>
            prevWizard.map((item, index) => {
                if (index === no - 1) {
                    return { ...item, current: false };
                } 
                else if (index === no - 2) {
                    return { ...item, current: true };
                }
                return item;
            })
        )
    }

    const handleSubmitQuestioner = async () => {
        setquestionerSubmit(true);
        
        await delay(4000)
        setSuccessed(true);

        await delay(10000);
        setquestionerSubmit(false);
        setSuccessed(false)
        setConfirmation(false);
        setBackdrop(false);
        setstandby(false);
        history("/event", { });
        document.querySelector("body").style.overflow='auto';
    }

    const handleClose = async () => {
        setConfirmation(false);
        // await delay (600);
        setBackdrop(false);
        document.querySelector("body").style.overflow='auto';
    }

    // Check group 
    const [foodCheck, setfoodCheck] = useState(false);
    const [selectedFood, setselectedFood] = useState('');

    const [beverageCheck, setbeverageCheck] = useState(false);
    const [selectedBeverage, setselectedBeverage] = useState('');
    const [selectedBeverageOptions, setselectedBeverageOptions] = useState([]);
    
    // Beverage Checkboxes 
    const [selectednonAlcohol, setselectednonAlcohol] = useState(false);
    const [selectedAlcohol, setselectedAlcohol] = useState(false);

    const [manpowerCheck, setmanpowerCheck] = useState(false);
    const [selectedManpower, setselectedManpower] = useState('');
    const [selectedManpowerOptions, setselectedManpowerOptions] = useState([]);

    // Manpower Checkboxes 
    const [selectedService, setselectedService] = useState(false);
    const [selectedBar, setselectedBar] = useState(false);
    const [selectedKitchen, setselectedKitchen] = useState(false);
    
    const [cutleriesCheck, setcutleriesCheck] = useState(false);
    const [decorCheck, setdecorCheck] = useState(false);

    const checkFood = () => {
        if(foodCheck === false && selectedFood === ''){
            setfoodCheck(prevstate => !prevstate);
            setFoodService(prevstate => !prevstate);
            setselectedFood('Canapes');
            setSelectedstate(true);
        }  
    }

    const handleSelectedFood = (event) => {
        const value = event.target.value;

        if(selectedFood === value){
            setselectedFood('');
            setfoodCheck(false);
            setFoodService(false);
            if(beverageCheck || manpowerCheck || cutleriesCheck || decorCheck){
                setSelectedstate(true);
            }
            else{
                setSelectedstate(false);
            }
        }
        else{
            setselectedFood(value);
        }
    }

    const checkBeverage = () => {
        if(beverageCheck === false && selectedBeverage === ''){
            setbeverageCheck(prevstate => !prevstate);
            setBeverageService(prevstate => !prevstate);
            setselectednonAlcohol(true);
            setselectedBeverageOptions([...selectedBeverageOptions, 'Non Alcohol']);
            setSelectedstate(true);
            // setselectedBeverage('Non Alcohol');
        }
    }

    const handleSelectBeverageOptions = (event) => {
        const {value, checked} = event.target;

        if(!checked){
            if(value === 'Non Alcohol'){
                setselectednonAlcohol(false);
                setselectedBeverageOptions(selectedBeverageOptions.filter(item => item !== value));
            }
            if(value === 'Alcohol'){
                setselectedAlcohol(false);
                setselectedBeverageOptions(selectedBeverageOptions.filter(item => item !== value));
            }
        }
        else{
            if(value === 'Non Alcohol'){
                setselectednonAlcohol(true);
                setselectedBeverageOptions([...selectedBeverageOptions, value]);
            }
            if(value === 'Alcohol'){
                setselectedAlcohol(true);
                setselectedBeverageOptions([...selectedBeverageOptions, value]);
            }
        }
    }
    const handleSelectedBeverage = (event) => {
        const value = event.target.value;
        if(selectedBeverage === value){
            setselectedBeverage('');
            setbeverageCheck(false);
            setBeverageService(false);
            if(foodCheck || manpowerCheck || cutleriesCheck || decorCheck){
                setSelectedstate(true);
            }
            else{
                setSelectedstate(false);
            }
        }
        else{
            setselectedBeverage(value);
        }
    }
    
    const checkManpower = () => {
        // Di bawah ini untuk check button 
        if(!manpowerCheck && selectedManpowerOptions.length === 0){
            setmanpowerCheck(prevstate => !prevstate);
            setManPowerService(prevstate => !prevstate);
            setselectedService(true);
            setselectedManpowerOptions([...selectedManpowerOptions, 'Service Team'])
            setSelectedstate(true);
        }

        // Di bawah ini untuk radio button
            // if(manpowerCheck === false && selectedManpower === ''){
            //     setmanpowerCheck(prevstate => !prevstate);
            //     setManPowerService(prevstate => !prevstate);
            //     setselectedManpower('Service Team');
            //     setSelectedstate(true);
            // }
    }

    const handleSelectManpowerOptions = (event) =>{
        const {value, checked} = event.target;
        if(!checked){
            if(value === 'Service Team'){
                setselectedService(false);
                setselectedManpowerOptions(selectedManpowerOptions.filter(item => item !== value));
            }
            if(value === 'Bar Team'){
                setselectedBar(false);
                setselectedManpowerOptions(selectedManpowerOptions.filter(item => item !== value));
            }
            if(value === 'Kitchen Team'){
                setselectedKitchen(false);
                setselectedManpowerOptions(selectedManpowerOptions.filter(item => item !== value));
            }
        }
        else{
            if(value === 'Service Team'){
                setselectedService(true);
                setselectedManpowerOptions([...selectedManpowerOptions, value]);
            }
            if(value === 'Bar Team'){
                setselectedBar(true);
                setselectedManpowerOptions([...selectedManpowerOptions, value]);
            }
            if(value === 'Kitchen Team'){
                setselectedKitchen(true)
                setselectedManpowerOptions([...selectedManpowerOptions, value]);
            }
        }
    }

    const handleSelectedManpower = (event) => {
        const value = event.target.value;
        if(selectedManpower === value){
            setselectedManpower('');
            setmanpowerCheck(false);
            setManPowerService(false);
            if(foodCheck || beverageCheck|| cutleriesCheck || decorCheck){
                setSelectedstate(true);
            }
            else{
                setSelectedstate(false);
            }
        }
        else{
            setselectedManpower(value);
        }
    }
    
    useEffect(()=>{
        if(selectedManpowerOptions.length === 0){
            setmanpowerCheck(false);
            setManPowerService(false);
        }
    }, [selectedManpowerOptions, manpowerCheck, ManpowerService])

    useEffect(()=>{
        if(selectedBeverageOptions.length === 0){
            setbeverageCheck(false);
            setBeverageService(false);
        }
    },[selectedBeverageOptions, beverageCheck, BeverageCheckService])

    const checkCutleries = () => {
        setcutleriesCheck(prevstate => !prevstate);
        setCultleriesService(prevstate => !prevstate);
        if(!cutleriesCheck){
            setSelectedstate(true);
        }
        else{
            if(foodCheck || beverageCheck|| manpowerCheck || decorCheck){
                setSelectedstate(true);
            }
            else{
                setSelectedstate(false);
            }
        }
    }

    const checkDecor = () => {
        setdecorCheck(prevstate => !prevstate);
        setDecorService(prevstate => !prevstate);
        if(!decorCheck){
            setSelectedstate(true);
        }
        else{
            if(foodCheck || beverageCheck|| manpowerCheck || cutleriesCheck){
                setSelectedstate(true);
            }
            else{
                setSelectedstate(false);
            }
        }
    }

    const handleToStartEvent = () => {
        document.querySelector("body").style.overflow='auto';
        setstandby(false)
        setTimeout(function() {
            history("/event", { } );
       }, 500);
    }

    // Input Tags 
    const [inputValueDiet, setInputValueDiet] = useState("");
    const [tagsDiet, setTagsDiet] = useState([]);
    const [placeHolderDiet, setplaceHolderDiet] = useState(false);

    const [inputValueReq, setInputValueReq] = useState("");
    const [tagsReq, setTagsReq] = useState([]);
    const [placeHolderReq, setplaceHolderReq] = useState(false);

    const handleInputChange = (e) => {
        setInputValueDiet(e.target.value);
    };

    const handleInputChangeReq = (e) => {
        setInputValueReq(e.target.value);
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && inputValueDiet.trim() !== "") {
            e.preventDefault();
            addTag();
        }
    };

    const handleKeyDownReq = (e) => {
        if (e.key === "Enter" && inputValueReq.trim() !== ""){
            e.preventDefault();
            addTagReq();
        }
    }

    const addTag = () => {
        if(inputValueDiet.trim() !== ""){
            setTagsDiet([...tagsDiet, inputValueDiet.trim()]);
            setInputValueDiet("");
        }
    }
    
    const addTagReq = () =>{
        if(inputValueReq.trim() !== ""){
            setTagsReq([...tagsReq, inputValueReq.trim()]);
            setInputValueReq("");
        }
    }

    const handleBlur = () =>{
        addTag();
    }

    const handleBlurReq = () =>{
        addTagReq();
    }

    const removeTag = (indexToRemove) => {
        setTagsDiet(tagsDiet.filter((_, index) => index !== indexToRemove));
    };

    const removeTagReq = (indexToRemove) => {
        setTagsReq(tagsReq.filter((_, index) => index !== indexToRemove));
    }

    useEffect(()=>{
        if(tagsDiet.length >= 1){
            setplaceHolderDiet(true);
        }
        else{
            setplaceHolderDiet(false);
        }
    }, [tagsDiet])

    useEffect(()=>{
        if(tagsReq.length >= 1){
            setplaceHolderReq(true);
        }
        else{
            setplaceHolderReq(false);
        }
    },[tagsReq])

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        

        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ]
        
        if(start && end){
            settbcSelected(`${formatDate(start.getDate())} ${months[start.getMonth()].slice()} ${start.getFullYear()} - ${formatDate(end.getDate())} ${months[end.getMonth()].slice()} ${end.getFullYear()}`);
            setTimeout(function(){
                setShowDaterange(false);
            },500);
        }
    }
    // Draggable scroll 
    const ref = useRef(null);
    const {onMouseDown} = useDraggableScroll(ref);

    // Validation Function
    // Hapus alert saat semua terisi 
    useEffect(()=>{
        if(
            dateShown !== 'Select date' ||
            clockSelected !== 'Select time' ||
            tbcSelected !== 'Get date range' ||
            choosenLocation !== 'Select your event area' ||
            jmlpax
        ){
            setval6(false);
        }
    },[dateShown,clockSelected,tbcSelected,choosenLocation,jmlpax]);

    useEffect(()=>{
        if(
            tagsDiet.length >= 1 ||
            tagsReq.length >= 1 ||
            yourConcept
        ){
            setval7(false)
        }
    },[tagsDiet,tagsReq,yourConcept])

    useEffect(()=>{
        if(foodCheck  || 
            beverageCheck || 
            manpowerCheck || 
            cutleriesCheck ||
            decorCheck ||
            yourBudget
        ){
            setval8(false);
        }
    },[selectedstate,yourBudget])

    useEffect(()=>{
        var foodDiv = document.getElementById("foodCheck");
        var beverageDiv = document.getElementById("beverageCheck");
        var manpowerDiv = document.getElementById("manpowerCheck");
        var cutleriesDiv = document.getElementById("cutleriesCheck");
        var decorDiv = document.getElementById("decorCheck");

        if(selectedstate){
            if(foodCheck){
                foodDiv.className="checkGroup checked";
            }
            else{
                foodDiv.className="checkGroup";
            }
            if(beverageCheck){
                beverageDiv.className="checkGroup checked";
            }
            else{
                beverageDiv.className="checkGroup";
            }
            if(manpowerCheck){
                manpowerDiv.className="checkGroup checked";
            }
            else{
                manpowerDiv.className="checkGroup";
            }
            if(cutleriesCheck){
                cutleriesDiv.className="checkGroup checked";
            }
            else{
                cutleriesDiv.className="checkGroup";
            }
            if(decorCheck){
                decorDiv.className="checkGroup checked";
            }
            else{
                decorDiv.className="checkGroup";
            }
        }
    },[foodCheck,beverageCheck,manpowerCheck,cutleriesCheck,decorCheck])

    useEffect(()=>{
        if(firstName || lastName || phoneNumber){
            setval9(false);
        }
    },[firstName,lastName,phoneNumber])

    // Filled state saat terisi 
    useEffect(()=>{
        var PSSpax = document.getElementById("PSSpax");
        if(PSSpax){
            if(jmlpax){
                PSSpax.className="input filled";
            }
            else{
                PSSpax.className="input";
            }
        }
    },[jmlpax]);

    useEffect(()=>{
        var PSSdate = document.getElementById("PSSdate");
        var PSSclock = document.getElementById("PSSclock");
        var PSSLocation = document.getElementById("PSSLocation");
        var PSSdaterange = document.getElementById("PSSdaterange");

        if(PSSdate){
            if(dateShown !== 'Select date'){
                PSSdate.className = "dropdown filled";
            }
        }

        if(PSSclock){
            if(clockSelected !== 'Select time'){
                PSSclock.className = "dropdown filled";
            }
        }

        if(PSSdaterange){
            if(tbcSelected !== 'Get date range'){
                PSSdaterange.className ="dropdown filled";
            }
        }

        if(PSSLocation){
            if(choosenLocation !== 'Select your event area'){
                PSSLocation.className="dropdown filled";
            }
        }
    },[dateShown,clockSelected,tbcSelected,choosenLocation]);

    useEffect(()=>{
        var PSSConcept = document.getElementById("ownConcept");
        if(PSSConcept){
            if(yourConcept){
                PSSConcept.className="input filled";
            }
            else{
                PSSConcept.className="input";
            }
        }
    },[yourConcept])

    useEffect(()=>{
        var PSSopenLocation = document.getElementById("eventArea");
        if(PSSopenLocation){
            if(openEventArea){
                PSSopenLocation.className="input filled";
            }
            else{
                PSSopenLocation.className="input";
            }
        }
    },[openEventArea])

    useEffect(()=>{
        var PSSbudget = document.getElementById("budgetCheck");
        if(PSSbudget){
            if(yourBudget){
                PSSbudget.className="input filled";
            }
            else{
                PSSbudget.className="input";
            }
        }
    },[yourBudget])

    // Optional Date range 
    useEffect(()=>{
        var PSSdate = document.getElementById("PSSdate");
        var PSSclock = document.getElementById("PSSclock");
        var PSSdaterange = document.getElementById("PSSdaterange");

        if(dateShown !== 'Select date'){
            setDisabledstate(true);
            PSSdaterange.className = "dropdown disabled";
        }
        else if (tbcSelected !== 'Get date range'){
            setDisabledstate(true);
            PSSdate.className= "dropdown disabled";
            PSSclock.className= "dropdown disabled";
        }
    },[dateShown,clockSelected,tbcSelected])

    useEffect(()=>{
        var PSSfirstName = document.getElementById("firstName");

        if(PSSfirstName){
            if(firstName){
                PSSfirstName.className="input filled";
            }
            else{
                PSSfirstName.className="input";
            }
        }
    },[firstName])

    useEffect(()=>{
        var PSSlastName = document.getElementById("lastName");

        if(PSSlastName){
            if(lastName){
                PSSlastName.className="input filled";
            }
            else{
                PSSlastName.className="input";
            }
        }
    },[lastName])

    useEffect(()=>{
        var PSSphoneNumber = document.getElementById("numberPhone");
        
        if(PSSphoneNumber){
            if(phoneNumber){
                PSSphoneNumber.className="input filled";
            }
            else{
                PSSphoneNumber.className="input";
            }
        }
    },[phoneNumber])
    
    const budgetChange = (e) =>{
        const rawValue = e.target.value.replace(/[^0-9]/g, '');
        setyourBudget(rawValue);
    }

    const paxChange = (e) =>{
        const rawValue = e.target.value.replace(/[^0-9]/g, '');
        setJmlpax(rawValue);
    }

    return (
        <div>
            <DesktopNavbar showPopup={showNavPopup} setShowPopup={setShowNavPopup}></DesktopNavbar>
            <FloatingWhatsAppCustom></FloatingWhatsAppCustom>
            <div className='detailMenuNavMobile'>
                <img src={circleback} draggable="false" className='pointer' onClick={e => {e.preventDefault();e.stopPropagation();history(-1)}}></img>
            </div>
            {
                loading?
                <img alt='loading' className='taplakLoading' src={spinner}></img>
                :
                <CSSTransition in={standby} timeout={{appear:1000,enter:2000,exit:1000}} mountOnEnter={true} appear={true} classNames="PSStrans">
                    {
                        questionerSubmit ? 
                        <div>
                        {
                            successed ?
                            <div className='submitSuccess'>
                                <h1>Questionaire Submitted!</h1>
                                <p>Questionaire succesfully sent, Your Event planning will be checked by Taplak Admin.</p>
                            </div>
                            :
                            <img alt='loading' className='taplakLoading' src={spinner}></img>
                        }
                        </div>
                        :
                        <main>
                            {
                                confirmation ? 
                                <div className='confirmation_wrapper' ref={ref} onMouseDown={onMouseDown}>
                                    <div className='confirmation confirmationIn'>
                                        <div className='confirmation_header'>
                                            <div className='flex align-center'>
                                                <img src={logoBlack} draggable='false'></img>
                                                <h1>Your Event Details</h1>
                                            </div>
                                            <i className='bx bx-x' onClick={e => {handleClose()}}></i>
                                        </div>
                                        <div className='confirmation_body'>
                                            <div className='confirmation_info'>
                                                <div className='text'>
                                                    <p>Fullname</p>
                                                    {
                                                        firstName === '' && lastName === '' ?
                                                        <h1>-</h1>
                                                        :
                                                        <h1>{firstName} {lastName}</h1>
                                                    }
                                                </div>
                                                <div className='text'>
                                                    <p>Phone Number</p>
                                                    {
                                                        phoneNumber === '' ?
                                                        <h1>-</h1>
                                                        :
                                                        <h1>{phoneNumber}</h1>
                                                    }
                                                </div>
                                            </div>

                                            <div className='confirmation_info'>
                                                <div className='text'>
                                                    <p>Event Date</p>
                                                    {
                                                        dateShown === 'Select date' ?
                                                        <h1>-</h1>
                                                        :
                                                        <h1>{dateShown}</h1>
                                                    }
                                                </div>
                                                <div className='text'>
                                                    <p>Time Date</p>
                                                    {
                                                        clockSelected === 'Select time' ?
                                                        <h1>-</h1>
                                                        :
                                                        <h1>{clockSelected}</h1>
                                                    }
                                                </div>
                                            </div>
                                            <div className='confirmation_info single'>
                                                <div className='text'>
                                                    <p>Event Date Range</p>
                                                    {
                                                        tbcSelected === 'Get date range' ?
                                                        <h1>-</h1>
                                                        :
                                                        <h1>{tbcSelected}</h1>
                                                    }
                                                </div>
                                            </div>
                                            <div className='confirmation_info'>
                                                <div className='text'>
                                                    <p>Event Area</p>
                                                    {
                                                        openEventArea === '' ?
                                                        <h1>-</h1>
                                                        :
                                                        <h1>{openEventArea}</h1>
                                                    }
                                                    {/* {
                                                        choosenLocation === 'Select your event area' ?
                                                        <h1>-</h1>
                                                        :
                                                        <h1>{choosenLocation}</h1>
                                                    } */}
                                                </div>
                                                <div className='text'>
                                                    <p>Event Pax</p>
                                                    <h1>{jmlpax ? parseInt(jmlpax).toLocaleString('id-ID') : 0} Pax</h1>
                                                </div>
                                            </div>

                                            <div className='confirmation_info single'>
                                                <div className='text'>
                                                    <p>Event Dietary Restrictions</p>
                                                    {
                                                        tagsDiet.length === 0 ?
                                                        <h1>-</h1>
                                                        :
                                                        <h1>{tagsDiet.join(", ")}</h1>
                                                    }
                                                </div>
                                            </div>
                                            <div className='confirmation_info single'>
                                                <div className='text'>
                                                    <p>Event Special Request</p>
                                                    {
                                                        tagsReq.length === 0 ?
                                                        <h1>-</h1>
                                                        :
                                                        <h1>{tagsReq.join(", ")}</h1>
                                                    }
                                                </div>
                                            </div>
                                            <div className='confirmation_info single'>
                                                <div className='text'>
                                                    <p>Event Concept</p>
                                                    {
                                                        yourConcept === '' ?
                                                        <h1>-</h1>
                                                        :
                                                        <h1>{yourConcept}</h1>
                                                    }
                                                </div>
                                            </div>

                                            <div className='confirmation_info'>
                                                <div className='text'>
                                                    <p>Event Food Services</p>
                                                    {
                                                        selectedFood === '' ?
                                                        <h1>No, Thank You</h1>
                                                        :
                                                        <h1>Yes, {selectedFood}</h1>
                                                    }
                                                </div>
                                                <div className='text'>
                                                    <p>Event Beverage Services</p>
                                                    {
                                                        selectedBeverageOptions.length === 0 ?
                                                        <h1>No, Thank You</h1>
                                                        :
                                                        <h1>Yes, {selectedBeverageOptions.join(", ")}</h1>

                                                        // selectedBeverage === '' ?
                                                        // <h1>No, Thank You</h1>
                                                        // :
                                                        // <h1>Yes, {selectedBeverage}</h1>
                                                    }
                                                </div>
                                            </div>
                                            <div className='confirmation_info'>
                                                <div className='text'>
                                                    <p>Event Manpower Services</p>
                                                    {
                                                        selectedManpowerOptions.length === 0 ?
                                                        <h1>No, Thank You</h1>
                                                        :
                                                        <h1>Yes, {selectedManpowerOptions.join(", ")}</h1>
                                                        
                                                        // selectedManpower === '' ?
                                                        // <h1>No, Thank you</h1>
                                                        // :
                                                        // <h1>Yes, {selectedManpower}</h1>
                                                    }
                                                </div>
                                                <div className='text'>
                                                    <p>Event Cutleries Services</p>
                                                    {
                                                        cutleriesCheck ?
                                                        <h1>Yes, I would love to</h1>
                                                        :
                                                        <h1>No, Thank you</h1>
                                                    }
                                                </div>
                                            </div>
                                            <div className='confirmation_info'>
                                                <div className='text'>
                                                    <p>Event Decor Services</p>
                                                    {
                                                        decorCheck ?
                                                        <h1>Yes, I would love to</h1>
                                                        :
                                                        <h1>No, Thank you</h1>
                                                    }
                                                </div>
                                                <div className='text'>
                                                    <p>Event Budget</p>
                                                    <h1>Rp{yourBudget ? parseInt(yourBudget).toLocaleString('id-ID') : 0}</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='confirmation_footer'>
                                            <button onClick={() => {handleSubmitQuestioner();}}  className='btn_next'>Start Planning</button>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                            }
                            {
                                backdrop ?
                                <div className='backdrop' onClick={e=>{handleClose()}}></div>
                                :
                                null
                            }
                            <section className='container first'>
                                <div className='breadcrumbs'>
                                    <img src={breadcrumbs} draggable='false'></img>
                                    <div className='breadcrumbs_text'>
                                        <h1>Start Planning Your Event</h1>
                                        <p>Please kindly fill the Questionaire on the form below</p>
                                    </div>
                                </div>
                            </section>
                            <section className='container separating plan'>
                                <div className='left__parts'>
                                    <div className='button__parts'>
                                        {wizard.map((wizard, index)=>{
                                            return<div className={`wizard__parts ${wizard.current ? 'current' : ''}`}>
                                            <div className='wizard__image'>
                                                { wizard.status ?
                                                    <img src={wizard.current ? checurrent : check} draggable='false'></img>
                                                    :
                                                    <img src={wizard.current ? current : ongoing} draggable='false'></img>
                                                }
                                            </div>
                                            <div className='wizard__text'>
                                                <p>Questionaire</p>
                                                <h1>{wizard.name}</h1>
                                            </div>
                                        </div>
                                        })}
                                    </div>
                                </div>
                                <div className='right__parts'>
                                    <div ref={topDivRef} className='scrollPoint'></div>
                                    <div className={`containerWrap ${wizard[0].current ? 'show' : 'hidden'} ${wizard[0].current ? 'slideUp' : ''}`}>

                                        <div className='inputGroup'>
                                            <div className='headerGroup'>
                                                <h1>When is Your Event?</h1>
                                            </div>
                                            {
                                                val6 && (
                                                    <div className='alert alert-fail'>
                                                        <i className='bx bx-info-circle'></i>
                                                        <p>Please fill the required fields!</p>
                                                    </div>
                                                )
                                            }
                                            {
                                                val10 && (
                                                    <div className='alert alert-fail'>
                                                        <i className='bx bx-info-circle'></i>
                                                        <p>Please select your Event date first!</p>
                                                    </div>
                                                )
                                            }
                                            <div class='inputFlex'>    
                                                <div className='inputText'>
                                                    <h4>Date</h4>
                                                    <div onClick={()=>{openDropdown("date")}} id="PSSdate" className='dropdown'>
                                                        <div className='text'>
                                                            <i className='bx bx-calendar-week'></i>
                                                            <p id="PSSdateshown">{dateShown}</p>
                                                        </div>
                                                        <img id="PSSdatedown" alt='icon' src={down}></img>
                                                        {   
                                                            showCalendar === true && (
                                                                <div className='dropdown_drop' ref={dateTop} onClick={e=>{e.stopPropagation();}}>
                                                                <DatePicker
                                                                    id="dateStartEnd"
                                                                    dateFormat="yyyy/mm/dd"
                                                                    selected={selectedDate}
                                                                    onChange={(date) => handleSelectDate(date)}
                                                                    monthsShown={1}
                                                                    inline
                                                                    filterDate={isWeekday}
                                                                    minDate={new Date()}
                                                                    showDisabledMonthNavigation
                                                                />
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>

                                                <div className='inputText'>
                                                    <h4>Time</h4>
                                                    <div onClick={() => {openDropdown("clock")}} id="PSSclock" className='dropdown'>
                                                        <div className='text'>
                                                            <i className='bx bx-time-five'></i>
                                                            <p id="PSSclockshown">{clockSelected}</p>
                                                        </div>
                                                        <img id="PSSclockdown" alt='icon' src={down}></img>
                                                        {
                                                            showClock === true &&(
                                                                <div className='dropdown_drop' ref={clockTop} onClick={e=>{e.stopPropagation();}}>
                                                                    <DatePicker selected={selectedTime} onChange={(date) => handleSelectTime(date)} showTimeSelect showTimeSelectOnly timeIntervals={30} timeCaption='Select Time' dateFormat='hh:mm aa' inline>
                                                                    </DatePicker>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <span className='orSeparator'>Or</span>

                                            <div className='inputText'>
                                                <h4>Event Date Range</h4>
                                                <div onClick={()=>{openDropdown("range")}} id="PSSdaterange" className='dropdown'>
                                                    <div className='text'>
                                                        <i className='bx bx-calendar-week'></i>
                                                        <p>{tbcSelected}</p>
                                                    </div>
                                                    <img alt='icon' src={down} id='PSSdaterangedown'></img>
                                                    {
                                                        showDaterange === true &&(
                                                            <div className='dropdown_drop range' onClick={e => {e.preventDefault();e.stopPropagation();}} ref={daterangeTop}>
                                                                <DatePicker 
                                                                    selectsRange={true}
                                                                    startDate={startDate} 
                                                                    endDate={endDate} 
                                                                    monthsShown={1} 
                                                                    onChange={onChange}
                                                                    minDate={new Date()}
                                                                    isClearable={false} 
                                                                    inline
                                                                    showDisabledMonthNavigation>

                                                                </DatePicker>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                                <small>Please choose between possible Date Range</small>
                                            </div>
                                        </div>
                                        
                                        <div className='inputGroup'>
                                            <div className='headerGroup'>                                    
                                                <h1>Where will your event area be?</h1>
                                            </div>
                                            <div className='inputText'>
                                                <h4>Event Area</h4>
                                                <label className='input' id='eventArea'>
                                                    <i className='bx bx-user'></i>
                                                    <input type='text' value={openEventArea} onChange={e => setopenEventArea(e.target.value)} placeholder='Enter Your Event Area' for='eventArea'></input>
                                                </label>
                                            </div>
                                            {/* <div className='inputText'>
                                                <h4>Event Area</h4>
                                                <div id="PSSLocation" onClick={()=>{openDropdown("area")}} className="dropdown">
                                                    <div className='text'>
                                                        <i className='bx bx-map-pin'></i>
                                                        <p id="homeloc">{choosenLocation}</p>
                                                    </div>
                                                    <img id="HPiconloc" src={down} alt="icon"></img>
                                                    {
                                                        showLocation === true && (
                                                            <div className='dropdown_drop' onClick={e => {e.preventDefault();e.stopPropagation();}} ref={locationTop}>
                                                                    <div className='dropdown_search'>
                                                                        <i className='bx bx-search-alt'></i>
                                                                        <input value={filterLocation} onChange={e => handleSearchLocation(e.target.value)} type='text' placeholder='Search Location'/>
                                                                    </div>
                                                                    <div className='border-top'>
                                                                    {
                                                                        eventarea.map((location, index) => {
                                                                            return<div className='location_pick'><p onClick={() => {handleLocation(location)}}>{location.name}</p></div>
                                                                        })
                                                                    }
                                                                    </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div> */}
                                        </div>
                                            
                                        <div className='inputGroup'>
                                            <div className='headerGroup'>
                                                <h1>How many people will attend?</h1>
                                            </div>
                                            <div className='inputText'>
                                                <h4>Number of pax</h4>
                                                <label className='input' id='PSSpax' for='numpax'>
                                                    <i className='bx bx-user'></i>
                                                    <input type='text' value={jmlpax === '' ? '' :  `${Number(jmlpax).toLocaleString('id-ID')}`} onChange={paxChange} placeholder='Enter Number of Pax' id='numpax'></input>
                                                </label>
                                            </div>
                                        </div>
                                        
                                        <div className='btn_flex'>
                                            <button onClick={() => {NextWizard(1);}}  className='btn_next'>Next</button>
                                        </div>     
                                    </div>

                                    <div className={`containerWrap ${wizard[1].current ? 'show' : 'hidden'} ${wizard[1].current ? 'slideUp' : ''}`}>
                                        <div className='inputGroup'>
                                            <div className='headerGroup'>
                                                <h1>Do you have dietary restriction?</h1>
                                                <p>Please specify how many pax has that dietary restriction</p>
                                            </div>
                                            {
                                                val7 && (
                                                    <div className='alert alert-fail'>
                                                        <i className='bx bx-info-circle'></i>
                                                        <p>Please fill the required fields!</p>
                                                    </div>
                                                )
                                            }
                                            <div className='inputText'>
                                                <h4>Dietary Restriction</h4>
                                                <label className={`input tags ${placeHolderDiet ? 'filled' : ''}`} id='dietaryRest'>
                                                    <i className='bx bx-bookmark-alt-minus'></i>
                                                    <div className='tag_wrapper'>
                                                        {
                                                            tagsDiet.map((tag,index)=>(
                                                                <div className='tag' key={index} onClick={() => removeTag(index)}>
                                                                    <p>{tag}</p>
                                                                    <div className='remove-tag'>&times;</div>
                                                                </div>
                                                            ))
                                                        }
                                                        <input for='dietaryRest' type='text' value={inputValueDiet} onChange={handleInputChange} onKeyDown={handleKeyDown} placeholder={placeHolderDiet ? '' : 'Enter Dietary Restrictions'} size={tagsDiet.length >= 1 ? inputValueDiet.length || 1 : null} onBlur={handleBlur}></input>
                                                    </div>
                                                </label>
                                                <small>Ex. 2 Pax Veggies, 6 pax Non Alcohol</small>
                                                <input value={tagsDiet} className='input d-none' readOnly></input>
                                            </div>
                                        </div>

                                        <div className='inputGroup'>
                                            <div className='headerGroup'>
                                                <h1>Do you have special request?</h1>
                                            </div>
                                            <div className='inputText'>
                                                <h4>Special Request</h4>
                                                <label className={`input tags ${placeHolderReq ? 'filled' : ''}`} id='specialReq'>
                                                    <i className='bx bx-bookmark-alt-plus'></i>
                                                    <div className='tag_wrapper'>
                                                        {
                                                            tagsReq.map((tag,index)=>(
                                                                <div className='tag' key={index} onClick={() => removeTagReq(index)}>
                                                                    <p>{tag}</p>
                                                                    <div className='remove-tag'>&times;</div>
                                                                </div>
                                                            ))
                                                        }
                                                        <input for='specialReq' type='text' value={inputValueReq} onChange={handleInputChangeReq} onKeyDown={handleKeyDownReq} placeholder={placeHolderReq ? '' : 'Enter Special Request'} size={tagsReq.length >= 1 ? inputValueReq.length || 1 : null} onBlur={handleBlurReq}></input>
                                                    </div>
                                                </label>

                                                <small>Ex. Chocolate Dessert, Full bar, Service Team, and etc.</small>
                                                <input value={tagsReq} className='input d-none' readOnly></input>
                                            </div>
                                        </div>
                                        
                                        <div className='inputGroup'>
                                            <div className='headerGroup'>
                                                <h1>Do you already have a concept?</h1>
                                            </div>
                                            <div className='inputText'>
                                                <h4>Event Concept</h4>
                                                <textarea className='input' placeholder='Kindly explain your concept here' onClick={e => {e.preventDefault();e.stopPropagation();handleCloseAll()}} value={yourConcept} onChange={e => setyourConcept(e.target.value)} rows={10} id='ownConcept'></textarea>
                                            </div>
                                        </div>

                                        <div className='btn_flex'>
                                            <button onClick={() => {BackWizard(2);}} className='btn_back'>Back</button>
                                            <button onClick={() => {NextWizard(2);}}  className='btn_next'>Next</button>
                                        </div>
                                    </div>

                                    <div className={`containerWrap ${wizard[2].current ? 'show' : 'hidden'} ${wizard[2].current ? 'slideUp' : ''}`}>
                                        <div className='inputGroup'>
                                            <div className='headerGroup'>
                                                <h1>Which Taplak Service do you wish to utilize?</h1>
                                                <p>Select one or more <u ><a onClick={handleToStartEvent}>Taplak Services</a></u> you would love to utilize</p>
                                            </div>
                                            {
                                                val8 && (
                                                    <div className='alert alert-fail'>
                                                        <i className='bx bx-info-circle'></i>
                                                        <p>Please fill the required fields!</p>
                                                    </div>
                                                )
                                            }
                                            <div id="foodCheck" className={`checkGroup ${foodCheck ? 'checked' : ''}`} onClick={checkFood}>
                                                <div className='termGroup'>
                                                    <input checked={foodService} value={foodService} type='checkbox' child='no' id="food" name="term"></input>
                                                    <label>Food</label>
                                                </div>
                                                <div className='radioGroup'>
                                                    <div className={`radioStyled ${selectedFood === 'Canapes' ? 'checked' : ''}`}>   
                                                        <input type="radio" value="Canapes" name="food" id="canapes" checked={selectedFood === 'Canapes'} onClick={handleSelectedFood}></input>
                                                        <label for="canapes">Canapes</label>
                                                    </div>
                                                    
                                                    <div  className={`radioStyled ${selectedFood === 'Buffet' ? 'checked' : ''}`}>
                                                        <input type="radio" value="Buffet" name="food" id="buffet" checked={selectedFood === 'Buffet'} onClick={handleSelectedFood}></input>
                                                        <label for="buffet">Buffet</label>
                                                    </div>

                                                    <div  className={`radioStyled ${selectedFood === 'Multiple Courses' ? 'checked' : ''}`}>
                                                        <input type="radio" value="Multiple Courses" name="food" id="course" checked={selectedFood === 'Multiple Courses'} onClick={handleSelectedFood}></input>
                                                        <label for="course">Multiple Courses</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="beverageCheck" className={`checkGroup ${beverageCheck ? 'checked' : ''}`} onClick={checkBeverage}>
                                                <div className='termGroup'>
                                                    <input checked={BeverageService} value={BeverageService} type='checkbox' child='no' id="beverage" name="term"></input>
                                                    <label>Beverage (Mixologist)</label>
                                                </div>
                                                {/* <div className='radioGroup'>
                                                    <div className={`radioStyled ${selectedBeverage === 'Non Alcohol' ? 'checked' : ''}`}>   
                                                        <input type="radio" value="Non Alcohol" name="beverage" id="nonalcohol" checked={selectedBeverage === 'Non Alcohol'} onClick={handleSelectedBeverage}></input>
                                                        <label for="nonalcohol">Non Alcohol</label>
                                                    </div>
                                                    
                                                    <div className={`radioStyled ${selectedBeverage === 'Alcohol' ? 'checked' : ''}`}>
                                                        <input type="radio" value="Alcohol" name="beverage" id="alcohol" checked={selectedBeverage === 'Alcohol'} onClick={handleSelectedBeverage}></input>
                                                        <label for="alcohol">Alcohol</label>
                                                    </div>
                                                </div> */}
                                                <div className='checkboxGroup'>
                                                    <div className={`checkboxStyled ${selectednonAlcohol ? 'checked' : ''}`}>
                                                        <input type='checkbox' child='yes' value="Non Alcohol" id='nonalcohol' onClick={handleSelectBeverageOptions} checked={selectednonAlcohol}></input>
                                                        <label for="nonalcohol">Non Alcohol</label>
                                                    </div>
                                                    <div className={`checkboxStyled ${selectedAlcohol ? 'checked' : ''}`}>
                                                        <input type='checkbox' child='yes' value="Alcohol" id='alcohol' onClick={handleSelectBeverageOptions} checked={selectedAlcohol}></input>
                                                        <label for="alcohol">Alcohol</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="manpowerCheck" className={`checkGroup ${manpowerCheck ? 'checked' : ''}`} onClick={checkManpower}>
                                                <div className='termGroup'>
                                                    <input checked={ManpowerService} value={ManpowerService} type='checkbox' child='no' id="manpower" name="term"></input>
                                                    <label>Manpower</label>
                                                </div>
                                                {/* <div className='radioGroup'>
                                                    <div className={`radioStyled ${selectedManpower === 'Service Team' ? 'checked' : ''}`}>   
                                                        <input type="radio" value="Service Team" name="manpower" id="service" checked={selectedManpower === 'Service Team'} onClick={handleSelectedManpower}></input>
                                                        <label for="service">Service Team</label>
                                                    </div>
                                                    
                                                    <div className={`radioStyled ${selectedManpower === 'Bar Team' ? 'checked' : ''}`}>
                                                        <input type="radio" value="Bar Team" name="manpower" id="bar" checked={selectedManpower === 'Bar Team'} onClick={handleSelectedManpower}></input>
                                                        <label for="bar">Bar Team</label>
                                                    </div>

                                                    <div className={`radioStyled ${selectedManpower === 'Kitchen Team' ? 'checked' : ''}`}>
                                                        <input type="radio" value="Kitchen Team" name="manpower" id="kitchen" checked={selectedManpower === 'Kitchen Team'} onClick={handleSelectedManpower}></input>
                                                        <label for="kitchen">Kitchen Team</label>
                                                    </div>
                                                </div> */}
                                                <div className='checkboxGroup'>
                                                    <div className={`checkboxStyled ${selectedService ? 'checked' : ''}`}>
                                                        <input type='checkbox' child='yes' value="Service Team" id='service' onClick={handleSelectManpowerOptions} checked={selectedService}></input>
                                                        <label for="service">Service Team</label>
                                                    </div>
                                                    <div className={`checkboxStyled ${selectedBar ? 'checked' : ''}`}>
                                                        <input type='checkbox' child='yes' value="Bar Team" id='bar' onClick={handleSelectManpowerOptions} checked={selectedBar}></input>
                                                        <label for="bar">Bar Team</label>
                                                    </div>
                                                    <div className={`checkboxStyled ${selectedKitchen ? 'checked' : ''}`}>
                                                        <input type='checkbox' child='yes' value="Kitchen Team" id='kitchen' onClick={handleSelectManpowerOptions} checked={selectedKitchen}></input>
                                                        <label for="kitchen">Kitchen Team</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="cutleriesCheck" className={`checkGroup ${cutleriesCheck ? 'checked' : ''}`} onClick={checkCutleries}>
                                                <div className='termGroup'>
                                                    <input checked={CultleriesService} value={CultleriesService} type='checkbox'  child='no' id="term4" name="term"></input>
                                                    <label>Cultleries and Ceramic Ware</label>
                                                </div>           
                                            </div>

                                            <div id="decorCheck" className={`checkGroup ${decorCheck ? 'checked' : ''}`} onClick={checkDecor}>
                                                <div className='termGroup'>
                                                    <input checked={DecorService} value={DecorService} type='checkbox' child='no' id="term5" name="term"></input>
                                                    <label for="term5">Decor</label>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className='inputGroup'>
                                            <div className='headerGroup'>
                                                <h1>Do you have budget in mind?</h1>
                                            </div>
                                            <div className='inputText'>
                                                <h4>Your Budget</h4>
                                                <label className='input' id='budgetCheck' for='budgets'>
                                                    <i className='bx bx-wallet'></i>
                                                    <input type='text' value={yourBudget === '' ? '' : `Rp${Number(yourBudget).toLocaleString('id-ID')}`} onChange={budgetChange} placeholder='Enter your Budget' id='budgets'></input>
                                                    {/* <input type='number' value={yourBudget} onChange={e => setyourBudget(e.target.value)} placeholder='Enter your Budget' id='budgets'></input> */}
                                                </label>
                                            </div>
                                        </div>

                                        <div className='btn_flex'>
                                            <button onClick={() => {BackWizard(3);}} className='btn_back'>Back</button>
                                            <button onClick={() => {NextWizard(3);}}  className='btn_next'>Next</button>
                                        </div>   
                                    </div>

                                    <div className={`containerWrap ${wizard[3].current ? 'show' : 'hidden'} ${wizard[3].current ? 'slideUp' : ''}`}>
                                        <div className='inputGroup'>
                                            <div className='headerGroup'>
                                                <h1>Contact Person</h1>
                                                <p>Please tell your Name and Phone Number so we can reach you</p>
                                            </div>
                                            {
                                                val9 && (
                                                    <div className='alert alert-fail'>
                                                        <i className='bx bx-info-circle'></i>
                                                        <p>Please fill the required fields!</p>
                                                    </div>
                                                )
                                            }
                                            <div className='inputFlex mb-1'>
                                                <div className='inputText'>
                                                    <h4>First Name</h4>
                                                    <label className='input' id='firstName'>
                                                        <i className='bx bx-user'></i>
                                                        <input type='text' value={firstName} onChange={e => setfirstName(e.target.value)} placeholder='Enter First Name' for='firstName'></input>
                                                    </label>
                                                </div>
                                                <div className='inputText'>
                                                    <h4>Last Name</h4>
                                                    <label className='input' id='lastName'>
                                                        <i className='bx bx-user'></i>
                                                        <input type='text' value={lastName} onChange={e => setlastName(e.target.value)} placeholder='Enter Last Name' fpr='lastName'></input>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='inputText'>
                                                <h4>Phone Number</h4>
                                                <label className='input' id='numberPhone'>
                                                    <i className='bx bx-phone-call'></i>
                                                    <input type='number' value={phoneNumber} onChange={e => setphoneNumber(e.target.value)} placeholder='Enter Phone Number' for='phoneNumber' maxLength={17}></input>
                                                </label>
                                            </div>
                                        </div>     
                                        <div className='btn_flex'>
                                            <button onClick={() => {BackWizard(4);}} className='btn_back'>Back</button>
                                            <button onClick={() => {NextWizard(4)}}  className='btn_next'>Finish Questionaire</button>
                                        </div>                              
                                    </div>
                                </div>
                            </section>
                        </main>
                    }
                </CSSTransition>
            }
            {
                showLogin && (
                <PageLogin fungsi={() => setShowLogin(false)}></PageLogin>
                )
            }
            
        </div>
    )
  }