import './index.css'
import { DesktopNavbar } from '../NavBar/Desktop/DesktopNavbar'
import {useState, useEffect, useRef} from 'react'
import "react-datepicker/dist/react-datepicker.css";
import { CSSTransition } from 'react-transition-group';
import { MobileNavbar } from '../NavBar/Mobile/MobileNavbar';
import eventimg from '../../assets/eventimg.png'
import spinner from '../../assets/spinner.gif'
import { HomeTypeCard } from '../Card/HomeTypeCard/HomeTypeCard';
import axios from 'axios';
import { baseUrl } from '../../lib/api';
import { FloatingWhatsAppCustom } from '../FloatingWhatsAppCustom/FloatingWhatsAppCustom';
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

export const PageEvent = () => {

    const [detail, setDetail] = useState()
    const [loading, setLoading] = useState(true)
    const [showNavPopup, setShowNavPopup] = useState(false)
    const handleCloseAll = () => {
        setShowNavPopup(false)
    }
    useEffect(() =>{
        window.scrollTo(0, 0)
        setLoading(true)
        const eventFromDB = async () => {
            try {
                const data = await callApigetEvent("incoming")
                setDetail(data)
            } catch (error) {
                console.log("Error eventFromDB")
            }
        }
        eventFromDB()
        // setDetail([{gambar:eventimg,judul:"Thanksgiving",style:"American style featuring Turkey and Sides", sisa:10, total:20, tanggal:"24 Feb", jam:"18:00 - 20:00", tempat:"Cilandak Town Square, Jalan Tahi Bonar Simatupang, RT.6/RW.9, West Cilandak, South Jakarta City, Jakarta, Indonesia"},
        // {gambar:eventimg,judul:"Asian Party",style:"Asian Style", sisa:3, total:10, tanggal:"24 Feb", jam:"18:00 - 20:00", tempat:"Hotel Santika, BSD City"},
        // {gambar:eventimg,judul:"Welcome",style:"American style featuring Turkey and Sides", sisa:10, total:10, tanggal:"24 Feb", jam:"18:00 - 20:00", tempat:"Hotel Santika, BSD City"},
        // {gambar:eventimg,judul:"Thanksgiving",style:"American style featuring Turkey and Sides", sisa:10, total:20, tanggal:"24 Feb", jam:"18:00 - 20:00", tempat:"Cilandak Town Square, Jalan Tahi Bonar Simatupang, RT.6/RW.9, West Cilandak, South Jakarta City, Jakarta, Indonesia"},
        // {gambar:eventimg,judul:"Asian Party",style:"Asian Style", sisa:3, total:10, tanggal:"24 Feb", jam:"18:00 - 20:00", tempat:"Hotel Santika, BSD City"},
        // {gambar:eventimg,judul:"Welcome",style:"American style featuring Turkey and Sides", sisa:10, total:10, tanggal:"24 Feb", jam:"18:00 - 20:00", tempat:"Hotel Santika, BSD City"}])
        localStorage.setItem('nav', 'e')
    },[])
    const [standby, setstandby] = useState(true)
    const firstUpdate = useRef(true);
    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
        }else{
            setLoading(false)
        }
    },[detail])

    const stylesParser = (styles) => {
        if (!styles) {
            return "N/A"
        }
        // styles = styles.join(', ').replace(/, ([^,]*)$/, ' and $1')
        // styles = styles[0].name
        styles = styles.map(({name}) => `${name}`).join(',');
        return styles
    }

    const parseTimeEvent = (dates) => {
        const timeStartArr = dates["start_time"].split(":")
        const timeEndArr = dates["end_time"].split(":")
        
        const parsedTimeStart = timeStartArr[0].concat(":", timeStartArr[1]);
        const parsedTimeEnd = timeEndArr[0].concat(":", timeEndArr[1]);

        const parsedTime = `${parsedTimeStart} - ${parsedTimeEnd}`
        return parsedTime
    }

    const dateEventParser = (dates) => {
        if (!dates || !dates.length) {
            return {
                "date": "N/A",
                "time_start": "N/A"
            }
        }
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ]

        const firstNonZeroValue = dates.find(value => value.remaining_seats !== 0);
    
        if (firstNonZeroValue !== undefined) {
          // You have the first non-zero value here
          dates = firstNonZeroValue
        //   console.log('First non-zero value:', firstNonZeroValue);
        } else {
          // No non-zero values found in the array
          console.log('No non-zero values found');
          dates = dates[0]
        }

        // let [_, month, date] = dates["date"]
        let [_, month, date] = dates["date_events"].split("-")
        const idxMonth = Number(month)
        month = monthNames[idxMonth].slice(0, 3)
        date = Number(date)
        const parsedDate = `${date} ${month}`

        return {
            "date": parsedDate,
            "time_start": parseTimeEvent(dates)
        }
    }

    const parseEvent = (eventData) => {
        eventData = eventData.map((el) => {
            const { date, time_start } = dateEventParser(el.events_date)
            // if(el.events_date.length !== 0){
            return {
                id: el.id,
                gambar: el.cover_img_url,
                judul: el.event_name,
                style: stylesParser(el.event_style),
                // sisa: el.events_date[0] ? el.events_date[0]["remaining_seats"]:0,
                sisa: parseSisa(el.events_date ? el.events_date: 0),
                // sisa: el.min_pax,
                total:el.events_date[0] ? el.events_date[0]["max_seat"]:0,
                // total: el.max_pax,
                tanggal: date,
                jam: time_start,
                tempat: el.vanue_location
            // }
        }
        })
        return eventData
    }

    const parseSisa = (events_dates) => {
        let sisaValue = 0
        // console.log("tes data 0=> "+JSON.stringify(events_dates))
        const firstNonZeroValue = events_dates.find(value => value.remaining_seats !== 0);
    
        if (firstNonZeroValue !== undefined) {
          // You have the first non-zero value here
          sisaValue = firstNonZeroValue.remaining_seats
        //   console.log('First non-zero value:', firstNonZeroValue);
        } else {
          // No non-zero values found in the array
          console.log('No non-zero values found');
        }

        // console.log('sisa value => '+ sisaValue);

        // events_dates.forEach((element,index) => {
        //     console.log("for each data => "+ JSON.stringify(element))
        //     if(element.remaining_seats === 0){
        //         console.log("for each data pertama null => ")
        //     } else {
        //         console.log("for each data pertama ada => ")
        //     }
        // })
        
        
        return sisaValue
    }

    const callApigetEvent = async (type) => {
        try {
            let params = null
            if (type) {
                params = { type }
            }
            const { data: res } = await axios({
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'get',
                url: `${baseUrl}event`,
                params
            })
            const eventData = res.data
            // console.log(eventData)
            const parsedEvent = parseEvent(eventData)
            return parsedEvent
        } catch (error) {
            console.log(error)
            console.log("error getEventFromDB")
            // Create error handler (pop-up or something)
        }
    }

    return (
        <div onClick={e => {e.stopPropagation();handleCloseAll()}} id="maincontainer" style={{minWidth:"100%", minHeight:"100vh", backgroundColor:"#111111", position:"relative", overflow:"unset"}}>
            <DesktopNavbar showPopup={showNavPopup} setShowPopup={setShowNavPopup}></DesktopNavbar>
            <MobileNavbar></MobileNavbar>
            <FloatingWhatsAppCustom></FloatingWhatsAppCustom>
            {
                loading?
                <img alt='loading' className='taplakLoading' src={spinner}></img>
                :
                <CSSTransition in={standby} timeout={{appear:1000,enter:2000,exit:1000}} mountOnEnter={true} appear={true} classNames="EPtrans">
                <div>
                    <div className='EPatas'>
                        <div className='kontainercardWrapper' style={{padding:"0"}}>
                            <div className='kontainerHeader'>
                                <h1>Next Event</h1>
                            </div>
                            <div className='kontainerList PageEventWide'>
                                <HomeTypeCard
                                    id = {detail[0].id}
                                    gambar = {detail[0].gambar} 
                                    judul = {detail[0].judul} 
                                    style = {detail[0].style} 
                                    sisa = {detail[0].sisa} 
                                    total = {detail[0].total} 
                                    tanggal = {detail[0].tanggal} 
                                    jam = {detail[0].jam} 
                                    tempat = {detail[0].tempat}
                                    showJoinNow = {true}
                                    fungsi={()=>setstandby(false)}
                                />
                            </div>
                        </div>

                        <div className='kontainercardWrapper' style={{padding:"50px 0 100px 0"}} data-aos="fade-up" data-aos-delay="400" data-aos-duration="600">
                            <div className='kontainerHeader'>
                                <h1>Upcoming Event</h1>
                            </div>
                            <div className="kontainerList PageEvent"> 
                                {
                                    detail.slice(0,detail.length).map((post) => (
                                        <HomeTypeCard
                                        id={post.id}
                                        gambar = {post.gambar} 
                                        judul = {post.judul} 
                                        style = {post.style} 
                                        sisa = {post.sisa} 
                                        total = {post.total} 
                                        tanggal = {post.tanggal} 
                                        jam = {post.jam} 
                                        tempat = {post.tempat}
                                        fungsi={()=>setstandby(false)}
                                        />
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                </CSSTransition>
            }
        </div>
    )
  }