import './index.css'
import { DesktopNavbar } from '../NavBar/Desktop/DesktopNavbar'
import {useState, useEffect, useRef} from 'react'
import "react-datepicker/dist/react-datepicker.css";
import { MobileNavbar } from '../NavBar/Mobile/MobileNavbar'
// import foto1 from '../../assets/foto1.png'
// import foto2 from '../../assets/foto2.png'
// import foto3 from '../../assets/foto3.png'
import { CategoryCard } from '../Card/CategoryTypeCard/CategoryCard';
import { CSSTransition } from 'react-transition-group';
import spinner from '../../assets/spinner.gif'
import { baseUrl } from '../../lib/api';
import axios from 'axios';
import { FloatingWhatsAppCustom } from '../FloatingWhatsAppCustom/FloatingWhatsAppCustom';

export const KategoriPage = () => {
    const [category, setCategory] = useState([])
    const [standby, setstandby] = useState(true)
    const [loading, setLoading] = useState(true)
    const firstUpdate = useRef(true);
    const [showNavPopup, setShowNavPopup] = useState(false)

    const handleCloseAll = () => {
        setShowNavPopup(false)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        localStorage.setItem('isBookingChefStillProggress',false)
        localStorage.removeItem('chefNameDraftFlow')
        localStorage.removeItem('chefIdDraftFlow')
        localStorage.setItem('isBookingChefStillProggressVenue',false)
        const fetchData = async () =>{
            setLoading(true);
            try{
                const categoryURL = `${baseUrl}category`
                const {data: response} = await axios.get(categoryURL);
                // Edit data for display reason, some photos can't be fetched
                // const finalData = response.data.map((el, idx) => {
                //     return {...el, 
                //         "cover_image": idx === 1 ? foto1 : idx === 2 ? foto2 : foto3
                //     }
                // })
                // setCategory(finalData);
                setCategory(response.data);
            }catch(error){
                console.error(error.message);
            }
            setLoading(false);
        }
        localStorage.setItem('nav', 'fnd')
        fetchData()
    },[])

   

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
        }else{
            setLoading(false)
        }
    },[category])

    return(
        <div onClick={e => {e.stopPropagation();handleCloseAll()}} id="maincontainer" style={{minWidth:"100%", minHeight:"100vh", backgroundColor:"#111111", position:"relative", overflow:"unset"}}>
            <DesktopNavbar showPopup={showNavPopup} setShowPopup={setShowNavPopup}></DesktopNavbar>
            <MobileNavbar></MobileNavbar>
            <FloatingWhatsAppCustom></FloatingWhatsAppCustom>
            {
                loading?
                <img alt='loading' className='taplakLoading' src={spinner}></img>
                :
                <CSSTransition in={standby} timeout={{appear:1000,enter:2000,exit:1000}} mountOnEnter={true} appear={true} classNames="KPtrans">
                <div className='CategoryCardContainer category'>
                    {category.map((post) => (
                        <CategoryCard standby={standby} fungsi={()=>setstandby(false)} gambar={post.category_image_url} judul={post.name} deskripsi={post.slug}/>
                        ))}
                </div>
                </CSSTransition>
            }
        </div>
    )
  }