/* eslint-disable array-callback-return */
import './index.css'
import { DesktopNavbar } from '../NavBar/Desktop/DesktopNavbar'
import {useState, useEffect, useRef} from 'react'
import "react-datepicker/dist/react-datepicker.css";
import beef from '../../assets/beef.svg'
import makanan1 from '../../assets/makanan1.png'
import makanan2 from '../../assets/makanan2.png'
import { useLocation, useNavigate } from "react-router-dom";
import pork from '../../assets/pork.svg'
import star from '../../assets/star.svg'
import stove from '../../assets/stove.svg'
import microwave from '../../assets/microwave.svg'
import toRupiah from '@develoka/angka-rupiah-js';
import circleback from '../../assets/circleback.svg'
import oven from '../../assets/oven.svg'
import carleft from '../../assets/carleft.svg'
import carright from '../../assets/carright.svg'
import { CSSTransition } from 'react-transition-group';
import spinner from '../../assets/spinner.gif'
import axios from 'axios';
import { baseUrl } from '../../lib/api';
import { FloatingWhatsAppCustom } from '../FloatingWhatsAppCustom/FloatingWhatsAppCustom';

export const PageDetailMenu = ({ id, chefId }) => {
    const { state } = useLocation()
    const [detail, setDetail] = useState()
    const [loading, setLoading] = useState(true)
    const [showNavPopup, setShowNavPopup] = useState(false)
    const firstUpdate2 = useRef(true);
    useEffect(() =>{
        const { dataMenu, chefId ,chefRating, chefName, chefFoto} = state

        // console.log(dataMenu)
        window.scrollTo(0, 0)
        setLoading(true)
        // get menu detail version 2
        const menuDetailFromDbV2 = async () => {
            try {
                const data = await callApigetMenuDetailV2(chefId, dataMenu,chefRating,chefName,chefFoto)
                setDetail(data)
            } catch (error) {
                console.log("Error menuDetailFromDB")
            }
        }
        
        // get menu detail version 1
        const menuDetailFromDB = async () => {
            try {
                const data = await callApigetMenuDetail()
                setDetail(data)
            } catch (error) {
                console.log("Error menuDetailFromDB")
            }
        }

        menuDetailFromDbV2()
        // menuDetailFromDB()
        localStorage.setItem('nav', 'fnd')
        localStorage.setItem('chefId', chefId)
        localStorage.setItem('menuId', dataMenu.food[0].bussines_id)
    },[])

    useEffect(() => {
        if (firstUpdate2.current) {
            firstUpdate2.current = false;
        }else{
            setLoading(false)
        }
    },[detail])

    const [menu, setMenu] = useState(1)
    const [standby, setstandby] = useState(true)
    const firstUpdate = useRef(true);
    const history = useNavigate()

    useEffect(()=>{
        
        if(standby===false){

            if(localStorage.getItem('chefNameDraftFlow') === detail.chef && parseInt(localStorage.getItem('chefIdDraftFlow') ?? 0) === detail.id){
                localStorage.setItem('isBookingChefStillProggress',true)
                localStorage.setItem('isBookingChefStillProggressVenue',true)
            } else {
                localStorage.setItem('isBookingChefStillProggress',false)
                localStorage.setItem('isBookingChefStillProggressVenue',false)
            }
            
            setTimeout(function() {
                history('/category/chefList/detailChef/detailMenu/setSchedule', { state: { detail } });
           }, 500);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[standby])

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
          } else {
            var elem = document.getElementById('DMPcardimg');
            elem.style.transition = "background-image 0.5s";
            elem.style.backgroundImage = `url(${detail.menu[menu-1].image})`;
          }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[menu])

    // Call API version 2
    const callApigetMenuDetailV2 = async (chefId, dataMenu, chefRating,chefName,chefFoto) => {
        try {
            // const { data: res } = await axios({
            //     method: 'get',
            //     url: `${baseUrl}chef/${chefId}/${foodId}`
            // })
            // const menuDetail = [res.data]
            const parsedMenuDetail = parseMenuDetail([dataMenu],chefRating,chefName,chefFoto,chefId)
            return parsedMenuDetail
        } catch (error) {
            console.log(error)
            console.log("error getMenuDetailFromDB")
            // Create error handler (pop-up or something)
        }
    }

    // Call API version 1
    const callApigetMenuDetail = async () => {
        try {
            const { data: res } = await axios({
                method: 'get',
                url: `${baseUrl}chef/Chef-Taplak/anniversary-pik`
            })
            const menuDetail = [res.data]
            const parsedMenuDetail = parseMenuDetail(menuDetail)
            return parsedMenuDetail
        } catch (error) {
            console.log("error getMenuDetailFromDB")
            // Create error handler (pop-up or something)
        }
    }

    // Helpers
    const ubahGambar = (e) => {
        if(e === "kiri"){
            if(menu===1){
                setMenu(detail.menu.length)
            }else{
                setMenu(menu-1)
            }
        }else{
            if(menu===detail.menu.length){
                setMenu(1)
            }else{
                setMenu(menu+1)
            }
        }
    }

    const handleCloseAll = () => {
        setShowNavPopup(false)
    }

    const menuParser = (menus) => {
        let parsedMenus = []
        menus.forEach(element => {
            if(element.id !== null && element.nama !== null){
                // console.log(element.nama)
                parsedMenus = [...parsedMenus,
                    {
                        name: element.nama,
                        image: element.gambar
                    }
                ]
            }
        });
        // const parsedMenus = menus.map((el, idx) => {
        //     return {
        //         nama: el.nama,
        //         gambar: el.gambar
        //     }
        // })
        // console.log("menu asd => "+parsedMenus)
        return parsedMenus
    }

    const parseMenuDetail = (menuDetail, chefRating,chefName,chefFoto,chefId) => {
        menuDetail = menuDetail.map((el) => {
            return {
                id: chefId,
                chef: chefName,
                foto: chefFoto,
                rate: chefRating,
                nama: el.jenis,
                max_pax: el.max,
                bahan: ["pork","beef"],
                harga: el.harga,
                tool: ['Oven','Microwave','Stove'],
                voucher:parseVoucher(el.voucher ?? "N/A"),
                menu: menuParser(el.food),
                equipmentData: parseEquipment(el.equipmentData !== "N/A" ? el.equipmentData:null),
                containData: parseContain(el.containData !== "N/A" ? el.containData:null),
            }
        })
        return menuDetail[0]
    } 

    const parseContain = (contain) => {
        if(!contain || contain.length < 1){
            return "N/A"
        }

        contain = contain.map(el => {
            if(el !== null){
                return {
                    containId: el["containId"],
                    deskripsi: el["deskripsi"],
                    icon: el["icon"],
                    file_url: el["file_url"]
                }
            }
            
        })

        return contain
    }

    const parseEquipment = (equipment) => {
        if(!equipment || equipment.length < 1){
            return "N/A"
        }

        equipment = equipment.map(el => {
            if(el !== null){
                return {
                    equipmentId: el["equipmentId"],
                    deskripsi: el["deskripsi"],
                    icon: el["icon"],
                    file_url: el["file_url"]
                }
            }
            
        })

        return equipment
    }

    const parseVoucher = (vouchers) => {
        if(vouchers === "N/A"){
            return "N/A"
        }

        vouchers = vouchers.map(el => {
            return {
                id: el["id"],
                name: el["name"],
                discount: el["discount"],
                quota: el["quota"]
            }
        })

        return vouchers
    }
    
    return (
        <div onClick={e => {e.stopPropagation();handleCloseAll()}} id="maincontainer" style={{minWidth:"100%", minHeight:"100vh", backgroundColor:"#111111", position:"relative", overflow:"unset"}}>
            <DesktopNavbar showPopup={showNavPopup} setShowPopup={setShowNavPopup}></DesktopNavbar>
            <FloatingWhatsAppCustom></FloatingWhatsAppCustom>
            <img onClick={e => {e.preventDefault();e.stopPropagation();history(-1)}} className='backmobile' alt="back" src={circleback}></img>
            {
                !loading && (
                <CSSTransition in={standby} timeout={{appear:1000,enter:2000,exit:1000}} mountOnEnter={true} appear={true} classNames="DMPtrans">
                <div className='flex DMPbuttonmobile' onClick={e => {e.preventDefault();e.stopPropagation();history("/category/chefList/detailChef/detailMenu/setSchedule", { state: { detail } })}}>
                    <p className='small bold' style={{marginLeft:"auto",marginRight:"auto"}}>Choose This Menu</p>
                    <p className='tiny normal' style={{marginLeft:"auto",marginRight:"auto"}}>Start from {toRupiah(detail.harga, {formal: false, symbol: 'IDR'})}/Pax</p>
                </div>
                </CSSTransition>
                )
            }
            {
                loading?
                <img alt='loading' className='taplakLoading' src={spinner}></img>
                :
                <CSSTransition in={standby} timeout={{appear:1000,enter:2000,exit:1000}} mountOnEnter={true} appear={true} classNames="DMPtrans">
                <div className='DMPkontenkontainer flex'>

                    <div className='DMPkontenkiri flex'>
                        <div className='DMPmenunav flex'>
                            <p className='bold white medium'>Menu</p>
                            <img onClick={() => ubahGambar("kiri")} src={carleft} alt="icon" className='DMPiconnav1'></img>
                            <p className='bold white medium'>{menu} <span className='white medium normal'>of {detail.menu.length} </span></p>
                            <img onClick={() => ubahGambar("kanan")} src={carright} alt='icon' className='DMPiconnav2'></img>
                        </div>

                        <div className='DMPcardkontainer flex'>
                            <div id='DMPcardimg' style={{backgroundImage:`url(${detail.menu[0].image})`}} className='DMPcardatas'></div>
                            <div className='DMPcardbawah'>
                                <p className='medium white bold'>{detail.menu[menu-1].name}</p>
                                {/* <p className='small white normal' style={{marginTop:"24px"}}>This Set Menu Contains</p> */}
                                <div className='flex' style={{marginTop:"24px"}}>
                                    {
                                        // detail.bahan.map((post) => {
                                        //     if(post==="beef"){
                                        //         return(<div style={{display:"flex", flexDirection:"column", marginRight:"24px"}}>
                                        //                     <img alt="icon" src={beef} className='DMPiconcard' style={{marginLeft:"auto", marginRight:"auto", marginBottom:"4px"}}></img>
                                        //                     <p className='small white normal' style={{textAlign:"center"}}>Beef</p>
                                        //                 </div>)
                                        //     }else if(post==="pork"){
                                        //         return(<div style={{display:"flex", flexDirection:"column",marginRight:"24px"}}>
                                        //                     <img alt="icon" src={pork} className='DMPiconcard' style={{marginLeft:"auto", marginRight:"auto", marginBottom:"4px"}}></img>
                                        //                     <p className='small white normal' style={{textAlign:"center"}}>Pork</p>
                                        //                 </div>)
                                        //     }
                                        // })

                                        detail.containData !== "N/A" ? 

                                        detail.containData.map((post) => 
                                            (<div style={{display:"flex", flexDirection:"column", marginRight:"24px"}}>
                                                            <img alt="icon" src={post.icon} className='DMPiconcard' style={{marginLeft:"auto", marginRight:"auto", marginBottom:"4px"}}></img>
                                                            <p className='small white normal' style={{textAlign:"center"}}>{post.deskripsi}</p>
                                                        </div>)
                                        ) :<div></div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='DMPkontenkanan'>
                        <p className='big bold white'>{detail.nama}</p>
                        <div className='flex'>
                            <p className='medium normal white'>By <span className='bold'>Chef {detail.chef}</span></p>
                            <img alt='icon' src={star} className='DMPiconnav1'></img>
                            <p className='medium normal white'>{detail.rate}</p>
                        </div>
                        <div style={{height:"1px",backgroundColor:"#6F6F6F", marginTop:"16px"}}></div>
                        <p className='small white marginbig'>Menu</p>
                        <p className='small white bold'>
                            {
                                detail.menu.map((post) => {
                                    if(post.name === detail.menu[0].name){
                                        return(<span>{post.name}</span>)

                                    }else{
                                        return(<span>, {post.name}</span>)
                                    }
                                })
                            }
                        </p>
                        <p className='small normal white marginbig'>Maximal guest</p>
                        <p className='small bold white'>{detail.max_pax} guest</p>
                        <p className='small normal white marginbig'>Required kitchen tools</p>
                        <p style={{color:"#AAAAAA", fontSize:"14px",margin:"0"}}>This Set Menu requires the following tools to be available for use in the Venue.</p>
                        {/* <div className='flex marginbig'>{
                            detail.tool.map((post) => {
                                if(post==="Oven"){
                                    return(<div style={{display:"flex", flexDirection:"column", marginRight:"24px"}}>
                                                <img alt="icon" src={oven} className='DMPiconcard' style={{marginLeft:"auto", marginRight:"auto", marginBottom:"4px"}}></img>
                                                <p className='small white normal' style={{textAlign:"center"}}>Oven</p>
                                            </div>)
                                }else if(post==="Stove"){
                                    return(<div style={{display:"flex", flexDirection:"column",marginRight:"24px"}}>
                                                <img alt="icon" src={stove} className='DMPiconcard' style={{marginLeft:"auto", marginRight:"auto", marginBottom:"4px"}}></img>
                                                <p className='small white normal' style={{textAlign:"center"}}>Stove</p>
                                            </div>)
                                }else if(post==="Microwave"){
                                    return(<div style={{display:"flex", flexDirection:"column",marginRight:"24px"}}>
                                                <img alt="icon" src={microwave} className='DMPiconcard' style={{marginLeft:"auto", marginRight:"auto", marginBottom:"4px"}}></img>
                                                <p className='small white normal' style={{textAlign:"center"}}>Microwave</p>
                                            </div>)
                                }
                            })
                        }</div> */}

                        <div className='flex marginbig'>{
                        
                                detail.equipmentData !== "N/A" ? 
                            
                            detail.equipmentData.map((post) => (
                                <div style={{display:"flex", flexDirection:"column", marginRight:"24px"}}>
                                                <img alt="icon" src={post.icon} className='DMPiconcard' style={{marginLeft:"auto", marginRight:"auto", marginBottom:"4px"}}></img>
                                                <p className='small white normal' style={{textAlign:"center"}}>{post.deskripsi}</p>
                                            </div>
                            )): <div></div>
                        }</div>

                        <div onClick={() => setstandby(false)} className='flex DMPbutton'>
                            <p className='small bold' style={{marginLeft:"auto",marginRight:"auto"}}>Choose This Menu</p>
                            <p className='tiny normal' style={{marginLeft:"auto",marginRight:"auto"}}>Start from {toRupiah(detail.harga, {formal: false, symbol: 'IDR'})}/Pax</p>
                        </div>
                    </div>
                </div>
                </CSSTransition>
            }
        </div>
    )
  }