import './index.css';
import { DesktopNavbar } from '../NavBar/Desktop/DesktopNavbar'
import { FloatingWhatsAppCustom } from '../FloatingWhatsAppCustom/FloatingWhatsAppCustom';
import { ChefCards } from "../Card/MenuListNew/ChefCard.js";
import { MenuBanner } from "../Banner/menuBanner.js";
import search from '../../assets/search.svg';
import forkspoonWhite from '../../assets/forkspoon2.svg';
import sort from '../../assets/options.svg';
import sortGray from '../../assets/options_gray.svg';
import pin from '../../assets/pin-1.svg' ;
import pinGray from '../../assets/pin-1_gray.svg';
import { useLocation, useNavigate } from "react-router-dom";
import { baseUrl } from '../../lib/api'
import { CSSTransition } from 'react-transition-group';
import spinner from '../../assets/spinner.gif'
import {useState, useEffect, useCallback, useRef} from 'react';
import search2 from '../../assets/search2.svg';
import back from '../../assets/back.svg';
import axios from 'axios'
import AOS from 'aos';
import useDraggableScroll from 'use-draggable-scroll';
import 'aos/dist/aos.css';
AOS.init();

export const ChefList = () =>{
    const [openLocation, setOpenLocation] = useState(false);
    const [openSortBy, setOpensortBy] = useState(false);
    const [showNavPopup, setShowNavPopup] = useState(false);
    const [showSearchMobile, setShowSearchMobile] = useState(false);
    const [showSearchMobileClass, setShowSearchMobileClass] = useState(false);
    const [showSortByMobile, setShowSortByMobile] = useState(false);
    const [showSortByMobileClass, setShowSortByMobileClass] = useState(false);
    const [standby, setstandby] = useState(true)
    const [sortBy, setSortBy] = useState('Sort by')
    const listCategory = []
    const listLocation = []
    const [filterChef, setFilterChef] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [filterLocation, setFilterLocation] = useState('Location')
    const [listLocationFound, setListLocationFound] = useState([])
    const [listCategoryFound, setListCategoryFound] = useState([])
    const [filteredCategory, setFilteredCategory] = useState([])
    const [isFirstStart, setIsFirstStart] = useState(true)
    const [filterListCategoryElement, setfilterListCategoryElement] = useState("")
    const [databaseLocation, setDatabaseLocation] = useState([])
    const [showFilterLocation, setShowFilterLocation] = useState(false)
    const [isFromMenu, setIsFromMenu] = useState(false)
    const loc = useLocation()
    const { state }  = useLocation()
    const [selectedSpeciality, setSelectedSpeciality] = useState()
    const [category, setCategory] = useState([])
    const [speciality, setSpeciality] = useState([])
    const [indexListSpeciality, setIndexListSpeciality] = useState()
    const [isFromSearchData, setIsFromSearchData] = useState(loc.state?.isFromSearch)
    const firstUpdate = useRef(true);
    const [loading, setLoading] = useState(true)

    const history = useNavigate();
    const iconwidth = "24px";
    const itemsPerPage = 6;
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );
    const draggableScroll = useRef(null);
    const {onMouseDown} = useDraggableScroll(draggableScroll);

    useEffect(() =>{
        const { dataChef, isFromSearch,dataChefSearch,isFromMenus } = state
        window.scrollTo(0, 0)
        setLoading(true) 
        setIsFromMenu(isFromMenus)
        const categoryChefFromDB = async (id_speciality) => {
            try {
                const data = await callApigetCategoryChef(id_speciality)
                setCategory(data)
                
            } catch (error) {
                console.log("Error categoryChefFromDB")
            }
        }
        const specialityChefSearchFromDB = async () => {
            try {
                const data = await callApigetSpeciality()
                if(data.length !== 0){
                    // console.log("tes list sorting category")
                    data.sort(function(a, b){
                        if(a.name < b.name) { return -1; }
                        if(a.name > b.name) { return 1; }
                        return 0;
                    })
                }
                // Create parser before set to state
                setSpeciality(data)
                setSelectedSpeciality(data[0].id_speciality)
                localStorage.setItem('categoryList',data[0].name)
                // categoryChefFromDB(data[0].id_speciality)
            } catch (error) {
                console.log("Error categoryChefFromDB")
            }
        }

        const specialityChefFromDB = async () => {
            try {
                const data = await callApigetSpeciality()
                if(data.length !== 0){
                    // console.log("tes list sorting category")
                    data.sort(function(a, b){
                        if(a.name < b.name) { return -1; }
                        if(a.name > b.name) { return 1; }
                        return 0;
                    })
                }
                // Create parser before set to state
                setSpeciality(data)
                setSelectedSpeciality(data[0].id_speciality)
                localStorage.setItem('categoryList',data[0].name)
                categoryChefFromDB(data[0].id_speciality)
            } catch (error) {
                console.log("Error categoryChefFromDB")
            }
        }

        const locationReferece = async () => {
            try {
                const data = await callApigetLocation()
                setListLocationFound(data)
            } catch (error) {
                console.log(error)
            }
        }
        // this.handleClick = this.handleClick.bind(this);
        locationReferece()
        if(isFromSearch){
            setIndexListSpeciality(0)
            specialityChefSearchFromDB()
            setIsFromSearchData(isFromSearch)
            const data = parseCategoryChefFromSearch(dataChef)
            setCategory(data)
        } else {
            setIndexListSpeciality(0)
            specialityChefFromDB() 
        }
        localStorage.setItem('nav', 'fnd')
        localStorage.setItem('categoryList','')
    },[])

    useEffect(() => {
        // Insert Hotjar tracking code into the head section
        const script = document.createElement('script');
        script.innerHTML = `
            (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:5070309,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `;
        document.head.appendChild(script);

        return () => {
            // Cleanup if necessary
            // document.head.removeChild(script);
        };
    }, []);

    const totalPages = Math.ceil((isFirstStart ? category.length :filteredCategory.length) / itemsPerPage);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const currentMenu = (isFirstStart ? category.slice(startIndex, endIndex) : filteredCategory.slice(startIndex, endIndex));

    const handlePageChange = (newPage,type) => {
        // console.log("new Page => "+newPage)
        window.scrollTo({top: 0, behavior: 'smooth'});
        if(type === "Back" ){
            if(newPage >= 1 ){
                setCurrentPage(newPage);
            } else {
                console.log("cannot load")
            }
        } else {
            if(newPage <= totalPages ){
                setCurrentPage(newPage);
            } else {
                console.log("cannot load")
            }
        }
        
        
    }

    useEffect(() => {
        // Handle all filter
        const filteredData = { filterChef, filterLocation, filterListCategoryElement }
        filterCategory(filteredData)

    }, [filterChef, filterLocation, filterListCategoryElement])

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
        }else{
            setLoading(false)
        }
    },[category,speciality])

    useEffect(() =>{
        for(var x in category){
            if(!listLocation.includes(category[x].area)){
                listLocation.push(category[x].area)
            }
        }
        for(var k in speciality){
            if(!listCategory.includes(speciality[k].name)){
                console.log("gak ada data")
                listCategory.push({id:speciality[k].id_speciality,name:speciality[k].name})
            } else {
                console.log("ada data nya")
            }
        } 

        
        setDatabaseLocation(listLocation)
        setListCategoryFound(listCategory)
        
        
    },[category,speciality])

    // this.handleClick = this.handleClick.bind(this);

    // const handleClick = (event)  =>{
    //     this.setState({
    //       currentPage: Number(event.target.id)
    //     });
    // }

    const filterCategory = (filter) => {
        const { filterChef, filterLocation, filterListCategoryElement } = filter
        let filteredData = category
        if (category.length) {
            if (filterChef) {
                const filteredChef = (chefName, filterChef) => chefName.includes(`${filterChef.toLowerCase()}`)
                filteredData = filteredData.filter(el => filteredChef(el["nama"].toLowerCase(), filterChef))
                setIsFirstStart(false)
            }

            if (filterLocation != "Location") {
                if (filterChef) {
                    const filteredChef = (chefName, filterChef) => chefName.includes(`${filterChef.toLowerCase()}`)
                    filteredData = filteredData.filter(el => filteredChef(el["nama"].toLowerCase(), filterChef))
                    setIsFirstStart(false)
                }else {
                    const filteredLocation = (location, filterLocation) => location.includes(filterLocation)
                    filteredData = filteredData.filter(el => filteredLocation(el["area"], filterLocation))
                    const filteredChef = (chefName, filterChef) => chefName.includes(`${filterChef.toLowerCase()}`)
                    filteredData = filteredData.filter(el => filteredChef(el["nama"].toLowerCase(), filterChef))
                
                    setIsFirstStart(false)
                }
                
            }

            if (filterListCategoryElement) {
                const filteredListCategory = (genres, filterListCategoryElement) => genres.includes(filterListCategoryElement)
                filteredData = filteredData.filter(el => filteredListCategory(el["jenis"], filterListCategoryElement))
            }

            setFilteredCategory(filteredData)
        }
    }

    const callApigetLocation = async () => {
        try {
            const { data: res} = await axios({
                method: 'get',
                url: `${baseUrl}location?terms=`
            })
            const locationRef = res.data
            console.log("this is location data => "+JSON.stringify(locationRef))
            const parsedLocationRef = parseLocationRef(locationRef)
            return parsedLocationRef
        } catch (error) {
            console.log(error)
        }
    }

    const parseLocationRef = (locationRef) => {
        locationRef = locationRef.map((el) => {
            return {
                id: el.id,
                name : el.name
            }
        })
        return locationRef
    }

    const handleListCategory = (e) => {
        var y = document.getElementById(localStorage.getItem('categoryList'))
        // console.log("e s=> "+e.name)
        setIndexListSpeciality(0)
        localStorage.setItem('categoryList',e.name)
        var x = document.getElementById(e.name)
        if(x.className === y.className){
            x.className = "categorylist underline"
            y.className = "categorylist underline"
        } else {
            x.className = "categorylist underline"
            y.className = "categorylist"
        }
        
        // setSelectedSpeciality(e.id)
        const categoryChefFromDB = async (id_speciality) => {
            try {
                /*
                Params is array of object
                [
                    {
                        "location": "Lake Cel"
                    },
                    {
                        "genre": "indonesian"
                    }
                ]
                */
                const data = await callApigetCategoryChef(id_speciality)
                // Create parser before set to state
                setSortBy('Sort by')
                setFilterLocation('Location')
                setFilterChef('')
                setCategory(data)
                setFilteredCategory(data)
            } catch (error) {
                console.log("Error categoryChefFromDB")
            }
        }

        categoryChefFromDB(e.id)
        setfilterListCategoryElement(e.name)
    }

    const callApigetSpeciality = async () => {
        try {
            
            const { data: res } = await axios({
                method: 'get',
                url: `${baseUrl}speciality`,
            })
            // console.log("Speciality => "+res.data)
            const specialityChefData = res.data
            // console.log("Speciality => "+specialityChefData[0].id_speciality)
            const allSpeciality = {
                "id_speciality": 0,
                "name": "All",
                "status": "active",
                "created_at": "2023-10-18T21:43:17.000000Z",
                "deleted_at": null,
                "updated_at": "2023-10-19T04:24:02.000000Z"
            }
            specialityChefData.splice(0,0,allSpeciality)
            return specialityChefData
        } catch (error) {
            console.log(error)
            console.log("error getCategoryChefFromDB")
            // Create error handler (pop-up or something)
        }
    }

    const callApigetCategoryChef = async (id_speciality) => {
        try {
            // console.log("ini id speciality sebelum di hit => "+id_speciality)
            const { data: res } = await axios({
                method: 'get',
                url: `${baseUrl}chef?term=&speciality_id=${id_speciality === 0 ? '': ''}`,
            })
            const categoryChefData = res.data
            const parsedCategoryChef = parseCategoryChef(categoryChefData)
            return parsedCategoryChef
        } catch (error) {
            console.log(error)
            console.log("error getCategoryChefFromDB ")
            // Create error handler (pop-up or something)
        }
    }


    const handleCloseAll = async () =>{
        if(showNavPopup){
            setShowNavPopup(false);
        }

        if(showSearchMobile){
            setShowSearchMobileClass(false);
            await delay(200)
            setShowSearchMobile(false);
        }
        if(showSortByMobile){
            setShowSortByMobileClass(false);
            await delay(200);
            setShowSortByMobile(false);
        }

        if(openLocation){
            setOpenLocation(false);
        }

        if (openSortBy){
            setOpensortBy(false);
        }
    }
    
    const openLocationHandle = () => {
        setOpenLocation(openLocation => !openLocation);
    }

    const openSortByHandle = () => {
        setOpensortBy(openSortBy => !openSortBy);
    }

    const handleOpenSearchBar = () => {
        setShowSearchMobile(true);
        setShowSearchMobileClass(true);
        setShowSortByMobile(false);
    }
    const handleOpenSortBy = () => {
        setShowSearchMobile(false);
        setShowSortByMobileClass(true);
        setShowSortByMobile(true);
    }

    const [y, setY] = useState(window.scrollY);

    const handleNavigation = useCallback(
        e => {
            const window = e.currentTarget;
            setY(window.scrollY);
            if(y<=50){
                var elem = document.getElementById("penggantidiubah");
                if(elem){
                    elem.style.transition = "background-color 0.5s";
                    elem.style.backgroundColor = "transparent";
                }
            }else{
                var elem2 = document.getElementById("penggantidiubah");
                if(elem2){
                    elem2.style.transition = "background-color 0.5s";
                    elem2.style.backgroundColor = "rgba(0, 0, 0, 1)";
                }
            }
        }, [y]
    );

    const parseCategoryChefFromSearch = (categoryChefData) => {
        categoryChefData = categoryChefData.map((el, idx) => {
            // if (el["slug"] == "Chef-Taplak") {
                return {
                    id: el.id,
                    gambar: el.gambar,
                    nama: el.nama,
                    biography:el.biography,
                    email: el.email,
                    rating:el.rating,
                    jenis: parseServicesToJenis(el.jenis),
                    booking_available:parseBookingAvailable(el.booking_available),
                    service: parseServicesSearchChef(el.jenis),
                    area: "Jakarta",
                    harga: el.harga,
                    speciality_chef :el.speciality_chef,
                    languages : el.languages,
                    chefCompany:el.chefCompany
                }
            // }
        })
        return categoryChefData.filter(el => el)
    }

    const parseCategoryChef = (categoryChefData) => {
        
        
        // console.log(categoryChefData[0].chef_galery.photos)
        const area = ["Uranus Tengah", "Venus", "Provinsi Pluto"]
        
        categoryChefData = categoryChefData.map((el, idx) => {
            
            // if (el["slug"] == "Chef-Taplak") {
                if(el.services.length !== 0){
                    // console.log("chef service awok")
                    return {
                        id: el.id,
                        gambar: el.user_image_url,
                        nama: el.name,
                        biography:el.chef_galery.description,
                        email: el.email,
                        rating: parseTotalRating(el.chef_rating),
                        languages: languagesStylesParser(el["proficient"]),
                        booking_available:parseBookingAvailable(el.booking_available),
                        service: parseServices(el.services),
                        chefEvent: parseChefEvent(el.chef_events),
                        speciality_chef: parseChefSpeciality(el.speciality_chef),
                        chefGalery : parseChefGalery(el.chef_galery.photos),
                        chefCompany : parseChefCompany(el.work_experience,el.education_experience),
                        // jenis: idx <= genres[idx] ? genres[idx] : genres[genres.length-1],
                        jenis: parseServicesToJenis(el.services),
                        area: "Jakarta",
                        harga: el.services[0].start_price,
                        // harga: el.starting_price,
                    }
                }
                
            // }
        })
        return categoryChefData.filter(el => el)
    }

    const parseTotalRating = (chef_rating) => {
        let rating = 0;
        for(const element of chef_rating){
            rating+=element.rate;
        }
        return rating
    }

    const parseBookingAvailable = (booking_available) => {
        booking_available = booking_available.map(el => {
            return {
                booking_date:el.booking_date
            }
        })

        return booking_available
    }

    const parseServicesSearchChef = (services) => {
        services = services.map(el => {
            return {
                id : el.id,
                nama : el.name,
                description: el.description,
                service_image_url: el.service_image_url,
                converted_price : el.converted_price,
                package_menu_id : el.package_menu_id,
                converted_discounted_price : el.converted_discounted_price,
                max_pax : el.max_pax,
                voucher: parseVoucher(el.voucher),
                prices: parseServicePrice(el.prices),
                menu: parseServiceMenuSearchChef(el.menu),
                equipmentData: parseEquipment(el.equipmentData),
                containData: parseContain(el.containData),
            }
        })
        return services
    }

    const parseServices = (services) => {
        services = services.map(el => {
            return {
                id: el.id,
                nama: el.name,
                description: el.description,
                service_image_url: el.service_image_url,
                formated_price : el.formated_price,
                formated_discounted_price : el.formated_discounted_price,
                converted_discounted_price : el.start_price,
                package_menu_id : el.package_menu_id,
                max_pax : el.max_pax,
                voucher: parseVoucher(el.voucher),
                prices: parseServicePrice(el.prices),
                menu: parseServiceMenu(el.menu),
                equipmentData: parseEquipment(el.equipment_data),
                containData: parseContain(el.contain_data),
            }
        })
        return services
    }

    const parseVoucher = (vouchers) => {
        if(!vouchers || vouchers < 1){
            return "N/A"
        }

        vouchers = vouchers.map(el => {
            return {
                id: el["id"],
                name: el["name"],
                discount: el["discount"],
                quota: el["quota"]
            }
        })

        return vouchers
    }

    const parseServicePrice = (services_price) => {
        services_price = services_price.map(el => {
            return {
                id: el.id,
                bussines_id: el.bussines_id,
                amount : el.amount,
                min : el.min,
                max : el.max,
                
            }
        })
        return services_price
    }

    const parseServiceMenuSearchChef = (services_menu) => {
        services_menu = services_menu.map(el => {
            return {
                id: el.id,
                bussines_id: el.bussines_id,
                name : el.name,
                description : el.description,
                image : el.image,
                category_name:el.category_name
            }
        })
        return services_menu
    }

    const parseServiceMenu = (services_menu) => {
        services_menu = services_menu.map(el => {
            return {
                id: el.id,
                bussines_id: el.bussines_id,
                name : el.name,
                description : el.description,
                image : el.menu_img,
                category_name:el.category_name
            }
        })
        return services_menu
    }

    const parseEquipmentSearchChef = (equipment) => {
        if(!equipment || equipment.length < 1 || equipment === "N/A"){
            return "N/A"
        }

        let eqipments = []

        equipment = equipment.map(el => {
            if(el !== null){
                eqipments.push({
                    equipmentId: el["equipmentId"],
                    deskripsi: el["deskripsi"],
                    icon: el["icon"],
                    file_url: el["file_url"]
                })
            } else {
                return {
                    equipmentId: null,
                    deskripsi: null,
                    icon: null,
                    file_url:null
                }
            }
            
        })

        return eqipments
    }

    const parseEquipment = (equipment) => {
        let eqipments = []
        if(!equipment || equipment < 1){
            return "N/A"
        }

        equipment = equipment.map(el => {
            if(el !== null){
                eqipments.push({
                    equipmentId: el["id_equipment"],
                    deskripsi: el["deskripsi"],
                    icon: el["icon"],
                    file_url: el["file_url"]
                })
            } else {
                return {
                    equipmentId: null,
                    deskripsi: null,
                    icon: null,
                    file_url:null
                }
            }
            
        })

        return eqipments
    }

    const parseChefSpeciality = (speciality) =>{
        let specialites = []

        speciality = speciality.map(el => {
            if(el !== null){
                specialites.push({
                    id_speciality: el["id_speciality"],
                    name_speciality: el["name_speciality"]
                })
            } else {
                return {
                    containId: null,
                    deskripsi: null,
                    icon: null
                }
            }
        })
        return specialites
    }

    const parseContainSearchChef = (contain) => {
        if(!contain || contain.length < 1 || contain === "N/A"){
            return "N/A"
        }
        let contains = []

        contain = contain.map(el => {
            if(el !== null){
                contains.push({
                    containId: el["containId"],
                    deskripsi: el["deskripsi"],
                    icon: el["icon"],
                    file_url: el["file_url"]
                })
            } else {
                return {
                    containId: null,
                    deskripsi: null,
                    icon: null,
                    file_url: null
                }
            }
            
        })

        return contains
    }

    const parseContain = (contain) => {
        let contains = []
        if(!contain || contain < 1){
            return "N/A"
        }

        contain = contain.map(el => {
            if(el !== null){
                contains.push({
                    containId: el["id_dietary"],
                    deskripsi: el["deskripsi"],
                    icon: el["icon"],
                    file_url: el["file_url"]
                })
            } else {
                return {
                    containId: null,
                    deskripsi: null,
                    icon: null,
                    file_url: null
                }
            }
            
        })

        return contains
    }

    const parseChefEvent = (chef_events) => {
        chef_events = chef_events.map(el => {
            const { date, time_start } = dateEventParser(el.events_date)
            return {
                id: el.id,
                gambar: el.cover_img_url,
                judul: el.event_name,
                style: stylesParser(el.event_style),
                sisa: el.min_pax,
                total: el.max_pax,
                tanggal: date,
                jam: time_start,
                tempat: el.vanue_location
            }
        })
        return chef_events
    }

    const dateEventParser = (dates) => {
        if (!dates || !dates.length) {
            return {
                "date": "N/A",
                "time_start": "N/A"
            }
        }
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ]
        dates = dates[0]

        let [_, month, date] = dates["date_events"].split("-")
        const idxMonth = Number(month)
        month = monthNames[idxMonth-1].slice(0, 3)
        date = Number(date)
        const parsedDate = `${date} ${month}`

        return {
            "date": parsedDate,
            "time_start": parseTimeEvent(dates)
        }
    }

    const parseTimeEvent = (dates) => {
        const timeStartArr = dates["start_time"].split(":")
        const timeEndArr = dates["end_time"].split(":")
        
        const parsedTimeStart = timeStartArr[0].concat(":", timeStartArr[1]);
        const parsedTimeEnd = timeEndArr[0].concat(":", timeEndArr[1]);

        const parsedTime = `${parsedTimeStart} - ${parsedTimeEnd}`
        return parsedTime
    }

    const stylesParser = (styles) => {
        if (!styles) {
            return "N/A"
        }
        styles = styles.map(({name}) => `${name}`).join(',');
        return styles
    }

    const parseChefGalery = (chef_galery) => {
        if(!chef_galery || chef_galery < 1 || chef_galery == null){
            return "N/A"
        }
        chef_galery = chef_galery.map(el => {
            return {
                photo: el,
            }
        })
        // console.log(chef_galery)
        return chef_galery
    }

    const languagesStylesParser = (proficient) => {
        let proficientList = []
        if (proficient.length === 0) {
            return "N/A"
        }
        proficient = proficient.map((el) =>{
            proficientList.push(el.proficient)
        })

        // console.log("ini proficient => "+ proficientList)
        proficientList = proficientList.join(', ').replace(/, ([^,]*)$/, ' and $1')
        return proficientList
    }

    const parseChefCompany = (company,education) => {
        let workeduList = []
        if(company.length !== 0){
            company = company.map((el) => {
                return {
                    name:el.company,
                    date: el.start_date.slice(0,-9)
                }
            })
        }
        
        if(education.length !== 0){
            education = education.map((el) => {
                return {
                    name:el.education,
                    date: el.start_date.slice(0,-9)
                }
            })
        }
        // company.concat(education)

        workeduList = [...company, ...education]

        return workeduList

    }

    const parseServicesToJenis = (services) => {
        services = services.map(el => {
            return {
                id : el.id,
                name : el.name,
                converted_price : el.converted_price,
                max_pax : el.max_pax,
                voucher: parseVoucher(el.voucher),
                prices: parseServicePrice(el.prices),
                menu: parseServiceMenu(el.menu),
                equipmentData: parseEquipmentSearchChef(el.equipmentData),
                containData: parseContainSearchChef(el.containData),
            }
        })
        return services
    }

    const handleSortBy = (e) => {
        // // based on dummy data, it goes like this
        // Default will be acending
        // let sortedCategory = category.sort((a, b) => a.harga - b.harga)
        let sortedCategory = category.sort((a, b) => b.harga - a.harga)
        if (e === "Lowest IDR - Highest IDR") {
            // sortedCategory = category.sort((a, b) => b.harga - a.harga)
            sortedCategory = category.sort((a, b) => a.harga - b.harga)
        }

        const filteredChef = (chefName, filterChef) => chefName.includes(`${filterChef.toLowerCase()}`)
        sortedCategory = sortedCategory.filter(el => filteredChef(el["nama"].toLowerCase(), filterChef))

        setIsFirstStart(false)
        setFilteredCategory(sortedCategory)
        setCategory(sortedCategory)
        setOpensortBy(false)
        setShowSortByMobile(false)
        setSortBy(e)
    }

    const handleFilterLocation = (e) => {
        setOpenLocation(false)
        setFilterLocation(e)
    }

    const handleShowFilter = (e) => {
        if(e === 'location'){
            openLocation? setOpenLocation(false):setOpenLocation(true)
            setOpensortBy(false)
        }else{
            openSortBy? setOpensortBy(false):setOpensortBy(true)
            setOpenLocation(false)
        }
    }

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);
        
        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);
    
    const [loaders, setLoader] = useState(true);
    const [texts, setText] = useState(false);
    
    const loadersFunct = async () =>{
        await delay(2000)
        setLoader(false);
        setText(true);
    }

    
    return(
        <div onClick={e => {e.stopPropagation();}} id="maincontainer">
            <DesktopNavbar showPopup={showNavPopup} setShowPopup={setShowNavPopup}></DesktopNavbar>
            <FloatingWhatsAppCustom></FloatingWhatsAppCustom>
            <div className="FloatingmobileNavbar" id='penggantidiubah'>
                {
                    showSearchMobile ? 
                        <div className='navbarSearch'>
                            <div className={`inner ${showSearchMobileClass ? 'popUpIn' : 'popUpOut'}`}>
                                <div className='flex gap-10 w-full'>
                                    <img src={back} onClick={e => {e.preventDefault();handleCloseAll()}} width={iconwidth} draggable="false"></img>
                                    <input placeholder="Find a Chef here..."></input>
                                </div>
                                <img src={search2} width={iconwidth} draggable="false"></img>
                            </div>
                        </div>
                    :
                    <div className="floatingNavbar">
                        <img onClick={e => {e.preventDefault();e.stopPropagation();history(-1)}} src={back} alt="iconback" width={iconwidth} draggable="false"></img>

                        <div className='flex gap-10'>
                            <img onClick={e => {e.preventDefault();e.stopPropagation();handleOpenSortBy();handleCloseAll()}} src={sort} alt="iconback" width={iconwidth}></img>
                            <img onClick={e => {e.preventDefault();e.stopPropagation();handleOpenSearchBar()}} src={search2} alt="iconSearch" width={iconwidth} draggable="false"></img>        
                        </div>
                        {
                            showSortByMobile ? 
                            <div className={`sortBy ${showSortByMobileClass ? 'popUpIn' : 'popUpOut'}`}>
                                <p onClick={e => {e.preventDefault();e.stopPropagation();handleSortBy('Highest IDR - Lowest IDR')}}>Highest IDR - Lowest IDR</p>
                                <p onClick={e => {e.preventDefault();e.stopPropagation();handleSortBy('Lowest IDR - Highest IDR')}}>Lowest IDR - Highest IDR</p>
                            </div>
                            :
                            null
                        }
                    </div>
                }
            </div>

            {
                loading ? 
                <img alt='loading' className='taplakLoading' src={spinner}></img>
                :
                <CSSTransition in={standby} timeout={{appear:1000,enter:2000,exit:1000}} mountOnEnter={true} appear={true} classNames="MLtrans">
                <div className='menuListWrapper'>
                    <div className='menuListInfo'>
                        <div>
                            <h1>Search by Chefs</h1>
                            <p>We're ready to serve you the best dining experiences.</p>
                        </div>
                        <div className='menuListSearch'>
                            <label htmlFor="searchMenusBig"><img src={search} draggable="false"></img></label>
                            <input id="searchMenusBig" value={filterChef} onInput={e => setFilterChef(e.target.value)} onChange={e => setFilterChef(e.target.value)} placeholder='Find a Chef here...'></input>
                        </div>
                        <div className='menuListFilters'>
                            <div className='filterLocation' onClick={()=> {handleShowFilter('location')}}>
                                <img src={pinGray} draggable="false"></img>
                                <p>{filterLocation}</p>
                            </div>
                            <div className='filterSortBy' onClick={()=>{handleShowFilter('sortBy')}}>
                                <img src={sortGray} draggable="false"></img>
                                <p>{sortBy}</p>
                            </div>
                        </div>
                        {
                            openLocation ? 
                            <div className={`filterFloat ${openLocation ? 'animatedFloatLocate' : ''}`}>
                                <div className='floatInner'>
                                    <div className='floatSearch'>
                                        <label htmlFor='searchLocation'><img src={search} draggable="false"></img></label>
                                        <input id='searchLocation' placeholder='Search Location...'></input>
                                    </div>
                                    <div className='floatSelect'>
                                        {listLocationFound.map((post) => (
                                            <p onClick={e => {e.preventDefault();e.stopPropagation();handleFilterLocation(post.name)}}>{post.name}</p>
                                        ))}
                                        {/* <p>Jakarta</p>
                                        <p>Bekasi</p>
                                        <p>Bekasi</p>
                                        <p>Bekasi</p>
                                        <p>Bekasi</p>
                                        <p>Bekasi</p> */}
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                        {
                            openSortBy ? 
                            <div className={`filterFloat ${openSortBy ? 'animatedFloatSort' : ''}`}>
                                <div className='floatInner'>
                                    <div className='floatSelect'>
                                        <p onClick={e => {e.preventDefault();e.stopPropagation();handleSortBy('Highest IDR - Lowest IDR')}}>Highest IDR - Lowest IDR</p>
                                        <p onClick={e => {e.preventDefault();e.stopPropagation();handleSortBy('Lowest IDR - Highest IDR')}}>Lowest IDR - Highest IDR</p>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>
                    <div className='menuListContents'>
                        {/* <div className='menuListFilters'> */}
                            {/* <div className='indicatorList'>
                                <i className='bx bxs-chevrons-left bx-flashing'></i>
                                <i className='bx bxs-chevrons-right bx-flashing'></i>
                            </div> */}
                            {/* <div className='inner' ref={draggableScroll}  onMouseDown={onMouseDown}> */}
                            {/* {listCategoryFound.map((post) => {
                                        if(indexListSpeciality === undefined){
                                            console.log("speciality => undifined cok")
                                            return (
                                                <p onClick={e => {handleListCategory(post)}} id={post.name} className='categorylist'>{post.name}</p>
                                                )
                                        } else {
                                            console.log("speciality => ada cuy")
                                            if(post === listCategoryFound[0]){
                                                return (
                                                    <p onClick={e => {handleListCategory(post)}} id={post.name} className='categorylist underline'>{post.name}</p>
                                                    )
                                            }else{
                                                return (
                                                    <p onClick={e => {handleListCategory(post)}} id={post.name} className='categorylist'>{post.name}</p>
                                                    )
                                            }
                                        }

                                        
                                    })} */}
                                {/* <div className='filters'>All</div>
                                <div className='filters active underline'>Asian</div>
                                <div className='filters'>Western</div>
                                <div className='filters'>Indonesian</div>
                                <div className='filters'>Indonesian</div>
                                <div className='filters'>Indonesian</div>
                                <div className='filters'>Indonesian</div> */}
                            {/* </div> */}
                        {/* </div> */}
                        <div className='cardList'>
                        {
                            isFirstStart ?
                            currentMenu.map((post) => (
                                <ChefCards
                                    data={post} isFromSearch={isFromSearchData} standby={standby} fungsi={()=>setstandby(false)} isFromMenu={isFromMenu}
                                />
                            )) :
                            
                            currentMenu.map((post) => (
                                <ChefCards
                                    data={post} isFromSearch={isFromSearchData} standby={standby} fungsi={()=>setstandby(false)} isFromMenu={isFromMenu}
                                />
                            ))
                        }
                            
                            {/* <ChefCards></ChefCards>
                            <ChefCards></ChefCards>
                            <ChefCards></ChefCards>
                            <ChefCards></ChefCards> */}
                        </div>
                        {
                            totalPages === 0 ?
                            <div className='dataNotfound' onLoad={loadersFunct()}>
                                <div class={`lds-ring ${loaders ? 'inline' : 'hidden'}`}><div></div><div></div><div></div><div></div></div>
                                <p className={`pag_not_found active ${texts ? 'inline' :'hidden'}`}>Data Not Found</p>
                            </div>
                            
                            :
                            <div className='pagination'>
                                <i onClick={() => handlePageChange(currentPage - 1, "Back")} disabled={currentPage === 1} className='bx bxs-chevron-left'></i>
                                <ul>
                                    <li >{currentPage} of {totalPages}</li>
                                </ul>
                                <i onClick={() => handlePageChange(currentPage + 1,"Next")} disabled={currentPage === totalPages} className='bx bxs-chevron-right'></i>
                                {/* <p onClick={() => handlePageChange(currentPage - 1, "Back")} disabled={currentPage === 1} className='pag_back active'>Back</p>
                                <ul>
                                    <li >Page {currentPage} of {totalPages}</li>
                                </ul>
                                <p onClick={() => handlePageChange(currentPage + 1,"Next")} disabled={currentPage === totalPages} className='pag_next active'>Next</p> */}
                            </div>
                        }
                        
                        <div className='line-separator list'>
                            <div className='line-img'>
                                <img src={forkspoonWhite} alt="icon" draggable="false"></img>
                            </div>
                        </div>
                        <MenuBanner></MenuBanner>
                    </div>
                </div>
                </CSSTransition>
            }
            
        </div>
    )
}