import './index.css'
import { DesktopNavbar } from '../NavBar/Desktop/DesktopNavbar'
import {useState, useEffect, useRef} from 'react'
import "react-datepicker/dist/react-datepicker.css";
import calendar from '../../assets/calendar.svg'
import { useLocation, useNavigate } from "react-router-dom";
import time from '../../assets/time.svg'
import React from 'react';
import location from '../../assets/pin.svg'
import foto1 from '../../assets/foto4.png'
import { CSSTransition } from 'react-transition-group';
import { MobileNavbar } from '../NavBar/Mobile/MobileNavbar';
import spinner from '../../assets/spinner.gif'
import { baseUrl } from '../../lib/api';
import toRupiah from '@develoka/angka-rupiah-js';
import PaymentModal from '../Modal/PaymentModal';
import { FloatingWhatsAppCustom } from '../FloatingWhatsAppCustom/FloatingWhatsAppCustom';

export const PageSubmitBookingEvent = () => {

    const [detail, setDetail] = useState()
    const { state }  = useLocation()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(true)
    const [eventIdBook, setEventIdBook] = useState(true)
    const [dateEvent, setDateEvent] = useState(true)
    const [databooked, setDatabooked] = useState(true)
    const [dateIdBook, setDateIdBook] = useState(true)
    const [fotoEventBook, setFotoEventBook] = useState(true)
    const [eventTotalAmount, setEventTotalAmount] = useState(true)
    const [eventGuest, setEventGuest] = useState(true)
    const [eventVoucherId, setEventVoucherId] = useState('')
    const [eventVoucher, setEventVoucher] = useState('')
    const [xenditPaymentLink, setXenditPaymentLink] = useState(true)
    const [showNavPopup, setShowNavPopup] = useState(false)
    const handleCloseAll = () => {
        setShowNavPopup(false)
    }
    useEffect(() =>{
        const { eventId, dateId ,totalAmount, guest,voucher,databooking,fotoEvent} = state
        let dateFormat = ''
        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ]
        const [year, month, _] = databooking.dataBooking.date_events.split("-")
        dateFormat = `${_} ${months[month-1].slice(0, 3)} ${year}`
        window.scrollTo(0, 0)
        // console.log("data event")
        // console.log(fotoEvent)
        // console.log(databooking)
        // console.log(databooking.dataBooking.payment_link)
        // console.log(eventId)
        // console.log(dateId)
        // console.log(voucher)
        setXenditPaymentLink(databooking.dataBooking.payment_link)
        setDateEvent(dateFormat)
        setFotoEventBook(fotoEvent)
        setDatabooked(databooking)
        setEventIdBook(eventId)
        setDateIdBook(dateId)
        setEventTotalAmount(totalAmount)
        setEventGuest(guest)
        setEventVoucher(voucher)
        if(voucher === "None"){
            setEventVoucherId('')
        } else {
            setEventVoucherId(voucher.id)
        }
        setLoading(true)
        setDetail({nama:"Zidane Dane",prefix:localStorage.getItem('prefix'),phoneNumber:localStorage.getItem('phoneNumber'),namaChef:"Fauzan Pradana", foto:foto1,Type:"Asian",tanggal:localStorage.getItem("tanggalEvent"), jam:localStorage.getItem("jam"),style:localStorage.getItem('style'),pax:localStorage.getItem('pax'), total:localStorage.getItem('total'),address:localStorage.getItem('venueLocation'),totalAfter:localStorage.getItem('totalAfter')})
        localStorage.setItem('nav', 'e')
    },[])
    const [standby, setstandby] = useState(true)
    const firstUpdate = useRef(true);
    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
        }else{
            setLoading(false)
        }
    },[detail])
    const history = useNavigate()

    const callAPIbookingEvent = async () => {
        // var axios = require('axios');
        // var FormData = require('form-data');
        // const formDataLogin = new FormData();
        // formDataLogin.append("event_id", eventIdBook)
        // formDataLogin.append("id_event_date",dateIdBook)
        // formDataLogin.append("total_amount",eventTotalAmount)
        // formDataLogin.append("guest",eventGuest)
        // formDataLogin.append("voucher_id",eventVoucherId)
        // axios({
        //     method: 'POST',
        //     url: baseUrl + 'bookingEvent',
        //     data : formDataLogin,
        //     headers: {
        //         'Authorization': `Bearer ${localStorage.getItem('taplakToken')}` 
        //     },
        //     withCredentials:false
        // })
        // .then(function (response) {
            
        //     console.log("response set book")
        //     console.log(response.data.status)
            // if(response.data.status === true){
                // console.log("Berhasil")
                // console.log(response.data.dataBooking.payment_link)
                // setXenditPaymentLink(response.data.dataBooking.payment_link)
                // setstandby(false)
                openModal()
            // } 
            // console.log(response.data.access_token)
            // localStorage.setItem('TaplakLoggedIn', 'true')
            // localStorage.setItem('username',capitalize(response.data.user.name))
            // localStorage.setItem('taplakToken',response.data.access_token)
            // window.location.reload()
            // fungsi()
            // console.log(JSON.stringify(response.data));
        // })
        //     .catch(function (error) {
        //     console.log(error);
        //     console.log("Gagal cok")
        //     // setValLog3(false)
        // });
    }

    // const openPopup = (linkUrl) => {
    //     const popupUrl = linkUrl; // Replace with the URL you want to open
    //     return <WebPopup url={popupUrl} />;
    //   };

    const openModal = () => {
        setIsModalOpen(true);
      };
    
      const closeModal = () => {
        setIsModalOpen(false);
      };

    useEffect(()=>{
        
        if(standby===false){
            setTimeout(function() {
                openModal()
                // openPopup(xenditPaymentLink)
                // window.open(xenditPaymentLink, "_blank", "noreferrer");
                // window.location.replace('https://checkout-staging.xendit.co/v2/64a25cacb69ce85862f96cf1');
                // history('/MyBooking')
           }, 500);
        }
    },[standby])

    const handleSeeMyOrder = () => {
        callAPIbookingEvent()
    }

    
    
    return (
        <div onClick={e => {e.stopPropagation();handleCloseAll()}} id="maincontainer" style={{minWidth:"100%", minHeight:"100vh", backgroundColor:"#111111", position:"relative", overflow:"unset"}}>
            <DesktopNavbar showPopup={showNavPopup} setShowPopup={setShowNavPopup}></DesktopNavbar>
            <MobileNavbar></MobileNavbar>
            <FloatingWhatsAppCustom></FloatingWhatsAppCustom>
            {
                loading?
                <img alt='loading' className='taplakLoading' src={spinner}></img>
                :
                <CSSTransition in={standby} timeout={{appear:1000,enter:2000,exit:1000}} mountOnEnter={true} appear={true} classNames="SBEPtrans">
                <div className='SBEPkontainer'>
                    <p className='white big bold SBEPtittle'>Thank You</p>
                    <p className='white semibig SBEPtittle'>Your booking has been submitted</p>


                    <div className='SBEPkonten2 flex'>
                        <div className='SBEPkontainerkiri'>
                            <div className='SBEPkontenputih'>
                                <div className='flex' style={{alignItems:""}}>
                                    <div style={{marginRight:"4px"}}>
                                        <p className='bold medium'>{databooked.userBooking.name}</p>
                                        <p className='medium' style={{marginTop:"4px"}}>{databooked.userBooking.email}</p>
                                    </div>
                                    
                                </div>
                                <div className='flex SBEPmargin'>
                                <img 
                                    src={`${fotoEventBook}`}
                                    alt="new"
                                    className="SBEPimage"
                                />
                                    {/* <div className='SBEPimage' style={{backgroundImage:`url(${fotoEventBook})`}}></div> */}
                                    <div>
                                        <p className='small'>{databooked.dataBooking.event_name}</p>
                                        <p className='small' style={{marginTop:"4px"}}>{React.createElement("div", { dangerouslySetInnerHTML: { __html: databooked.dataBooking.event_description } })}</p>
                                    </div>
                                </div>
                                <div className='SBEPmargin flex' style={{alignItems:"center"}}>
                                    <img alt="icon" className='SBEPicon' src={calendar}></img>
                                    <p className='small' style={{marginRight:"32px"}}>{dateEvent}</p>
                                    <img alt="icon" className='SBEPicon' src={time}></img>
                                    <p className='small' >{databooked.dataBooking.start_time}</p>
                                </div>
                                <div className='SBEPmargin flex' style={{alignItems:"center"}}>
                                    <img alt="icon" className='SBEPicon' src={location}></img>
                                    <p className='small' >{databooked.dataBooking.vanue_location}</p>
                                </div>
                            </div>
                        </div>
                        <div className='SBEPkontainerkanan'>
                            <p className='white bold medium'>Invoice Preview</p>
                            <div className='SBEPmargin2 flex'>
                                <p className='small white'>{detail.pax} pax</p>
                                <p className='small white' style={{marginLeft:"auto"}}>{toRupiah(detail.total, {formal: false, symbol: 'IDR'}).slice(0, -3)}</p>
                            </div>
                            {/* <div className='SBEPline SBEPmargin3'></div> */}
                            {/* <div className='SBEPmargin3 flex'>
                                <p className='small white'>Service Fee</p>
                                <p className='small white' style={{marginLeft:"auto"}}>-</p>
                            </div> */}
                            <div className='SBEPline SBEPmargin3'></div>
                            <div className='SBEPmargin3 flex'>
                                <p className='small white'>Voucher Amount</p>
                                {
                                    eventVoucher !== "None" ? 
                                    <p className='small white' style={{marginLeft:"auto"}}>{eventVoucher.discount}%</p>
                                    :
                                    <p className='small white' style={{marginLeft:"auto"}}>-</p>
                                }
                                {/* <p className='small white' style={{marginLeft:"auto"}}>-</p> */}
                            </div>
                            <div className='SBEPline SBEPmargin3'></div>
                            <div className='SBEPmargin3 flex'>
                                <p className='small bold white'>Total</p>
                                <p className='small bold white' style={{marginLeft:"auto"}}>{toRupiah(detail.totalAfter,{formal: false, symbol: 'IDR'}).slice(0, -3)}</p>
                            </div>

                            <button onClick={() => handleSeeMyOrder()} className='SBEPbutton small bold SBEPmargin'>Proceed To Payment</button>
                            {/* <button className='SBEPbutton2 small bold SBEPmargin4'>Explore More</button> */}
                        </div>
                    </div>
                    
                </div>
                </CSSTransition>
            }

                <PaymentModal isOpen={isModalOpen} onClose={closeModal} xenditPaymentLink={xenditPaymentLink}/>
        </div>
    )
  }