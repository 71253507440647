import './index.css'
import pinGrey from '../../../assets/pin-2.svg'
import calendarGrey from '../../../assets/calendar-2.svg'
import { useNavigate } from "react-router-dom";
// import SoldOutBadge from '../../Badge/SoldOutBadge';
// import '../../Badge/index.css';

export const HomeTypeCard = ({id, gambar, judul, style, sisa, total, tanggal, jam, tempat, showJoinNow, fungsi}) => {
    const history = useNavigate()
    const handleToEventDetail = () => {
        fungsi()
        setTimeout(function() {
            history("/event/detailEvent", { state: { id: id ,isSoldOut: sisa === 0 ? true :false} });
       }, 500);
    }

    const handleJoinNow = () => {
        fungsi()
        setTimeout(function() {
            history("/event/detailEvent/setSchedule");
       }, 500);
    }
    

    return (
        <div className={sisa === 0 ? "card sold-out" : "card"} onClick={e => {handleToEventDetail()}}>
            <div className='kontainerImage'>
                <img src={`${gambar}`} alt="new" draggable="false"/>
                {
                    sisa === 0 ? <div className="sold-out-badge">
                        <span>Sold Out</span>
                    </div>
                    :
                    <div></div>
                }
            </div>
                
            {/* <div  style={{backgroundImage:`url(${gambar})`}} className="gambarEvent"></div> */}
            <div className="kontainerDetail">
                <div className='detailEventWide'>
                    <div className='kontainerDetailHeader'>
                        {/* <h1 onClick={e => {sisa !== 0 ? handleToEventDetail():console.log("cannot redirect")}} >{judul}</h1> */}
                        <h1 onClick={e => {handleToEventDetail()}} >{judul}</h1>
                        <p>{style}</p>
                    </div>
                    <div className='kontainerSeats'>
                        {
                            sisa <= 3 ?
                            <p className="red-seats">{sisa} left seats</p>
                            :
                            <p>{sisa} left seats</p>
                        }
                        <p className='from'>from {total}</p>
                    </div>
                    {/* <SoldOutBadge /> */}
                </div>
                
                <div className='detailEventWide'>
                    <div className="kontainerJam">
                        <img src={calendarGrey} className="iconcard" alt="iconcard"></img>
                        <div className='detailFlex'>
                            <p>{tanggal}</p> 
                            <p>•</p>
                            <p>{jam}</p>
                        </div>
                    </div>
                    <div className="kontainerAlamat">
                        <img src={pinGrey} className="iconcard" alt="iconcard"></img>
                        <p>{tempat}</p>
                    </div>
                </div>
                {
                    showJoinNow && (
                        <div onClick={e => {e.preventDefault();e.stopPropagation();handleJoinNow()}} className='HTCjoinnow small'>Join Now   →</div>
                    )
                }
            </div>
            
        </div>
    )
  }