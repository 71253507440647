import './index.css'
import logo from '../../../assets/taplak.png'
import {useState, useEffect, useCallback} from 'react'
import { useNavigate } from "react-router-dom";
import { PageLogin } from '../../LoginPage/PageLogin';
import {baseUrl} from '../../../lib/api'

export const DesktopNavbar = ({showPopup, setShowPopup, zindex}) => {
    const history = useNavigate()
    const [y, setY] = useState(window.scrollY);
    const [showLogin, setShowLogin] = useState(false)

    const callApiLogout = async () => {
      // var axios = require('axios');
      // axios({
      //   method: 'POST',
      //   url: baseUrl + 'logoutCustomer',
      //   withCredentials:false,
      //   headers: {
      //     'Authorization': 'Bearer ' + localStorage.getItem('taplakToken')
      //   }
      // })
      // .then(function (response) {
          // console.log("batasssss")
          // console.log(JSON.stringify(response.data));
          localStorage.setItem('TaplakLoggedIn', 'false');
          localStorage.removeItem("taplakToken")
          localStorage.removeItem("username")
          setShowPopup(false)
          // window.location.reload();
      // })
      //     .catch(function (error) {
      //     console.log(error);
      // });
    }

    const handleNavigation = useCallback(
        e => {
          const window = e.currentTarget;
          setY(window.scrollY);
          if(y<=50){
            var elem = document.getElementById("diubah");
            elem.style.transition = "background-color 0.5s";
            elem.style.backgroundColor = "transparent";
        }else{
            var elem2 = document.getElementById("diubah");
            elem2.style.transition = "background-color 0.5s";
            elem2.style.backgroundColor = "rgba(0, 0, 0, 0.9)";
            }
        }, [y]
      );
      
      useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);
        return () => {
          window.removeEventListener("scroll", handleNavigation);
        };
      }, [handleNavigation]);


    return (
        <div className={`kontainer ${zindex ? 'z-indexnone' : ''}`} id='diubah'>
          <div className='kontennavbar'>
            <img onClick={() => history("/")} className="logo" alt='logoTaplak' src={logo}></img>
            {localStorage.getItem('nav') === 'fnd' ?
              <p onClick={() => history("/menus", { state: { dataChef : "tes", isFromMenus:true}})} className="navigasi textnav underline">FOOD & DRINK</p>
              :
              <p onClick={() => history("/menus", { state: { dataChef : "tes", isFromMenus:true}})} className="navigasi textnav">FOOD & DRINK</p>
            }
            {localStorage.getItem('nav') === 'e' ?
              <p onClick={() => history("/event")} className="navigasi textnav underline">EVENT</p>
              :
              <p onClick={() => history("/event")} className="navigasi textnav">EVENT</p>
            }
            
            {
              localStorage.getItem('TaplakLoggedIn')==='true' && (
                <div className='navigasi textnav'>
                  {localStorage.getItem('nav') === 'mb' ?
                    <p onClick={() => history("/MyBooking")} className="underline">MY BOOKINGS</p>
                    :
                    <p onClick={() => history("/MyBooking")}>MY BOOKINGS</p>
                  }
                </div>
              )
            }
            {
              localStorage.getItem('TaplakLoggedIn')==='true' ?
              <div className="login textnav">
                
                {
                  localStorage.getItem('nav') === 'u'?
                  <p className='underline' onClick={e => {e.preventDefault();e.stopPropagation();showPopup?setShowPopup(false):setShowPopup(true)}}>{localStorage.getItem('username')}</p>
                  :
                  <p onClick={e => {e.preventDefault();e.stopPropagation();showPopup?setShowPopup(false):setShowPopup(true)}}>{localStorage.getItem('username')}</p>
                }
              </div>
              :
              <p className="login textnav" onClick={() => setShowLogin(true)}>LOGIN/REGISTER</p>
            }
            {
              showPopup && (
                <div className='LGpopupuser'>
                  <p onClick={e => {e.preventDefault();e.stopPropagation();callApiLogout()}} className='white tiny' style={{cursor:"pointer"}}>Sign Out</p>
                  <p onClick={() => history("/userSettings")} className='white tiny' style={{marginTop:"12px",cursor:"pointer"}}>User Settings</p>
                </div>
              )
            }
          </div>
          {
              showLogin && (
              <PageLogin fungsi={() => setShowLogin(false)}></PageLogin>
              )
          }
        </div>
    )
  }