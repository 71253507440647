import './index.css';
import chefImg from '../../assets/chef_bg.avif';
import star from '../../assets/star.svg';
import microwave from '../../assets/microwave.svg';
import {useState, useEffect, useCallback, useRef} from 'react';
import { MenuCard } from '../Card/ChefMenuCard/ChefMenuCard';
import { DesktopNavbar } from '../NavBar/Desktop/DesktopNavbar';
import { useLocation, useNavigate } from "react-router-dom";
import circleback from '../../assets/circleback.svg';
import spinner from '../../assets/spinner.gif'
import carleft from '../../assets/carleft.svg';
import carright from '../../assets/carright.svg';
import toRupiah from '@develoka/angka-rupiah-js';
import axios from 'axios'
import { baseUrl } from '../../lib/api'
import pattern from '../../assets/assets/bycategory/chef-cricle.png';

export const MenuDetail = () =>{
    const { state } = useLocation()
    const iconwidth = "16px";
    const iconwidthNavigasi = "24px";
    const [detail, setDetail] = useState()
    const [detailChefGet, setDetailChefGet] = useState()
    const [loading, setLoading] = useState(true)
    const iconwidthtools = "48px";
    const [standby, setstandby] = useState(true)
    const [isFromMenu, setIsFromMenu] = useState(true)
    const [menu, setMenu] = useState(1)
    const [detailChefs, setDetailChefs] = useState()
    const [scrollButton, setScrollButton] = useState(false);
    const [showNavPopup, setShowNavPopup] = useState(false);
    const [y, setY] = useState(window.scrollY);
    const firstUpdate2 = useRef(true);
    const history = useNavigate()

    useEffect(() =>{
        const { dataMenu,detailChef,isFromMenu } = state
        setDetailChefs(detailChef)
        // console.log(dataMenu)
        window.scrollTo(0, 0)
        setLoading(true)
        setIsFromMenu(isFromMenu)
        console.log("data booking list 2131234 => "+dataMenu.idChef)
        // get menu detail version 2
        const menuDetail = async (booking_available) => {
            try {
                const data = await callApigetMenuDetail(dataMenu,detailChef,isFromMenu,booking_available)
                console.log("ini data menu nya => 2 "+JSON.stringify(data))
                setDetail(data)
            } catch (error) {
                console.log("Error menuDetailFromDB")
            }
        }
        const chefDetail = async () => {
            try {
                const data = await callApigetCategoryChef(isFromMenu ? dataMenu.idChef: detailChef.id)
                console.log("ini data menu nya => 22 "+JSON.stringify(data))
                menuDetail(data.booking_available)
                setDetailChefGet(data)
            } catch (error) {
                console.log("Error menuDetailFromDB")
            }
        }

        console.log("data booking list 4 => "+JSON.stringify(detailChef.booking_available))
        console.log("ini data menu nya => 3 "+isFromMenu)
        chefDetail()
        // menuDetail()
        // menuDetailFromDB()
        localStorage.setItem('nav', 'fnd')
        // localStorage.setItem('chefId', chefId)
        localStorage.setItem('menuId', dataMenu.id)
    },[])

    useEffect(() => {
        // Insert Hotjar tracking code into the head section
        const script = document.createElement('script');
        script.innerHTML = `
            (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:5070309,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `;
        document.head.appendChild(script);

        return () => {
            // Cleanup if necessary
            // document.head.removeChild(script);
        };
    }, []);

    useEffect(() => {
        if (firstUpdate2.current) {
            firstUpdate2.current = false;
        }else{
            setLoading(false)
        }
    },[detail])

    const callApigetCategoryChef = async (chefId) => {
        try {
            // console.log("ini id speciality sebelum di hit => "+id_speciality)
            const { data: res } = await axios({
                method: 'get',
                url: `${baseUrl}chef/${chefId}`,
            })
            const categoryChefData = res.data
            const parsedCategoryChef = parseCategoryChef(categoryChefData)
            // setTimeout(function() {
            //     history("/chefs/detail", { state : {dataChef : parsedCategoryChef,isFromMenu:isFromMenu}})
            // }, 500);  
            console.log("ini data menu nya chuaks > "+JSON.stringify(categoryChefData.services[0].contain_data))
            console.log("ini data menu nya chuaks > "+JSON.stringify(categoryChefData.services[0].equipment_data))
            console.log("ini data menu nya chuaks > "+JSON.stringify(parsedCategoryChef.service[0].equipmentData))
            console.log("ini data menu nya chuaks > "+JSON.stringify(parsedCategoryChef.service[0].containData))
            return parsedCategoryChef
        } catch (error) {
            console.log(error)
            console.log("error getCategoryChefFromDB")
            // Create error handler (pop-up or something)
        }
    }

    const parseCategoryChef = (categoryChefData) => {
        
        
        // console.log(categoryChefData[0].chef_galery.photos)
        const area = ["Uranus Tengah", "Venus", "Provinsi Pluto"]
        
        // categoryChefData = categoryChefData.map((el, idx) => {
            
            // if (el["slug"] == "Chef-Taplak") {
                // if(el.services.length !== 0){
                    // console.log("chef service awok")
            categoryChefData = {
                        id: categoryChefData.id,
                        gambar: categoryChefData.user_image_url,
                        nama: categoryChefData.name,
                        biography:categoryChefData.chef_galery.description,
                        email: categoryChefData.email,
                        rating: parseTotalRating(categoryChefData.chef_rating),
                        languages: languagesStylesParser(categoryChefData["proficient"]),
                        booking_available:parseBookingAvailable(categoryChefData.booking_available),
                        service: parseServices(categoryChefData.services),
                        chefEvent: parseChefEvent(categoryChefData.chef_events),
                        speciality_chef: parseChefSpeciality(categoryChefData.speciality_chef),
                        chefGalery : parseChefGalery(categoryChefData.chef_galery.photos),
                        chefCompany : parseChefCompany(categoryChefData.work_experience,categoryChefData.education_experience),
                        // jenis: idx <= genres[idx] ? genres[idx] : genres[genres.length-1],
                        jenis: parseServicesToJenis(categoryChefData.services),
                        area: "Jakarta",
                        harga: categoryChefData.services[0].start_price,
                        // harga: el.starting_price,
                    }
                // }
                
            // }
        // })
        return categoryChefData
    }

    const parseTotalRating = (chef_rating) => {
        let rating = 0;
        for(const element of chef_rating){
            rating+=element.rate;
        }
        return rating
    }

    const languagesStylesParser = (proficient) => {
        let proficientList = []
        if (proficient.length === 0) {
            return "N/A"
        }
        proficient = proficient.map((el) =>{
            proficientList.push(el.proficient)
        })

        // console.log("ini proficient => "+ proficientList)
        proficientList = proficientList.join(', ').replace(/, ([^,]*)$/, ' and $1')
        return proficientList
    }

    const parseServices = (services) => {
        services = services.map(el => {
            return {
                id: el.id,
                nama: el.name,
                service_image_url: el.service_image_url,
                formated_price : el.formated_price,
                formated_discounted_price : el.formated_discounted_price,
                converted_discounted_price : el.start_price,
                package_menu_id : el.package_menu_id,
                max_pax : el.max_pax,
                voucher: parseVoucher(el.voucher),
                prices: parseServicePrice(el.prices),
                menu: parseServiceMenu(el.menu),
                containData: parseContain(el.contain_data < 1 ? null : el.contain_data),
                equipmentData: parseEquipment(el.equipment_data < 1 ? null : el.equipment_data),
            }
        })
        return services
    }

    const parseServicePrice = (services_price) => {
        services_price = services_price.map(el => {
            return {
                id: el.id,
                bussines_id: el.bussines_id,
                amount : el.amount,
                min : el.min,
                max : el.max,
                
            }
        })
        return services_price
    }

    const parseServiceMenu = (services_menu) => {
        services_menu = services_menu.map(el => {
            return {
                id: el.id,
                bussines_id: el.bussines_id,
                name : el.name,
                description : el.description,
                image : el.menu_img,
                category_name:el.category_name
            }
        })
        return services_menu
    }

    const parseChefEvent = (chef_events) => {
        chef_events = chef_events.map(el => {
            const { date, time_start } = dateEventParser(el.events_date)
            return {
                id: el.id,
                gambar: el.cover_img_url,
                judul: el.event_name,
                style: stylesParser(el.event_style),
                sisa: el.min_pax,
                total: el.max_pax,
                tanggal: date,
                jam: time_start,
                tempat: el.vanue_location
            }
        })
        return chef_events
    }

    const stylesParser = (styles) => {
        if (!styles) {
            return "N/A"
        }
        styles = styles.map(({name}) => `${name}`).join(',');
        return styles
    }

    const dateEventParser = (dates) => {
        if (!dates || !dates.length) {
            return {
                "date": "N/A",
                "time_start": "N/A"
            }
        }
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ]
        dates = dates[0]

        let [_, month, date] = dates["date_events"].split("-")
        const idxMonth = Number(month)
        month = monthNames[idxMonth-1].slice(0, 3)
        date = Number(date)
        const parsedDate = `${date} ${month}`

        return {
            "date": parsedDate,
            "time_start": parseTimeEvent(dates)
        }
    }

    const parseTimeEvent = (dates) => {
        const timeStartArr = dates["start_time"].split(":")
        const timeEndArr = dates["end_time"].split(":")
        
        const parsedTimeStart = timeStartArr[0].concat(":", timeStartArr[1]);
        const parsedTimeEnd = timeEndArr[0].concat(":", timeEndArr[1]);

        const parsedTime = `${parsedTimeStart} - ${parsedTimeEnd}`
        return parsedTime
    }

    const parseChefSpeciality = (speciality) =>{
        let specialites = []

        speciality = speciality.map(el => {
            if(el !== null){
                specialites.push({
                    id_speciality: el["id_speciality"],
                    name_speciality: el["name_speciality"]
                })
            } else {
                return {
                    containId: null,
                    deskripsi: null,
                    icon: null
                }
            }
        })
        return specialites
    }

    const parseChefGalery = (chef_galery) => {
        if(!chef_galery || chef_galery < 1 || chef_galery == null){
            return "N/A"
        }
        chef_galery = chef_galery.map(el => {
            return {
                photo: el,
            }
        })
        // console.log(chef_galery)
        return chef_galery
    }

    const parseChefCompany = (company,education) => {
        let workeduList = []
        if(company.length !== 0){
            company = company.map((el) => {
                return {
                    name:el.company,
                    date: el.start_date.slice(0,-9)
                }
            })
        }
        
        if(education.length !== 0){
            education = education.map((el) => {
                return {
                    name:el.education,
                    date: el.start_date.slice(0,-9)
                }
            })
        }
        // company.concat(education)

        workeduList = [...company, ...education]

        return workeduList

    }

    const parseServicesToJenis = (services) => {
        services = services.map(el => {
            return {
                id : el.id,
                name : el.name,
                converted_price : el.converted_price,
                max_pax : el.max_pax,
            }
        })
        return services
    }

    const callApigetMenuDetail = async (dataMenu,detailChef,isFromMenu,booking_available) => {
        try {
            console.log("ini data menu nya =12 > "+JSON.stringify(dataMenu.containData))
            const parsedMenuDetail = parseMenuDetail([dataMenu],detailChef,isFromMenu,booking_available)
            console.log("ini data menu nya jos => "+JSON.stringify(parsedMenuDetail))
            return parsedMenuDetail
        } catch (error) {
            console.log(error)
            console.log("error getMenuDetailFromDB")
            // Create error handler (pop-up or something)
        }
    }

    const parseMenuDetail = (menuDetail,detailChef,isFromMenu,booking_available) => {
        menuDetail = menuDetail.map((el) => {
            return {
                id: isFromMenu ? el.idChef: detailChef.id,
                chef: isFromMenu ? el.namaChef: detailChef.nama,
                foto: isFromMenu ? el.photoChef: detailChef.gambar,
                rate: isFromMenu ? el.rating: detailChef.rating,
                booking_available: isFromMenu ? parseBookingAvailable(booking_available) :parseBookingAvailable(detailChef.booking_available),
                nama: el.nama,
                max_pax: el.max_pax,
                service_image_url:el.service_image_url,
                harga: el.converted_discounted_price,
                prices: el.prices,
                package_menu_id: el.package_menu_id,
                voucher:parseVoucher(el.voucher ?? "N/A"),
                menuString: menuParsedString(el.menu),
                menu: menuParser(el.menu),
                equipmentData: parseEquipment(el.equipmentData !== "N/A" ? el.equipmentData:null),
                containData: parseContain(el.containData !== "N/A" ? el.containData:null),
            }
        })
        return menuDetail[0]
    } 

    const parseBookingAvailable = (booking_available) => {
        booking_available = booking_available.map(el => {
            return {
                booking_date:el.booking_date
            }
        })

        return booking_available
    }

    const parseVoucher = (vouchers) => {
        if(vouchers === "N/A"){
            return "N/A"
        }

        vouchers = vouchers.map(el => {
            return {
                id: el["id"],
                name: el["name"],
                discount: el["discount"],
                quota: el["quota"]
            }
        })

        return vouchers
    }

    const menuParsedString = (menus) => {
        let menusArray = []
        if (menus.length === 0 || menus === "N/A") {
            return "N/A"
        }
        
        menus = menus.map((el) =>{
            menusArray.push(el.name)
        })

        // console.log("ini proficient => "+ proficientList)
        menusArray = menusArray.join(', ').replace(/, ([^,]*)$/, ' and $1')
        return menusArray
    }

    const menuParser = (menus) => {
        let parsedMenus = []
        menus.forEach(element => {
            if(element.id !== null && element.name !== null){
                // console.log(element.nama)
                parsedMenus = [...parsedMenus,
                    {
                        name: element.name,
                        image: element.image,
                        description :element.description,
                        category_name:element.category_name
                    }
                ]
            }
        });
        // const parsedMenus = menus.map((el, idx) => {
        //     return {
        //         nama: el.nama,
        //         gambar: el.gambar
        //     }
        // })
        // console.log("menu asd => "+parsedMenus)
        return parsedMenus
    }

    const parseEquipment = (equipment) => {
        let eqipments = []
        if(!equipment || equipment.length < 1 || equipment === "N/A"){
            return "N/A"
        }

        equipment = equipment.map(el => {
            if(el !== null){
                console.log("equipment yang tidak kosong => "+el.deskripsi)
                eqipments.push({
                    equipmentId: el["id_equipment"],
                    deskripsi: el["deskripsi"],
                    icon: el["icon"],
                    file_url: el["file_url"]
                })
            } else {
                console.log("equipment yang kosong => ")
                return {
                    equipmentId: null,
                    deskripsi: null,
                    icon: null,
                    file_url: null
                }
            }
            
        })

        return eqipments
    }

    const parseContain = (contain) => {
        let contains = []
        if(!contain || contain.length < 1 || contain === "N/A"){
            return "N/A"
        }

        contain = contain.map(el => {
            if(el !== null){
                console.log("equipment yang tidak kosong => "+el.deskripsi)
                contains.push({
                    containId: el["id_dietary"],
                    deskripsi: el["deskripsi"],
                    icon: el["icon"],
                    file_url: el["file_url"]
                })
            } else {
                console.log("equipment yang kosong => ")
                return {
                    containId: null,
                    deskripsi: null,
                    icon: null,
                    file_url:null
                }
            }
            
        })

        return contains
    }

    const ubahGambar = (e) => {
        if(detail.menu.length !== 0){
            if(e === "kiri"){
                if(menu===1){
                    setMenu(detail.menu.length)
                }else{
                    setMenu(menu-1)
                }
            }else{
                if(menu===detail.menu.length){
                    setMenu(1)
                }else{
                    setMenu(menu+1)
                }
            }
        }
        
    }

    const handleNavigation = useCallback(
        e => {
            const window = e.currentTarget;
            setY(window.scrollY);
            if(y<=50){
                setScrollButton(false);
                let elem = document.getElementById("penggantidiubah");
                if(elem){
                    elem.style.transition = "background-color 0.5s";
                    elem.style.backgroundColor = "transparent";
                }
            }
            else{
                setScrollButton(true);
                let elem2 = document.getElementById("penggantidiubah");
                if(elem2){
                    elem2.style.transition = "background-color 0.5s";
                    elem2.style.backgroundColor = "rgba(0, 0, 0, 1)";
                }
            }
        }, [y]
    );

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);
        
        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);

    const handletoChef= () =>{

        console.log("fisrt pirces 123 => "+JSON.stringify(detailChefGet.service))

        if(detailChefs !== ""){
            history("/chefs/detail", { state : {dataChef : detailChefs,isFromMenu:isFromMenu}})
        } else {
            setTimeout(function() {
                history("/chefs/detail", { state : {dataChef : detailChefGet,isFromMenu:false}})
            }, 500);
        }
        
    }

    useEffect(()=>{
        
        if(standby===false){

            if(localStorage.getItem('chefNameDraftFlow') === detail.chef && parseInt(localStorage.getItem('chefIdDraftFlow') ?? 0) === detail.id){
                localStorage.setItem('isBookingChefStillProggress',true)
                localStorage.setItem('isBookingChefStillProggressVenue',true)
            } else {
                localStorage.setItem('isBookingChefStillProggress',false)
                localStorage.setItem('isBookingChefStillProggressVenue',false)
            }
            console.log("data booking list => "+JSON.stringify(detail.booking_available))
            setTimeout(function() {
                history('/category/chefList/detailChef/detailMenu/setSchedule', { state: { details:detail } });
           }, 500);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[standby])

    return(
        <div onClick={e => {e.stopPropagation();}} id="maincontainer">
            <DesktopNavbar showPopup={showNavPopup} setShowPopup={setShowNavPopup}></DesktopNavbar>
            <div className='detailMenuNavMobile' onClick={e => {e.preventDefault();e.stopPropagation();history(-1)}}>
                <img src={circleback} draggable="false" className='pointer'></img>
            </div>
            {
                loading?
                <img alt='loading' className='taplakLoading' src={spinner}></img>
                :
            <div className='detailMenuWrapper'>
                <div className='detailMenuRight'>
                    <div className='detailMenuImage'>
                        <img src={detail.foto} draggable="false"></img>
                        <div className='MenuImageDetail' onClick={()=>{handletoChef()}}>
                            <h1>{detail.chef}</h1>
                            <p>See More</p>
                        </div>
                    </div>
                    <div className='detailMenuContents'>
                        <div className='head'>
                            <h1>{detail.nama}</h1>
                            <div className='flex justify-between'>
                                <p>By <span className='chefname'>{detail.chef}</span></p>
                                <div className='flex gap-5'>
                                    <img src={star} width={iconwidth}></img>
                                    <p className='ratingDetail'>{detail.rate}</p>
                                </div>
                            </div>
                        </div>
                        <div className='body'>
                            <div className='bodyText'>
                                <p>Maximal Pax</p>
                                <h1>{detail.max_pax} Pax</h1>
                            </div>
                            <div className='bodyText'>
                                <p>Menu</p>
                                {
                                    detail.menu.map((post) => {
                                        if(post.name === detail.menu[0].name){
                                            return( <h1>• {post.name}</h1>)

                                        }else{
                                            return(<h1>• {post.name}</h1>)
                                        }
                                    })
                                }
                                {/* <h1>{detail.menuString}</h1> */}
                            </div>
                            <div className='bodyText'>
                                <h1>Dietary Menus</h1>
                                <p>This set menu contains the following ingredients.</p>
                            </div>
                            <div className='bodyToolsImg'>
                                {
                                    detail.containData !== "N/A"  ? 
                                    detail.containData.map((post) => (
                                        <div className='icons'>
                                            <img src={post.file_url} draggable="false" width={iconwidthtools}></img>
                                            <p>{post.deskripsi}</p>
                                        </div>
                                    ))
                                    
                                    :
                                    <div className='bodyText'>
                                        <p>-</p>
                                    </div>
                                }
                                
                            </div>
                            <div className='bodyText'>
                                <h1>Required Kitchen Tools</h1>
                                <p>This set menu requires the following tools to be available for use in the venue.</p>
                            </div>
                            <div className='bodyToolsImg'>
                                {
                                    detail.equipmentData !== "N/A" ? 
                                    detail.equipmentData.map((post) => (
                                        <div className='icons'>
                                            <img src={post.file_url} draggable="false" width={iconwidthtools}></img>
                                            <p>{post.deskripsi}</p>
                                        </div>
                                    ))
                                    
                                    :
                                    <div className='bodyText'>
                                        <p>-</p>
                                    </div>
                                }
                                
                            </div>
                        </div>
                        <div onClick={() => setstandby(false)} className={`button mobile ${scrollButton ? 'slideUpMobile' : 'slideDownMobile'}`}>
                            <h1>Choose This Menu</h1>
                            <p>Start From {toRupiah(detail.harga, {formal: false, symbol: 'IDR'})}/pax</p>
                        </div>
                        <div onClick={() => setstandby(false)} className='button'>
                            <h1>Choose This Menu</h1>
                            <p>Start From {toRupiah(detail.harga, {formal: false, symbol: 'IDR'})}/pax</p>
                        </div>
                    </div>
                </div>
                <div className='detailMenuLeft'>
                    <div className='cardListHead flex justify-between align-center'>
                        <h1>Menu</h1>
                        <div className='flex gap-5 align-center'>
                            <img src={carleft} onClick={() => ubahGambar("kiri")} className='pointer' draggable="false" width={iconwidthNavigasi}></img>
                            <p>{menu} of {detail.menu.length}</p>
                            <img src={carright} onClick={() => ubahGambar("kanan")} className='pointer' draggable="false" width={iconwidthNavigasi}></img>
                        </div>
                    </div>
                    <div className='cardList'>
                        {
                            detail.menu.length !==0 && (<MenuCard image={detail.menu[menu-1].image} name={detail.menu[menu-1].name} description={detail.menu[menu-1].description} categoryName={detail.menu[menu-1].category_name}serviceName={detail.name} detail={detail} detailChef={detailChefs}></MenuCard>)
                        }
                        
                        {/* <MenuCard image="tes" name="tes" serviceName="tes 2"/> */}
                        {/* <MenuCard ></MenuCard> */}
                    </div>
                    <div className='cardChefProfile flex align-center justify-between' onClick={()=>{handletoChef()}}>
                        <img src={pattern} className='pattern' draggable="false"></img>
                        <div className='profile flex gap-5 align-center'>
                            <div className='ChefProfileImage'>
                                <img src={detail.foto} draggable="false"></img>
                            </div>
                            <div className='ChefProfileText'>
                                <p>Chef</p>
                                <h1>{detail.chef}</h1>
                            </div>
                        </div>
                        <div className='profile ChefProfileRating flex gap-5'>
                            <img src={star} draggable="false"></img>
                            <h1>{detail.rate}</h1>
                        </div>
                    </div>
                </div>
            </div>
        }
        </div>

    )
}