/* eslint-disable react-hooks/exhaustive-deps */

import './index.css'
import { DesktopNavbar } from '../NavBar/Desktop/DesktopNavbar'
import {useState, useEffect, useRef} from 'react'
import "react-datepicker/dist/react-datepicker.css";
import home from '../../assets/home.svg'
import apart from '../../assets/apart.svg'
import office from '../../assets/office.svg'
import villa from '../../assets/villa.png'
import { useLocation, useNavigate } from "react-router-dom";
import imageAdd from '../../assets/imageAdd.svg'
import yellowcheck from '../../assets/yellowcheck.svg'
import close from '../../assets/close2.svg'
import circleback from '../../assets/circleback.svg'
import down from '../../assets/down.svg'
import "react-datepicker/dist/react-datepicker.css";
import { CSSTransition } from 'react-transition-group';
import spinner from '../../assets/spinner.gif'
import axios from 'axios'
import { baseUrl } from '../../lib/api';
import { FloatingWhatsAppCustom } from '../FloatingWhatsAppCustom/FloatingWhatsAppCustom';

import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";

export const PageSetVenue = () => {
    const { state } = useLocation()
    const [detail,setDetail] = useState()
    const [menus,setMenus] = useState()
    const [prefix, setPrefix] = useState()
    const [phoneNumber, setPhoneNumber] = useState()
    const [addressChoosen, setAddressChoosen] = useState("Select Address")
    const [isAddressChoosen, setIsAddressChoosen] = useState(false)
    const history = useNavigate()
    const [venueName, setVenueName] = useState('')
    const [address, setAddress] = useState('')
    const [addressDetail, setAddressDetail] = useState('')
    const [addressId, setAddressId] = useState('')
    const [type, setType] = useState('')
    const [image,setImage] = useState()
    const [image2,setImage2] = useState()
    const [image3,setImage3] = useState()
    const [imgprev, setImgprev] = useState()
    const [imgprev2, setImgprev2] = useState()
    const [imgprev3, setImgprev3] = useState()
    const [arrayImgprev, setArrayImgprev] = useState([])
    const prefixAvailable = ["+62","+65"]
    const [showPrefix, setShowPrefix] = useState(false)
    const [showAddress, setShowAddress] = useState(false)
    const [val1, setval1] = useState(false)
    const [val2, setval2] = useState(false)
    const [val3, setval3] = useState(false)
    const [val4, setval4] = useState(false)
    const [val5, setval5] = useState(false)
    const [loading, setLoading] = useState(true)
    const firstUpdate2 = useRef(true);
    const firstUpdate = useRef(true);
    const firstUpdate3 = useRef(true);
    const [showNavPopup, setShowNavPopup] = useState(false)
    const [open, setOpen] = useState(false);
 
    const handleClickToOpen = () => {
        setOpen(true);
    };
 
    const handleToClose = () => {
        setOpen(false);
    };
    
    
    useEffect(() =>{
        const { menus } = state
        window.scrollTo(0, 0)
        setLoading(true)

        let isBookingChefStillProggress = localStorage.getItem("isBookingChefStillProggressVenue")
        // console.log("ini adwd =>>>"+localStorage.getItem("isBookingChefStillProggressVenue"))
        // console.log("ini adwd =>>>"+isBookingChefStillProggress)
        if(isBookingChefStillProggress === "true"){
            // console.log("ini data ada cok")
            setPrefix(localStorage.getItem("prefix") !== null ? localStorage.getItem("prefix"):undefined)
            setPhoneNumber(localStorage.getItem("phoneNumber") !== null ? localStorage.getItem("phoneNumber"):undefined)
            setAddressChoosen(localStorage.getItem("addressChoosen") !== null ? localStorage.getItem("addressChoosen"):"Select Address")
            setAddress(localStorage.getItem("address") !== null ? localStorage.getItem("address"):'')
            setVenueName(localStorage.getItem("venueName") !== null ? localStorage.getItem("venueName"):'')
            setType(localStorage.getItem("type") !== null ? localStorage.getItem("type"):'')
            setAddressDetail(localStorage.getItem("addressDetail") !== null ? localStorage.getItem("addressDetail"):'')
            setIsAddressChoosen(localStorage.getItem("isAddressChoosen") !== null ? localStorage.getItem("isAddressChoosen"):false)
        }
        
        // console.log("ini is address chosen =>>>"+isAddressChoosen)
        // console.log("ini chosen =>>>"+localStorage.getItem("isAddressChoosen"))

        setMenus(menus)
        getListAddress()
        // setDetail({prefix:"+62",phoneNumber:"89567488573",listAddress:["Jalan Margonda Raya no 12, Depok","Jalan Radio Dalam no 17, Kebayoran Baru, Jakarta Selatan"]})
        localStorage.setItem('nav', 'fnd')
    },[])

    useEffect(() => {
        if (firstUpdate2.current) {
            firstUpdate2.current = false;
        }else{
            setLoading(false)
            setPrefix(detail.prefix)
            // setPhoneNumber(detail.phoneNumber)
        }
    },[detail])

    const openPopup = (e) => {
        if(e==="prefix"){
            setShowPrefix(true)
            setShowAddress(false)
        }else{
            setShowAddress(true)
            setShowPrefix(false)
        }
    }

    useEffect(()=>{
        if (firstUpdate.current) {
            firstUpdate.current = false;
        }else{
            var elem = document.getElementById("PSVprefix");
            if(showPrefix===true){
                elem.className = "PSVprefix PSVyellow";
            }else{
                elem.className = "PSVprefix";
            }
        }
    },[showPrefix])

    useEffect(()=>{
        if (firstUpdate3.current) {
            firstUpdate3.current = false;
        }else{
            var elem = document.getElementById("PSVchoose");
            var elem2 = document.getElementById("PSVchoosedown");
            elem2.style.transition = "transform 0.5s"
            var defaultclassname = elem.className
            if(showAddress===true){
                elem.className = defaultclassname + " PSVyellow"
                elem2.style.transform = "rotate(180deg)"
            }else{
                elem.className = defaultclassname.replace("PSVyellow","")
                elem2.style.transform = "rotate(0deg)"
            }
        }
    },[showAddress])

    const handleChooseAddress = (e,addressId) => {
        setAddressChoosen(e)
        setIsAddressChoosen(true)
        var elem = document.getElementById("PSVchoose");
        elem.className = "PSVchoose tiny black flex";
        var elem2 = document.getElementById("PSVenterAddress");
        // elem2.disabled = true
        setAddressId(addressId)
        setAddress(e)
    }

    const callAPIbookingEvent = async (isAddressHasChoosen) => {
        var axios = require('axios');
        var FormData = require('form-data');
        const formDataLogin = new FormData();
        formDataLogin.append("menu_id", localStorage.getItem("menuId"))
        formDataLogin.append("date",localStorage.getItem("tanggal"))
        formDataLogin.append("times",localStorage.getItem("jam"))
        formDataLogin.append("guest",localStorage.getItem("pax"))
        formDataLogin.append("amount",localStorage.getItem("totalAfter"))
        formDataLogin.append("voucher_id",localStorage.getItem("voucherId"))
        formDataLogin.append("id_veneu_user",addressId)
        axios({
            method: 'POST',
            url: baseUrl + 'chef/setBooking',
            data : formDataLogin,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('taplakToken')}` 
            },
            withCredentials:false
        })
        .then(function (response) {
            
            if(response.data.status === true){
                // console.log("Berhasil")
                // console.log(response.data.message)
                // console.log(response.data.booking_id)
                localStorage.setItem('bookingId',response.data.message.booking_id)
                localStorage.setItem('userName',response.data.message.user_order.name)

                if(isAddressHasChoosen){
                    // console.log("is address chosen")
                    if(addressChoosen !== address){
                        // console.log("different address chosen")
                        handleClickToOpen()
                        // callSetNewAddress()
                    } else {
                        // console.log("same address chosen")
                        setstandby(false)
                    }
                } else {
                    // console.log("new enter address chosen")
                    callSetVenue(response.data.message.booking_id,isAddressHasChoosen)
                    // callSetNewAddress()
                    // setstandby(false)
                }

                // callSetVenue(response.data.message.booking_id,isAddressHasChoosen)
                // setstandby(false)
            } else {
                console.log("Gagal booking event")
                alert("Session Login anda sudah Expired, Silahkan Login ulang");
            }
        })
            .catch(function (error) {
            console.log(error.response.data.message);
            alert(error.response.data.message);
        });
    }

    const handleSubmit = () => {
        let isError=false
        var elem = document.getElementById("PSVphone");
        var elem2 = document.getElementById("PSVchoose");
        var elem3 = document.getElementById("PSVname");
        var elem4 = document.getElementById("PSVenterAddress")
        if(phoneNumber === undefined || phoneNumber === ''){
            elem.className = "PSVphone tiny PSVred";
            // console.log("ini phone number gak ada"+phoneNumber)
            isError=true
            setval1(true)
        }else{
            // console.log("ini phone number ada"+phoneNumber)
            elem.className = "PSVphone tiny";
            setval1(false)
        }if(addressChoosen === "Select Address"){
            if(address === ''){
                elem2.className = "PSVchoose verytiny grey flex PSVred";
                elem4.className = "PSVform tiny PSVred"
                isError=true
                setval2(true)
            }else{
                elem2.className = "PSVchoose verytiny grey flex";
                elem4.className = "PSVform tiny"
                setval2(false)
            }
        }else{
            elem2.className = "PSVchoose verytiny grey flex";
            elem4.className = "PSVform tiny"
            setval2(false)
        }if(venueName===''){
            elem3.className = "PSVform tiny PSVred";
            isError=true
            setval3(true)
        }else{
            elem3.className = "PSVform tiny";
            setval3(false)
        }if(type===''){
            isError=true
            setval4(true)
        }else{
            setval4(false)
        }
        // if(!image || !image2 || !image3){
        //     isError=true
        //     setval5(true)
        // }else{
        //     setval5(false)
        // }

        if(isError){
            return
        }else{
            // console.log(type)
            // console.log(venueName)
            // console.log(address)
            // console.log(addressDetail)

            localStorage.setItem('prefix', prefix)
            localStorage.setItem('phoneNumber', phoneNumber)
            localStorage.setItem('addressChoosen', addressChoosen)
            localStorage.setItem('address',address)
            localStorage.setItem('venueName',venueName)
            localStorage.setItem('type', type)
            localStorage.setItem('addressDetail', addressDetail)
            localStorage.setItem('addressId', addressId)
            localStorage.setItem('isAddressChoosen', isAddressChoosen)
            // localStorage.setItem('photoVenue', photoVenue)
            localStorage.setItem('image1', JSON.stringify(image))
            localStorage.setItem('isBookingChefStillProggressVenue',true)

            if(localStorage.getItem('isAddressChoosen')){
                // console.log("is address chosen")
                if(addressChoosen !== address){
                    // console.log("different address chosen")
                    handleClickToOpen()
                    // callSetNewAddress()
                } else {
                    // console.log("same address chosen")
                    localStorage.setItem('isAggreAddNewAddress', false)
                    setstandby(false)
                }
            } else {
                // console.log("new enter address chosen")
                // callSetVenue(response.data.message.booking_id,isAddressHasChoosen)
                // callSetNewAddress()
                localStorage.setItem('isAggreAddNewAddress', true)
                setstandby(false)
            }

            // callAPIbookingEvent(isAddressChoosen)
            // callSetVenue()
            
            // setstandby(false)
        }
    }

    const [standby, setstandby] = useState(true)
    useEffect(()=>{  
        if(standby===false){
            setTimeout(function() {
                history("/category/chefList/detailChef/detailMenu/setSchedule/setVenue/submitBooking",{state : { menus:menus, image1:image, image2:image2,image3:image3}})
           }, 500);
        }
    },[standby])

    const handleCloseAll = () => {
        setShowAddress(false)
        setShowPrefix(false)
        setShowNavPopup(false)
    }

    const setNewAddress = () => {
        localStorage.setItem('isAggreAddNewAddress', true)
        handleToClose()
        setstandby(false)
        // callSetNewAddress()
    }

    const notSetNewAddress = () => {
        localStorage.setItem('isAggreAddNewAddress', false)
        handleToClose()
        setstandby(false)
    }

    const getListAddress = async () => {
        try {
            const {data:res  } = await axios({
                method:'get',
                // url: `${baseUrl}category/type/chef`,
                url: `${baseUrl}veneu`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('taplakToken')}` 
                }
            })
            // console.log("get list address")
            // console.log(res.data)
            const listAddress = res.data
            const parsedListAddress = parseListAddress(listAddress)
            // console.log("get list address => "+parsedListAddress.prefix)
            // console.log("get list address => "+parsedListAddress.phoneNumber)
            setDetail(parsedListAddress)
        } catch (error){
            alert("Session Login anda sudah Expired, Silahkan Login ulang");
            // window.history.back();
            callApiLogout()
            console.log(error)
            console.log("error getDetailChefFromDB")
        }
    }

    const callApiLogout = async () => {
        // var axios = require('axios');
        // axios({
        //   method: 'POST',
        //   url: baseUrl + 'logoutCustomer',
        //   withCredentials:false,
        //   headers: {
        //     'Authorization': 'Bearer ' + localStorage.getItem('taplakToken')
        //   }
        // })
        // .then(function (response) {
            console.log("batasssss")
            // console.log(JSON.stringify(response.data));
            localStorage.setItem('TaplakLoggedIn', 'false');
            localStorage.removeItem("taplakToken")
            localStorage.removeItem("username")
            window.history.back();
            // window.location.reload();
        // })
        //     .catch(function (error) {
        //     console.log(error);
        // });
      }

    const parseListAddress = (listAddress) =>{
        return {
            prefix :"+62",
            phoneNumber : "89567488573",
            listAddress : parseAddress(listAddress)
        }
    }

    const parseAddress = (listAddress) =>{
        listAddress = listAddress.map((el) => {
            return {
                id : el.id_veneu_user,
                address : el.address
            }
        })

        return listAddress
    }

    const callSetNewAddress = async () => {
        
        var axios = require('axios');
        var FormData = require('form-data');
        const formDataSetvenue = new FormData();
        formDataSetvenue.append("phone_number",phoneNumber)
        formDataSetvenue.append("address",address)
        formDataSetvenue.append("address_details",addressDetail)
        
        // formDataSetvenue.append("venue_image",value)
        axios({
            method: 'POST',
            url: baseUrl + 'veneu/store',
            data : formDataSetvenue,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('taplakToken')}` 
            },
            withCredentials:false
        })
        .then(function (response) {
            
            // console.log("response set book")
            // console.log(response.data.status)
            if(response.data.status === true){
                // console.log("Berhasil set new Address")
                // handleToClose()
                setstandby(false)
            } 
            // console.log(response.data.access_token)
            // localStorage.setItem('TaplakLoggedIn', 'true')
            // localStorage.setItem('username',capitalize(response.data.user.name))
            // localStorage.setItem('taplakToken',response.data.access_token)
            // window.location.reload()
            // fungsi()
            // console.log(JSON.stringify(response.data));
        })
            .catch(function (error) {
            console.log(error);
            console.log("gagal set new address");
            // setValLog3(false)
        });
    }

    const validatePhoneNumber = (value) => {
        // If the input starts with 0, reject the change
        if (value.startsWith('0')) {
          return false;
        }
        // Otherwise, accept the change
        return true;
      };
    
      const handlePhoneNumberChange = (event) => {
        const newValue = event.target.value;
    
        // Check if the new value is valid
        if (validatePhoneNumber(newValue)) {
          setPhoneNumber(newValue);
        }
        // If not valid, you can choose to show an error message or take other actions
      };


    const callSetVenue = async (booking_id,isAddressHasChoosen) => {
        let value = [];
        if(!image || !image2 || !image3){
            value = ""
        } else {
            value = [image,image,image3]
        }
        // value = value.map(el =>{
        //     return {
        //         venue_image: el,
        //     }
        // })
        var axios = require('axios');
        var FormData = require('form-data');
        const formDataSetvenue = new FormData();
        formDataSetvenue.append("booking_id", booking_id)
        formDataSetvenue.append("phone_number",phoneNumber)
        formDataSetvenue.append("veneu_profile",type)
        formDataSetvenue.append("address",address)
        formDataSetvenue.append("address_details",addressDetail)
        if(value === ""){
            formDataSetvenue.append("venue_image",value)
        } else {
            value.map(el =>{
                formDataSetvenue.append("venue_image",el)
            })
        }
        
        // formDataSetvenue.append("venue_image",value)
        axios({
            method: 'POST',
            url: baseUrl + 'chef/setVeneuBooking',
            data : formDataSetvenue,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('taplakToken')}` 
            },
            withCredentials:false
        })
        .then(function (response) {
            
            // console.log("response set book")
            // console.log(response.data.status)
            if(response.data.status === true){
                console.log("Berhasil set vennue")
                
                
                setstandby(false)
            } 
            // console.log(response.data.access_token)
            // localStorage.setItem('TaplakLoggedIn', 'true')
            // localStorage.setItem('username',capitalize(response.data.user.name))
            // localStorage.setItem('taplakToken',response.data.access_token)
            // window.location.reload()
            // fungsi()
            // console.log(JSON.stringify(response.data));
        })
            .catch(function (error) {
            console.log(error);
            // setValLog3(false)
        });
    }

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );
    const [animated, setAnimated] = useState(false);
    const [visible, setVisible] = useState(false);
    const pushInfo = async () => {
        setVisible(true);
        setAnimated(true);
        document.querySelector("body").style.overflow='hidden';
    };
    const closeInfo = async () =>{
        setAnimated(false);
        await delay(500);
        setVisible(false);
        document.querySelector("body").style.overflow='auto';
    }
    return (
        <div onClick={e => {e.stopPropagation();handleCloseAll()}} id="maincontainer" style={{minWidth:"100%", minHeight:"100vh", backgroundColor:"#111111", position:"relative", overflow:"unset"}}>
            <DesktopNavbar showPopup={showNavPopup} setShowPopup={setShowNavPopup}></DesktopNavbar>
            <FloatingWhatsAppCustom></FloatingWhatsAppCustom>
            <img onClick={e => {e.preventDefault();e.stopPropagation();history(-1)}} className='backmobile' alt="back" src={circleback}></img>
            {
                loading?
                <img alt='loading' className='taplakLoading' src={spinner}></img>
                :
                <CSSTransition in={standby} timeout={{appear:1000,enter:2000,exit:1000}} mountOnEnter={true} appear={true} classNames="PSVtrans">
                <div className='PSVcontainer'>
                    {
                        visible ?
                        <div className={`informasiWrapper ${animated ? 'informasiIn' : 'informasiOut'}`}>
                            <div className='informasiHead'>
                                <i class='bx bx-info-circle'></i>
                                <h1>Information</h1>
                            </div>
                            <div className='informasiBody'>
                                <p><span>Venue Picture : </span>Kindly upload a picture of your kitchen, dining room or any other service are so our talents can evaluate what they need to bring or prepare prior to arrival.</p>
                            </div>
                            <div className='informasiFooter'>
                                <button onClick={closeInfo}>Tutup</button>
                            </div>
                        </div>
                        :
                        null
                    }
                    <div className={`backdrop ${visible ? 'block' : 'hidden'}`} onClick={closeInfo}></div>
                    <p className='big bold white PSVtittle'>Set Venue</p>
                    <Dialog open={open} onClose={notSetNewAddress}>
                        <DialogTitle>{"Simpan Alamat Baru?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Anda menambahkan alamat baru, apakah anda ingin menyimpan alamat ini ?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={setNewAddress}
                                color="primary" autoFocus>
                                Ya, Simpan
                            </Button>
                            <Button onClick={notSetNewAddress}
                                color="primary" autoFocus>
                                Tidak
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <div className='PSVflex flex'>
                        <div className='PSVkontainerkiri'>
                            <div className='PSVkontenwrapper'>
                                <p className='medium'>Phone Number</p>
                                <div className='flex' style={{marginTop:"8px"}}>
                                    <div id="PSVprefix" onClick={e => {e.preventDefault();e.stopPropagation();showPrefix?setShowPrefix(false):openPopup("prefix")}} className='PSVprefix'>
                                        <p className='tiny'>{prefix}</p>
                                        {
                                            showPrefix && (
                                                <div className='PSVpopup'>
                                                    {prefixAvailable.map((post) => (
                                                        <p onClick={e => {e.preventDefault();e.stopPropagation();setPrefix(post);setShowPrefix(false)}} className='tiny' style={{marginBottom:"8px", cursor:"pointer"}}>{post}</p>
                                                    )
                                                    )}
                                                </div>
                                            )
                                        }
                                    </div>
                                    <input onClick={e => {e.preventDefault();e.stopPropagation();handleCloseAll()}} id="PSVphone" type="text" className='PSVphone tiny' onChange={handlePhoneNumberChange} value={phoneNumber} placeholder="Phone Number"></input>
                                </div>
                                {
                                    val1 && (
                                        <p className='bold verytiny' style={{color:"#FA591D", marginTop:"8px", marginBottom:"8px", textAlign:"right"}}>Please input your phone number!</p>
                                    )
                                }
                                <p className='medium PSVmargin'>Choose Address</p>
                                <div  id="PSVchoose" onClick={e => {e.preventDefault();e.stopPropagation();showAddress?setShowAddress(false):openPopup("address")}} className='PSVchoose verytiny grey flex' style={{marginTop:"8px"}}>{addressChoosen}
                                <img id="PSVchoosedown"  alt='icon' className='PSVicon3' src={down}></img>
                                </div>
                                
                                {
                                    val2 && (
                                        <p className='bold verytiny' style={{color:"#FA591D", marginTop:"8px", marginBottom:"8px", textAlign:"right"}}>Please select the address or input manually on "Enter Address"!</p>
                                    )
                                }
                                <div style={{position:"relative"}}>
                                {
                                    showAddress && (
                                        <div className='PSVpopup' style={{marginTop:"8px"}}>
                                            {detail.listAddress.map((post) => (
                                                <p onClick={e => {e.preventDefault();e.stopPropagation();handleChooseAddress(post.address,post.id);setShowAddress(false)}} className='tiny' style={{cursor:"pointer"}}>{post.address}</p>
                                            )
                                            )}
                                        </div>
                                    )
                                }
                                </div>
                                <div className='orSetVenue'>Or</div>
                                <p className='medium'>Enter New Address</p>
                                <textarea onClick={e => {e.preventDefault();e.stopPropagation();handleCloseAll()}} id="PSVenterAddress" type='text' className='PSVform tiny' rows="5" style={{marginTop:"8px"}} value={address} onChange={e => setAddress(e.target.value)} placeholder="Enter a Location"></textarea>
                                <p className='medium PSVmargin'>Venue Name</p>
                                <input onClick={e => {e.preventDefault();e.stopPropagation();handleCloseAll()}} id="PSVname" type='text' className='PSVform tiny' style={{marginTop:"8px"}} value={venueName} onChange={e => setVenueName(e.target.value)} placeholder="*i.e Jason's House / My Villa"></input>
                                {
                                    val3 && (
                                        <p className='bold verytiny' style={{color:"#FA591D", marginTop:"8px", marginBottom:"8px", textAlign:"right"}}>Please input your venue name!</p>
                                    )
                                }
                                <p className='medium PSVmargin'>Select Venue Profile</p>
                                <div className='flex' style={{marginTop:"8px"}}>
                                    {type==="home"?
                                        <div className='PSVprofile2'>
                                            <img alt="icon" className='PSVicon' src={home}></img>
                                            <p className='verytiny'>Home</p>
                                            <img alt='icon' className='PSVicon2' src={yellowcheck}></img>
                                        </div>
                                    :
                                    <div onClick={e => {e.preventDefault();e.stopPropagation();setType('home');handleCloseAll();}} className='PSVprofile'>
                                        <img alt="icon" className='PSVicon' src={home}></img>
                                        <p className='verytiny'>Home</p>
                                    </div>
                                    }

                                    {type==="apart"?
                                    <div className='PSVprofile2' style={{marginLeft:"auto"}}>
                                        <img alt="icon" className='PSVicon' src={apart}></img>
                                        <p className='verytiny'>Apartment</p>
                                        <img alt='icon' className='PSVicon2' src={yellowcheck}></img>
                                    </div>
                                    :
                                    <div onClick={e=>{e.preventDefault();e.stopPropagation();setType('apart');handleCloseAll()}} className='PSVprofile' style={{marginLeft:"auto"}}>
                                        <img alt="icon" className='PSVicon' src={apart}></img>
                                        <p className='verytiny'>Apartment</p>
                                    </div>
                                    }
                                
                                    {
                                        type==="office"?
                                        <div className='PSVprofile2' style={{marginLeft:"auto"}}>
                                            <img alt="icon" className='PSVicon' src={office}></img>
                                            <p className='verytiny'>Office</p>
                                            <img alt='icon' className='PSVicon2' src={yellowcheck}></img>
                                        </div>
                                        :
                                        <div onClick={e => {e.preventDefault();e.stopPropagation();setType('office');handleCloseAll();}} className='PSVprofile' style={{marginLeft:"auto"}}>
                                            <img alt="icon" className='PSVicon' src={office}></img>
                                            <p className='verytiny'>Office</p>
                                        </div>
                                    } 

                                    {
                                        type==="others"?
                                        <div className='PSVprofile2' style={{marginLeft:"auto"}}>
                                            <img alt="icon" className='PSVicon' src={villa}></img>
                                            <p className='verytiny'>Others</p>
                                            <img alt='icon' className='PSVicon2' src={yellowcheck}></img>
                                        </div>
                                        :
                                        <div onClick={e => {e.preventDefault();e.stopPropagation();setType('others');handleCloseAll();}} className='PSVprofile' style={{marginLeft:"auto"}}>
                                            <img alt="icon" className='PSVicon' src={villa}></img>
                                            <p className='verytiny'>Others</p>
                                        </div>
                                    } 
                                    
                                </div>
                                {
                                    val4 && (
                                        <p className='bold verytiny' style={{color:"#FA591D", marginTop:"8px", marginBottom:"8px", textAlign:"right"}}>Please select your venue type!</p>
                                    )
                                }
                            </div>
                        </div>

                        <div className='PSVkontainerkiri'>
                            <div className='PSVkontenwrapper'>
                                {/* <p className='medium'>Enter Address</p>
                                <textarea onClick={e => {e.preventDefault();e.stopPropagation();handleCloseAll()}} id="PSVenterAddress" type='text' className='PSVform tiny' rows="5" style={{marginTop:"8px"}} value={address} onChange={e => setAddress(e.target.value)} placeholder="Enter a Location"></textarea> */}
                                <p className='medium PSVmargin'>Address Detail</p>
                                <textarea onClick={e => {e.preventDefault();e.stopPropagation();handleCloseAll()}} type='text' className='PSVform tiny' rows="5" style={{marginTop:"8px"}} value={addressDetail} onChange={e => setAddressDetail(e.target.value)} placeholder="Kindly enter any informatioon about your Venue/Kitchen/Dining Area that will help the Taplak partner maximise eour experience"></textarea>
                                <div className='infoPush PSVmargin gap-5'>
                                    <p className='medium'>Venue Picture (Optional)</p>
                                    <div onClick={e => pushInfo()} className='button'>
                                        i
                                    </div>
                                </div>
                                <div className='flex justify-between' style={{marginTop:"8px"}}>
                                    <div style={{width:"30%"}}>
                                        <label  for="inputFile" style={{backgroundImage:`url(${imgprev})`}} className='PSVimageadd'>
                                            {imgprev?
                                                <img onClick={(e) => {e.preventDefault();e.stopPropagation();setImage();setImgprev()}} alt="icon" className='PSVicon2' src={close}></img>
                                                :
                                                <img alt="icon" className='PSVicon' src={imageAdd}></img>
                                            }
                                        </label>  
                                        <label for="inputFile" className='verytiny PSVimagebutton full-center'>Choose file</label>
                                        <input type="file" id="inputFile" onChange={e => {e.preventDefault();e.stopPropagation();setImage(e.target.files[0]);setImgprev(URL.createObjectURL(e.target.files[0]))}} style={{display:"none"}} accept="image/*"></input>
                                    </div>
                                    
                                    <div style={{width:"30%"}}>
                                        <label for="inputFile2" style={{backgroundImage:`url(${imgprev2})`}} className='PSVimageadd'>
                                            {imgprev2?
                                                <img onClick={(e) => {e.preventDefault();e.stopPropagation();setImage2();setImgprev2()}} alt="icon" className='PSVicon2' src={close}></img>
                                                :
                                                <img alt="icon" className='PSVicon' src={imageAdd}></img>
                                            }
                                        </label>  
                                        <label for="inputFile2" className='verytiny PSVimagebutton full-center'>Choose file</label>
                                        <input type="file" id="inputFile2" onChange={e => {e.preventDefault();e.stopPropagation();setImage2(e.target.files[0]);setImgprev2(URL.createObjectURL(e.target.files[0]))}} style={{display:"none"}} accept="image/*"></input>
                                    </div>

                                    <div style={{width:"30%"}}>
                                        <label for="inputFile3" style={{backgroundImage:`url(${imgprev3})`}} className='PSVimageadd'>
                                            {imgprev3?
                                                <img onClick={(e) => {e.preventDefault();e.stopPropagation();setImage3();setImgprev3()}} alt="icon" className='PSVicon2' src={close}></img>
                                                :
                                                <img alt="icon" className='PSVicon' src={imageAdd}></img>
                                            }
                                        </label>  
                                        <label for="inputFile3" className='verytiny PSVimagebutton full-center'>Choose file</label>
                                        <input type="file" id="inputFile3" onChange={e => {e.preventDefault();setImage3(e.target.files[0]);setImgprev3(URL.createObjectURL(e.target.files[0]))}} style={{display:"none"}} accept="image/*"></input>
                                    </div>
                                </div>
                                {
                                    val5 && (
                                        <p className='bold verytiny' style={{color:"#FA591D", marginTop:"16px", marginBottom:"8px", textAlign:"right"}}>Please input 3 photos of your venue!</p>
                                    )
                                }
                            </div>
                            <button onClick={e => {e.preventDefault();e.stopPropagation();handleSubmit()}} className='bold small PSVbutton PSVmargin'>Continue</button>
                            {/* <button onClick={e => {e.preventDefault();e.stopPropagation();handleClickToOpen()}} className='bold small PSVbutton PSVmargin'>Continuess</button> */}
                        </div>
                    </div>

                </div>
                </CSSTransition>
            }  
        </div>
    )
  }