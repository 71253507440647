import './index.css';
import { DesktopNavbar } from '../NavBar/Desktop/DesktopNavbar'
import { FloatingWhatsAppCustom } from '../FloatingWhatsAppCustom/FloatingWhatsAppCustom';
import { MenuCards } from "../Card/MenuListNew/MenuCard.js";
import { ChefBanner } from "../Banner/chefBanner.js";
import search from '../../assets/search.svg';
import forkspoonWhite from '../../assets/forkspoon2.svg';
import sort from '../../assets/options.svg';
import sortGray from '../../assets/options_gray.svg';
import pin from '../../assets/pin-1.svg' ;
import pinGray from '../../assets/pin-1_gray.svg';
import spinner from '../../assets/spinner.gif'
import { useLocation, useNavigate } from "react-router-dom";
import {useState, useEffect, useCallback, useRef} from 'react';
import search2 from '../../assets/search2.svg';
import back from '../../assets/back.svg';
import { CSSTransition } from 'react-transition-group';
import useDraggableScroll from 'use-draggable-scroll';
import { baseUrl } from '../../lib/api'
import axios from 'axios'

export const MenuList = () =>{
    const [openLocation, setOpenLocation] = useState(false);
    const [openSortBy, setOpensortBy] = useState(false);
    const [showNavPopup, setShowNavPopup] = useState(false);
    const [showSearchMobile, setShowSearchMobile] = useState(false);
    const [showSearchMobileClass, setShowSearchMobileClass] = useState(false);
    const [showSortByMobile, setShowSortByMobile] = useState(false);
    const [showSortByMobileClass, setShowSortByMobileClass] = useState(false);
    const [menus, setMenus] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const { state }  = useLocation()
    const [locationRefereces, setLocationReferences] = useState([])
    const [filteredMenus, setFilteredMenus] = useState([])
    const [filterMenu, setFilterMenu] = useState('')
    const [sortBy, setSortBy] = useState('Sort by')
    const [filterListCategoryElement, setfilterListCategoryElement] = useState("")
    const [category, setCategory] = useState([])
    const [filterLocation, setFilterLocation] = useState('Location')
    const [standby, setstandby] = useState(true)
    const [indexListCategory, setIndexListCategory] = useState()
    const [isFirstStart, setIsFirstStart] = useState(true)
    const [loading, setLoading] = useState(true)
    const history = useNavigate();
    const [isFromMenu, setIsFromMenu] = useState(false)
    const firstUpdate = useRef(true);
    const iconwidth = "24px";
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );
    const itemsPerPage = 4;
    const draggableScroll = useRef(null);
    const {onMouseDown} = useDraggableScroll(draggableScroll);
    
    useEffect(() =>{
        const { dataChef,isFromMenus } = state
        window.scrollTo(0, 0)
        setLoading(true) 
        setIsFromMenu(isFromMenus)
        const menusChefFromDB = async (id_category) => {
            try {
                const data = await callApigetMenusChef(id_category)
                setMenus(data)
            } catch (error) {
                console.log(error)
                console.log("Error categoryChefFromDB")
            }
        }

        const locationReferece = async () => {
            try {
                const data = await callApigetLocation()
                setLocationReferences(data)
            } catch (error) {
                console.log(error)
            }
        }
        const fetchData = async () =>{
            setLoading(true);
            try{
                const categoryURL = `${baseUrl}category`
                const {data: response} = await axios.get(categoryURL);
                // Edit data for display reason, some photos can't be fetched
                // const finalData = response.data.map((el, idx) => {
                //     return {...el, 
                //         "cover_image": idx === 1 ? foto1 : idx === 2 ? foto2 : foto3
                //     }
                // })
                // setCategory(finalData);
                const categoryChefData = response.data
                const allCategory = {
                    "id": 0,
                    "name": "All",
                    "slug": "all",
                    "status": "active",
                    "created_at": "2023-10-18T21:43:17.000000Z",
                    "updated_at": "2023-10-19T04:24:02.000000Z"
                }
                categoryChefData.splice(0,0,allCategory)
                setCategory(categoryChefData);
                localStorage.setItem('categoryList',categoryChefData[0].name)
                menusChefFromDB(categoryChefData[0].id)
            }catch(error){
                console.error(error.message);
            }
            setLoading(false);
        }
        setIndexListCategory(0)
        fetchData()

        
        locationReferece()

        localStorage.setItem('nav', 'fnd')
        localStorage.setItem('categoryList','')
    },[])

    useEffect(() => {
        // Insert Hotjar tracking code into the head section
        const script = document.createElement('script');
        script.innerHTML = `
            (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:5070309,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `;
        document.head.appendChild(script);

        return () => {
            // Cleanup if necessary
            // document.head.removeChild(script);
        };
    }, []);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
        }else{
            setLoading(false)
        }
    },[menus])

    const totalPages = Math.ceil((isFirstStart ? menus.length :filteredMenus.length) / itemsPerPage);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const currentMenu = (isFirstStart ? menus.slice(startIndex, endIndex) : filteredMenus.slice(startIndex, endIndex));

    const handlePageChange = async (newPage,type) => {
        // console.log("new Page => "+newPage)
        window.scrollTo({top: 0, behavior: 'smooth'});
        if(type === "Back" ){
            if(newPage >= 1 ){
                setCurrentPage(newPage);
            } else {
                console.log("cannot load")
            }
        } else {
            if(newPage <= totalPages ){
                setCurrentPage(newPage);
            } else {
                console.log("cannot load")
            }
        }
    }

    const handleSortBy = (e) => {
        // // based on dummy data, it goes like this
        // Default will be acending
        // let sortedCategory = category.sort((a, b) => a.harga - b.harga)
        
        let sortedCategory = menus.sort((a, b) => b.converted_discounted_price - a.converted_discounted_price)
        if (e === "Lowest IDR - Highest IDR") {
            // sortedCategory = category.sort((a, b) => b.harga - a.harga)
            sortedCategory = menus.sort((a, b) => a.converted_discounted_price - b.converted_discounted_price)
        }
        const filteredChef = (chefName, filterMenu) => chefName.includes(`${filterMenu.toLowerCase()}`)
        sortedCategory = sortedCategory.filter(el => filteredChef(el["nama"].toLowerCase(), filterMenu))

        setIsFirstStart(false)
        setMenus(sortedCategory)
        setFilteredMenus(sortedCategory)
        setOpensortBy(false)
        setShowSortByMobile(false)
        setSortBy(e)
    }

    const callApigetMenusChef = async (id_category) => {
        try {
            const { data: res } = await axios({
                method: 'get',
                url: `${baseUrl}menus?name=&location_id=&orderd_price=&category_id=${id_category === 0 ? '': ''}`,
            })
            const menusChefData = res.data
            // console.log("getMenusChefFromDB => "+JSON.stringify(menusChefData[2].contain_data))
            const parsedMenusChef = parseMenusChef(menusChefData)
            // console.log("getMenusChefFromDB 2 => "+JSON.stringify(parsedMenusChef[2].containData))
            return parsedMenusChef
        } catch (error) {
            console.log(error)
            console.log("error getMenusChefFromDB")
        }
    }
    const callApigetLocation = async () => {
        try {
            const { data: res} = await axios({
                method: 'get',
                url: `${baseUrl}location?terms=`
            })
            const locationRef = res.data
            console.log("this is location data => "+JSON.stringify(locationRef))
            const parsedLocationRef = parseLocationRef(locationRef)
            return parsedLocationRef
        } catch (error) {
            console.log(error)
        }
    }

    const parseLocationRef = (locationRef) => {
        locationRef = locationRef.map((el) => {
            return {
                id: el.id,
                name : el.name
            }
        })
        return locationRef
    }

    const parseMenusChef = (service) => {
        service = service.map((el) => {
            return {
                id: el.id,
                idChef : el.cheff_data.id,
                namaChef : el.cheff_data.name,
                photoChef : el.cheff_data.user_image_url,
                rating : parseTotalRating(el.cheff_data.chef_rating),
                nama: el.name,
                description: el.description,
                service_image_url: el.service_image_url,
                formated_price : el.formated_price,
                formated_discounted_price : el.formated_discounted_price,
                converted_discounted_price : el.start_price,
                package_menu_id : el.package_menu_id,
                max_pax : el.max_pax,
                address: el.location.name,
                category: el.category.name,
                voucher: parseVoucher(el.voucher),
                prices: parseServicePrice(el.prices),
                menu: parseServiceMenu(el.menu),
                equipmentData: parseEquipment(el.equipment_data),
                containData: parseContain(el.contain_data),
            }
        })
        return service 
    }

    const parseTotalRating = (chef_rating) => {
        let rating = 0;
        for(const element of chef_rating){
            rating+=element.rate;
        }
        return rating
    }

    const parseVoucher = (vouchers) => {
        if(!vouchers || vouchers < 1){
            return "N/A"
        }

        vouchers = vouchers.map(el => {
            return {
                id: el["id"],
                name: el["name"],
                discount: el["discount"],
                quota: el["quota"]
            }
        })

        return vouchers
    }

    const parseServicePrice = (services_price) => {
        services_price = services_price.map(el => {
            return {
                id: el.id,
                bussines_id: el.bussines_id,
                amount : el.amount,
                min : el.min,
                max : el.max,
                
            }
        })
        return services_price
    }

    const parseServiceMenu = (services_menu) => {
        services_menu = services_menu.map(el => {
            return {
                id: el.id,
                bussines_id: el.bussines_id,
                name : el.name,
                description : el.description,
                image : el.menu_img,
                category_name:el.category_name
            }
        })
        return services_menu
    }

    const parseEquipment = (equipment) => {
        let eqipments = []
        if(!equipment || equipment < 1){
            return "N/A"
        }

        equipment = equipment.map(el => {
            if(el !== null){
                console.log("equipment yang tidak kosong => "+el.deskripsi)
                eqipments.push({
                    equipmentId: el["id_equipment"],
                    deskripsi: el["deskripsi"],
                    icon: el["icon"],
                    file_url: el["file_url"],
                })
            } else {
                console.log("equipment yang kosong => ")
                return {
                    equipmentId: null,
                    deskripsi: null,
                    icon: null,
                    file_url: null
                }
            }
            
        })

        return eqipments
    }

    const parseContain = (contain) => {
        let contains = []
        if(!contain || contain < 1){
            return "N/A"
        }

        contain = contain.map(el => {
            if(el !== null){
                console.log("equipment yang tidak kosong => "+el.deskripsi)
                contains.push({
                    containId: el["id_dietary"],
                    deskripsi: el["deskripsi"],
                    icon: el["icon"],
                    file_url: el["file_url"]
                })
            } else {
                console.log("equipment yang kosong => ")
                return {
                    containId: null,
                    deskripsi: null,
                    icon: null,
                    file_url: null
                }
            }
            
        })

        return contains
    }

    const handleCloseAll = async () =>{
        if(showNavPopup){
            setShowNavPopup(false);
        }

        if(showSearchMobile){
            setShowSearchMobileClass(false);
            await delay(200)
            setShowSearchMobile(false);
        }
        if(showSortByMobile){
            setShowSortByMobileClass(false);
            await delay(200);
            setShowSortByMobile(false);
        }

        if(openLocation){
            setOpenLocation(false);
        }

        if (openSortBy){
            setOpensortBy(false);
        }
    }

    const handleShowFilter = (e) => {
        if(e === 'location'){
            openLocation? setOpenLocation(false):setOpenLocation(true)
            setOpensortBy(false)
        }else{
            openSortBy? setOpensortBy(false):setOpensortBy(true)
            setOpenLocation(false)
        }
    }

    const handleFilterLocation = (e) => {
        setOpenLocation(false)
        setFilterLocation(e)
    }

    const handleListCategory = (e) => {
        var y = document.getElementById(localStorage.getItem('categoryList'))
        // console.log("e s=> "+e.name)
        setIndexListCategory(0)
        localStorage.setItem('categoryList',e.name)
        var x = document.getElementById(e.name)
        if(x.className === y.className){
            x.className = "categorylist underline"
            y.className = "categorylist underline"
        } else {
            x.className = "categorylist underline"
            y.className = "categorylist"
        }
        
        // setSelectedSpeciality(e.id)
        const categoryMenuFromDB = async (id_speciality) => {
            try {
                /*
                Params is array of object
                [
                    {
                        "location": "Lake Cel"
                    },
                    {
                        "genre": "indonesian"
                    }
                ]
                */
                const data = await callApigetMenusChef(id_speciality)
                // Create parser before set to state
                setSortBy('Sort by')
                setFilterLocation('Location')
                setFilterMenu('')
                setMenus(data)
                setFilteredMenus(data)
            } catch (error) {
                console.log("Error categoryChefFromDB")
            }
        }

        categoryMenuFromDB(e.id)
        setfilterListCategoryElement(e.name)
    }

    useEffect(() => {
        // Handle all filter
        const filteredData = { filterMenu, filterLocation, filterListCategoryElement}
        filterCategory(filteredData)

    }, [filterMenu, filterLocation,filterListCategoryElement])

    const filterCategory = (filter) => {
        const { filterMenu, filterLocation , filterListCategoryElement} = filter
        let filteredData = menus
        if (menus.length) {
            if (filterMenu) {
                const filteredChef = (chefName, filterMenu) => chefName.includes(`${filterMenu.toLowerCase()}`)
                filteredData = filteredData.filter(el => filteredChef(el["nama"].toLowerCase(), filterMenu))
                setIsFirstStart(false)
            }
            console.log("new filtered data => "+ filterLocation)
            if (filterLocation != "Location") {
                if (filterMenu !== "" || filter !== null) {
                    const filteredLocation = (location, filterLocation) => location.includes(filterLocation)
                    filteredData = filteredData.filter(el => filteredLocation(el["address"], filterLocation))
                    const filteredChef = (chefName, filterMenu) => chefName.includes(`${filterMenu.toLowerCase()}`)
                    filteredData = filteredData.filter(el => filteredChef(el["nama"].toLowerCase(), filterMenu))
                    setIsFirstStart(false)
                } else {
                    const filteredLocation = (location, filterLocation) => location.includes(filterLocation)
                    filteredData = filteredData.filter(el => filteredLocation(el["address"], filterLocation))
                
                    setIsFirstStart(false)
                }
                
            }

            // if (filterListCategoryElement) {
            //     const filteredListCategory = (genres, filterListCategoryElement) => genres.includes(filterListCategoryElement)
            //     filteredData = filteredData.filter(el => filteredListCategory(el["category"], filterListCategoryElement))
            // }
            
            console.log("new filtered data => "+ JSON.stringify(filteredData))

            setFilteredMenus(filteredData)
        }
    }

    const handleOpenSearchBar = () => {
        setShowSearchMobile(true);
        setShowSearchMobileClass(true);
        setShowSortByMobile(false);
    }
    const handleOpenSortBy = () => {
        setShowSearchMobile(false);
        setShowSortByMobileClass(true);
        setShowSortByMobile(true);
    }

    const [y, setY] = useState(window.scrollY);

    const handleNavigation = useCallback(
        e => {
            const window = e.currentTarget;
            setY(window.scrollY);
            if(y<=50){
                var elem = document.getElementById("penggantidiubah");
                if(elem){
                    elem.style.transition = "background-color 0.5s";
                    elem.style.backgroundColor = "transparent";
                }
            }else{
                var elem2 = document.getElementById("penggantidiubah");
                if(elem2){
                    elem2.style.transition = "background-color 0.5s";
                    elem2.style.backgroundColor = "rgba(0, 0, 0, 1)";
                }
            }
        }, [y]
    );

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);
        
        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);
    
    const [loaders, setLoader] = useState(true);
    const [texts, setText] = useState(false);
    
    const loadersFunct = async () =>{
        await delay(2000)
        setLoader(false);
        setText(true);
    }
    return(
        <div onClick={e => {e.stopPropagation();}} id="maincontainer">
            <DesktopNavbar showPopup={showNavPopup} setShowPopup={setShowNavPopup}></DesktopNavbar>
            <FloatingWhatsAppCustom></FloatingWhatsAppCustom>
            <div className="FloatingmobileNavbar" id='penggantidiubah'>
                {
                    showSearchMobile ? 
                        <div className='navbarSearch'>
                            <div className={`inner ${showSearchMobileClass ? 'popUpIn' : 'popUpOut'}`}>
                                <div className='flex gap-10 w-full'>
                                    <img src={back} onClick={e => {e.preventDefault();handleCloseAll()}} width={iconwidth} draggable="false"></img>
                                    <input placeholder="Find a Menu here..."></input>
                                </div>
                                <img src={search2} width={iconwidth} draggable="false"></img>
                            </div>
                        </div>
                    :
                    <div className="floatingNavbar">
                        <img onClick={e => {e.preventDefault();e.stopPropagation();history(-1)}} src={back} alt="iconback" width={iconwidth} draggable="false"></img>

                        <div className='flex gap-10'>
                            <img onClick={e => {e.preventDefault();e.stopPropagation();handleOpenSortBy();handleCloseAll()}} src={sort} alt="iconback" width={iconwidth}></img>
                            <img onClick={e => {e.preventDefault();e.stopPropagation();handleOpenSearchBar()}} src={search2} alt="iconSearch" width={iconwidth} draggable="false"></img>        
                        </div>
                        {
                            showSortByMobile ? 
                            <div className={`sortBy ${showSortByMobileClass ? 'popUpIn' : 'popUpOut'}`}>
                                <p onClick={e => {e.preventDefault();e.stopPropagation();handleSortBy('Highest IDR - Lowest IDR')}}>Highest IDR - Lowest IDR</p>
                                <p onClick={e => {e.preventDefault();e.stopPropagation();handleSortBy('Lowest IDR - Highest IDR')}}>Lowest IDR - Highest IDR</p>
                            </div>
                            :
                            null
                        }
                    </div>
                }
            </div>
            {
                loading ? 
                <img alt='loading' className='taplakLoading' src={spinner}></img>
                :
                <CSSTransition in={standby} timeout={{appear:1000,enter:2000,exit:1000}} mountOnEnter={true} appear={true} classNames="MLtrans">
                <div className='menuListWrapper'>
                    <div className='menuListInfo'>
                        <div>
                            <h1>Search by Menus</h1>
                            <p>Dozen of good meals are ready to give you the best taste.</p>
                        </div>
                        <div className='menuListSearch'>
                            <label htmlFor="searchMenusBig"><img src={search} draggable="false"></img></label>
                            <input id="searchMenusBig" value={filterMenu} onChange={e => setFilterMenu(e.target.value)} placeholder="Find a Menu here..."></input>
                        </div>
                        <div className='menuListFilters'>
                            <div className='filterLocation' onClick={()=> {handleShowFilter('location')}}>
                                <img src={pinGray} draggable="false"></img>
                                <p>{filterLocation}</p>
                            </div>
                            <div className='filterSortBy' onClick={()=>{handleShowFilter('sortBy')}}>
                                <img src={sortGray} draggable="false"></img>
                                <p>{sortBy}</p>
                            </div>
                        </div>

                        {
                            openLocation ? 
                            <div className={`filterFloat ${openLocation ? 'animatedFloatLocate' : ''}`}>
                                <div className='floatInner'>
                                    <div className='floatSearch'>
                                        <label htmlFor='searchLocation'><img src={search} draggable="false"></img></label>
                                        <input id='searchLocation' placeholder='Search Location...'></input>
                                    </div>
                                    <div className='floatSelect'>
                                        {
                                            locationRefereces !==0 && (
                                                locationRefereces.map((post)=>(
                                                    <p onClick={e => {e.preventDefault();e.stopPropagation();handleFilterLocation(post.name)}}>{post.name}</p>
                                                ))
                                            )
                                        }
                                        {/* <p>Jakarta</p>
                                        <p>Bekasi</p>
                                        <p>Bekasi</p>
                                        <p>Bekasi</p>
                                        <p>Bekasi</p>
                                        <p>Bekasi</p> */}
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                        {
                            openSortBy ? 
                            <div className={`filterFloat ${openSortBy ? 'animatedFloatSort' : ''}`}>
                                <div className='floatInner'>
                                    <div className='floatSelect'>
                                        <p onClick={e => {e.preventDefault();e.stopPropagation();handleSortBy('Highest IDR - Lowest IDR')}}>Highest IDR - Lowest IDR</p>
                                        <p onClick={e => {e.preventDefault();e.stopPropagation();handleSortBy('Lowest IDR - Highest IDR')}}>Lowest IDR - Highest IDR</p>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>
                    <div className='menuListContents'>
                        {/* <div className='menuListFilters'>
                            <div className='indicatorList'>
                                <i className='bx bxs-chevrons-left bx-flashing'></i>
                                <i className='bx bxs-chevrons-right bx-flashing'></i>
                            </div>
                        <div className='inner' ref={draggableScroll}  onMouseDown={onMouseDown}>
                            
                        {category.map((post) => {
                                        if(indexListCategory === undefined){
                                            console.log("speciality => undifined cok")
                                            return (
                                                <p onClick={e => {handleListCategory(post)}} id={post.name} className='categorylist'>{post.name}</p>
                                                )
                                        } else {
                                            console.log("speciality => ada cuy")
                                            if(post === category[0]){
                                                return (
                                                    <p onClick={e => {handleListCategory(post)}} id={post.name} className='categorylist underline'>{post.name}</p>
                                                    )
                                            }else{
                                                return (
                                                    <p onClick={e => {handleListCategory(post)}} id={post.name} className='categorylist'>{post.name}</p>
                                                    )
                                            }
                                        }

                                        
                                    })}
                                    </div>
                        </div> */}
                        <div className='cardList'>
                        {
                            isFirstStart ?
                            currentMenu.length!==0 && (
                                currentMenu.map((post) => (
                                    // <MenuCards/>
                                    <MenuCards  data={post} detailChef="" isFromMenu={isFromMenu}></MenuCards>
                                ))
                            ) 
                            :
                            
                            currentMenu.length!==0 && (
                                currentMenu.map((post) => (
                                    // <MenuCards/>
                                    <MenuCards  data={post} detailChef="" isFromMenu={isFromMenu}></MenuCards>
                                ))
                            )
                        }
                            {/* <MenuCards></MenuCards>
                            <MenuCards></MenuCards>
                            <MenuCards></MenuCards>
                            <MenuCards></MenuCards>
                            <MenuCards></MenuCards>
                            <MenuCards></MenuCards>
                            <MenuCards></MenuCards>
                            <MenuCards></MenuCards> */}
                        </div>
                        {
                            totalPages === 0 ?
                            <div className='dataNotfound' onLoad={loadersFunct()}>
                                <div class={`lds-ring ${loaders ? 'inline' : 'hidden'}`}><div></div><div></div><div></div><div></div></div>
                                <p className={`pag_not_found active ${texts ? 'inline' :'hidden'}`}>Data Not Found</p>
                            </div>
                            
                            :
                            <div className='pagination'>
                                <i onClick={() => handlePageChange(currentPage - 1, "Back")} disabled={currentPage === 1} className='bx bxs-chevron-left'></i>
                                <ul>
                                    <li >{currentPage} of {totalPages}</li>
                                </ul>
                                <i onClick={() => handlePageChange(currentPage + 1,"Next")} disabled={currentPage === totalPages} className='bx bxs-chevron-right'></i>
                            </div>
                        }
                        
                        <div className='line-separator list'>
                            <div className='line-img'>
                                <img src={forkspoonWhite} alt="icon" draggable="false"></img>
                            </div>
                        </div>
                        <ChefBanner></ChefBanner>
                    </div>
                </div>

                </CSSTransition>
            }
            
        </div>
    )
}