import { DesktopNavbar } from '../NavBar/Desktop/DesktopNavbar'
import { MobileNavbar } from '../NavBar/Mobile/MobileNavbar';
import { FloatingWhatsAppCustom } from '../FloatingWhatsAppCustom/FloatingWhatsAppCustom';
import { CSSTransition } from 'react-transition-group';
import {useState, useEffect, useRef} from 'react'
import { useNavigate } from "react-router-dom";
import React from 'react';
import breadcrumbs from '../../assets/event/breadcrumbs.png';
import canapes from '../../assets/event/canapes.png';
import buffet from '../../assets/event/buffet.png';
import multiple from '../../assets/event/multiple.png';
import alcohol from '../../assets/event/alcohol.png';
import nonalcohol from '../../assets/event/nonalcoho.png';
import kitchen from '../../assets/event/kitchen.png';
import service from '../../assets/event/service.png';
import './index.css'
import circleback from '../../assets/circleback.svg'

export const TaplakEvents = ()=> {
    const [detail,setDetail] = useState()
    const [showNavPopup, setShowNavPopup] = useState(false)
    const handleCloseAll = () => {
        setShowNavPopup(false)
    }

    useEffect(() =>{
        window.scrollTo(0, 0)
        localStorage.setItem('nav', 'e')
    },[])

    const [standby, setstandby] = useState(true)
    const history = useNavigate()
    
    useEffect(()=>{
        
        if(standby===false){
            setTimeout(function() {
                // history('/')
                history('/category/chefList/detailChef/detailMenu/setSchedule/setVenue', { state: {menus: detail.menu}});
            }, 500);
        }
    },[standby])
    
    const [activeTab, setactiveTab] = useState(1);
    const [slideUp, setslideUp] = useState()
    const [slideDown, setslideDown] = useState()
    const handleChange = (getData) =>{
        topDivRef.current.scrollIntoView({behavior:"smooth", block:"start", linear:"nearest"});
        setactiveTab(getData);
        setslideDown(getData);
        setTimeout(function(){
            setslideUp(getData);
            setslideDown(false);
        },500)
    }

    const services = [
        {
            no: 1,
            name: "Food",
            title:"Food Services",
            describe:"Delight in the diverse food services Taplak offers, from canapés and family-style meals to buffets and multi-course plated dining.",
            point:[
                {
                    title:"A. Canapes",
                    describe:"Savor small, bite-sized appetizers topped with various ingredients like spreads, meats, or cheeses.",
                    img_url:canapes,
                },
                {
                    title:"B. Buffet",
                    describe:"Enjoy a meal where a variety of dishes are displayed for guests to choose from.",
                    img_url:buffet,
                },
                {
                    title:"C. Multiple Course",
                    describe:"Indulge in a curated multi-course meal at home, featuring everything from appetizers, main course, all the way to desserts.",
                    img_url:multiple,
                }
            ],
            closing:false,
            backtop: true,
        },
        {
            no: 2,
            name: "Beverage",
            title:"Beverage Services",
            describe:"Stay hydrated with our beverage service, perfect for rounding off your event.",
            point:[
                {
                    title:"A. Alcohol",
                    describe:"From custom bottled cocktails to complete bartending services, Taplak offers all the alcoholic beverage options for your event.",
                    img_url:alcohol,
                },
                {
                    title:"A. Non Alcohol",
                    describe:"Non-alcoholic drinks, such as mocktails, refreshers and many more, are also available and can be custom-tailored for your event.",
                    img_url:nonalcohol,
                },
            ],
            closing: false,
            backtop:true,
        },
        {
            no: 3,
            name: "Manpower",
            title:"Manpower Services",
            describe:"Taplak offers additional services to ensure top-notch support for your event. We provide staffing for various areas in the food and beverage industry, including servers, bartenders, and cooks.",
            point:[
                {
                    title:"A. Service Team",
                    describe:"Servers, stewards, and any other staff you need to manage the front of the house.",
                    img_url:service,
                },
                {
                    title:"B. Bar Team",
                    describe:"Bartenders and bar backs to ensure top-quality beverage and bar service.",
                    img_url:false
                },
                {
                    title:"B. Kitchen Team",
                    describe:"Cooks and supervisors to ensure smooth food production and service.",
                    img_url:kitchen,
                },
            ],
            closing: false,
            backtop:true,
        },
        {
            no: 4,
            name: "Cutleries",
            title:"Cutleries & Crockery Services",
            describe:"Elevate your event with the right cutlery, crockery, and glassware.",
            point:[],
            closing: false,
            backtop:false,
        },
        {
            no: 5,
            name: "Decor",
            title:"Decor Services",
            describe:"Discover decor services through our specialized partners in the field.",
            point:[],
            closing: false,
            backtop:false,
        },
    ]

    const topDivRef = useRef(null)
    const scrollTop= () =>{
        topDivRef.current.scrollIntoView({behavior:"smooth", block:"start", linear:"nearest"});
    }

    const handleToSetEvent = () =>{
        document.querySelector("body").style.overflow='auto';
        setstandby(false);
        setTimeout(function() {
                history("/event/setEvent", { } );
        }, 500);
    }

    const hadleToPortfolio = () =>{
        document.querySelector("body").style.overflow='auto';
        setstandby(false);
        setTimeout(function() {
                history("/event/portfolio", { } );
        }, 500);
    }

    return (
        <div>
            <DesktopNavbar showPopup={showNavPopup} setShowPopup={setShowNavPopup}></DesktopNavbar>
            <FloatingWhatsAppCustom></FloatingWhatsAppCustom>
            <div className='detailMenuNavMobile'>
                <img src={circleback} draggable="false" className='pointer' onClick={e => {e.preventDefault();e.stopPropagation();history(-1)}}></img>
            </div>
            <CSSTransition in={standby} timeout={{appear:1000,enter:2000,exit:1000}} mountOnEnter={true} appear={true} classNames="PSStrans">
                <main>
                    <section className='container first'>
                        <div className='breadcrumbs'>
                            <img src={breadcrumbs} draggable='false'></img>
                            <div className='breadcrumbs_text'>
                                <h1>Taplak Event Service</h1>
                                <p>Explanation and instructions of Taplak Event Services</p>
                            </div>
                        </div>
                    </section>
                    <section className='container separating'>
                        <div className='left__parts'>
                            <div className='button__parts'>
                                {services.map((service,index)=>{
                                    return <button className={`${activeTab == service.no ? 'active' : ''}`} key={index} onClick={e=>{handleChange(service.no)}}>{service.name}</button>
                                })}
                            </div>
                        </div>
                        <div className='right__parts'>
                            <div ref={topDivRef} className='scrollPoint'></div>
                            <div className='button__navigate'>
                                <button onClick={e=>{hadleToPortfolio()}}>
                                    <p>Our Portfolio</p>
                                    <i className='bx bx-book-reader'></i>
                                </button>
                                <button onClick={e=>{handleToSetEvent()}}>
                                    <p>Start Planning your Event</p>
                                    <i className='bx bx-right-arrow-alt'></i>
                                </button>
                            </div>
                            {services.map((service,index)=>{
                                return <div key={index} className={`${activeTab == service.no ? 'show' : 'hidden'} ${slideUp == service.no ? 'slideUp' : ''} ${slideDown == service.no ? 'slideDown' : ''}`}>
                                    <div className='title__parts'>
                                        <h1>{service.title}</h1>
                                        <p>{service.describe}</p>
                                    </div>
                                    <ul className='point__parts'>
                                        {service.point.map((points,index)=>{
                                            return <li key={index}>
                                                <h1>{points.title}</h1>
                                                <p>{points.describe}</p>
                                                {
                                                    points.img_url ?
                                                    <div className='image__point'>
                                                        <img src={points.img_url} draggable="false"></img>
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </li>
                                        })} 
                                    </ul>
                                    <div className='footer__parts'>
                                        <p>{service.closing}</p>
                                    </div>
                                    {
                                        service.backtop ?
                                        <button className='back__navigate' onClick={e=>{scrollTop()}}>
                                            <p>Back to top</p>
                                            <i className='bx bx-right-arrow-circle bx-rotate-270'></i>
                                        </button>
                                        :
                                        null
                                    }
                                </div>
                            })}
                        </div>
                    </section>
                </main>
            </CSSTransition>
        </div>
    )
}