import { DesktopNavbar } from '../NavBar/Desktop/DesktopNavbar'
import { FloatingWhatsAppCustom } from '../FloatingWhatsAppCustom/FloatingWhatsAppCustom';
import { CSSTransition } from 'react-transition-group';
import {useState, useEffect, useRef} from 'react'
import { useNavigate } from "react-router-dom";
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// styling 
import './index.css';

// icon 
import circleback from '../../assets/circleback.svg'
import forkspoonWhite from '../../assets/forkspoon2.svg'

// Foto 
import AMOR1 from "../../assets/galleries/AMOR1.jpg";
import AMOR2 from "../../assets/galleries/AMOR2.jpg";
import AMOR3 from "../../assets/galleries/AMOR3.jpg";
import AMOR4 from "../../assets/galleries/AMOR4.jpg";
import AMOR5 from "../../assets/galleries/AMOR5.jpg";
import AMOR6 from "../../assets/galleries/AMOR6.jpg";
import AMOR7 from "../../assets/galleries/AMOR7.jpg";
import AMOR8 from "../../assets/galleries/AMOR8.jpg";
import AMOR9 from "../../assets/galleries/AMOR9.jpg";

// Foto preview 
import PREV1 from "../../assets/galleries/preview/AMOR1.png";
import PREV2 from "../../assets/galleries/preview/AMOR2.png";
import PREV3 from "../../assets/galleries/preview/AMOR3.png";
import PREV4 from "../../assets/galleries/preview/AMOR4.png";
import PREV5 from "../../assets/galleries/preview/AMOR5.png";
import PREV6 from "../../assets/galleries/preview/AMOR6.png";
import PREV7 from "../../assets/galleries/preview/AMOR7.jpg";
import PREV8 from "../../assets/galleries/preview/AMOR8.jpg";
import PREV9 from "../../assets/galleries/preview/AMOR9.png";


export const EventPortfolio = () =>{
    const history = useNavigate()
    const [detail,setDetail] = useState()
    const [showNavPopup, setShowNavPopup] = useState(false)
    const [standby, setstandby] = useState(true)
    const [loadGalleries, setLoadGalleries] = useState(9);
    const [loadingAnimate, setLoadingAnimate] = useState(false);
    const topDivRef = useRef(null)
    
    useEffect(()=>{      
        if(standby===false){
            setTimeout(function() {
                history('/category/chefList/detailChef/detailMenu/setSchedule/setVenue/portfolio', { state: {menus: detail.menu}});
            }, 500);
        }
    },[standby])

    const eventsGalleries = [
        {
            src: AMOR1, name: 'astro', aspect: 'vertical', prev: PREV1,
        },
        {
            src: AMOR2, name: 'astro', aspect: 'horizontal', prev: PREV2,
        },
        {
            src: AMOR3, name: 'astro', aspect: 'square', prev: PREV3,
        },
        {
            src: AMOR4, name: 'astro', aspect: 'horizontal', prev: PREV4,
        },
        {
            src: AMOR5, name: 'astro', aspect: 'horizontal', prev: PREV5,
        },
        {
            src: AMOR6, name: 'astro', aspect: 'horizontal', prev: PREV6,
        },
        {
            src: AMOR7, name: 'astro', aspect: 'horizontal', prev: PREV7,
        },
        {
            src: AMOR8, name: 'astro', aspect: 'vertical', prev: PREV8,
        },
        {
            src: AMOR9, name: 'astro', aspect: 'square', prev: PREV9,
        }
    ]

    const loadMoreImages = () =>{
        setLoadingAnimate(true);
        setTimeout(() => {   
            setLoadGalleries(prev => prev + 3);
            setLoadingAnimate(false);
        }, 1000);
    }

    const scrollTop= () =>{
        topDivRef.current.scrollIntoView({behavior:"smooth", block:"start", linear:"nearest"});
    }

    const handleToEvents = () =>{
        document.querySelector("body").style.overflow='auto';
        setstandby(false);
        setTimeout(function() {
                history("/event/setEvent", { } );
        }, 500);
    }

    return (
        <div>
            <DesktopNavbar showPopup={showNavPopup} setShowPopup={setShowNavPopup}></DesktopNavbar>
            <FloatingWhatsAppCustom></FloatingWhatsAppCustom>
            <div className='detailMenuNavMobile'>
                <img src={circleback} draggable="false" className='pointer' onClick={e => {e.preventDefault();e.stopPropagation();history(-1)}}></img>
            </div>
            <CSSTransition in={standby} timeout={{appear:1000,enter:2000,exit:1000}} mountOnEnter={true} appear={true} classNames="PSStrans">
                <main>
                    <div ref={topDivRef} className='scrollPoint'></div>
                    <section className='container first portfolio'>
                        <div className='section_title center'>
                            <h1>Our Porftolios</h1>
                            <p>Get a glimpse of what Taplak has in store for you!</p>
                            <button onClick={e=>{handleToEvents()}}>Start Planning your Event <i className='bx bx-right-arrow-alt'></i></button>
                        </div>
                        <div className='line-separator portfolio'>
                            <div className='line-img'>
                                <img src={forkspoonWhite} alt="icon" draggable="false" loading='lazy'></img>
                            </div>
                        </div>
                        <div className='TaplakGalleries__body portfolio'>
                            {eventsGalleries.slice(0, loadGalleries).map((image, index) => (
                                <div className={`TaplakGalleries__img popups ${image.aspect === 'square' ? 'square' : image.aspect === 'vertical' ? 'vertical' : 'horizontal'}`}>
                                    <LazyLoadImage alt={image.src} src={image.src} placeholderSrc={image.prev} effect='blur' className='image' draggable='false'/>
                                </div>
                            ))}
                        </div>
                        <button className='back__navigate' onClick={e=>{scrollTop()}}>
                            <p>Back to top</p>
                            <i className='bx bx-right-arrow-circle bx-rotate-270'></i>
                        </button>
                    </section>
                </main>
            </CSSTransition>
        </div>
    )
}