import './index.css'
import { DesktopNavbar } from '../NavBar/Desktop/DesktopNavbar'
import {useState, useEffect, useRef} from 'react'
import "react-datepicker/dist/react-datepicker.css";
import foto1 from '../../assets/foto4.png'
import fototes from '../../assets/foto1.png'
import makanan1 from '../../assets/makanan1.png'
import makanan2 from '../../assets/makanan2.png'
// import porto1 from '../../assets/porto1.png'
// import porto2 from '../../assets/porto2.png'
// import porto3 from '../../assets/porto3.png'
// import porto4 from '../../assets/porto4.png'
// import porto5 from '../../assets/porto5.png'
// import porto6 from '../../assets/porto6.png'
import { useNavigate } from "react-router-dom";
import eventimg from '../../assets/eventimg.png'
import star from '../../assets/star.svg'
import ig from '../../assets/instagram.svg'
import fb from '../../assets/facebook.svg'
import circleback from '../../assets/circleback.svg'
import linkedin from '../../assets/linkedin.svg'
import { MenuCardDCP } from '../Card/DCPMenuCard/DCPMenuCard';
import { MenuCardDCPSearch } from '../Card/DCPMenuCardSearch/DCPMenuCardSearch';
import { CSSTransition } from 'react-transition-group';
import spinner from '../../assets/spinner.gif'
import closepopup from '../../assets/closepopup.svg'
import carleft from '../../assets/carleft.svg'
import carright from '../../assets/carright.svg'
import axios from 'axios';
import { baseUrl } from '../../lib/api';
import { useLocation } from 'react-router-dom';
import { FloatingWhatsAppCustom } from '../FloatingWhatsAppCustom/FloatingWhatsAppCustom';

export const PageDetailChefSearch = () => {
    const history = useNavigate()
    const firstUpdate = useRef(true);
    const firstUpdate2 = useRef(true);
    const [detail, setDetail] = useState([])
    const [longDesc, setLongDesc] = useState(false)
    const [longHistory, setLongHistory] = useState(false)
    const [standby, setstandby] = useState(true)
    const [loading, setLoading] = useState(true)
    const [porto, setPorto] = useState([])
    const [loadmore, setloadmore] = useState(false)
    const [showpopup, setshowpopup] = useState(false)
    const [index, setindex] = useState()
    const [showNavPopup, setShowNavPopup] = useState(false)
    const loc = useLocation()

    const handleCloseAll = () => {
        setShowNavPopup(false)
    }

    const handleLoadMore = () => {
        var elem = document.getElementsByName("DCPloadmore")
        if(loadmore){
            elem.className = "DCPmygallerykontainer DCPbeforeload"
            for(let i=6;i<elem.length;i++){
                elem[i].style.display = "none"
            }
            setloadmore(false)
        }else{
            elem.className = "DCPmygallerykontainer"
            for(let i=6;i<elem.length;i++){
                elem[i].style.display = "unset"
            }
            setloadmore(true)
        }
    }

    const handleChangeIndex = (e) => {
        // console.log(index)
        if(e==="minus"){
            if(index===0){
                setindex(porto.length - 1)
            }else{
                setindex(index - 1)
            }
        }else{
            if(index===(porto.length - 1)){
                setindex(0)
            }else{
                setindex(index + 1)
            }
        }
    }

    useEffect(() =>{
        window.scrollTo(0, 0)
        setLoading(true)
        
        const chefDataId = loc.state?.data.id
        const chefData = loc.state?.data
        const isFromSearch = loc.state?.isFromSearch
        
        // Hit detail chef API
        const detailChefFromDBV2 = async (chefData) => {
            try {
                const data = await callAPIGetDetailChefV2(chefData)
                return data
            } catch (error) {
                console.log("Error detailChefFromDB")
            }
        }
        
        // Hit gallery chef API
        const chefGalleryFromDBV2 = async (photos) => {
            try {
                const data = await callAPIGetChefGalleryV2(photos)
                setPorto(data)
            } catch (error) {
                console.log("Error chefGalleryFromDB")
            }
        }

        // Hit event chef API
        const chefEventFromDBV2 = async (chefData, type) => {
            try {
                const data = await callAPIGetChefEventV2(chefData, type)
                return data
            } catch (error) {
                console.log("Error chefEventFromDB")
                return []
            }
        }
        const chefDetailCombine = async (id, type) => {
            try {
                let detailChef = await detailChefFromDBV2(id)
                const upcomingEvent = await chefEventFromDBV2(id, type)
                detailChef = { ...detailChef, upcomingEvent }
                setDetail(detailChef)
            } catch (error) {
                console.log(error)
                console.log("Error happened in detail data combination")
            }
        }

        const callAPIGetdataMenuChefFromSearch = async (chefId) => {
            try {
                const data = await callAPIGetdataMenuChefFromSearchDB(chefId)
                // console.log("data menu useEffect")
                // console.log(data)
                return data
            } catch (error) {
                console.log(error)
                console.log("Error detail menu chef search")
            }
        }

        const chefDetailFromSearch = async () => {
            try {
                let detailChef = await parseDetailChefFromSearch(chefData,chefData.id)
                // console.log("hasil chef searchsad chuaks")
                // console.log(detailChef)
                const dataMenuChefFromSearch = await callAPIGetdataMenuChefFromSearch(chefData.id)
                detailChef = await parseDetailChefFromSearch2(detailChef,dataMenuChefFromSearch)
                // console.log("hasil chef searchsad")
                // console.log(detailChef)
                setDetail(detailChef)
                // console.log(detail)
            } catch (error) {
                console.log(error)
                console.log("Error happened in detail data combination")
            }
        }

        if(loc.state?.isFromSearch){
            // console.log(chefData)
            // console.log(chefData.id)
            callAPIGetdataMenuChefFromSearch(chefData.id)
            chefDetailFromSearch()
            // console.log("test from search")
            setLoading(false)
        } else {
            console.log("test from list")
            // Execution
            const _type = "finish"
            chefDetailCombine(chefData, _type)
            chefGalleryFromDBV2(chefData.chefGalery)
        }
        
        
        localStorage.setItem('nav', 'fnd')
    },[])

    useEffect(() => {
        if (firstUpdate2.current) {
            firstUpdate2.current = false;
        }else{
            if(detail.length !==0 && porto.length!==0){
                setLoading(false)
            }
        }
    },[detail,porto])

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
          } else {
            var elem = document.getElementById('DCPdeskripsichef');
            if(longDesc === true){
                elem.style.height = 'unset';
            }else{
                elem.style.height = "60px";
            }
          }
    },[longDesc])
    // Call API Version 2: used
    const callAPIGetDetailChefV2 = async (chefData) => {
        try {
            // const { data: res } = await axios({
            //     method: 'get',
            //     url: `${baseUrl}chef/${id}`
            // })
            const parsedDetailChef = parseDetailChefV2([chefData])
            
            return parsedDetailChef[0]

        } catch (error) {
            console.log(error)
            console.log("error getDetailChefFromDB")
        }
    }

    const callAPIGetChefGalleryV2 = async (photos) => {
        try {
            // const { data: res } = await axios({
            //     method: 'get',
            //     url: `${baseUrl}chef/${id}/gallery`
            // })
            // const chefGallery = ["res.data"]
            const parsedGalleryChef = parseChefGallery(photos)
            
            return parsedGalleryChef

        } catch (error) {
            console.log(error)
            console.log("error getDetailChefGalleryFromDB")
        }
    }

    const callAPIGetChefEventV2 = async (chefData, type) => {
        try {
            let params = null
            if (type) {
                params = { type }
            }
            // const { data: res } = await axios({
            //     headers: {
            //         'Content-Type': 'application/json'
            //     },
            //     method: 'get',
            //     url: `${baseUrl}chef/${id}/event`,
            //     // url: `${baseUrl}event`,
            //     // params
            // })
            const parsedChefEvent = parseChefEvent(chefData, type)

            return parsedChefEvent

        } catch (error) {
            console.log(error)
            console.log("error getDetailChefEventFromDB")
        }
    }

    // Call API Version 1
    const callApigetDetailChef = async () => {
        try {
            const { data: res } = await axios({
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'get',
                url: `${baseUrl}chef/Chef-Taplak`
            })
            const detailChefData = [res.data]
            const parsedDetailChefData= parseDetailChef(detailChefData)
            return parsedDetailChefData
        } catch (error) {
            console.log(error)
            console.log("error getDetailChefFromDB")
            // Create error handler (pop-up or something)
        }
    }

    const callApigetChefGallery = async () => {
        try {
            const { data: res } = await axios({
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'get',
                url: `${baseUrl}chef/Chef-Taplak/gallery`
            })
            const chefGallery = res.data
            const parsedChefGallery= parseChefGallery(chefGallery)
            return parsedChefGallery
        } catch (error) {
            console.log("error getChefGalleryFromDB")
            // Create error handler (pop-up or something)
        }
    }

    const callApigetChefEvent = async (type) => {
        try {
            let params = null
            if (type) {
                params = { type }
            }
            const { data: res } = await axios({
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'get',
                url: `${baseUrl}chef/Chef-Taplak/event`,
                params
            })
            const chefEvent = res.data
            const parsedChefEvent= parseChefEvent(chefEvent, type)
            return parsedChefEvent
        } catch (error) {
            console.log("error getChefEventFromDB")
            // Create error handler (pop-up or something)
        }
    }

    // Helpers
    const parseDetailChefFromSearch = (detailChef,chefId) => {
        const parseEduOrExp = (arrInput) => {
            arrInput = arrInput.map((el) => {
                // console.log(el)
                return {
                    institusi: el,
                    tahun: `2023`
                }
            })
            return arrInput
        }

        // console.log("tes console gambar cok => "+detailChef["gambar"])
        // detailChef = detailChef.map((el) => {
            return {
                id: detailChef["id"],
                nama: detailChef["nama"],
                // foto: el["cover_image"],
                foto: detailChef["gambar"],
                rating: detailChef["rating"],
                menu : parseServiceFromSearch(detailChef["jenis"]),
                // menu : callAPIGetdataMenuChefFromSearchDB(chefId),
                // menu: [
                //     {id: 1, jenis: "Asian", max: 6,harga: 150, 
                //     food:[{nama: "Beef bulgogi", gambar: makanan1},{nama: "Yaki gyoza", gambar: makanan2}]},
                //     {id: 2, jenis: "Western", max: 3, harga: 200, 
                //     food:[{nama: "Beef bulgogi", gambar: makanan2},{nama: "Yaki gyoza", gambar: makanan1}]}
                // ],
                deskripsi:  "N/A",
                bahasa: "N/A",
                instagram:  "N/A",
                facebook:"N/A",
                linkedin:  "N/A",
                // education: [...parseEduOrExp(el.educations), ...parseEduOrExp(el.experiences)]
                education:  "N/A"
            }
        // })

        return detailChef
    }

    // Helpers
    const parseDetailChefFromSearch2 = (detailChef,dataMenu) => {
        const parseEduOrExp = (arrInput) => {
            arrInput = arrInput.map((el) => {
                // console.log(el)
                return {
                    institusi: el,
                    tahun: `2023`
                }
            })
            return arrInput
        }

        // console.log("ini gambar nya => "+detailChef["gambar"])

        // detailChef = detailChef.map((el) => {
            return {
                id: detailChef["id"],
                nama: detailChef["nama"],
                // foto: el["cover_image"],
                foto: detailChef["foto"],
                rating: detailChef["rating"],
                // menu : parseFoodFromSearch2(dataMenu),
                menu : parseServiceFromSearch2(detailChef["menu"],dataMenu),
                // menu : dataMenu,
                // menu: [
                //     {id: 1, jenis: "Asian", max: 6,harga: 150, 
                //     food:[{nama: "Beef bulgogi", gambar: makanan1},{nama: "Yaki gyoza", gambar: makanan2}]},
                //     {id: 2, jenis: "Western", max: 3, harga: 200, 
                //     food:[{nama: "Beef bulgogi", gambar: makanan2},{nama: "Yaki gyoza", gambar: makanan1}]}
                // ],
                deskripsi:  "N/A",
                bahasa: "N/A",
                instagram:  "N/A",
                facebook:"N/A",
                linkedin:  "N/A",
                // education: [...parseEduOrExp(el.educations), ...parseEduOrExp(el.experiences)]
                education:  "N/A"
            }
        // })

        return detailChef
    }

    // Helpers
    const parseDetailChefV2 = (detailChef) => {
        const parseEduOrExp = (arrInput) => {
            arrInput = arrInput.map((el) => {
                // console.log(el)
                return {
                    institusi: el,
                    tahun: `2023`
                }
            })
            return arrInput
        }
        detailChef = detailChef.map((el) => {
            return {
                id: el["id"],
                nama: el["nama"],
                // foto: el["cover_image"],
                foto: el["gambar"],
                rating: el["rating"],
                menu : parseService(el["service"]),
                // menu: [
                //     {id: 1, jenis: "Asian", max: 6,harga: 150, 
                //     food:[{nama: "Beef bulgogi", gambar: makanan1},{nama: "Yaki gyoza", gambar: makanan2}]},
                //     {id: 2, jenis: "Western", max: 3, harga: 200, 
                //     food:[{nama: "Beef bulgogi", gambar: makanan2},{nama: "Yaki gyoza", gambar: makanan1}]}
                // ],
                deskripsi: el["biography"] ? el["biography"] : "N/A",
                bahasa: languagesStylesParser(el["languages"]),
                instagram: el["chefCompany"]["instagram"] ? el["chefCompany"]["instagram"] : "N/A",
                facebook: el["chefCompany"]["facebook"] ? el["chefCompany"] : "N/A",
                linkedin: el["chefCompany"]["linkedin"] ? el["chefCompany"]["linkedin"] : "N/A",
                // education: [...parseEduOrExp(el.educations), ...parseEduOrExp(el.experiences)]
                education: [...parseEduOrExp([el["chefCompany"]["company_name"]])]
            }
        })

        return detailChef
    }

    const parseService = (service) => {
        service = service.map((el) => {
            return {
                id: el.id,
                jenis: el.nama,
                max:6,
                harga:150,
                food: parseFood(el.menu)
            }
        })
        return service 
    }
    const parseServiceFromSearch = (service,chefId) => {
        service = service.map((el) => {
            return {
                id: el.id,
                jenis: el.name,
                max:el.max_pax,
                harga:el.converted_price,
                // food: callAPIGetdataMenuChefFromSearchDB(chefId)
            }
        })
        return service 
    }

    const parseServiceFromSearch2 = (service,menus) => {
        service = service.map((el) => {
            return {
                id: el.id,
                jenis: el.jenis,
                max:el.max,
                harga:el.harga,
                food: parseFoodFromSearch2(menus,el.id)
            }
        })

        // console.log("service sda => "+ service)
        return service 
    }

    const parseFood = (menus) =>{
        menus = menus.map((el) => {
            return {
                id: el.id,
                bussines_id : el.bussines_id,
                nama: el.name,
                gambar: el.image
            }
        })
        return menus
    }

    const parseFoodFromSearch = (menus) =>{
        menus = menus.map((el) => {
            return {
                id: el.id,
                bussines_id : el.bussines_id,
                nama: el.name,
                gambar: el.menu_img
            }
        })
        return menus
    }

    const parseFoodFromSearch2 = (menus,ServiceId) =>{
        var menus_fix = []
        menus = menus.map((el) => {
            // console.log("menu busines id => "+el.bussines_id)
            // console.log("service id => "+ServiceId)
            if(el.bussines_id === ServiceId) {
                // console.log("Menu yang busines id nya => "+el.bussines_id)
                // console.log(el.nama)
            // }
            menus_fix.push({
                id: el.id,
                bussines_id : el.bussines_id,
                nama: el.nama,
                gambar: el.gambar
            })

                // return {
                //     id: el.id,
                //     bussines_id : el.bussines_id,
                //     nama: el.nama,
                //     gambar: el.gambar
                // }
            } else {
                return {
                    id: null,
                    bussines_id : null,
                    nama: null,
                    gambar: null
                }
            }
            
        })
        // console.log("tes tes => "+menus_fix)
        return menus_fix
    }

    const callAPIGetdataMenuChefFromSearchDB = async (chefId) => {
        try {
            const { data: res } = await axios({
                method: 'get',
                url: `${baseUrl}dataMenuChef/${chefId}`
            })

            const dataChef = res.data.menu
            // console.log("get data menu from seach")
            // console.log(dataChef)
            // console.log([dataChef])
            const parsedDetailChef = parseFoodFromSearch(dataChef)
            
            return parsedDetailChef

        } catch (error) {
            console.log(error)
            console.log("error getDetailChefFromDB")
        }
    }

    const parseChefGallery = (chefGallery) => {
        chefGallery = chefGallery.map((el, idx) => {
            return {
                foto: el,
                // foto: idx === 1 ? porto1 : idx === 2 ? porto2 : porto3,
                nama: "chef galery",
                deskripsi: chefGallery.description ? chefGallery.description : "N/A"
            }
            // return {
            //     foto: el.image_url,
            //     // foto: idx === 1 ? porto1 : idx === 2 ? porto2 : porto3,
            //     nama: el.name,
            //     deskripsi: el.description ? el.description : "N/A"
            // }
        })
        // chefGallery = {
        //     foto: foto1,
        //     // foto: idx === 1 ? porto1 : idx === 2 ? porto2 : porto3,
        //     nama: "chef galery",
        //     deskripsi: chefGallery.description ? chefGallery.description : "N/A"
        // }
        return chefGallery
    }

    const parseTimeEvent = (dates) => {
        const timeStartArr = dates["start_time"].split(":")
        const timeEndArr = dates["end_time"].split(":")
        
        const parsedTimeStart = timeStartArr[0].concat(":", timeStartArr[1]);
        const parsedTimeEnd = timeEndArr[0].concat(":", timeEndArr[1]);

        const parsedTime = `${parsedTimeStart} - ${parsedTimeEnd}`
        return parsedTime
    }

    const dateEventParser = (dates) => {
        if (!dates || !dates.length) {
            return {
                "date": "N/A",
                "time_start": "N/A"
            }
        }
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ]
        dates = dates[0]

        // let [_, month, date] = dates["date"]
        let [_, month, date] = dates["date_events"]
        const idxMonth = Number(month)
        month = monthNames[idxMonth].slice(0, 3)
        date = Number(date)
        const parsedDate = `${date} ${month}`

        return {
            "date": parsedDate,
            "time_start": parseTimeEvent(dates)
        }
    }

    const parseChefEvent = (chefData, type) => {
        // let mapChefEvent = (el) => {
        //     return {
        //         id: el.id,
        //         gambar: el.cover_image,
        //         // gambar: eventimg,
        //         judul: el.name,
        //         name: el.name,
        //         style: languagesStylesParser(el.styles),
        //         sisa: el.remaining_pax,
        //         total: el.max_pax,
        //         tanggal: "24 Feb",
        //         jam: "18:00 - 20:00",
        //         tempat: el.venue_location
        //     }
        // }
        // if (type) {
        //     mapChefEvent = (el) => {
        //         return {
        //             id: el.id,
        //             gambar: el.cover_image,
        //             // gambar: eventimg,
        //             judul: el.name,
        //             name: el.name,
        //             style: languagesStylesParser(el.styles),
        //             sisa: el.remaining_pax,
        //             total: el.max_pax,
        //             tanggal: "24 Feb",
        //             jam: "18:00 - 20:00",
        //             tempat: el.venue_location
        //         }
        //     }
        // }
        // chefEvent = chefEvent.map(mapChefEvent)
        // return chefEvent

        chefData = chefData.chefEvent.map((el) => {
            // const { date, time_start } = dateEventParser(el.events_date)
            return {
                id: el.id,
                gambar: el.gambar,
                judul: el.judul,
                style: el.style,
                sisa: el.sisa,
                total: el.total,
                tanggal: el.tanggal,
                jam: el.jam,
                tempat: el.tempat
            }
        })
        return chefData
    }

    const stylesParser = (styles) => {
        if (!styles) {
            return "N/A"
        }
        styles = styles.join(', ').replace(/, ([^,]*)$/, ' and $1')
        return styles
    }

    const languagesStylesParser = (arrData) => {
        if (!arrData) {
            return "N/A"
        }
        arrData = arrData.join(', ').replace(/, ([^,]*)$/, ' and $1')
        return arrData
    }

    // Use it to get year for education or expoerience
    const parseYear = (date) => {
        if (!date) {
            return "Present"
        }
        const year = date.split("-")[0]
        return year
    }

    const parseDetailChef = (detailChef) => {
        const parseEduOrExp = (arrInput) => {
            arrInput = arrInput.map((el) => {
                return {
                    institusi: el.name,
                    tahun: `${parseYear(el.start_date)} - ${parseYear(el.end_date)}`
                }
            })
            return arrInput
        }
        detailChef = detailChef.map((el) => {
            return {
                nama: el.full_name,
                // foto: el.cover_image,
                foto: foto1,
                rating: el.rating,
                deskripsi: el.biography ? el.biography : "N/A",
                menu: [
                    {jenis: "Asian", max: 6,harga: 150, 
                    food:[{nama: "Beef bulgogi", gambar: makanan1},{nama: "Yaki gyoza", gambar: makanan2}]},
                    {jenis: "Western", max: 3, harga: 200, 
                    food:[{nama: "Beef bulgogi", gambar: makanan2},{nama: "Yaki gyoza", gambar: makanan1}]}
                ],
                bahasa: el.languages,
                instagram: el.instagram ? el.instagram : "N/A",
                facebook: el.facebook ? el.facebook : "N/A",
                linkedin: el.linkedin ? el.linkedin : "N/A",
                education: [...parseEduOrExp(el.educations), ...parseEduOrExp(el.experiences)],
                upcomingEvent: [
                    {gambar: eventimg, judul: "Thanksgiving", style: "American style featuring Turkey and Sides", sisa: 10, total: 20, tanggal: "24 Feb", jam: "18:00 - 20:00", tempat: "Hotel Santika, BSD City"},
                    {gambar: eventimg, judul: "Asian Party", style: "Asian Style", sisa: 3, total: 10, tanggal: "24 Feb", jam: "18:00 - 20:00", tempat: "Hotel Santika, BSD City"}]
            }
        })
        return detailChef[0]
    }

    return (
        <div onClick={e => {e.stopPropagation();handleCloseAll()}} id="maincontainer" style={{minWidth:"100%", minHeight:"100vh", backgroundColor:"#111111", position:"relative", overflow:"unset"}}>
            <DesktopNavbar showPopup={showNavPopup} setShowPopup={setShowNavPopup}></DesktopNavbar>
            <FloatingWhatsAppCustom></FloatingWhatsAppCustom>
            <img onClick={e => {e.preventDefault();e.stopPropagation();history(-1)}} className='backmobile' alt="back" src={circleback}></img>
            {
                loading?
                <img alt='loading' className='taplakLoading' src={spinner}></img>
                :
                <CSSTransition in={standby} timeout={{appear:1000,enter:2000,exit:1000}} mountOnEnter={true} appear={true} classNames="DCtrans">
                <div>
                    
                <div className='DCPkontainer'>
                    <div className='DCPdetailchefkontainer'>
                        {/* <img alt="new" src={detail.foto} class="DCPfotochef"></img> */}
                        <div style={{backgroundImage:`url(${detail.foto})`}} className='DCPfotochef'></div>
                        {/* <div style={{backgroundImage:`url(${detail.gambar})`}} className='DCPfotochef'></div> */}
                        <div className='DCPkontainertengah'>
                            <div className='DCPnamarate'>
                                    <p className='DCPnama'>{detail.nama}</p>
                                    <img src={star} alt="icon" className='DCPiconrate'></img>
                                    <p className='DCPrate'>{detail.rating}</p>
                                </div>
                                <div id="DCPdeskripsichef" className='DCPdeskripsichef'>
                                    {detail.deskripsi}
                                    {
                                        longDesc?
                                        <p onClick={() => setLongDesc(false)} className='DCPshowmore2'>Show Less</p>
                                        :
                                        <p onClick={() => setLongDesc(true)} className='DCPshowmore1'>Show More</p>
                                    }
                                </div>
                                <div className='mobileline'></div>
                                <div className='DCPtypelistkontainer'>
                                    <p className='DCPapa'>Type of cuisine</p>
                                    <div className='DCPtypelist'>
                                    
                                        {detail.length!==0 && (
                                            detail.menu.map((post) => {
                                                if(post.jenis === detail.menu[0].jenis){
                                                    return(<p>{post.jenis} cuisine</p>)

                                                }else{
                                                    return(<p>, {post.jenis} cuisine</p>)
                                                }
                                            })
                                        )}
                                    </div>
                                </div>

                                {/* <div className='DCPtypelistkontainer'>
                                    <p className='DCPapa'>Spoken</p>
                                    <div className='DCPtypelist'>
                                        {detail.length!==0 && (
                                            <p>{ detail.bahasa }</p>
                                        )}
                                    </div>
                                </div> */}

                                {/* <div className='mobileline'></div>
                                
                                    {detail.length!==0 && (
                                            <div className='DCPSocialMediaContainer'>
                                                <a href={detail.instagram}><img className='DCPicon' alt="icon" src={ig}></img></a>
                                                <a href={detail.facebook}><img alt="icon" className='DCPicon' src={fb}></img></a>
                                                <a href={detail.linkedin}><img alt="icon" className='DCPicon' src={linkedin}></img></a>
                                            </div>
                                        )}

                                <div className='mobileline'></div> */}
                        </div>
                        {/* <div className='DCPkontenkanandetail'>
                            <div className='DCPeducation'>Education & Work
                                {
                                    longHistory?
                                    <p onClick={() => setLongHistory(false)} className='DCPshowmore1'>Show Less</p>
                                    :
                                    <p onClick={() => setLongHistory(true)} className='DCPshowmore1'>Show More</p>
                                }
                            </div>

                            {
                                (detail.length!==0 && longHistory===false) && (
                                    <div className='mobileonly'>
                                    <p className='DCPinstitusi'>{detail.education[0].institusi}</p>
                                    <p className='DCPtahun'>{detail.education[0].tahun}</p>
                                    </div>
                                )
                            }

                            {
                                (detail.length!==0 && longHistory) && (
                                    detail.education.map((post) => (
                                        <div className='mobileonly'>
                                        <p className='DCPinstitusi'>{post.institusi}</p>
                                        <p className='DCPtahun'>{post.tahun}</p>
                                        </div>
                                    ))
                                )
                            }

                            {
                                (detail.length!==0) && (
                                    detail.education.map((post) => (
                                        <div className='desktoponly'>
                                        <p className='DCPinstitusi'>{post.institusi}</p>
                                        <p className='DCPtahun'>{post.tahun}</p>
                                        </div>
                                    ))
                                )
                            }
                            
                            <div className='mobileline'></div>
                        </div> */}
                    </div>
                    {/* <p className='DCPpilihanmenu white fontbold'>My Gallery</p>

                    <div className='DCPmygallery flexwrap'>
                        {
                            porto.map((post, i) => (
                                <div name="DCPloadmore" className='DCPmygallerykontainer DCPbeforeload'>
                                    <div onClick={e => {e.preventDefault();e.stopPropagation();setindex(i);setshowpopup(true)}} style={{backgroundImage:`url(${post.foto})`}} className='DCPmygalleryimg'>
                                        <div className='DCPgradient'></div>
                                        <p className='tiny white DCPimgtext'>{post.nama}</p>
                                    </div>
                                </div>
                            ))
                        }
                        {
                            loadmore?
                                <p className='tiny' onClick={() => handleLoadMore()} style={{cursor:"pointer",marginTop:"16px",width:"100%",textAlign:"center",color:"#AAAAAA"}}>SHOW LESS</p>
                            :
                                <p className='tiny' onClick={() => handleLoadMore()} style={{cursor:"pointer",marginTop:"16px",width:"100%",textAlign:"center",color:"#AAAAAA"}}>LOAD MORE</p>
                        }
                        
                    </div> */}

                    <div className='kontainercardWrapper detailChef'>
                        <div className='kontainerHeader'>
                            <h1>Pilihan Menu</h1>
                        </div>
                        <div className='kontainerList menuChef'>
                                
                                {
                                    detail.length!==0 && (
                                        detail.menu.map((post) => (
                                            <MenuCardDCP data={post} chefId={detail.id} chefRating={detail.rating} chefName={detail.nama} chefFoto={detail.foto} standby={standby} fungsi={()=>setstandby(false)} />
                                        ))
                                    )
                                }                            
                        </div>
                    </div>

                    {/* <p className='DCPpilihanmenu white fontbold'>Upcoming Event</p> */}

                </div>
                {/* <div className="DCPupcomingkontainerList">
                {detail.length!==0 && (detail.upcomingEvent.map((post) => (
                    <HomeTypeCard
                        id = {post.id}
                        gambar = {post.gambar} 
                        judul = {post.judul} 
                        style = {post.style} 
                        sisa = {post.sisa} 
                        total = {post.total} 
                        tanggal = {post.tanggal} 
                        jam = {post.jam} 
                        tempat = {post.tempat}
                        fungsi={()=>setstandby(false)} 
                    />
                    )))}
                </div> */}
                </div>
                </CSSTransition>
                
            }
            {showpopup && (
                <div className='DCPpopupwrapper'>
                    <div onClick={e => {e.preventDefault();e.stopPropagation();setshowpopup(false)}} className='DCPqrouter'/>
                    <div className='DCPpopupkonten flex'>
                        <div className='DCPpopupkiri' style={{backgroundImage:`url(${porto[index].foto})`}}/>
                        <div className='DCPpopupkanan'>
                            <div className='DCPpopupkanankonten'>
                                <p className='semibig white'>{porto[index].nama}</p>
                                <p className='tiny white' style={{marginTop:"16px"}}>{porto[index].deskripsi}</p>
                            </div>
                        </div>
                    </div>
                    <img onClick={e => {e.preventDefault();e.stopPropagation();setshowpopup(false)}} className='DCPclosepopup' alt="close" src={closepopup}></img>
                    <img onClick={e => {e.preventDefault();e.stopPropagation();handleChangeIndex("minus")}} className='DCPleft' alt="close" src={carleft}></img>
                    <img onClick={e => {e.preventDefault();e.stopPropagation();handleChangeIndex("plus")}} className='DCPright' alt="close" src={carright}></img>
                </div>
            )}
        </div>
    )
  }