import './index.css';
import chef from "../../assets/assets/bycategory/chef.png";
import patternchef from "../../assets/assets/bycategory/chef-cricle.png";
import chefBg from "../../assets/chef_bg2.png";
import { useLocation, useNavigate } from "react-router-dom";

export const ChefBanner = () => {
    const history = useNavigate();
    const handleToChefList = () =>{
        // history("/chefs");
        history("/chefs", { state: { dataChef : "tes", isFromSearch:false,dataChefSearch : "tes", isFromMenus:false}})
    }
    
    return (
        <div className='chefBannerSingle version2' onClick={()=>{handleToChefList()}}>
            <img className='img-bg' src={chefBg} draggablefalse></img>
            <div className='chefBannerText'>
                <h1>Explore Chefs</h1>
                <p>We’re ready to serve you the best dining experiences</p>
                <div className='btnExplore chefsBanner'>Explore Now</div>
            </div>
        </div>
        // <div className="chefBannerSingle" onClick={()=>{handleToChefList()}}>
        //     <img className='img-1' src={chef} draggable="false"></img>
        //     <img className='img-2' src={patternchef} draggable="false"></img>
        //     <img className='img-3' src={patternchef} draggable="false"></img>
        //     <div className='chefBannerText'>
        //         <h1>Explore Chefs</h1>
        //         <p>We’re ready to serve you the best dining experiences</p>
        //         <div className='btnExplore chefsBanner'>Explore Now</div>
        //     </div>
        // </div>
    )
}