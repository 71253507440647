/* eslint-disable react-hooks/exhaustive-deps */
import './index.css'
import { DesktopNavbar } from '../NavBar/Desktop/DesktopNavbar'
import { HomeTypeCard } from '../Card/HomeTypeCard/HomeTypeCard'
import down from '../../assets/down.svg'
import AbImage1 from "../../assets/abimage1.png"
import AbImage2 from "../../assets/abimage2.png"
import AbImage3 from "../../assets/abimage3.png"
import forkspoon from '../../assets/forkspoon.svg'
import forkspoonWhite from '../../assets/forkspoon2.svg'
import pin from '../../assets/pin.svg'
import calendar from '../../assets/calendar.svg'
import React from 'react';
import user from '../../assets/user.svg'
import plus from '../../assets/plus.svg'
import minus from '../../assets/minus.svg'
import {useState, useEffect, useRef} from 'react'
import { useNavigate } from "react-router-dom";
import search from '../../assets/search.svg' 
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { MobileNavbar } from '../NavBar/Mobile/MobileNavbar'
import { CSSTransition } from 'react-transition-group';
import spinner from '../../assets/spinner.gif'
import axios from 'axios'
import { baseUrl } from '../../lib/api.js'
import { FloatingWhatsAppCustom } from '../FloatingWhatsAppCustom/FloatingWhatsAppCustom'
import useDraggableScroll from 'use-draggable-scroll';
import AOS from 'aos';
import 'aos/dist/aos.css';

// analytics.js
import ReactGA from 'react-ga4';

// how it work assets 
import weCook from '../../assets/work_cook.png';
import weCookBG from '../../assets/work_coobg.png';
import weServe from "../../assets/work_serve.png";
import weServePrimary from "../../assets/work_serve_primary.png";
import weServeBG from "../../assets/work_servebg.png";
import youEnjoy from "../../assets/work_enjoy.png";
import youEnjoyBG from "../../assets/work_enjoybg.png";

//faq assets
import faqBG from '../../assets/faq-img.png';
import arrows from '../../assets/faq_arrows.svg';
import email from '../../assets/faq_email24.svg';
import phone from '../../assets/faq_phone24.svg';

// ByCategory menu assets 
import food1 from "../../assets/assets/bycategory/menus-food1.png";
import food2 from "../../assets/assets/bycategory/menus-food2.png";
import food1back from "../../assets/assets/bycategory/food1back.png";
import food2back from "../../assets/assets/bycategory/food2back.png";
import patternmenu from "../../assets/assets/bycategory/menu-circle.png";
import chef from "../../assets/assets/bycategory/chef.png";
import patternchef from "../../assets/assets/bycategory/chef-cricle.png";
import chefBack from "../../assets/assets/bycategory/chef_back.png";
import logo from "../../assets/assets/bycategory/logo.png";
import logoblack from "../../assets/assets/bycategory/logoblack.png";

// Logo Friends 
import FLogo1 from "../../assets/friends/zglass.png";
import FLogo2 from "../../assets/friends/wisma.png";
import FLogo3 from "../../assets/friends/paper.png";
import FLogo4 from "../../assets/friends/mvp.png";
import FLogo5 from "../../assets/friends/multifortuna.png";
import FLogo6 from "../../assets/friends/mondial.png";
import FLogo7 from "../../assets/friends/melissa.png";
import FLogo8 from "../../assets/friends/mastrada.png";
import FLogo9 from "../../assets/friends/lexus.png";
import FLogo10 from "../../assets/friends/kitchenaid.png";
import FLogo11 from "../../assets/friends/franco.png";
import FLogo12 from "../../assets/friends/colehan.png";
import FLogo13 from "../../assets/friends/clemence.png";
import FLogo14 from "../../assets/friends/amcham.png";
import FLogo15 from "../../assets/friends/agathon.png";


// Galleries 
import profile from "../../assets/galleries/black__profile.png";

// Astro Galleries 
import AMOR1 from "../../assets/galleries/AMOR1.jpg";
import AMOR2 from "../../assets/galleries/AMOR2.jpg";
import AMOR3 from "../../assets/galleries/AMOR3.jpg";
import AMOR4 from "../../assets/galleries/AMOR4.jpg";
import AMOR5 from "../../assets/galleries/AMOR5.jpg";
import AMOR6 from "../../assets/galleries/AMOR6.jpg";
import AMOR7 from "../../assets/galleries/AMOR7.jpg";
import AMOR8 from "../../assets/galleries/AMOR8.jpg";
import AMOR9 from "../../assets/galleries/AMOR9.jpg";
import AMOR10 from "../../assets/galleries/AMOR10.jpg";
import AMOR11 from "../../assets/galleries/AMOR11.jpg";
import AMOR12 from "../../assets/galleries/AMOR12.jpg";
import AMOR13 from "../../assets/galleries/AMOR13.jpg";
import AMOR14 from "../../assets/galleries/AMOR14.jpg";
import AMOR15 from "../../assets/galleries/AMOR15.jpg";
import AMOR16 from "../../assets/galleries/AMOR16.jpg";
import AMOR17 from "../../assets/galleries/AMOR17.jpg";
import AMOR18 from "../../assets/galleries/AMOR18.jpg";
import AMOR19 from "../../assets/galleries/AMOR19.jpg";
import AMOR20 from "../../assets/galleries/AMOR20.jpg";
import AMOR21 from "../../assets/galleries/AMOR21.jpg";
import AMOR22 from "../../assets/galleries/AMOR22.jpg";
import AMOR23 from "../../assets/galleries/AMOR23.jpg";
import AMOR24 from "../../assets/galleries/AMOR24.jpg";

// Harvard Galleries 
import ANS1 from "../../assets/galleries/ANS03921.jpg";
import ANS2 from "../../assets/galleries/ANS03922.jpg";
import ANS3 from "../../assets/galleries/ANS03925.jpg";
import ANS4 from "../../assets/galleries/ANS03926.jpg";
import ANS5 from "../../assets/galleries/ANS03927.jpg";
import ANS6 from "../../assets/galleries/ANS03930.jpg";
import ANS7 from "../../assets/galleries/ANS03990.jpg";
import ANS8 from "../../assets/galleries/ANS03991.jpg";
import ANS9 from "../../assets/galleries/ANS03995.jpg";
import ANS10 from "../../assets/galleries/ANS03996.jpg";
import ANS11 from "../../assets/galleries/ANS03999.jpg";
import ANS12 from "../../assets/galleries/ANS04000.jpg";
import ANS13 from "../../assets/galleries/ANS04019.jpg";
import ANS14 from "../../assets/galleries/ANS04137.jpg";
import ANS15 from "../../assets/galleries/ANS04139.jpg";
import ANS16 from "../../assets/galleries/ANS04141.jpg";
import ANS17 from "../../assets/galleries/ANS04142.jpg";
import ANS18 from "../../assets/galleries/ANS04143.jpg";
import ANS19 from "../../assets/galleries/ANS04147.jpg";
import ANS20 from "../../assets/galleries/ANS04148.jpg";
import ANS21 from "../../assets/galleries/ANS04149.jpg";

// AUG Galleries 
import AUG1 from "../../assets/galleries/20230825_110355.jpg";
import AUG2 from "../../assets/galleries/20230825_110957_01.jpg";
import AUG3 from "../../assets/galleries/20230825_114819.jpg";
import AUG4 from "../../assets/galleries/20230825_114912.jpg";
import AUG5 from "../../assets/galleries/20230825_120316 copy.jpg";
import AUG6 from "../../assets/galleries/20230825_120730.jpg";
import AUG7 from "../../assets/galleries/20230825_120918_01.jpg";
import AUG8 from "../../assets/galleries/20230825_120918_05.jpg";
import AUG9 from "../../assets/galleries/20230825_121053.jpg";
import AUG10 from "../../assets/galleries/20230825_121115.jpg";
import AUG11 from "../../assets/galleries/20230825_121146_02.jpg";
import AUG12 from "../../assets/galleries/20230825_121246.jpg";
import AUG13 from "../../assets/galleries/20230825_122627.jpg";
import AUG14 from "../../assets/galleries/20230825_122833.jpg";
import AUG15 from "../../assets/galleries/20230825_123337.jpg";
import AUG16 from "../../assets/galleries/20230825_123341.jpg";
import AUG17 from "../../assets/galleries/20230825_125859 copy.jpg";
import AUG18 from "../../assets/galleries/20230825_130046.jpg";

import cancellationPolicy from "../../assets/cancellation_policy.png";
AOS.init();

export const HomePage = () => {
    const history = useNavigate()
    const [listEvent, setListEvent] = useState([])
    const [frontPageContent, setFrontPageContent] = useState([])
    const [faqContents, setFaqContents] = useState([])
    const [pindah, setPindah] = useState(false)
    const [guest, setGuest] = useState(1)
    const [showCategory, setShowCategory] = useState(false)
    const [category, setCategory] = useState("Pilih Kategori Makananmu")
    const [categoryDetail, setCategoryDetail] = useState("Pilih Kategori Makananmu")
    const [showLocation, setShowLocation] = useState(false)
    // const location = ['Jakarta', 'Bandung', 'Yogyakarta']
    const [location, setLocation] = useState([])
    const [listCategory, setListCateory] = useState([])
    const [foundLocation, setFoundLocation] = useState([])
    const [dataListChef, setDataListChef] = useState([])
    const [shownLocation, setShownLocation] = useState()
    const [filterLocation, setFilterLocation] = useState('')
    const [choosenLocation, setChoosenLocation] = useState('Pilih Daerah Lokasi Kamu')
    const [choosenLocationDetail, setChoosenLocationDetail] = useState('Pilih Daerah Lokasi Kamu')
    const [showDate, setShowDate] = useState(false)
    const [selectedDate, setSelectedDate] = useState()
    const [dateShown, setDateShown] = useState('Kapan Acaranya Dilaksanakan?')
    const firstUpdate = useRef(true);
    const firstUpdate2 = useRef(true);
    const [standby, setstandby] = useState(true)
    const [toChefList, setToChefList] = useState(false)
    const [loading, setLoading] = useState()
    const [showNavPopup, setShowNavPopup] = useState(false)

    const handleCloseAll = () => {
        setShowCategory(false)
        setShowDate(false)
        setShowLocation(false)
        setShowNavPopup(false)
        setcardLeft(false);
        setcardRight(false);

    }

    const handleSetCategory = (e) =>{
        setCategory(e.name)
        setCategoryDetail(e)
        setShowCategory(false)
        var elem = document.getElementById("homecat");
        elem.className = "inputquestion verytiny black";
    }

    useEffect(() => {
        // Insert Hotjar tracking code into the head section
        const script = document.createElement('script');
        script.innerHTML = `
            (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:5070309,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `;
        document.head.appendChild(script);

        return () => {
            // Cleanup if necessary
            // document.head.removeChild(script);
        };
    }, []);

    // useEffect(() => {
    //     localStorage.removeItem("isFromBookingChef")
    //     if (firstUpdate.current) {
    //         firstUpdate.current = false;
    //       } else {
    //         var elem = document.getElementById("HPiconcat");
    //         var elem2 = document.getElementById('formcategory')
    //         elem.style.transition = "transform 0.5s"
    //         if(showCategory === true){
    //             elem.style.transform = "rotate(180deg)"
    //             elem2.className="inputWrapper yellow"
    //         }else{
    //             elem.style.transform = "rotate(0deg)"
    //             elem2.className="inputWrapper"
    //         }
    //     }
    // },[showCategory])

    // useEffect(() => {
    //     if (firstUpdate.current) {
    //         firstUpdate.current = false;
    //       } else {
    //         var elem = document.getElementById("HPiconloc");
    //         var elem2 = document.getElementById("formloc")
    //         elem.style.transition = "transform 0.5s"
    //         if(showLocation === true){
    //             elem2.className = "inputWrapper yellow"
    //             elem.style.transform = "rotate(180deg)"
    //         }else{
    //             elem.style.transform = "rotate(0deg)"
    //             elem2.className="inputWrapper"
    //         }
    //     }
    // },[showLocation])

    // useEffect(() => {
    //     if (firstUpdate.current) {
    //         firstUpdate.current = false;
    //       } else {
    //         var elem = document.getElementById("HPicondate");
    //         var elem2 = document.getElementById("formdate")
    //         elem.style.transition = "transform 0.5s"
    //         if(showDate === true){
    //             elem2.className = "inputWrapper yellow"
    //             elem.style.transform = "rotate(180deg)"
    //         }else{
    //             elem2.className = "inputWrapper"
    //             elem.style.transform = "rotate(0deg)"
    //         }
    //     }
    // },[showDate])

    const handleSelectLocation = (e) => {
        setChoosenLocation(e.name)
        setChoosenLocationDetail(e)
        setShowLocation(false)
        var elem = document.getElementById("homeloc");
        elem.className = "inputquestion verytiny black";
    }

    const handleSelectDate = (e) => {
        setSelectedDate(e)
        
        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ]
        setDateShown(`${formatDate(e.getDate())} ${months[e.getMonth()].slice(0, 3)} ${e.getFullYear()}`)
        var elem = document.getElementById("homedate");
        elem.className = "inputquestion verytiny black";
        setShowDate(false)
    }

    const formatDate = (data) =>{
        if(data<10){
          return `0${data}`
        }else{
          return data
        }
      }
    const handleGuestChange = (e) => {
        if(e === "minus"){
            if(guest === 1){
                return
            }else{
                setGuest(guest-1)
                var elem = document.getElementById("homegue");
                elem.className = "inputquestion verytiny black";
            }
        }else{
            setGuest(guest+1)
            var elem2 = document.getElementById("homegue");
            elem2.className = "inputquestion verytiny black";   
        }
    }

    const handleSearchLocation = (e) => {
        setFilterLocation(e);
        setFoundLocation([]);
        for (var x in location){
          if (location[x].name.toLowerCase().includes(e.toLowerCase())){
            foundLocation.push(location[x])
          }
        }
        setShownLocation(foundLocation);
    }

    const handleSearchChef = async () => {
        // console.log(choosenLocationDetail.id)
        // console.log(categoryDetail.id)
        // console.log(dateShown)
        try {
            const { data: res } = await axios({
                method: 'get',
                // url: `${baseUrl}category/type/chef`,
                url: `${baseUrl}getChef?date=07-08-2023&location_id=${choosenLocationDetail.id}&category_id=${categoryDetail.id}&specialty_id=`,
                // params: paramsToBePassed
            })
            const categoryChefData = res.data
            const totalChefData = res.total_data
            if(totalChefData != 0){
                const parsedCategoryChef = parseCategoryChef(categoryChefData)
                // console.log("hasil parse")
                // console.log(parsedCategoryChef)
                localStorage.setItem('locationId', choosenLocationDetail.id)
                setDataListChef(parsedCategoryChef)
                setPindah(true)
            } else {
                console.log("error seach chef")
                alert("Chef Not found");
            }
        } catch (error) {
            console.log("error seach chef")
            console.log(error)
        }
    }

    useEffect(()=>{
        if(pindah===true){
            setTimeout(function() {
                ReactGA.event('button_click', {
                    category: 'User',
                    action: 'Clicked Button',
                    label: 'Chefs Page'
                  });
                  
                // history('/category/chefList', { state: { dataChef : dataListChef, isFromSearch:true,dataChefSearch : dataListChef,} });
                history('/chefs', { state: { dataChef : dataListChef, isFromSearch:true,dataChefSearch : dataListChef,} });
           }, 500);
        }
    },[pindah])

    const parseCategoryChef = (categoryChefData) => {
        const genres = [
            ["Asian", "Western"],
            ["Asian", "Indonesian"],
            ["Western", "Indian"]
        ]
        const area = ["Uranus Tengah", "Venus", "Provinsi Pluto"]
        categoryChefData = categoryChefData.map((el, idx) => {
            // if (el["slug"] == "Chef-Taplak") {
                return {
                    id: el.id,
                    gambar: el.user_image_url,
                    nama: el.name,
                    email: el.email,
                    rating: parseTotalRating(el.chef_rating),
                    biography:el.chef_galery.description,
                    booking_available:parseBookingAvailable(el.booking_available),
                    // jenis: idx <= genres[idx] ? genres[idx] : genres[genres.length-1],
                    jenis: parseServicesToJenis(el.services),
                    area: "Jakarta",
                    harga: el.services[0].start_price,
                    speciality_chef: parseChefSpeciality(el.speciality_chef),
                    languages: languagesStylesParser(el["proficient"]),
                    chefCompany : parseChefCompany(el.work_experience,el.education_experience),
                    // harga: el.starting_price,
                }
            // }
        })
        return categoryChefData.filter(el => el)
    }

    const parseBookingAvailable = (booking_available) => {
        booking_available = booking_available.map(el => {
            return {
                booking_date:el.booking_date
            }
        })

        return booking_available
    }

    const parseChefCompany = (company,education) => {
        let workeduList = []
        if(company.length !== 0){
            company = company.map((el) => {
                return {
                    name:el.company,
                    date: el.start_date.slice(0,-9)
                }
            })
        }
        
        if(education.length !== 0){
            education = education.map((el) => {
                return {
                    name:el.education,
                    date: el.start_date.slice(0,-9)
                }
            })
        }
        // company.concat(education)

        workeduList = [...company, ...education]

        return workeduList

    }

    const parseTotalRating = (chef_rating) => {
        let rating = 0;
        for(const element of chef_rating){
            rating+=element.rate;
        }
        return rating
    }

    const languagesStylesParser = (proficient) => {
        let proficientList = []
        if (proficient.length === 0) {
            return "N/A"
        }
        proficient = proficient.map((el) =>{
            proficientList.push(el.proficient)
        })

        // console.log("ini proficient => "+ proficientList)
        proficientList = proficientList.join(', ').replace(/, ([^,]*)$/, ' and $1')
        return proficientList
    }

    const parseChefSpeciality = (speciality) =>{
        let specialites = []

        speciality = speciality.map(el => {
            if(el !== null){
                specialites.push({
                    id_speciality: el["id_speciality"],
                    name_speciality: el["name_speciality"]
                })
            } else {
                return {
                    containId: null,
                    deskripsi: null,
                    icon: null
                }
            }
        })
        return specialites
    }

    const parseServicesToJenis = (services) => {
        services = services.map(el => {
            return {
                id : el.id,
                name : el.name,
                description: el.description,
                service_image_url: el.service_image_url,
                converted_price : el.converted_price,
                converted_discounted_price : el.start_price,
                package_menu_id : el.package_menu_id,
                max_pax : el.max_pax,
                voucher: parseVoucher(el.voucher),
                prices: parseServicePrice(el.prices),
                menu: parseServiceMenu(el.menu),
                equipmentData: parseEquipment(el.equipment_data),
                containData: parseContain(el.contain_data),
            }
        })
        return services
    }

    const parseVoucher = (vouchers) => {
        if(!vouchers || vouchers < 1){
            return "N/A"
        }

        vouchers = vouchers.map(el => {
            return {
                id: el["id"],
                name: el["name"],
                discount: el["discount"],
                quota: el["quota"]
            }
        })

        return vouchers
    }

    const parseServicePrice = (services_price) => {
        services_price = services_price.map(el => {
            return {
                id: el.id,
                bussines_id: el.bussines_id,
                amount : el.amount,
                max : el.max,
                
            }
        })
        return services_price
    }

    const parseServiceMenu = (services_menu) => {
        services_menu = services_menu.map(el => {
            return {
                id: el.id,
                bussines_id: el.bussines_id,
                name : el.name,
                description : el.description,
                image : el.menu_img,
                category_name:el.category_name
            }
        })
        return services_menu
    }

    const parseEquipment = (equipment) => {
        let eqipments = []

        equipment = equipment.map(el => {
            if(el !== null){
                eqipments.push({
                    equipmentId: el["id_equipment"],
                    deskripsi: el["deskripsi"],
                    icon: el["icon"],
                    file_url: el["file_url"]
                })
            } else {
                return {
                    equipmentId: null,
                    deskripsi: null,
                    icon: null,
                    file_url:null
                }
            }
            
        })

        return eqipments
    }

    const parseContain = (contain) => {
        let contains = []

        contain = contain.map(el => {
            if(el !== null){
                contains.push({
                    containId: el["id_dietary"],
                    deskripsi: el["deskripsi"],
                    icon: el["icon"],
                    file_url: el["file_url"]
                })
            } else {
                return {
                    containId: null,
                    deskripsi: null,
                    icon: null,
                    file_url: null
                }
            }
            
        })

        return contains
    }

    
    useEffect(() =>{
        window.scrollTo(0, 0)
        setLoading(true)
        const eventFromDB = async () => {
            try {
                const data = await callApigetEvent("incoming")
                setListEvent(data)
            } catch (error) {
                console.log("Error eventFromDB")
            }
        }

        const frontPageContentDB = async () => {
            try {
                const data = await callFrontPageContent()
                // console.log("data frontpage content => "+JSON.stringify(data))
                setFrontPageContent(data)
            } catch (error) {
                console.log(error)
                console.log("Error frontPageContentDB")
            }
        }

        const faqContentDB = async () => {
            try {
                const data = await callFaqContent()
                // console.log("data faq content => "+JSON.stringify(data))
                // console.log("data faq content => "+JSON.stringify(data[0].answer))
                setFaqContents(data)
            } catch (error) {
                console.log(error)
                console.log("Error frontPageContentDB")
            }
        }

        const locationSearchFromDB = async () => {
            try {
                const data = await callApiLocationSearch("tes")
                // console.log("set location")
                setLocation(data)
            
            } catch (error) {
                console.log("Error locationSearchFromDB")
                console.log(error)
            }
        }

        const categorySearchFromDB = async () => {
            try {
                const data = await callApiCategorySearch("tes")
                // console.log("set category")
                setListCateory(data)
            
            } catch (error) {
                console.log("Error locationSearchFromDB")
                console.log(error)
            }
        }
        // Turn on to use it
        localStorage.setItem('isBookingChefStillProggress',false)
        localStorage.setItem('isBookingChefStillProggressVenue',false)
        localStorage.removeItem('chefNameDraftFlow')
        localStorage.removeItem('chefIdDraftFlow')
        eventFromDB()
        locationSearchFromDB()
        categorySearchFromDB()
        frontPageContentDB()
        faqContentDB()
        // console.log("Kocak dah")
        // console.log(location)
        // console.log(listEvent.length)
        // setListEvent([{id:1,cover_image:eventimg,name:"Thanksgiving",styles:"American style featuring Turkey and Sides", sisa:10, max_seat:20, date:"24 Feb", time_start:"18:00 - 20:00", venue_location:"Hotel Santika, BSD City"},
        //             {id:2,cover_image:eventimg,name:"Asian Party",styles:"Asian Style", sisa:3, max_seat:10, date:"24 Feb", time_start:"18:00 - 20:00", venue_location:"Hotel Santika, BSD City"},
        //             {id:3,cover_image:eventimg,name:"Welcome",styles:"American style featuring Turkey and Sides", sisa:10, max_seat:10, date:"24 Feb", time_start:"18:00 - 20:00", venue_location:"Hotel Santika, BSD City"},
        //             {id:4,cover_image:eventimg,name:"Thanksgiving",styles:"American style featuring Turkey and Sides", sisa:10, max_seat:20, date:"24 Feb", time_start:"18:00 - 20:00", venue_location:"Hotel Santika, BSD City"},
        //             {id:5,cover_image:eventimg,name:"Asian Party",styles:"Asian Style", sisa:3, max_seat:10, date:"24 Feb", time_start:"18:00 - 20:00", venue_location:"Hotel Santika, BSD City"},
        //             {id:6,cover_image:eventimg,name:"Welcome",styles:"American style featuring Turkey and Sides", sisa:10, max_seat:10, date:"24 Feb", time_start:"18:00 - 20:00", venue_location:"Hotel Santika, BSD City"}])
        localStorage.setItem('nav', 'home')    
    },[])

    useEffect(() => {
        console.log(location);
     }, [location]);

    const stylesParser = (styles) => {
        if (!styles) {
            return "N/A"
        }
        // styles = styles[0].name
        styles = styles.map(({name}) => `${name}`).join(',');
        // styles = styles.join(', ').replace(/, ([^,]*)$/, ' and $1')
        return styles
    }

    const parseTimeEvent = (dates) => {
        // const timeStartArr = dates["time_start"].split(":")
        const timeStartArr = dates["start_time"].split(":")
        // const timeEndArr = dates["time_end"].split(":")
        const timeEndArr = dates["end_time"].split(":")
        
        const parsedTimeStart = timeStartArr[0].concat(":", timeStartArr[1]);
        const parsedTimeEnd = timeEndArr[0].concat(":", timeEndArr[1]);

        const parsedTime = `${parsedTimeStart} - ${parsedTimeEnd}`
        return parsedTime
    }

    const dateEventParser = (dates) => {
        if (!dates || !dates.length) {
            return {
                "date": "N/A",
                "time_start": "N/A"
            }
        }
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ]
        
        const firstNonZeroValue = dates.find(value => value.remaining_seats !== 0);
    
        if (firstNonZeroValue !== undefined) {
          // You have the first non-zero value here
          dates = firstNonZeroValue
          console.log('First non-zero value:', firstNonZeroValue);
        } else {
          // No non-zero values found in the array
          console.log('No non-zero values found');
          dates = dates[0]
        }  
        

        // console.log("ini full datatanggal => "+dates.date_events)

        // let [_, month, date] = dates["date"]
        let [_, month, date] = dates.date_events.split("-")
        // console.log("ini tanggal => "+date)
        // console.log("ini bulan => "+month)
        const idxMonth = Number(month)
        month = monthNames[idxMonth-1].slice(0, 3)
        date = Number(date)
        const parsedDate = `${date} ${month}`
        // console.log("ini tanggal => "+date)
        // console.log("ini bulan => "+month)
        return {
            "date": parsedDate,
            "time_start": parseTimeEvent(dates)
        }
    }

    const parseFaqContent = (faqData) => {
        faqData = faqData.map((el) => {
            return {
                id : el.id,
                question: el.question,
                answer: el.answer
            }
        })

        return faqData
    }

    const parseFrontPageContent = (fronPageData) => {
        fronPageData = fronPageData.map((el) => {
            return {
                id : el.id,
                title: el.title,
                content: el.content,
                slug: el.slug
            }
        })

        return fronPageData
    }

    const parseEvent = (eventData) => {
        eventData = eventData.map((el) => {
            // const { date, time_start } = dateEventParser(el.dates)
            const { date, time_start } = dateEventParser(el.events_date)
            // console.log("date =>"+el.events_date.length)
            // console.log("date =>"+el.event_name)
            // if(el.events_date.length !== 0){
                return {
                    id: el.id,
                    cover_image: el.cover_img_url,
                    name: el.event_name,
                    styles: stylesParser(el.event_style),
                    // sisa: el.min_pax,
                    // sisa: el.events_date[0] ? el.events_date[0]["remaining_seats"]: 0,
                    sisa: parseSisa(el.events_date ? el.events_date: 0),
                    // max_seat: el.max_pax,
                    max_seat: el.events_date[0] ? el.events_date[0]["max_seat"]:0,
                    date: date,
                    time_start: time_start,
                    venue_location: el.vanue_location
                // }
            }
            
        })
        return eventData
    }


    const parseSisa = (events_dates) => {
        let sisaValue = 0
        // console.log("tes data 0=> "+JSON.stringify(events_dates))
        const firstNonZeroValue = events_dates.find(value => value.remaining_seats !== 0);
    
        if (firstNonZeroValue !== undefined) {
          // You have the first non-zero value here
          sisaValue = firstNonZeroValue.remaining_seats
          console.log('First non-zero value:', firstNonZeroValue);
        } else {
          // No non-zero values found in the array
          console.log('No non-zero values found');
        }

        // console.log('sisa value => '+ sisaValue);

        // events_dates.forEach((element,index) => {
        //     console.log("for each data => "+ JSON.stringify(element))
        //     if(element.remaining_seats === 0){
        //         console.log("for each data pertama null => ")
        //     } else {
        //         console.log("for each data pertama ada => ")
        //     }
        // })
        
        
        return sisaValue
    }

    const parseLocationSearch = (eventData) => {
        eventData = eventData.map((el) => {
            return {
                id: el.id,
                name: el.name,
                lat: el.lat,
                lng: el.lng,
                country_id: el.country_id,
                timezone_id: el.timezone_id,
                status: el.status,
                created_at: el.created_at,
                updated_at: el.updated_at
            }
        })
        return eventData
    }

    const parseCategorySearch = (eventData) => {
        eventData = eventData.map((el) => {
            return {
                id: el.id,
                name: el.name,
                slug: el.slug,
                image: el.image,
                status: el.status,
                created_at: el.created_at,
                updated_at: el.updated_at,
                category_image_url: el.category_image_url,
            }
        })
        return eventData
    }

    const callApiCategorySearch = async (type) => {
        try {
            const { data: res } = await axios({
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'get',
                url: `${baseUrl}category?terms=`,
                // params
            })
            const eventData = res.data
            
            const parsedEvent = parseCategorySearch(eventData)
            return parsedEvent
        } catch (error) {
            console.log("error callApiLocationSearch")
            // Create error handler (pop-up or something)
        }
    }

    const callApiLocationSearch = async (type) => {
        try {
            const { data: res } = await axios({
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'get',
                url: `${baseUrl}location?terms=`,
                // params
            })
            const eventData = res.data
            
            const parsedEvent = parseLocationSearch(eventData)
            return parsedEvent
        } catch (error) {
            console.log("error callApiLocationSearch")
            // Create error handler (pop-up or something)
        }
    }

    const callFaqContent = async () => {
        try {
            const { data: res } = await axios({
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'post',
                url: `${baseUrl}faq`,
                // params
            })
            const faqPageData = res.data
            
            const parsedFaqPageContent = parseFaqContent(faqPageData)
            return parsedFaqPageContent
        } catch (error) {
            console.log(error)
            console.log("error get Front page data")
        }
    }

    const callFrontPageContent = async () => {
        try {
            const { data: res } = await axios({
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'post',
                url: `${baseUrl}front_pages?term=`,
                // params
            })
            const eventFrontPageData = res.data
            
            const parsedFrontPageContent = parseFrontPageContent(eventFrontPageData)
            return parsedFrontPageContent
        } catch (error) {
            console.log(error)
            console.log("error get Front page data")
        }
    }

    const callApigetEvent = async (type) => {
        try {
            let params = null
            if (type) {
                params = { type }
            }
            const { data: res } = await axios({
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'get',
                url: `${baseUrl}event`,
                // params
            })
            const eventData = res.data
            // console.log("link image")
            // console.log(res.data[0].cover_img)
            
            const parsedEvent = parseEvent(eventData)
            return parsedEvent
        } catch (error) {
            console.log(error)
            console.log("error getEventFromDB")
            // Create error handler (pop-up or something)
        }
    }

    useEffect(() => {
        if (firstUpdate2.current) {
            firstUpdate2.current = false;
        }else{
            setLoading(false)
            console.log("selesai")
            // console.log(listEvent.length)
        }
    },[listEvent])

    // useEffect(()=>{
        
    //     if(toChefList===true){
    //         setTimeout(function() {
    //             history("/category/chefList" , { state: { dataChef : "tes", isFromSearch:false,dataChefSearch : "tes"}});
    //        }, 500);
    //     }
    // },[toChefList])

    const handleToChefList = async () => {
        ReactGA.event('button_click', {
            category: 'User',
            action: 'Clicked Button',
            label: 'Chefs Page'
          });
          
        history("/chefs", { state: { dataChef : "tes", isFromSearch:false,dataChefSearch : "tes", isFromMenus:false}})
        document.querySelector("body").style.overflow='auto';
        // history("/category/chefList" , { state: { dataChef : "tes", isFromSearch:false,dataChefSearch : "tes"}});
        // setTimeout(function() {
        //     history("/category/chefList" , { state: { dataChef : "tes", isFromSearch:false,dataChefSearch : "tes"}});
        // }, 1);
    }
    const handleToMenuList = () => {
        ReactGA.event('button_click', {
            category: 'User',
            action: 'Clicked Button',
            label: 'Menus Page'
          });
          
        history("/menus", { state: { dataChef : "tes", isFromMenus:true}} );
        document.querySelector("body").style.overflow='auto';
    }
    const handleToStartEvent = () => {
        document.querySelector("body").style.overflow='auto';
        setstandby(false)
        localStorage.setItem('nav', 'e')
        setTimeout(function() {
            ReactGA.event('button_click', {
                category: 'User',
                action: 'Clicked Button',
                label: 'Event Page'
              });
              
            history("/event", { } );
       }, 500);
    }

    const handleOpenPopUp = (e) => {
        setFoundLocation([]);
        if(e === "date"){
            setShowCategory(false)
            setShowLocation(false)
            setShowDate(true)
        }else if(e === "category"){
            setShowCategory(true)
            setShowLocation(false)
            setShowDate(false)
        }else{
            for (let x in location){
                foundLocation.push(location[x])
            }
            setShownLocation(foundLocation)
            setShowCategory(false)
            setShowLocation(true)
            setShowDate(false)
        }
    }

    const handleToCategory = () => {
        setstandby(false)
        setTimeout(function() {
            history("/category");
       }, 500);
    }

    const ref = useRef(null);
    const {onMouseDown} = useDraggableScroll(ref);

    // const handleSearchHome = async (date, categoryId, LocationId) => {
    //     const params = {
    //              "date": date,
    //              "location_id": categoryId,
    //              "category_id": LocationId,
    //     }
    //     try {
    //         const { data: res } = await axios({
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             },
    //             method: 'get',
    //             url: `${baseUrl}getChef`,
    //             params
    //         })
    //         const totalData = res.total_data
    //         const listChefData = res.data
    //         if(totalData != 0){

    //         }
    //         const parsedListChef = parseCategoryChef(listChefData)
    //         return parsedListChef
    //     } catch (error) {
    //         console.log("error callApiLocationSearch")
    //         // Create error handler (pop-up or something)
    //     }
    // }

    // const parseCategoryChef = (categoryChefData) => {
    //     const genres = [
    //         ["Asian", "Western"],
    //         ["Asian", "Indonesian"],
    //         ["Western", "Indian"]
    //     ]
    //     console.log("chef galery awok")
    //     console.log(categoryChefData[0].chef_galery.photos)
    //     const area = ["Uranus Tengah", "Venus", "Provinsi Pluto"]
    //     categoryChefData = categoryChefData.map((el, idx) => {
    //         // if (el["slug"] == "Chef-Taplak") {
    //             return {
    //                 id: el.id,
    //                 gambar: el.user_image_url,
    //                 nama: el.name,
    //                 email: el.email,
    //                 rating: parseTotalRating(el.chef_rating),
    //                 // jenis: el.genres,
    //                 service: parseServices(el.services),
    //                 chefEvent: parseChefEvent(el.chef_events),
    //                 chefGalery : parseChefGalery(el.chef_galery.photos),
    //                 chefCompany : parseChefCompany(el.company),
    //                 // jenis: idx <= genres[idx] ? genres[idx] : genres[genres.length-1],
    //                 jenis: parseServicesToJenis(el.services),
    //                 area: idx <= area.length ? area[idx] : area[area.length-1],
    //                 harga: 150000,
    //                 // harga: el.starting_price,
    //             }
    //         // }
    //     })
    //     return categoryChefData.filter(el => el)
    // }

    const handleToEvent = () => {
        setstandby(false)
        setTimeout(function() {
            ReactGA.event('button_click', {
                category: 'User',
                action: 'Clicked Button',
                label: 'Popups Homepage'
              });
              
            history("/popups");
       }, 500);
    }

    const [activeFaqIdStatic, setactiveFaqIdStatic] = useState(null);
    const [activeFaqId, setActiveFaqId] = useState(null);
    const openFaq = (id) => {
        // console.log("test FAQ", id);
        setactiveFaqIdStatic(true);
        setActiveFaqId(id);
    }
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );
    const [visible, setVisible] = useState(false);
    const [cardLeft, setcardLeft] = useState(false);
    const [cardRight, setcardRight] = useState(false);
    const [animatedCancel, setAnimatedCancel] = useState(false);
    const [visibleCancel, setVisibleCancel] = useState(false);
    const handleCancellation = async () => {
        setVisibleCancel(true);
        setAnimatedCancel(true);
        document.querySelector("body").style.overflow='hidden';
    };
    const closeCancelInfo = async () =>{
        setAnimatedCancel(false);
        await delay(500);
        setVisibleCancel(false);
        document.querySelector("body").style.overflow='auto';
    }
    const handleToggle = async () => {
        setVisible((current) => !current);
        
        if (visible){
            document.querySelector("body").style.overflow='auto';
        }
        else{
            document.querySelector("body").style.overflow='hidden';
        }

        await delay(200);
        setcardLeft(true);

        await delay(400);
        setcardRight(true);
    };
    
    const [visibleGalleries, setvisibleGalleries] = useState(false);
    const [animatedGalleries, setanimatedGalleries] = useState(false);

    const [tabGalleries, setTabGalleries] = useState('private');
    const [eventsImage, seteventsImage] = useState(null);
    const [eventsName, setEventsName] = useState("Events Name");
    
    const [linkInstagram, setLinkInstagram] = useState("");
    const [linkFacebook, setlinkFacebook] = useState("");

    const closeGalleries = () =>{
        setanimatedGalleries(false);
        setTimeout(function(){
            setvisibleGalleries(false);
            seteventsImage("");
            setEventsName("");
            setlinkFacebook("");
            setLinkInstagram("");
            document.querySelector("body").style.overflow='auto';
        },500)
    }

    const [loadGalleries, setLoadGalleries] = useState(8);
    const [loadingAnimate, setLoadingAnimate] = useState(false);

    
    const loadMoreImages = () =>{
        setLoadingAnimate(true);
        setTimeout(() => {   
            setLoadGalleries(prev => prev + 8);
            setLoadingAnimate(false);
        }, 1000);
    }

    const handleChangeTabs = (getDataTab) => {
        setTabGalleries(getDataTab);
        setLoadGalleries(8);
    }

    const popupsGalleries = [
        {
            src: AMOR1, name: 'astro', aspect: 'vertical',
        },
        {
            src: AMOR2, name: 'astro', aspect: 'horizontal',
        },
        {
            src: AMOR3, name: 'astro', aspect: 'square',
        },
        {
            src: AMOR4, name: 'astro', aspect: 'horizontal',
        },
        {
            src: AMOR5, name: 'astro', aspect: 'horizontal',
        },
        {
            src: AMOR6, name: 'astro', aspect: 'horizontal',
        },
        {
            src: AMOR7, name: 'astro', aspect: 'square',
        },
        {
            src: AMOR8, name: 'astro', aspect: 'square',
        },
        {
            src: AMOR9, name: 'astro', aspect: 'square',
        },
        {
            src: AMOR10, name: 'astro', aspect: 'square',
        },
        {
            src: AMOR11, name: 'astro', aspect: 'square',
        },
        {
            src: AMOR12, name: 'astro', aspect: 'vertical',
        },
        {
            src: AMOR13, name: 'astro', aspect: 'horizontal',
        },
        {
            src: AMOR14, name: 'astro', aspect: 'horizontal',
        },
        {
            src: AMOR15, name: 'astro', aspect: 'vertical',
        },
        {
            src: AMOR16, name: 'astro', aspect: 'horizontal',
        },
        {
            src: AMOR17, name: 'astro', aspect: 'square',
        },
        {
            src: AMOR18, name: 'astro', aspect: 'horizontal',
        },
        {
            src: AMOR19, name: 'astro', aspect: 'horizontal',
        },
        {
            src: AMOR20, name: 'astro', aspect: 'square',
        },
        {
            src: AMOR21, name: 'astro', aspect: 'square',
        },
        {
            src: AMOR22, name: 'astro', aspect: 'vertical',
        },
        {
            src: AMOR23, name: 'astro', aspect: 'vertical',
        },
        {
            src: AMOR24, name: 'astro', aspect: 'square',
        },
    ]

    const eventsGalleries = [
        {
            src: ANS1, name: 'harvard', aspect: 'vertical',
        },
        {
            src: ANS2, name: 'harvard', aspect: 'horizontal',
        },
        {
            src: ANS3, name: 'harvard', aspect: 'vertical',
        },
        {
            src: ANS4, name: 'harvard', aspect: 'vertical',
        },
        {
            src: ANS5, name: 'harvard', aspect: 'horizontal',
        },
        {
            src: ANS6, name: 'harvard', aspect: 'horizontal',
        },
        {
            src: ANS7, name: 'harvard', aspect: 'vertical',
        },
        {
            src: ANS8, name: 'harvard', aspect: 'vertical',
        },
        {
            src: ANS9, name: 'harvard', aspect: 'vertical',
        },
        {
            src: ANS10, name: 'harvard', aspect: 'vertical',
        },
        {
            src: ANS11, name: 'harvard', aspect: 'vertical',
        },
        {
            src: ANS12, name: 'harvard', aspect: 'horizontal',
        },
        {
            src: ANS13, name: 'harvard', aspect: 'vertical',
        },
        {
            src: ANS14, name: 'harvard', aspect: 'vertical',
        },
        {
            src: ANS15, name: 'harvard', aspect: 'vertical',
        },
        {
            src: ANS16, name: 'harvard', aspect: 'vertical',
        },
        {
            src: ANS17, name: 'harvard', aspect: 'vertical',
        },
        {
            src: ANS18, name: 'harvard', aspect: 'vertical',
        },
        {
            src: ANS19, name: 'harvard', aspect: 'horizontal',
        },
        {
            src: ANS20, name: 'harvard', aspect: 'horizontal',
        },
        {
            src: ANS21, name: 'harvard', aspect: 'horizontal',
        },
    ]

    const privateGalleries = [
        {
            src: AUG1, name: 'aug', aspect: 'square',
        },
        {
            src: AUG2, name: 'aug', aspect: 'square',
        },
        {
            src: AUG3, name: 'aug', aspect: 'vertical',
        },
        {
            src: AUG4, name: 'aug', aspect: 'square',
        },
        {
            src: AUG5, name: 'aug', aspect: 'square',
        },
        {
            src: AUG6, name: 'aug', aspect: 'horizontal',
        },
        {
            src: AUG7, name: 'aug', aspect: 'vertical',
        },
        {
            src: AUG8, name: 'aug', aspect: 'vertical',
        },
        {
            src: AUG9, name: 'aug', aspect: 'vertical',
        },
        {
            src: AUG10, name: 'aug', aspect: 'vertical',
        },
        {
            src: AUG11, name: 'aug', aspect: 'vertical',
        },
        {
            src: AUG12, name: 'aug', aspect: 'vertical',
        },
        {
            src: AUG13, name: 'aug', aspect: 'vertical',
        },
        {
            src: AUG14, name: 'aug', aspect: 'vertical',
        },
        {
            src: AUG15, name: 'aug', aspect: 'vertical',
        },
        {
            src: AUG16, name: 'aug', aspect: 'vertical',
        },
        {
            src: AUG17, name: 'aug', aspect: 'vertical',
        },
        {
            src: AUG18, name: 'aug', aspect: 'vertical',
        },
    ]

    const handlePopups = (image, event) =>{
        image.name === 'astro' ? AstroGoodsFunct(event) : image.name === 'hermes' ? HermesFunct(event) : OtherFunct(event);
    }

    const AstroGoodsFunct = (event) =>{
        document.querySelector("body").style.overflow='hidden';

        setLinkInstagram("https://www.instagram.com/astrogoods.id/?hl=en");
        setEventsName("Astro Goods");

        const imgSrc = event.target.src;
        seteventsImage(imgSrc);

        setTimeout(function(){
            setvisibleGalleries(true)
            setanimatedGalleries(true)
        },500);
    }

    const HermesFunct = (event) =>{
        document.querySelector("body").style.overflow='hidden';

        setLinkInstagram("https://www.instagram.com");
        setEventsName("Hermest Events");
        setlinkFacebook("https://www.facebook.com/")

        const imgSrc = event.target.src;
        seteventsImage(imgSrc);

        setTimeout(function(){
            setvisibleGalleries(true)
            setanimatedGalleries(true)
        },500);
    }

    const OtherFunct = (event) =>{
        document.querySelector("body").style.overflow='hidden';

        setLinkInstagram("https://www.instagram.com/");
        setEventsName("Other Goods");

        setlinkFacebook("https://www.facebook.com/")
        const imgSrc = event.target.src;
        seteventsImage(imgSrc);

        setTimeout(function(){
            setvisibleGalleries(true)
            setanimatedGalleries(true)
        },500);
    }

    return (
        <div onClick={e => {e.stopPropagation();handleCloseAll()}} className='maincontainer' id="maincontainer">
            <DesktopNavbar zindex={visible} showPopup={showNavPopup} setShowPopup={setShowNavPopup}></DesktopNavbar>
            <MobileNavbar zindex={visible}></MobileNavbar>
            <FloatingWhatsAppCustom></FloatingWhatsAppCustom>
            {
                loading?
                <img  alt="loading" className='taplakLoading' src={spinner}></img>
                :
                <CSSTransition in={standby} timeout={{appear:2000,enter:2000,exit:500}} unmountOnExit={true} mountOnEnter={true} appear={true} classNames="main">
                <div className='testingtransisi'>
                    <div className="header"></div>

                    <div className={`popup-byCategory ${visible ? 'visible-flex' : ''}`}>
                        <div className="dark-overlay" onClick={handleToggle}></div>

                        <div className="byCategoryWrapper">
                            <div className='byCategoryTitle'>
                                <h1>Explore Now</h1>
                                <p>Choose between a list of menus or talents and find the right private dining experiences for you.</p>
                            </div>
                            <div className='byCategoryContent'>
                                <div className={`CardFlipWrap ${cardLeft ? 'SwipeUpIn' : ''}`} onClick={()=> {handleToMenuList()}}>
                                    <div className='flipper-hug'>
                                        <div className='front'>
                                            <div className='byCategoryCard menus'>
                                                <img className='img-1' src={food1back} draggable="false"></img>
                                                <img className='img-2' src={food2back} draggable="false"></img>
                                                <img className='img-3' src={patternmenu} draggable="false"></img>
                                                <img className='img-4' src={logoblack} draggable="false"></img>
                                            </div>
                                        </div>
                                        <div className='back'>
                                            <div className='byCategoryCard menus'>
                                                <img className='img-1' src={food1} draggable="false"></img>
                                                <img className='img-2' src={food2} draggable="false"></img>
                                                <img className='img-3' src={patternmenu} draggable="false"></img>
                                                <div className='byCategoryCard-text'>
                                                    <h1>Explore Menus</h1>
                                                    <p>Dozen of good meals are ready to give you the best taste.</p>
                                                    <div className='btn-byCategoryCard'>Explore Now</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='line-separator2'>Or</div>
                                
                                <div className={`CardFlipWrap ${cardRight ? 'SwipeUpIn' : ''}`} onClick={() => {handleToChefList()}}>
                                    <div className='flipper-hug'>
                                        <div className='front'>
                                            <div className='byCategoryCard chefs'>
                                                <img className='img-1' src={chefBack} draggable="false"></img>
                                                <img className='img-2' src={patternchef} draggable="false"></img>
                                                <img className='img-3' src={patternchef} draggable="false"></img>
                                                <img className='img-4' src={logo} draggable="false"></img>
                                            </div>
                                        </div>
                                        <div className='back'>
                                            <div className='byCategoryCard chefs'>
                                                <img className='img-1' src={chef} draggable="false"></img>
                                                <img className='img-2' src={patternchef} draggable="false"></img>
                                                <img className='img-3' src={patternchef} draggable="false"></img>
                                                <div className='byCategoryCard-text'>
                                                    <h1>Explore Chefs</h1>
                                                    <p>We’re ready to serve you the best dining experiences</p>
                                                    <div className='btn-byCategoryCard'>Explore Now</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='headerWrapper'>
                        <div className='headerLeft'>
                            <h1>More Than<br></br> Just Dining</h1>
                            <p>Taplak is Indonesia’s first digital platform aggregating Menus and Hospitality Providers (Taplak Talents) to help Diners discover, curate, and create dining experiences – whether they be intimate private gatherings to large caterings!</p>
                            {/* <p>Taplak is the first online platform in Indonesia connecting diners to a private dining experience. Choose between a list of menus or talents and find the right private dining experience for you.</p> */}
                            {/* <a onClick={e => {handleToCategory()}} className='headerButton'>Explore Now</a> */}
                            {/* <a onClick={handleToggle} className='headerButton'>Explore Now</a> */}
                            <div className='headerButtonWrap'>
                                <a onClick={()=> {handleToggle()}} className='btn primary'>
                                    <i className='bx bx-dish'></i>
                                    <span>
                                        Private Dining
                                    </span>
                                </a>
                                <a onClick={()=> {handleToStartEvent()}} className='btn secondary'>
                                    <i className='bx bx-calendar'></i>
                                    <span>
                                        Taplak Events
                                    </span>
                                </a>
                                <a onClick={() => {handleToEvent()}} className='btn secondary'>
                                    <i className='bx bx-book-reader'></i>
                                    <span>
                                        Pop ups
                                    </span>
                                </a>
                            </div>
                        </div>
                        {/* <div className='headerRight'>
                            <div className="questionWrapper">
                                <p className="question">Type of Food</p>
                                <div id="formcategory" onClick={e => {e.preventDefault();e.stopPropagation();showCategory? setShowCategory(false):handleOpenPopUp('category')}} className="inputWrapper">
                                    <img className="iconkiri" src={forkspoon} alt="icon"></img>
                                    <p id="homecat" className="inputquestion">{category}</p>
                                    <img id="HPiconcat" className="iconkanan" src={down} alt="icon"></img>
                                    
                                </div>
                                {
                                    showCategory === true && (
                                        <div className="popupkontainer">
                                                {
                                                    listCategory.map((post) => (
                                                        <p className="HPpopupfont" onClick={e => {e.preventDefault();e.stopPropagation();handleSetCategory(post)}}>{post.name}</p>
                                                    ))
                                                }
                                        </div>
                                    )
                                }
                            </div>
                            <div className="questionWrapper">
                                <p className="question">Location</p>
                                <div id="formloc" onClick={e => {e.preventDefault();e.stopPropagation();showLocation? setShowLocation(false):handleOpenPopUp('location')}} className="inputWrapper">
                                    <img className="iconkiri" src={pin} alt="icon"></img>
                                    <p id="homeloc" className="inputquestion">{choosenLocation}</p>
                                    <img id="HPiconloc" className="iconkanan" src={down} alt="icon"></img>
                                </div>
                                {
                                    showLocation === true && (
                                        <div className="popupkontainer">
                                                <div onClick={e => {e.preventDefault();e.stopPropagation();}} className="inputfind">
                                                    <img src={search} alt='icon' className="iconsearch"></img>
                                                    <input value={filterLocation} onChange={e => handleSearchLocation(e.target.value)} className="fieldlocation" type='text' placeholder='Search Location'/>
                                                </div>
                                                {
                                                    shownLocation.map((post) => (
                                                        <p className="HPpopupfont" onClick={e => {e.preventDefault();e.stopPropagation();handleSelectLocation(post)}}>{post.name}</p>
                                                    ))
                                                }
                                        </div>
                                    )
                                }
                            </div>
                            <div className="questionWrapper">
                                <p className="question">Date</p>
                                <div id="formdate" onClick={e => {e.preventDefault();e.stopPropagation();showDate? setShowDate(false):handleOpenPopUp('date')}} className="inputWrapper">
                                    <img className="iconkiri" src={calendar} alt="icon"></img>
                                    <p id="homedate" className="inputquestion">{dateShown}</p>
                                    <img id="HPicondate" className="iconkanan" src={down} alt="icon"></img>
                                    
                                </div>
                                {
                                    showDate === true && (
                                        <div className="popupkontainerDate">
                                            <DatePicker
                                                id="dateStartEnd"
                                                dateFormat="MM/dd/yyyy"
                                                className={'form-control form-control-sm'}
                                                autoFocus={true}
                                                shouldCloseOnSelect={false}
                                                selected={selectedDate}
                                                onChange={(date) => handleSelectDate(date)}
                                                monthsShown={1}
                                                inline
                                                minDate={new Date()}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                            <div className="questionWrapper">
                                <p className="question">Guest</p>
                                <div className="inputWrapper">
                                    <img className="iconkiri" src={user} alt="icon"></img>
                                    <p id="homegue" className="inputquestion">{guest} Guest</p>
                                    <img className="iconkanan" onClick={e => {e.preventDefault();e.stopPropagation();handleGuestChange("minus")}} src={minus} style={{marginRight:"15px", cursor:"pointer"}} alt="icon"></img>
                                    <img className="iconkiri" onClick={e => {e.preventDefault();e.stopPropagation();handleGuestChange("plus")}} src={plus} alt="icon" style={{cursor:"pointer"}}></img>
                                </div>
                            </div>
                            <button onClick={e => {handleSearchChef()}} className="button2">Search</button>
                        </div> */}
                    </div>

                    <div className='AboutUsWrapper' data-aos="fade-up" data-aos-delay="800" data-aos-duration="2000">
                        <div className='AboutUsContent'>
                            <div className='AboutUsTextContent'>
                                <div className='AboutUsHead'>
                                    <h1>About Us</h1>
                                    {
                                        frontPageContent.length !== 0 ?
                                        React.createElement("p", { dangerouslySetInnerHTML: { __html: frontPageContent[0].content  } })
                                        // <div dangerouslySetInnerHTML={{ __html: frontPageContent[0].content }} />
                                        :
                                        <p></p>
                                    }
                                    
                                    {/* <p>{frontPageContent[0].content}</p> */}
                                    {/* <a className='AboutUsButton'>Read More</a> */}
                                </div>
                                <div className='AboutUsGridImage'>
                                    <div className='AboutUsImage' data-aos="fade-up" data-aos-duration="2000">
                                        <img src={AbImage2} draggable="false"></img>
                                    </div>
                                    <div className='AboutUsImage middle' data-aos="fade-up" data-aos-duration="2000">
                                        <img src={AbImage1} draggable="false"></img>
                                    </div>
                                    <div className='AboutUsImage' data-aos="fade-up" data-aos-delay="300" data-aos-duration="2000">
                                        <img src={AbImage3} draggable="false"></img>
                                    </div>
                                </div>
                            </div>
                            <div className='AboutUsBackground'></div>
                        </div>
                    </div>

                    <div className='line-separator' data-aos="fade-up" data-aos-delay="800" data-aos-duration="2000">
                        <div className='line-img'>
                            <img src={forkspoonWhite} alt="icon" draggable="false"></img>
                        </div>
                    </div>

                    <div className='HowItWrapper' data-aos="fade-up" data-aos-delay="800" data-aos-duration="2000">
                        <div className='HowItHeading'>
                            <h1>How Taplak Works</h1>
                            {/* {
                                        frontPageContent.length !== 0 ?
                                        React.createElement("p", { dangerouslySetInnerHTML: { __html: frontPageContent[2].content  } })
                                        // <div dangerouslySetInnerHTML={{ __html: frontPageContent[0].content }} />
                                        :
                                        <p></p>
                                    } */}
                            {/* <p>Cek ketiga tahap bagaimana Taplak melayani hingga memberi Dining experience terbaik untukmu.</p> */}
                            {/* <a onClick={e => {handleToChefList()}} className='HowItButton'>Find Your Chef</a> */}
                            <a className='btn__primary heading' onClick={e => {handleToChefList()}}>Explore Chefs</a>
                        </div>
                        <div className='HowItGrids'>
                            <div className='gridPoints' data-aos="fade-up" data-aos-delay="400" data-aos-duration="2000">
                                <div className='gridPointsImage'>
                                    <img src={weCookBG} alt="work_cookbg.png" draggable="false"></img>
                                    <div className='gridPointsIcon'>
                                        <img src={weCook} alt='work_cook.png' draggable="false"></img>
                                    </div>
                                </div>
                                <div className='gridPointsText'>
                                    <h1>1. You choose a menu or chef</h1>
                                    <p>Browse and Select through our list of Menus, Hospitality Providers (Taplak Talents), and Related Services.</p>
                                </div>
                            </div>
                            <div className='gridPoints' data-aos="fade-up" data-aos-delay="450" data-aos-duration="2000">
                                <div className='gridPointsImage'> 
                                    <img src={weServeBG} alt="work_servebg.png" draggable="false"></img>
                                    <div className='gridPointsIcon'>
                                        <img src={weServe} alt='work_serve.png' draggable="false"></img>
                                    </div>
                                </div>
                                <div className='gridPointsText'>
                                    <h1>2. Booking and Confirmation</h1>
                                    <p>Complete pre-booking requirements and wait as we confirm of your booking. You can proceed to payment once the booking is confirmed.</p>
                                    <p>(Taplak Talents will have to accept your booking to ensure their availability for your event).</p>
                                </div>
                            </div>
                            <div className='gridPoints' data-aos="fade-up" data-aos-delay="500" data-aos-duration="2000">
                                <div className='gridPointsImage'>
                                    <img src={youEnjoyBG} alt="you_enjoybg.png" draggable="false"></img>
                                     <div className='gridPointsIcon'>
                                        <img src={youEnjoy} alt='work_enjoy.png' draggable="false"></img>
                                    </div>
                                </div>
                                <div className='gridPointsText'>
                                    <h1>3. We come and serve you!</h1>
                                    <p>Our talents come and prepare to create your dining experience. Sit back, Relax and Enjoy!.</p>  
                                    <div className='flex gap-5'>
                                        <a className='btn__primary body' onClick={e => {handleToChefList()}}>Explore Chefs</a>
                                        <a className='btn__secondary body' onClick={e => {handleToMenuList()}}>Explore Menus</a>
                                    </div> 
                                </div>
                                 
                            </div>
                        </div>
                    </div>
                    
                    <div className='line-separator' data-aos="fade-up" data-aos-duration="2000">
                        <div className='line-img'>
                            <img src={forkspoonWhite} alt="icon" draggable="false"></img>
                        </div>
                    </div>

                    <div className='TaplakFriends__v2'>
                        <h1 data-aos="fade-up" data-aos-delay="500" data-aos-duration="2000">Taplak Friends</h1>
                        <div className='TaplakFriends__wrapper'>
                            <div className='TaplakFriends__logo' data-aos="fade-up" data-aos-delay="500" data-aos-duration="2000">
                                <img src={FLogo1} draggable="false"></img>
                            </div>
                            <div className='TaplakFriends__logo' data-aos="fade-up" data-aos-delay="700" data-aos-duration="2000">
                                <img src={FLogo2} draggable="false"></img>
                            </div>
                            <div className='TaplakFriends__logo' data-aos="fade-up" data-aos-delay="900" data-aos-duration="2000">
                                <img src={FLogo3} draggable="false"></img>
                            </div>
                            <div className='TaplakFriends__logo' data-aos="fade-up" data-aos-delay="1100" data-aos-duration="2000">
                                <img src={FLogo4} draggable="false"></img>
                            </div>
                            <div className='TaplakFriends__logo' data-aos="fade-up" data-aos-delay="500" data-aos-duration="2000">
                                <img src={FLogo5} draggable="false"></img>
                            </div>
                            <div className='TaplakFriends__logo' data-aos="fade-up" data-aos-delay="700" data-aos-duration="2000">
                                <img src={FLogo6} draggable="false"></img>
                            </div>
                            <div className='TaplakFriends__logo' data-aos="fade-up" data-aos-delay="900" data-aos-duration="2000">
                                <img src={FLogo7} draggable="false"></img>
                            </div>
                            <div className='TaplakFriends__logo' data-aos="fade-up" data-aos-delay="1100" data-aos-duration="2000">
                                <img src={FLogo8} draggable="false"></img>
                            </div>
                            <div className='TaplakFriends__logo' data-aos="fade-up" data-aos-delay="500" data-aos-duration="2000">
                                <img src={FLogo9} draggable="false"></img>
                            </div>
                            <div className='TaplakFriends__logo' data-aos="fade-up" data-aos-delay="700" data-aos-duration="2000">
                                <img src={FLogo10} draggable="false"></img>
                            </div>
                            <div className='TaplakFriends__logo' data-aos="fade-up" data-aos-delay="900" data-aos-duration="2000">
                                <img src={FLogo11} draggable="false"></img>
                            </div>
                            <div className='TaplakFriends__logo' data-aos="fade-up" data-aos-delay="1100" data-aos-duration="2000">
                                <img src={FLogo12} draggable="false"></img>
                            </div>
                            <div className='TaplakFriends__logo' data-aos="fade-up" data-aos-delay="500" data-aos-duration="2000">
                                <img src={FLogo13} draggable="false"></img>
                            </div>
                            <div className='TaplakFriends__logo' data-aos="fade-up" data-aos-delay="700" data-aos-duration="2000">
                                <img src={FLogo14} draggable="false"></img>
                            </div>
                            <div className='TaplakFriends__logo' data-aos="fade-up" data-aos-delay="900" data-aos-duration="2000">
                                <img src={FLogo15} draggable="false"></img>
                            </div>
                        </div>
                    </div>

                    {
                    visibleGalleries ?
                    <div className={`taplakGalleries__popup ${animatedGalleries ? 'informasiIn' : 'informasiOut'}`}>
                        <div className='head__part'>
                            <div className='head__profile'>
                                <div className='head__images'>
                                    <img src={profile} draggable='false'></img>
                                </div>
                                <div className='head__texts'>
                                    <h1>Taplak Indonesia</h1>
                                    <div className='texts__event'>
                                        <i className='bx bx-globe-alt'></i>
                                        <p>Taplak Events</p>
                                        {/* <p>Events. {eventsName}</p> */}
                                    </div>
                                </div>
                            </div>
                            <i className='bx bx-x' onClick={closeGalleries}></i>
                        </div>
                        <div className='body__part'>
                            <img src={eventsImage} draggable='false'></img>
                            <div className='actions'>
                                <a className='bx bx-fullscreen' href={eventsImage} target='_blank'></a>
                                <a className='bx bx-download' href={eventsImage} download></a>
                            </div>
                        </div>
                        <div className='footer__Part'>
                            {/* <p>{eventsName}. Social Media:</p> */}
                            <p>Social Media:</p>
                            <div className='footer__social'>
                                <a href='https://www.instagram.com/taplakindonesia/' id='instagram' target='_blank'>
                                    <i className='bx bxl-instagram'></i>
                                </a>
                                {/* <a href='{linkInstagram}' id='instagram' target='_blank' className={`${linkInstagram ? 'show' : 'hidden'}`}>
                                    <i className='bx bxl-instagram'></i>
                                </a> */}
                                {/* <a href={linkFacebook} id='facebook' target='_blank' className={`${linkFacebook ? 'show' : 'hidden'}`}>
                                    <i className='bx bxl-facebook'></i>
                                </a> */}
                            </div>
                        </div>
                    </div>
                    :
                    null
                    }
                    <div className={`backdrop ${visibleGalleries ? 'block' : 'hidden'}`} onClick={closeGalleries}></div>

                    <div className='TaplakGalleries'>
                        <div className='section__wrapper'>
                            <div className='TaplakGalleries__head'>
                                <div className='text'>
                                    <h1 data-aos="fade-up" data-aos-duration="2000" data-aos-delay="200">Taplak Galleries</h1>
                                    <p data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500">Get a glimpse of what Taplak has in store for you!</p>
                                </div>
                                <div className='tabs' data-aos="fade-up" data-aos-duration="2000" data-aos-delay="700">
                                    <div className={`tabs__list ${tabGalleries == 'private' ? 'active' : ''}`} onClick={e=>{handleChangeTabs('private')}}>
                                        <img src={weServePrimary}></img>
                                        <p>Private Dining</p>
                                    </div>
                                    <div className={`tabs__list ${tabGalleries == 'events' ? 'active' : ''}`} onClick={e=>{handleChangeTabs('events')}}>
                                        <img src={weServePrimary}></img>
                                        <p>Events</p>
                                    </div>
                                    <div className={`tabs__list ${tabGalleries == 'popups' ? 'active' : ''}`} onClick={e=>{handleChangeTabs('popups')}}>
                                        <img src={weServePrimary}></img>
                                        <p>Pop Ups</p>
                                    </div>
                                </div>
                            </div>
                            {
                                tabGalleries === 'popups' ?
                                <div className='TaplakGalleries__body' data-aos="fade-up" data-aos-duration="2000" data-aos-delay="800">
                                    {popupsGalleries.slice(0, loadGalleries).map((image, index) => (
                                        <div className={`TaplakGalleries__img popups ${image.aspect === 'square' ? 'square' : image.aspect === 'vertical' ? 'vertical' : 'horizontal'}`}>
                                            <img src={image.src} key={index} draggable='false' onClick={(event) => handlePopups(image, event)}></img>
                                        </div>
                                    ))}
                                </div>
                                :
                                tabGalleries === 'events' ?
                                <div className='TaplakGalleries__body' data-aos="fade-up" data-aos-duration="2000" data-aos-delay="800">
                                    {eventsGalleries.slice(0,loadGalleries).map((image,index)=>(
                                        <div className={`TaplakGalleries__img popups ${image.aspect === 'square' ? 'square' : image.aspect === 'vertical' ? 'vertical' : 'horizontal'}`}>
                                            <img src={image.src} key={index} draggable='false' onClick={(event) => handlePopups(image, event)}></img>
                                        </div>
                                    ))}
                                </div>
                                :
                                <div className='TaplakGalleries__body' data-aos="fade-up" data-aos-duration="2000" data-aos-delay="800">
                                    {privateGalleries.slice(0,loadGalleries).map((image,index)=>(
                                        <div className={`TaplakGalleries__img popups ${image.aspect === 'square' ? 'square' : image.aspect === 'vertical' ? 'vertical' : 'horizontal'}`}>
                                            <img src={image.src} key={index} draggable='false' onClick={(event) => handlePopups(image, event)}></img>
                                        </div>
                                    ))}
                                </div>
                            }          
                            <div className='TaplakGalleries__footer' data-aos="fade-up" data-aos-duration="2000" data-aos-delay="900">
                                <a onClick={loadMoreImages}>
                                    Load More 
                                    {
                                        loadingAnimate ?
                                            <i className='bx bx-loader-alt bx-spin'></i>
                                        :
                                            null
                                    }
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className='line-separator' data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1000">
                        <div className='line-img'>
                            <img src={forkspoonWhite} alt="icon" draggable="false"></img>
                        </div>
                    </div>
                    {
                        visibleCancel ?
                        <div className={`informasiWrapper cancellation ${animatedCancel ? 'informasiIn' : 'informasiOut'}`}>
                            <div className='informasiHead'>
                                <i class='bx bx-info-circle'></i>
                                <h1>Cancellation Policy</h1>
                            </div>
                            <div className='informasiBody'>
                                <a href={cancellationPolicy} download>
                                    <img src={cancellationPolicy} draggable="false"></img>
                                </a>
                                <p>*In case the image isn't clear, click on image to download it.</p>
                            </div>
                            <div className='informasiFooter'>
                                <button onClick={closeCancelInfo}>Tutup</button>
                            </div>
                        </div>
                        :
                        null
                    }
                    <div className={`backdrop ${visibleCancel ? 'block' : 'hidden'}`} onClick={closeCancelInfo}></div>

                    <div className='faqWrapper' data-aos="fade-up" data-aos-delay="800" data-aos-duration="2000">
                        <div className='faqGridMain'>
                            <div className='faqHeadGrids'>
                                <div className='faqGridImg'>
                                    <img src={faqBG} alt='faq-bg.png' draggable="false"></img>
                                    <div className='faqGridIcon'></div>
                                </div>
                                <div className='faqInfos'>
                                    <h1>Frequently Asked Question</h1>
                                    <p>Need More Infos? Let us know what’s inside your mind.</p>
                                    <div className='faqInfosFlex'>
                                        <img src={phone} draggable="false"></img>
                                        <p>+628591234123</p>
                                    </div>
                                    <div className='faqInfosFlex'>
                                        <img src={email} draggable="false"></img>
                                        <p>taplak@taplak.id</p>
                                    </div>
                                </div>
                            </div>
                            <div className='faqCollection'>
                                <div className={`faqContents ${activeFaqId === activeFaqIdStatic ? 'active' : ''}`}>
                                    <div className='faqHead' onClick={() => openFaq(activeFaqIdStatic)}>
                                        <h1>Payment Terms</h1>
                                        <img src={arrows}></img>
                                    </div>
                                    <div className='faqBody'>
                                        <p>We Require 100% payment upon booking. Should there be a cancellation from either party, please see our <span className='pointer line__thru' onClick={() => handleCancellation()}>Cancellation Policy</span></p>
                                    </div>
                                </div>
                            {
                                faqContents.length !== 0 ?
                                faqContents.map((post) => (
                                    <div key={post.id} className={`faqContents ${activeFaqId === post.id ? 'active' : ''}`}>    
                                    <div className='faqHead' onClick={() => openFaq(post.id)}>
                                        <h1>{post.question}</h1>
                                        <img src={arrows}></img>
                                    </div>
                                    <div className='faqBody'>
                                    {/* <div dangerouslySetInnerHTML={{ __html: faqContents.answer }} /> */}
                                    {React.createElement("div", { dangerouslySetInnerHTML: { __html: post.answer } })}
                                    {/* <p >{React.createElement("div", { dangerouslySetInnerHTML: { __html: faqContents.answer } })}</p> */}
                                        {/* <p>Lorem ipsum dolor sit amet consectetur. Sit integer quis tincidunt cras tortor. Lorem ipsum dolor sit amet consectetur. Sit integer quis tincidunt cras tortor. Lorem ipsum dolor sit amet consectetur. Sit integer quis tincidunt cras tortor.</p> */}
                                    </div>
                                </div>
                                )) :<div></div>
                                
                                    
                            }
                            </div>
                        </div>
                    </div>

                    <div className='line-separator' data-aos="fade-up" data-aos-duration="2000" data-aos-delay="800">
                        <div className='line-img'>
                            <img src={forkspoonWhite} alt="icon" draggable="false"></img>
                        </div>
                    </div>

                    <div className='kontainercardWrapper' data-aos="fade-up" data-aos-delay="800" data-aos-duration="2000">
                        <div className='kontainerHeader'>
                            <h1>Upcoming Event</h1>
                            <p onClick={e => {handleToEvent()}}>See More</p>
                        </div>
                        <div className="kontainerList" ref={ref} onMouseDown={onMouseDown}>
                            {
                                listEvent?.length > 0 ? 
                                listEvent.map((post) => (
                                    // <div className='EPdeepupcomingevent'>
                                    //     {

                                        
                                    //        post !==undefined ? <HomeTypeCard
                                    //             id = {post.id}
                                    //             gambar = {post.cover_image} 
                                    //             judul = {post.name} 
                                    //             style = {post.styles} 
                                    //             sisa = {post.sisa} 
                                    //             total = {post.max_seat} 
                                    //             tanggal = {post.date} 
                                    //             jam = {post.time_start} 
                                    //             tempat = {post.venue_location} 
                                    //             fungsi={()=>setstandby(false)}
                                    //         /> : <div></div>
                                    //     } 
                                    // </div>
                                    <HomeTypeCard
                                    id = {post.id}
                                    gambar = {post.cover_image} 
                                    judul = {post.name} 
                                    style = {post.styles} 
                                    sisa = {post.sisa} 
                                    total = {post.max_seat} 
                                    tanggal = {post.date} 
                                    jam = {post.time_start} 
                                    tempat = {post.venue_location} 
                                    fungsi={()=>setstandby(false)}
                                />
                                    )
                            ) :<div></div>
                        }
                        </div>
                    </div>
                </div>
                </CSSTransition>
            }
            
        </div>
        
    )
  }