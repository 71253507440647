/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react'
import google from '../../assets/facebook2.svg'
import facebook from '../../assets/google.svg'
import down from '../../assets/down.svg'
import email from '../../assets/email.svg'
import { useNavigate } from "react-router-dom";
import './index.css'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {baseUrl} from '../../lib/api'
import phoneNumList from '../../phone_list.json'
import chefIconLogin from "../../assets/chef.png";
import chefIconRegister from "../../assets/chef-hat.png";
import OTPInputGroup from '../OTPPage/otpInputs'

export const PageLogin = ({fungsi, openRegister}) => {
    const [mode, setMode] = useState('signin')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [regEmail, setRegEmail] = useState('')
    const [regFirstName, setRegFirstName] = useState('')
    const [regLastName, setRegLastName] = useState('')
    const [regPhoneNumber, setPhoneNumber] = useState('')
    const [dataRegistrasi, setDataRegistrasi] = useState('')
    const [regBirthday, setRegBirthday] = useState('')
    const [birthdayShown, setBirthdayShown] = useState('Enter Birth Date')
    const [regPassword, setRegPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isRegisterSuccess, setIsRegisterSuccess] = useState(false)
    const [termCondition, setTermCondition] = useState(false)
    const [privacyPolicy, setPrivacyPolicy] = useState(false)
    const prefixAvailable = [...new Set(phoneNumList.map((phone) => { return phone.code }))].sort()
    const [showPrefix, setShowPrefix] = useState(false)
    // const [prefix, setPrefix] = useState('+..')
    const [prefix, setPrefix] = useState('+62')
    const [showCalendar, setShowCalendar] = useState(false)
    const [showSuccessReg, setShowSuccessReg] = useState(false)
    const [showFailedReg, setShowFailedReg] = useState(false)
    const [childValue, setChildValue] = useState('');
    const [childValueId, setChildValueId] = useState('');
    const [childIsValue, setChildIsValue] = useState(false);

    const [valLog1, setValLog1] = useState(true)
    const [valLog2, setValLog2] = useState(true)
    const [valLog3, setValLog3] = useState(true)
    const [valReg1, setValReg1] = useState(true)
    const [valReg2, setValReg2] = useState(true)
    const [valReg3, setValReg3] = useState(true)
    const [valReg4, setValReg4] = useState(true)
    const [valReg5, setValReg5] = useState(true)
    const [valReg6, setValReg6] = useState(true)
    const [valReg7, setValReg7] = useState(true)
    const [valReg8, setValReg8] = useState(true)
    const [valOtp, setValOtp] = useState(true)
    const [valOtpSuccess, setValOtpSuccess] = useState(true)


    const formatDate = (data) =>{
        if(data<10){
          return `0${data}`
        }else{
          return data
        }
      }

    const handleSelectDate = (e) => {
        setRegBirthday(e)
        setBirthdayShown(`${e.getFullYear()}-${formatDate(e.getMonth()+1)}-${formatDate(e.getDate())}`)
        setShowCalendar(false)
    }

    const handleChildValue = (value) => {
        setChildValue((prevInputValues) => ({
            ...prevInputValues,
            [value[0]]: value[1],
        }));
        // console.log("show otp value 1 => "+JSON.stringify(value[0]))
        // console.log("show otp value 1 => "+JSON.stringify(value[1]))
      };
    const handleChildValueId = (value) => {
        setChildValueId(value);
        // console.log("show otp value 2 => "+JSON.stringify(value))
      };

      const handleChildIsValue = (value) => {
        setChildIsValue(value);
        // console.log("show otp value 3 => "+value)
        setValOtp(true)
      };

      const handleShowChildValue = () => {
        console.log("show otp value => "+JSON.stringify(childValue))
        // if(childValue.input1)
        // console.log("show otp value => "+childValue.length)
        // console.log("show otp value => "+childValue.input2)
        if(childValue.input1 !== "" && childValue.input2 !== "" && childValue.input3 !== "" && childValue.input4 !== ""){
            console.log("semua ada datanya")
            callAPIValidateOTP(`${childValue.input1}${childValue.input2}${childValue.input3}${childValue.input4}`)
        } else {
            console.log("ada yg kosong")
            setValOtp(false)
        }
    };

    const handleOpenPopUp = (e) => {
        if(e==="prefix"){
            setShowPrefix(true)
            setShowCalendar(false)
        }else{
            setShowCalendar(true)
            setShowPrefix(false)
        }
    }
    useEffect(() => {
        if(openRegister===true){
            setMode("register")
        }
    },[])
    const firstUpdate = useRef(true);
    useEffect(()=>{
        if (firstUpdate.current) {
            firstUpdate.current = false;
        } else {
            if(valReg6){
                var elem = document.getElementById("LGdown1")
                elem.style.transition = "transform 0.5s"
                if(showCalendar){
                    elem.style.transform = "rotate(180deg)"
                }else{
                    elem.style.transform = "rotate(0deg)"
                }
            }else{
                var elem2 = document.getElementById("LGdown2")
                elem2.style.transition = "transform 0.5s"
                if(showCalendar){
                    elem2.style.transform = "rotate(180deg)"
                }else{
                    elem2.style.transform = "rotate(0deg)"
                }
            }
        }
    },[showCalendar])

    const history = useNavigate()

    


    const login = e =>{
        e.preventDefault()
        let isError = false
        if(username.length===0){
            setValLog1(false)
            isError=true
        }else if(username.length!==0){
            if(!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(username))){
                setValLog1(false)
                isError=true
            }else{
                setValLog1(true)
            }
        }
        if(password.length===0){
            setValLog2(false)
            isError=true
        }else{
            setValLog2(true)
        }
        if(isError){
            return
        }
        callAPILogin()
    }

    const capitalize = (str) => {
        return str.replace(/\w\S*/g, function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    const callAPILogin = async () => {
        var axios = require('axios');
        var FormData = require('form-data');
        const formDataLogin = new FormData();
        formDataLogin.append("email", username)
        formDataLogin.append("password",password)
        axios({
            method: 'POST',
            // url: baseUrl + 'login',
            url: baseUrl + 'loginCustomer',
            data : formDataLogin,
            withCredentials:false
        })
        .then(function (response) {
            // console.log("batas")
            // console.log(response.data.access_token)
            localStorage.setItem('TaplakLoggedIn', 'true')
            localStorage.setItem('username',capitalize(response.data.user.name))
            localStorage.setItem('taplakToken',response.data.access_token)
            // window.location.reload()
            fungsi()
            // console.log(JSON.stringify(response.data));
        })
            .catch(function (error) {
            console.log(error);
            setValLog3(false)
        });
    }

    const callAPIRegister = async () => {
        var axios = require('axios');
        var FormData = require('form-data');
        const formDataRegister = new FormData();
        // console.log(prefix)
        // console.log(regPhoneNumber)
        // console.log(regEmail)
        // console.log(regPassword)
        // console.log(birthdayShown)
        // console.log(regFirstName)
        // console.log(regLastName)
        formDataRegister.append('phone_code', prefix);
        formDataRegister.append('phone', "0"+regPhoneNumber);
        formDataRegister.append('email', regEmail);
        formDataRegister.append('password', regPassword);
        formDataRegister.append('birth_date', birthdayShown);
        formDataRegister.append('first_name', regFirstName);
        formDataRegister.append('last_name',regLastName)
        axios({
            method: 'POST',
            url: baseUrl + 'registerStoreCustomer',
            data : formDataRegister,
            withCredentials:false
        })
            .then(function (response) {
            console.log("batas response regis => "+response.status)
            if(response.data.status){
                console.log("berhasil")
                setShowSuccessReg(true)
                setDataRegistrasi(response.data.data_registrasi)
            }else{
                console.log("gagal")
                setShowFailedReg(true)
            }
            // console.log(JSON.stringify(response.data));
        })
            .catch(function (error) {
            console.log(error);
        });
    }

    const handleClickRegist = () => {
        let isError=false

        if(regEmail.length===0){
            isError=true
            setValReg1(false)
        }else if(regEmail.length!==0){
            if(!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(regEmail))){
                isError=true
                setValReg1(false)
            }else{
                setValReg1(true)
            }
        }if(regFirstName.length===0){
            isError=true
            setValReg2(false)
        }else{
            setValReg2(true)
        }if(regLastName.length===0){
            isError=true
            setValReg3(false)
        }else{
            setValReg3(true)
        }if(prefix==="+.."){
            isError=true
            setValReg4(false)
        }else{
            setValReg4(true)
        }if(regPhoneNumber.length===0){
            isError=true
            setValReg5(false)
        }else{
            setValReg5(true)
        }if(regBirthday===""){
            isError=true
            setValReg6(false)
        }else{
            setValReg6(true)
        }if(regPassword.length<8){
            isError=true
            setValReg7(false)
        }else{
            setValReg7(true)
        }if(confirmPassword.length<8){
            isError=true
            setValReg8(false)
        }else{
            setValReg8(true)
        }if(regPassword!==confirmPassword){
            isError=true
            setValReg8(false)
        }

        if(isError){
            return
        }
        callAPIRegister()
      };

    // useEffect(() => {
    //     window.addEventListener("touchstart", handleClickRegist);
        
    //     return () => {
    //         window.removeEventListener("touchstart", handleClickRegist);
    //     };
    // }, [handleClickRegist]);

    const callAPIValidateOTP = async (otpValue) => {
        let axios = require('axios');
        let FormData = require('form-data');
        const formDataRegister = new FormData();
        formDataRegister.append('email', dataRegistrasi);
        formDataRegister.append('kode_otp', otpValue);
        axios({
            method: 'POST',
            url: baseUrl + 'validate_otp',
            data : formDataRegister,
            withCredentials:false
        })
            .then(function (response) {
            // console.log("batas")
            if(response.data.status){
                console.log("berhasil")
                setValOtpSuccess(true)
                callAPIActivationUser()
            }else{
                setValOtpSuccess(false)
                console.log("gagal")
            }
            // console.log(JSON.stringify(response.data));
        })
            .catch(function (error) {
            console.log(error);
        });
    }

    const callAPIActivationUser = async () => {
        let axios = require('axios');
        let FormData = require('form-data');
        const formDataRegister = new FormData();
        formDataRegister.append('email', dataRegistrasi);
        axios({
            method: 'POST',
            url: baseUrl + 'activation_user',
            data : formDataRegister,
            withCredentials:false
        })
            .then(function (response) {
            // console.log("batas")
            if(response.data.status){
                console.log("berhasil")
                fungsi()
            }else{
                console.log("gagal")
            }
            // console.log(JSON.stringify(response.data));
        })
            .catch(function (error) {
            console.log(error);
        });
    }

    const register = e => {
        e.preventDefault()
        let isError=false

        if(regEmail.length===0){
            isError=true
            setValReg1(false)
        }else if(regEmail.length!==0){
            if(!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(regEmail))){
                isError=true
                setValReg1(false)
            }else{
                setValReg1(true)
            }
        }if(regFirstName.length===0){
            isError=true
            setValReg2(false)
        }else{
            setValReg2(true)
        }if(regLastName.length===0){
            isError=true
            setValReg3(false)
        }else{
            setValReg3(true)
        }if(prefix==="+.."){
            isError=true
            setValReg4(false)
        }else{
            setValReg4(true)
        }if(regPhoneNumber.length===0){
            isError=true
            setValReg5(false)
        }else{
            setValReg5(true)
        }if(regBirthday===""){
            isError=true
            setValReg6(false)
        }else{
            setValReg6(true)
        }if(regPassword.length<8){
            isError=true
            setValReg7(false)
        }else{
            setValReg7(true)
        }if(confirmPassword.length<8){
            isError=true
            setValReg8(false)
        }else{
            setValReg8(true)
        }if(regPassword!==confirmPassword){
            isError=true
            setValReg8(false)
        }

        if(isError){
            return
        }
        callAPIRegister()
        
    }

    const validatePhoneNumber = (value) => {
        // If the input starts with 0, reject the change
        if (value.startsWith('0')) {
          return false;
        }
        // Otherwise, accept the change
        return true;
      };
    
      const handlePhoneNumberChange = (event) => {
        const newValue = event.target.value;
    
        // Check if the new value is valid
        if (validatePhoneNumber(newValue)) {
          setPhoneNumber(newValue);
        }
        // If not valid, you can choose to show an error message or take other actions
      };

    const handleLoginAsChef = () => {
        window.open("https://chef.taplak.id/login", "_blank", "noreferrer");
    }

    const handleRegisterAsChef = () => {
        window.open("https://chef.taplak.id/register", "_blank", "noreferrer");
    }
    
    return (
        <div>
            <div onClick={fungsi} className='LoginKontainer'></div>
            <div className='LoginKonten'>
                {
                    showSuccessReg ? 
                    <div className='LGafterreg'>
                        <p className='bold semibig' style={{textAlign:"center"}}>Thank You</p>
                        <img alt="icon" className='LGemail' src={email}></img>
                        <p className='normal LGnotifreg'>We have received your registration. Please kindly check your email to get the OTP.</p>
                        <p className='normal LGnotifreg2'>Please confirm the OTP below.</p>
                        <div className='OTPInputWrap'>
                            <OTPInputGroup onChildValue={(e) =>handleChildValue(e)} onChildIsValue={handleChildIsValue} onChildValueId={handleChildValueId}/>
                        </div>
                        {
                            !valOtp && 
                                (
                                    <p className='bold verytiny' style={{color:"#FA591D", marginTop:"-4px", marginBottom:"8px", textAlign:"center"}}>Please enter otp correctly</p>
                                )
                            
                        }
                        {
                            !valOtpSuccess && 
                                (
                                    <p className='bold verytiny' style={{color:"#FA591D", marginTop:"-4px", marginBottom:"8px", textAlign:"center"}}>Data not found, Please check your OTP properly</p>
                                )
                            
                        }
                        {
                            childIsValue ? 
                            <button onClick={handleShowChildValue} className='LGbuttonok small bold'>OK</button>
                            :
                            <button onClick={handleShowChildValue} className='LGbuttonok small bold' disabled>OK</button>
                        }
                        
                    </div>
                    :
                    showFailedReg ?
                    <div className='LGafterreg'>
                        <p className='bold semibig' style={{textAlign:"center"}}>Email Already Registered</p>
                        <p className='normal LGnotifreg LGmargin'>Your email is already registered at taplak.id</p>
                        <p className='normal LGnotifreg LGmargin2'>Have you forgotten your password?</p>
                        <button onClick={() => history('/forgotPassword')} className='LGbuttonok small bold'>Forgot Password</button>
                        <button onClick={fungsi} className='LGbuttonok2 small bold'>No, Thanks</button>
                    </div>
                    :
                    <div>
                        <p className='semibig LGwelcome'>Welcome</p>
                        <div className='LGkontenwrapper flex' style={{marginBottom:"36px"}}>
                            <div className='LGkiri'>
                                {
                                    mode==="signin" ?
                                    <div>
                                        <div className='flex'>
                                            <p className='medium bold LGunderline' style={{marginRight:"16px", cursor:"pointer"}}>Sign in</p>
                                            <p onClick={() => setMode('register')} style={{cursor:"pointer"}} className='medium'>Register</p>
                                        </div>
                                        <form className='LGformwrap' onSubmit={login}>
                                            {(valLog1|valLog3)?
                                                <input id="LGemail" autoCorrect="off" autoCapitalize="none" type="email" value={username} onInput={e => setUsername(e.target.value)} onChange={e => setUsername(e.target.value)} className='LGform' placeholder='Email'></input>
                                                :
                                                <input id="LGemail" autoCorrect="off" autoCapitalize="none" type="email" value={username} onInput={e => setUsername(e.target.value)} onChange={e => setUsername(e.target.value)} className='LGform LGerror' placeholder='Email'></input>
                                            }
                                            
                                            {
                                                !valLog1 && (
                                                    <p className='bold verytiny' style={{color:"#FA591D", marginTop:"-4px", marginBottom:"8px", textAlign:"left"}}>Please input email correctly!</p>
                                                )
                                            }
                                            
                                            {
                                                (valLog2|valLog3)?
                                                <input id="LGpassword" autoCorrect="off" autoCapitalize="none" type="password" value={password} onInput={e => setPassword(e.target.value)} onChange={e => setPassword(e.target.value)} className='LGform' placeholder='Password'></input>
                                                :
                                                <input id="LGpassword" autoCorrect="off" autoCapitalize="none" type="password" value={password} onInput={e => setPassword(e.target.value)} onChange={e => setPassword(e.target.value)} className='LGform LGerror' placeholder='Password'></input>
                                            }
                                            
                                            {
                                                !valLog2 && (
                                                    <p className='bold verytiny' style={{color:"#FA591D", marginTop:"-4px", marginBottom:"8px", textAlign:"right"}}>Please input password!</p>
                                                )
                                            }
                                            {
                                                !valLog3 && (
                                                    <p className='bold verytiny' style={{color:"#FA591D", marginTop:"-4px", marginBottom:"8px", textAlign:"right"}}>Account not found, plese input a valid one!</p>
                                                )
                                            }
                                            <p onClick={() => history('/forgotPassword')} className='tiny bold LGforget'>Forgot password?</p>
                                            <button type='submit' className='small bold LGbutton1'>Sign in</button>
                                        </form>
                                        
                                    </div>
                                        :
                                    <div>
                                        <div className='flex'>
                                            <p onClick={() => setMode('signin')} className='medium' style={{marginRight:"16px", cursor:"pointer"}}>Sign in</p>
                                            <p className='medium bold LGunderline' style={{cursor:"pointer"}}>Register</p>
                                        </div>
                                        <form className='LGformwrap' onSubmit={register}>
                                            {
                                                valReg1?
                                                <input id='Reg1' type="email" value={regEmail} onChange={e => setRegEmail(e.target.value)} className='LGform' placeholder='Email'></input>
                                                :
                                                <input id='Reg1' type="email" value={regEmail} onChange={e => setRegEmail(e.target.value)} className='LGform LGerror' placeholder='Email'></input>
                                            }
                                            {
                                                !valReg1 && (
                                                    <p className='bold verytiny' style={{color:"#FA591D", marginTop:"-4px", marginBottom:"8px", textAlign:"left"}}>Please input email correctly!</p>
                                                )
                                            }

                                            {
                                                valReg2?
                                                <input id='Reg2' type='text' value={regFirstName} onChange={e => setRegFirstName(e.target.value)} className='LGform' placeholder='Enter First Name'></input>
                                                :
                                                <input id='Reg2' type='text' value={regFirstName} onChange={e => setRegFirstName(e.target.value)} className='LGform LGerror' placeholder='Enter First Name'></input>
                                            }
                                            {
                                                !valReg2 && (
                                                    <p className='bold verytiny' style={{color:"#FA591D", marginTop:"-4px", marginBottom:"8px", textAlign:"left"}}>Please input your first name!</p>
                                                )
                                            }

                                            {
                                                valReg3?
                                                <input id='Reg3' type='text' value={regLastName} onChange={e => setRegLastName(e.target.value)} className='LGform' placeholder='Enter Last Name'></input>
                                                :
                                                <input id='Reg3' type='text' value={regLastName} onChange={e => setRegLastName(e.target.value)} className='LGform LGerror' placeholder='Enter Last Name'></input>
                                            }

                                            {
                                                !valReg3 && (
                                                    <p className='bold verytiny' style={{color:"#FA591D", marginTop:"-4px", marginBottom:"8px", textAlign:"left"}}>Please input your last name!</p>
                                                )
                                            }

                                            
                                            
                                            
                                            <div className='flex'>
                                                {
                                                    (valReg4 && showPrefix) && (
                                                        <button id='Reg4' onClick={()=>showPrefix?setShowPrefix(false):handleOpenPopUp("prefix")} type='button' className='LGprefix LGyellow' style={{ color: '#6F6F6F' }}>
                                                        {prefix}
                                                        {
                                                            showPrefix && (
                                                                <div className='LGpopup' style={{ height: '100px', overflow: 'scroll' }}>
                                                                    {prefixAvailable.map((post) => (
                                                                        <p onClick={() => {setPrefix(post);setShowPrefix(false)}} className='tiny' style={{marginBottom:"8px", cursor:"pointer"}}>{post}</p>
                                                                    )
                                                                    )}
                                                                </div>
                                                            )
                                                        }
                                                        
                                                    </button>
                                                    )
                                                }

                                                    

                                                
                                                {
                                                    (valReg4 && !showPrefix) && (
                                                        <button id='Reg4' onClick={()=>showPrefix?setShowPrefix(false):handleOpenPopUp("prefix")} type='button' className='LGprefix' style={prefix === "+.." ? { color: '#6F6F6F' } : null}>
                                                            {prefix}
                                                            {
                                                                showPrefix && (
                                                                    <div className='LGpopup'>
                                                                        {prefixAvailable.map((post) => (
                                                                            <p onClick={() => {setPrefix(post);setShowPrefix(false)}} className='tiny' style={{marginBottom:"8px", cursor:"pointer"}}>{post}</p>
                                                                        )
                                                                        )}
                                                                    </div>
                                                                )
                                                            }
                                                            
                                                        </button>
                                                    )
                                                }
                                                    
                                                {
                                                    !valReg4 && (
                                                        <button id='Reg4' onClick={()=>showPrefix?setShowPrefix(false):handleOpenPopUp("prefix")} type='button' className='LGprefix LGerror'>
                                                            {prefix}
                                                            {
                                                                showPrefix && (
                                                                    <div className='LGpopup'>
                                                                        {prefixAvailable.map((post) => (
                                                                            <p onClick={() => {setPrefix(post);setShowPrefix(false)}} className='tiny' style={{marginBottom:"8px", cursor:"pointer"}}>{post}</p>
                                                                        )
                                                                        )}
                                                                    </div>
                                                                )
                                                            }
                                                            
                                                        </button>
                                                    )
                                                }
                                                
                                                
                                                {
                                                    valReg5?
                                                    <input id='Reg5' type='text'  value={regPhoneNumber} onChange={handlePhoneNumberChange} className='LGform' placeholder='Enter Your Phone Number'></input>    
                                                    :
                                                    <input id='Reg5' type='text'  value={regPhoneNumber} onChange={handlePhoneNumberChange} className='LGform LGerror' placeholder='Enter Your Phone Number'></input>
                                                }                                            
                                            </div>
                                            {
                                                !valReg4 && (
                                                    <p className='bold verytiny' style={{color:"#FA591D", marginTop:"-4px", marginBottom:"8px", textAlign:"left"}}>Please choose phone number prefix!</p>
                                                )   
                                                }

                                                {
                                                    !valReg5 && (
                                                        <p className='bold verytiny' style={{color:"#FA591D", marginTop:"-4px", marginBottom:"8px", textAlign:"left"}}>Please input your phone number!</p>
                                                    )
                                                }    
                                            
                                            {
                                                valReg6? showCalendar?
                                                <div id='Reg6' onClick={()=>!showCalendar&&handleOpenPopUp("cal")} className='LGbirthdate LGyellow flex'>
                                                    <p onClick={()=>showCalendar?setShowCalendar(false):handleOpenPopUp("cal")} style={{fontSize:"14px", lineHeight:"14px", margin:"0", width:"-webkit-fill-available"}}>{birthdayShown}</p>
                                                    
                                                    <img id="LGdown1" onClick={()=>showCalendar?setShowCalendar(false):handleOpenPopUp("cal")}  alt='icon' src={down} className="LGicon"></img>
                                                    
                                                    {showCalendar && (
                                                        <div className='LGpopup1'>
                                                        <DatePicker
                                                            id="dateStartEnd"
                                                            dateFormat="MM/dd/yyyy"
                                                            className={'form-control form-control-sm'}
                                                            autoFocus={true}
                                                            shouldCloseOnSelect={false}
                                                            selected={regBirthday}
                                                            onChange={(date) => handleSelectDate(date)}
                                                            monthsShown={1}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            inline
                                                            maxDate={new Date()}

                                                            style={{width:"100%"}}
                                                        />
                                                        </div>
                                                    )}
                                                    
                                                </div>
                                                :
                                                <div id='Reg6' onClick={()=>!showCalendar&&handleOpenPopUp("cal")} className='LGbirthdate flex' style={birthdayShown === "Enter Birth Date" ? { color: '#6F6F6F' } : { color: 'black' }}>
                                                    <p onClick={()=>showCalendar?setShowCalendar(false):handleOpenPopUp("cal")} style={{fontSize:"14px", lineHeight:"14px", margin:"0", width:"-webkit-fill-available"}}>{birthdayShown}</p>
                                                    
                                                    <img id="LGdown1" onClick={()=>showCalendar?setShowCalendar(false):handleOpenPopUp("cal")}  alt='icon' src={down} className="LGicon"></img>
                                                    
                                                    {showCalendar && (
                                                        <div className='LGpopup1'>
                                                        <DatePicker
                                                            id="dateStartEnd"
                                                            dateFormat="MM/dd/yyyy"
                                                            className={'form-control form-control-sm'}
                                                            autoFocus={true}
                                                            shouldCloseOnSelect={false}
                                                            selected={regBirthday}
                                                            onChange={(date) => handleSelectDate(date)}
                                                            monthsShown={1}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            inline
                                                            maxDate={new Date()}

                                                            style={{width:"100%"}}
                                                        />
                                                        </div>
                                                    )}
                                                    
                                                </div>
                                                :
                                                <div id='Reg6' onClick={()=>!showCalendar&&handleOpenPopUp("cal")} className='LGbirthdate LGerror flex'>
                                                    <p onClick={()=>showCalendar?setShowCalendar(false):handleOpenPopUp("cal")} style={{fontSize:"14px", lineHeight:"14px", margin:"0",width:"-webkit-fill-available"}}>{birthdayShown}</p>
                                                    
                                                    <img id="LGdown2" onClick={()=>showCalendar?setShowCalendar(false):handleOpenPopUp("cal")}  alt='icon' src={down} className="LGicon"></img>
                                                    
                                                    {showCalendar && (
                                                        <div className='LGpopup1'>
                                                        <DatePicker
                                                            id="dateStartEnd"
                                                            dateFormat="MM/dd/yyyy"
                                                            className={'form-control form-control-sm'}
                                                            autoFocus={true}
                                                            shouldCloseOnSelect={false}
                                                            selected={regBirthday}
                                                            onChange={(date) => handleSelectDate(date)}
                                                            monthsShown={1}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            inline
                                                            maxDate={new Date()}

                                                            style={{width:"100%"}}
                                                        />
                                                        </div>
                                                    )}
                                                    
                                                </div>
                                            }
                                            {
                                                !valReg6 && (
                                                    <p className='bold verytiny' style={{color:"#FA591D", marginTop:"-4px", marginBottom:"8px", textAlign:"left"}}>Please choose your birth date!</p>
                                                )
                                            }

                                            {
                                                valReg7?
                                                <input id='Reg7' type="password" value={regPassword} onChange={e => setRegPassword(e.target.value)} className='LGform' placeholder='Password'></input>
                                                :
                                                <input id='Reg7' type="password" value={regPassword} onChange={e => setRegPassword(e.target.value)} className='LGform LGerror' placeholder='Password'></input>
                                            }
                                            
                                            {
                                                !valReg7 && (
                                                    <p className='bold verytiny' style={{color:"#FA591D", marginTop:"-4px", marginBottom:"8px", textAlign:"left"}}>Please input your password with minimum 8 character!</p>
                                                )
                                            }

                                            {
                                                valReg8?
                                                <input id='Reg8' type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} className='LGform' placeholder='Confirm Password'></input>
                                                :
                                                <input id='Reg8' type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} className='LGform LGerror' placeholder='Confirm Password'></input>
                                            }

                                            {
                                                !valReg8 && (
                                                    <p className='bold verytiny' style={{color:"#FA591D", marginTop:"-4px", marginBottom:"8px", textAlign:"left"}}>Please input your confirmation password correctly!</p>
                                                )
                                            }
                                            
                                            <div className='flex' style={{alignItems:"center", marginTop:"8px"}}>
                                                <input checked={termCondition} value={termCondition} onChange={() => termCondition?setTermCondition(false):setTermCondition(true)} className='LGcheckbox' type='checkbox' id="term" name="term"></input>
                                                <label for="term" className='tiny'>I accept the <span style={{fontWeight:"700"}}>Terms and Conditions</span></label>
                                            </div>
                                            <div className='flex' style={{alignItems:"center", marginTop:"8px"}}>
                                                <input checked={privacyPolicy} value={privacyPolicy} onChange={() => privacyPolicy?setPrivacyPolicy(false):setPrivacyPolicy(true)} className='LGcheckbox' type='checkbox' id="term2" name="term"></input>
                                                <label for="term2" className='tiny'>I accept the <span style={{fontWeight:"700"}}>Privacy Policy</span></label>
                                            </div>
                                            
                                            {
                                                (privacyPolicy && termCondition) ?
                                                <button type='submit' onTouchStart={handleClickRegist} className='small bold LGbutton1'>Register</button>
                                                :
                                                <button type='submit' className='small bold LGbutton1' disabled>Register</button>
                                            }
                                        </form>
                                    </div>
                                }
                            </div>
                            <div className='orLogin'>Or</div>
                            <div className='LGkanan'>
                                {/* <div className='flex LGgoogle'>
                                    <img className='LGicon' src={google} alt='icon'></img>
                                    <p className='tiny bold' style={{marginRight:"auto"}}>Continue with Google</p>
                                </div>
                                <div className='flex LGFacebook'>
                                    <img className='LGicon' src={facebook} alt='icon'></img>
                                    <p className='tiny bold' style={{marginRight:"auto"}}>Continue with Facebook</p>
                                </div> */}
                                <div className='flex ChefButton base' onClick={handleLoginAsChef}>
                                    {/* <img className='LGicon' src={google} alt='icon'></img> */}
                                    <img src={chefIconLogin} className='iconChef'></img>
                                    Login as Chef
                                </div>
                                <div className='flex ChefButton outline' onClick={handleRegisterAsChef}>
                                    {/* <img className='LGicon' src={facebook} alt='icon'></img> */}
                                    <img src={chefIconRegister} className='iconChef'></img>
                                    Register as Chef
                                </div>
                            </div>

                        </div>
                    </div>
                }
                
            </div>
        </div>
    )
  }