import "../../MenuList/index.css";
import image from "../../../assets/makanan1.png";
import iconChefs from "../../../assets/cheficons.png";
import { useNavigate } from "react-router-dom";
import {useState, useEffect} from 'react';
import toRupiah from '@develoka/angka-rupiah-js';

export const MenuCards = ({data,detailChef,isFromMenu}) => {
    const history = useNavigate()
    const [amount, setAmount] = useState("")
    const handletoDetailMenu = () =>{
        console.log("data booking list 3 => "+JSON.stringify(detailChef.booking_available))
        history("/menus/detail", {state :{dataMenu : data,detailChef: detailChef, isFromMenu:isFromMenu}});
    }
    useEffect(() =>{
        console.log("fisrt pirces => "+JSON.stringify(data.containData))
        // console.log("fisrt pirces => "+JSON.stringify(data))
        setAmount(data  ? data.converted_discounted_price : "0")
    },[data,amount])
    return(
        <div className="byCategoryCards cardEntry" onClick={() => {handletoDetailMenu()}}>
            <div className="byCategoryCardsImage">
                <img src={data.service_image_url} draggable="false"></img>
            </div>
            <div className="byCategoryCardsContents">

                <div className="head">
                    <h1 className="line-clamps-1">{data  ? data.nama : ""}</h1>
                    <div className="headSeats">
                        <p>Max</p>
                        <h1>{data  ? data.max_pax : ""} Pax</h1>
                    </div>
                </div>
                <div className="body">
                    <p>{data.description}</p>
                    <div className="bodyChefs">
                        <img src={iconChefs}></img>
                        {
                            isFromMenu ?
                            <h1>{data.namaChef }</h1>
                            :
                            <h1>{detailChef.nama }</h1>
                        }
                        
                    </div>
                </div>
                <div className="footer">
                    <div className="price">
                        <p>Start From</p>
                        <h1>{toRupiah(amount, {formal: false, symbol: 'IDR'}).slice(0, -3)}</h1>
                        {/* <h1>{data.data.converted_discounted_price}</h1> */}
                    </div>
                    <div className="dietaryMenuCard">
                        {
                            data.containData !== "N/A"
                            ?
                            data.containData.map((post)=>
                            {
                                if(post === data.containData[0]){
                                  return <p className="text-right">Menu Contains</p>
                                }
                            })
                            :
                            null
                        }
                        <div className="dietaryMenuImg">
                        {
                            data.containData !== "N/A" 
                            ?
                            data.containData.map((post) => 
                                {
                                    if(post === data.containData[0]){
                                        return <img alt="icon" src={post.file_url}></img>
                                    }
                                })
                            :
                            null
                        }
                        {
                            (data.containData !== "N/A" && data.containData.length > 1) && (
                                <p className='more'>
                                    {data.containData.length > 1 ? ` +${data.containData.length - 1} more` : null}
                                </p>
                            )
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}