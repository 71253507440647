import './index.css'
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

export const CategoryCard = ({standby, fungsi ,gambar, judul, deskripsi}) => {
    const history = useNavigate()
    const [pindah, setPindah] = useState(false)

    useEffect(()=>{
        
        if(pindah===true){
            localStorage.setItem('isBookingChefStillProggress',false)
            localStorage.setItem('isBookingChefStillProggressVenue',false)
            localStorage.removeItem('chefNameDraftFlow')
            localStorage.removeItem('chefIdDraftFlow')
            setTimeout(function() {
                history("/category/chefList" , { state: { dataChef : "tes", isFromSearch:false,dataChefSearch : "tes"}});
           }, 500);
        }
    },[pindah])

    return (
        <div data-aos="fade-up" data-aos-delay="300" onClick={() => {fungsi();setPindah(true)}} className='CardContainer'>
            <div className='cardImage' style={{backgroundImage:`url(${gambar})`}}></div>
            <div className='gradient'></div>
            <p className='CategoryTitle'>{judul}</p>
            {/* <p className='CategoryDesc'>{deskripsi}</p> */}
        </div>
    )
  }