/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react'
import google from '../../assets/facebook2.svg'
import facebook from '../../assets/google.svg'
import down from '../../assets/down.svg'
import email from '../../assets/email.svg'
import { useNavigate } from "react-router-dom";
import './indexOTP.css'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {baseUrl} from '../../lib/api'
import phoneNumList from '../../phone_list.json'
import chefIconLogin from "../../assets/chef.png";
import chefIconRegister from "../../assets/chef-hat.png";
import OTPInputGroup from '../OTPPage/otpInputs'

export const ForgotPasswordOTP = ({fungsi, openRegister}) => {
    const [mode, setMode] = useState('signin')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [regEmail, setRegEmail] = useState('')
    const [regFirstName, setRegFirstName] = useState('')
    const [regLastName, setRegLastName] = useState('')
    const [regPhoneNumber, setPhoneNumber] = useState('')
    const [dataRegistrasi, setDataRegistrasi] = useState('')
    const [regBirthday, setRegBirthday] = useState('')
    const [birthdayShown, setBirthdayShown] = useState('Enter Birth Date')
    const [regPassword, setRegPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isRegisterSuccess, setIsRegisterSuccess] = useState(false)
    const [termCondition, setTermCondition] = useState(false)
    const [privacyPolicy, setPrivacyPolicy] = useState(false)
    const prefixAvailable = [...new Set(phoneNumList.map((phone) => { return phone.code }))].sort()
    const [showPrefix, setShowPrefix] = useState(false)
    // const [prefix, setPrefix] = useState('+..')
    const [prefix, setPrefix] = useState('+62')
    const [showCalendar, setShowCalendar] = useState(false)
    const [showSuccessReg, setShowSuccessReg] = useState(false)
    const [showFailedReg, setShowFailedReg] = useState(false)
    const [childValue, setChildValue] = useState('');
    const [userId, setUserId] = useState();
    const [childValueId, setChildValueId] = useState('');
    const [childIsValue, setChildIsValue] = useState(false);
    const [showSuccessSendEmail, setShowSuccessSendEmail] = useState(true)
    const [successUpdatePassword, setSuccessUpdatePassword] = useState(false)
    const [valLog1, setValLog1] = useState(true)
    const [valLog2, setValLog2] = useState(true)
    const [valLog3, setValLog3] = useState(true)
    const [valReg1, setValReg1] = useState(true)
    const [valReg2, setValReg2] = useState(true)
    const [valReg3, setValReg3] = useState(true)
    const [valReg4, setValReg4] = useState(true)
    const [valReg5, setValReg5] = useState(true)
    const [valReg6, setValReg6] = useState(true)
    const [valReg7, setValReg7] = useState(true)
    const [valReg8, setValReg8] = useState(true)
    const [valOtp, setValOtp] = useState(true)
    const [valOtpSuccess, setValOtpSuccess] = useState(true)


    const formatDate = (data) =>{
        if(data<10){
          return `0${data}`
        }else{
          return data
        }
      }

    const handleSelectDate = (e) => {
        setRegBirthday(e)
        setBirthdayShown(`${e.getFullYear()}-${formatDate(e.getMonth()+1)}-${formatDate(e.getDate())}`)
        setShowCalendar(false)
    }

    const handleChildValue = (value) => {
        setChildValue((prevInputValues) => ({
            ...prevInputValues,
            [value[0]]: value[1],
        }));
        // console.log("show otp value 1 => "+JSON.stringify(value[0]))
        // console.log("show otp value 1 => "+JSON.stringify(value[1]))
      };
    const handleChildValueId = (value) => {
        setChildValueId(value);
        // console.log("show otp value 2 => "+JSON.stringify(value))
      };

      const handleChildIsValue = (value) => {
        setChildIsValue(value);
        // console.log("show otp value 3 => "+value)
        setValOtp(true)
      };

      const handleShowChildValue = () => {
        console.log("show otp value => "+JSON.stringify(childValue))
        // if(childValue.input1)
        // console.log("show otp value => "+childValue.length)
        // console.log("show otp value => "+childValue.input2)
        if(childValue.input1 !== "" && childValue.input2 !== "" && childValue.input3 !== "" && childValue.input4 !== ""){
            console.log("semua ada datanya")
            callAPIValidateOTP(`${childValue.input1}${childValue.input2}${childValue.input3}${childValue.input4}`)
        } else {
            console.log("ada yg kosong")
            setValOtp(false)
        }
    };

    const handleOpenPopUp = (e) => {
        if(e==="prefix"){
            setShowPrefix(true)
            setShowCalendar(false)
        }else{
            setShowCalendar(true)
            setShowPrefix(false)
        }
    }
    useEffect(() => {
        if(openRegister===true){
            setMode("register")
        }
    },[])
    const firstUpdate = useRef(true);
    useEffect(()=>{
        if (firstUpdate.current) {
            firstUpdate.current = false;
        } else {
            if(valReg6){
                var elem = document.getElementById("LGdown1")
                elem.style.transition = "transform 0.5s"
                if(showCalendar){
                    elem.style.transform = "rotate(180deg)"
                }else{
                    elem.style.transform = "rotate(0deg)"
                }
            }else{
                var elem2 = document.getElementById("LGdown2")
                elem2.style.transition = "transform 0.5s"
                if(showCalendar){
                    elem2.style.transform = "rotate(180deg)"
                }else{
                    elem2.style.transform = "rotate(0deg)"
                }
            }
        }
    },[showCalendar])

    const history = useNavigate()

    


    const login = e =>{
        e.preventDefault()
        let isError = false
        if(username.length===0){
            setValLog1(false)
            isError=true
        }else if(username.length!==0){
            if(!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(username))){
                setValLog1(false)
                isError=true
            }else{
                setValLog1(true)
            }
        }
        if(password.length===0){
            setValLog2(false)
            isError=true
        }else{
            setValLog2(true)
        }
        if(isError){
            return
        }
        callAPILogin()
    }

    const capitalize = (str) => {
        return str.replace(/\w\S*/g, function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    const callAPILogin = async () => {
        var axios = require('axios');
        var FormData = require('form-data');
        const formDataLogin = new FormData();
        formDataLogin.append("email", username)
        formDataLogin.append("password",password)
        axios({
            method: 'POST',
            // url: baseUrl + 'login',
            url: baseUrl + 'loginCustomer',
            data : formDataLogin,
            withCredentials:false
        })
        .then(function (response) {
            // console.log("batas")
            // console.log(response.data.access_token)
            localStorage.setItem('TaplakLoggedIn', 'true')
            localStorage.setItem('username',capitalize(response.data.user.name))
            localStorage.setItem('taplakToken',response.data.access_token)
            // window.location.reload()
            fungsi()
            // console.log(JSON.stringify(response.data));
        })
            .catch(function (error) {
            console.log(error);
            setValLog3(false)
        });
    }

    const callAPIRegister = async () => {
        var axios = require('axios');
        var FormData = require('form-data');
        const formDataRegister = new FormData();
        // console.log(prefix)
        // console.log(regPhoneNumber)
        // console.log(regEmail)
        // console.log(regPassword)
        // console.log(birthdayShown)
        // console.log(regFirstName)
        // console.log(regLastName)
        formDataRegister.append('phone_code', prefix);
        formDataRegister.append('phone', "0"+regPhoneNumber);
        formDataRegister.append('email', regEmail);
        formDataRegister.append('password', regPassword);
        formDataRegister.append('birth_date', birthdayShown);
        formDataRegister.append('first_name', regFirstName);
        formDataRegister.append('last_name',regLastName)
        axios({
            method: 'POST',
            url: baseUrl + 'registerStoreCustomer',
            data : formDataRegister,
            withCredentials:false
        })
            .then(function (response) {
            console.log("batas response regis => "+response.status)
            if(response.data.status){
                console.log("berhasil")
                setShowSuccessReg(true)
                setDataRegistrasi(response.data.data_registrasi)
            }else{
                console.log("gagal")
                setShowFailedReg(true)
            }
            // console.log(JSON.stringify(response.data));
        })
            .catch(function (error) {
            console.log(error);
        });
    }

    const handleClickRegist = () => {
        let isError=false

        if(regEmail.length===0){
            isError=true
            setValReg1(false)
        }else if(regEmail.length!==0){
            if(!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(regEmail))){
                isError=true
                setValReg1(false)
            }else{
                setValReg1(true)
            }
        }if(regFirstName.length===0){
            isError=true
            setValReg2(false)
        }else{
            setValReg2(true)
        }if(regLastName.length===0){
            isError=true
            setValReg3(false)
        }else{
            setValReg3(true)
        }if(prefix==="+.."){
            isError=true
            setValReg4(false)
        }else{
            setValReg4(true)
        }if(regPhoneNumber.length===0){
            isError=true
            setValReg5(false)
        }else{
            setValReg5(true)
        }if(regBirthday===""){
            isError=true
            setValReg6(false)
        }else{
            setValReg6(true)
        }if(regPassword.length<8){
            isError=true
            setValReg7(false)
        }else{
            setValReg7(true)
        }if(confirmPassword.length<8){
            isError=true
            setValReg8(false)
        }else{
            setValReg8(true)
        }if(regPassword!==confirmPassword){
            isError=true
            setValReg8(false)
        }

        if(isError){
            return
        }
        callAPIRegister()
      };

    // useEffect(() => {
    //     window.addEventListener("touchstart", handleClickRegist);
        
    //     return () => {
    //         window.removeEventListener("touchstart", handleClickRegist);
    //     };
    // }, [handleClickRegist]);

    const callAPIValidateOTP = async (otpValue) => {
        let axios = require('axios');
        // let FormData = require('form-data');
        // const formDataRegister = new FormData();
        axios({
            method: 'get',
            url: baseUrl + 'validasiOtp/'+otpValue,
            withCredentials:false
        })
            .then(function (response) {
            // console.log("batas")
            if(response.data.status_code == 200){
                console.log("berhasil")
                setUserId(response.data.data.id)
                setShowSuccessSendEmail(false)
                // callAPIActivationUser()
            }else{
                setValOtpSuccess(false)
                console.log("gagal")
            }
            // console.log(JSON.stringify(response.data));
        })
            .catch(function (error) {
            console.log(error);
        });
    }

    const callAPIActivationUser = async () => {
        let axios = require('axios');
        let FormData = require('form-data');
        const formDataRegister = new FormData();
        formDataRegister.append('email', dataRegistrasi);
        axios({
            method: 'POST',
            url: baseUrl + 'activation_user',
            data : formDataRegister,
            withCredentials:false
        })
            .then(function (response) {
            // console.log("batas")
            if(response.data.status){
                console.log("berhasil")
                fungsi()
            }else{
                console.log("gagal")
            }
            // console.log(JSON.stringify(response.data));
        })
            .catch(function (error) {
            console.log(error);
        });
    }

    const register = e => {
        e.preventDefault()
        let isError=false

        if(regEmail.length===0){
            isError=true
            setValReg1(false)
        }else if(regEmail.length!==0){
            if(!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(regEmail))){
                isError=true
                setValReg1(false)
            }else{
                setValReg1(true)
            }
        }if(regFirstName.length===0){
            isError=true
            setValReg2(false)
        }else{
            setValReg2(true)
        }if(regLastName.length===0){
            isError=true
            setValReg3(false)
        }else{
            setValReg3(true)
        }if(prefix==="+.."){
            isError=true
            setValReg4(false)
        }else{
            setValReg4(true)
        }if(regPhoneNumber.length===0){
            isError=true
            setValReg5(false)
        }else{
            setValReg5(true)
        }if(regBirthday===""){
            isError=true
            setValReg6(false)
        }else{
            setValReg6(true)
        }if(regPassword.length<8){
            isError=true
            setValReg7(false)
        }else{
            setValReg7(true)
        }if(confirmPassword.length<8){
            isError=true
            setValReg8(false)
        }else{
            setValReg8(true)
        }if(regPassword!==confirmPassword){
            isError=true
            setValReg8(false)
        }

        if(isError){
            return
        }
        callAPIRegister()
        
    }

    const validatePhoneNumber = (value) => {
        // If the input starts with 0, reject the change
        if (value.startsWith('0')) {
          return false;
        }
        // Otherwise, accept the change
        return true;
      };
    
      const handlePhoneNumberChange = (event) => {
        const newValue = event.target.value;
    
        // Check if the new value is valid
        if (validatePhoneNumber(newValue)) {
          setPhoneNumber(newValue);
        }
        // If not valid, you can choose to show an error message or take other actions
      };

    const handleLoginAsChef = () => {
        window.open("https://chef.taplak.id/login", "_blank", "noreferrer");
    }

    const handleRegisterAsChef = () => {
        window.open("https://chef.taplak.id/register", "_blank", "noreferrer");
    }

    const handleClickNewPassword = () => {
        let isError=false

        if(regPassword.length<8){
            isError=true
            setValReg7(false)
        }else{
            setValReg7(true)
        }if(confirmPassword.length<8){
            isError=true
            setValReg8(false)
        }else{
            setValReg8(true)
        }if(regPassword!==confirmPassword){
            isError=true
            setValReg8(false)
        }

        if(isError){
            return
        }
        callAPIUpdatePassword()
      };

      const callAPIUpdatePassword = async () => {
        let axios = require('axios');
        let FormData = require('form-data');
        const formDataRegister = new FormData();
        formDataRegister.append('user_id', userId);
        formDataRegister.append('password', regPassword);
        axios({
            method: 'POST',
            url: baseUrl + 'updatePassword',
            data : formDataRegister,
            withCredentials:false
        })
            .then(function (response) {
            // console.log("batas")
            if(response.data.status_code == 200){
                console.log("berhasil")
                setSuccessUpdatePassword(true)
            }else{
                console.log("gagal")
            }
            // console.log(JSON.stringify(response.data));
        })
            .catch(function (error) {
            console.log(error);
        });
    }
    
    return (
        <div>
            <div onClick={fungsi} className='LoginKontainer'></div>
            {
                showSuccessSendEmail ?
                <div className='LoginKonten FPOTP OTPslideUP'>
                    <div className='LGafterreg'>
                        {/* <p className='bold semibig' style={{textAlign:"center"}}>Thank You</p> */}
                        <img alt="icon" className='LGemail' src={email}></img>
                        <p className='normal LGnotifreg FPOTP'>OTP has been sent successfully. Please kindly check your email or Whatsapp to get the OTP.</p>
                        <p className='normal LGnotifreg2 FPOTP'>Please confirm the OTP below.</p>
                        <div className='OTPInputWrap'>
                            <OTPInputGroup onChildValue={(e) =>handleChildValue(e)} onChildIsValue={handleChildIsValue} onChildValueId={handleChildValueId}/>
                        </div>
                        {
                            !valOtp && 
                                (
                                    <p className='bold verytiny popOut' style={{color:"#FA591D", marginTop:"-4px", marginBottom:"8px", textAlign:"center"}}>Please enter otp correctly</p>
                                )
                            
                        }
                        {
                            !valOtpSuccess && 
                                (
                                    <p className='bold verytiny popOut' style={{color:"#FA591D", marginTop:"-4px", marginBottom:"8px", textAlign:"center"}}>Data not found, Please check your OTP properly</p>
                                )
                            
                        }
                        {
                            childIsValue ? 
                            <button onClick={handleShowChildValue} className='LGbuttonok FPOTP small bold'>OK</button>
                            :
                            <button onClick={handleShowChildValue} className='LGbuttonok FPOTP disabled small bold' disabled>OK</button>
                        }
                        
                    </div>
                </div>
                :
                successUpdatePassword ? 
                <div className='LoginKonten FPOTP OTPslideUP2'>
                    <div className='LGafterreg'>
                        <p className='bold semibig' style={{textAlign:"center"}}>Password updated successfully</p>
                        {
                        
                            <button onClick={fungsi} className='LGbuttonok small bold'>OK</button>
                        }
                    </div>
                </div>
                :
                <div className='LoginKonten FPOTP OTPslideUP3'>
                    <div className='LGafterreg'>
                        <p className='bold semibig' style={{textAlign:"center"}}>OTP is available</p>
                        {/* <p className='normal LGnotifreg'>We have received your registration. Please kindly check your email to get the OTP.</p> */}
                        <p className='normal LGnotifreg FPOTP'>Please input your new passwod</p>
                        {
                            valReg7?
                            <input id='Reg7' type="password" value={regPassword} onChange={e => setRegPassword(e.target.value)} className='LGform' placeholder='Password'></input>
                            :
                            <input id='Reg7' type="password" value={regPassword} onChange={e => setRegPassword(e.target.value)} className='LGform LGerror' placeholder='Password'></input>
                        }
                        
                        {
                            !valReg7 && (
                                <p className='bold verytiny popOut' style={{color:"#FA591D", marginTop:"-4px", marginBottom:"8px", textAlign:"left"}}>Please input your password with minimum 8 character!</p>
                            )
                        }

                        {
                            valReg8?
                            <input id='Reg8' type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} className='LGform' placeholder='Confirm Password'></input>
                            :
                            <input id='Reg8' type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} className='LGform LGerror' placeholder='Confirm Password'></input>
                        }

                        {
                            !valReg8 && (
                                <p className='bold verytiny popOut' style={{color:"#FA591D", marginTop:"-4px", marginBottom:"8px", textAlign:"left"}}>Please input your confirmation password correctly!</p>
                            )
                        }
                        {
                            
                            <button onClick={handleClickNewPassword} className='LGbuttonok FPOTP small bold'>Submit Password</button>
                        }
                        
                    </div>
                </div>
            }
        </div>
    )
  }