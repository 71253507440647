/* eslint-disable no-dupe-keys */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import './index.css'
import { DesktopNavbar } from '../NavBar/Desktop/DesktopNavbar'
import {useState, useEffect, useRef} from 'react'
import "react-datepicker/dist/react-datepicker.css";
import calendar from '../../assets/calendar.svg'
import makanan1 from '../../assets/makanan1.png'
import eventimg from '../../assets/eventimg.png'
import { useLocation, useNavigate } from "react-router-dom";
import circleback from '../../assets/circleback.svg'
import down from '../../assets/down.svg'
import user from '../../assets/user.svg'
import plus from '../../assets/plus.svg'
import minus from '../../assets/minus.svg'
import "react-datepicker/dist/react-datepicker.css";
import { PageLogin } from '../LoginPage/PageLogin'
import { CSSTransition } from 'react-transition-group';
import spinner from '../../assets/spinner.gif'
import location from '../../assets/pin.svg'
import { baseUrl } from '../../lib/api';
import toRupiah from '@develoka/angka-rupiah-js';
import { FloatingWhatsAppCustom } from '../FloatingWhatsAppCustom/FloatingWhatsAppCustom';

export const PageSetEventSchedule = () => {
    const [detail,setDetail] = useState()
    const [guest, setGuest] = useState(1)
    const history = useNavigate()
    const { state }  = useLocation()
    const [total, setTotal] = useState(0)
    const [showCalendar, setShowCalendar] = useState(false)
    const [selectedDate, setSelectedDate] = useState()
    const [isLogged, setIsLogged] = useState()
    const [remainingSeat, setRemainingSeat] = useState(0)
    const [selectedVoucher, setSelectedVoucher] = useState(0)
    const [dateId, setDateId] = useState()
    const [dateShown, setDateShown] = useState('Select date & time')
    const [selectedYMDDate, setSelectedYMDDate] = useState('')
    const [selectedStartTime, setSelectedStartTime] = useState('')
    const [selectedEndTime, setSelectedEndTime] = useState('')
    const [databooking, setDatabooking] = useState()
    const [clockSelected, setClockSelected] = useState('')
    const [showLogin, setShowLogin] = useState(false)
    const [specialRequest, setSpecialRequest] = useState('')
    const [val1, setval1] = useState(false)
    const [loading, setLoading] = useState(true)
    const firstUpdate2 = useRef(true);
    const firstUpdate = useRef(true);
    const firstUpdate5 = useRef(true);
    const [voucher, setVoucher] = useState()
    const [voucherAmount, setVoucherAmount] = useState(0) 
    const [totalAfter, setTotalAfter] = useState()
    const [showNavPopup, setShowNavPopup] = useState(false)

    const handleSelectDate = (e) => {
        // console.log("remaining seats => "+e[4])
        setSelectedDate(e[0])
        setClockSelected(e[1])
        var elem = document.getElementById("PSSdateshown");
        var elem2 = document.getElementById("PSSdate");
        elem.className = "tiny normal PSSpertanyaantext black"
        setDateShown(`${e[0]}  |   ${e[1]}`)
        setDateId(e[2])
        setGuest(1)
        setRemainingSeat(e[4])
        elem2.className = "PSSpertanyaan flex";
        setval1(false)
        setShowCalendar(false)
        setSelectedYMDDate(e[3])
        setSelectedStartTime(e[1].slice(0,5))
        setSelectedEndTime(e[1].slice(8,13))
    }

    const formatDate = (data) =>{
            if(data<10){
          return `0${data}`
        }else{
          return data
        }
    }

    const handleGuest = (e) => {
        var elem = document.getElementById("PSSguestshown");
        var elem2 = document.getElementById("PSSdate");
        elem.className = "tiny normal PSSpertanyaantext black"
        if(e === "minus"){
            if(guest === 1){
                return
            }else{
                setGuest(guest-1)
            }
        }else{
            if(dateShown === 'Select date & time'){
                elem2.className = "PSSpertanyaan flex PSSred";
                setval1(true)
            } else {
                if(guest === remainingSeat){
                    return
                }else{
                    setGuest(guest+1)
                }
            }
        }
    }

    useEffect(() =>{
        const { detail } = state
        window.scrollTo(0, 0)
        setLoading(true)
        setIsLogged(localStorage.getItem('TaplakLoggedIn'))
        const scheduleEventData = () => {
            try {
                const parsedEventData = parseScheduleEvent([detail])
                
                setDetail(parsedEventData)
            } catch (error) {
                console.log("Error Happened s")
                console.log(error)
            }
        }

        scheduleEventData()
        
        // setDetail({nama:"Thanksgiving",foto:eventimg, style:"American style featuring Turkey and Sides",fotoMakanan:makanan1,menu:["Beef bulgogi", "Yaki gyoza"],pricelist:[{min:1,max:4,harga:200},{min:5,max:6,harga:150}], jadwal:[{tanggal:"24 Feb 2022",jam:"18:00 - 19:00"},{tanggal:"24 Feb 2022",jam:"19:00 - 20:00"}, {tanggal:"27 Feb 2022",jam:"18:00 - 19:00"}],tempat:"Cilandak Town Square, Jalan Tahi Bonar Simatupang, RT.6/RW.9, West Cilandak, South Jakarta City, Jakarta, Indonesia" })
        localStorage.setItem('nav', 'e')
    },[])

    useEffect(() => {
        if (firstUpdate2.current) {
            firstUpdate2.current = false;
        }else{
            setLoading(false)
            setTotal(detail.pricelist[0].harga)
            // setTotal(detail.pricelist[0].harga*1000)
        }
    },[detail])

    useEffect(() => {
        console.log(total)
        setTotalAfter(total)
        console.log(totalAfter)
    },[total])

    useEffect(() => {
        console.log(voucherAmount)
    },[voucherAmount])

    useEffect(() => {
        const discount = (total*voucherAmount)/100
        // console.log("discount nyas")
        // console.log(discount)
        // console.log(total)
        // console.log(voucherAmount)
        if(voucherAmount === 0){
            console.log("voucher kosong")
            setTotalAfter(total)
        } else {
            setTotalAfter(total-discount)
        }
        // console.log(totalAfter)
    },[()=>handleAddVoucher()])


    useEffect(()=>{
        if (firstUpdate.current) {
            firstUpdate.current = false;
        }else{
            var elem = document.getElementById("PSSdate");
            var elem2 = document.getElementById("PSSdatedown");
            elem2.style.transition = "transform 0.5s"
            if(showCalendar===true){
                elem.className = "PSSpertanyaan flex PSSyellow";
                elem2.style.transform = "rotate(180deg)"
            }else{
                elem.className = "PSSpertanyaan flex";
                elem2.style.transform = "rotate(0)"
            }
        }
    },[showCalendar])

    const handleOpenPopUp = (e) => {
        if(e === "date"){
            setShowCalendar(true)
        }
    }

    const convertToRupiah = (angka) => {
        var rupiah = '';		
        var angkarev = angka.toString().split('').reverse().join('');
        for(var i = 0; i < angkarev.length; i++) if(i%3 === 0) rupiah += angkarev.substr(i,3)+'.';
        return rupiah.split('',rupiah.length-1).reverse().join('');
    }

    useEffect(()=>{
        if (firstUpdate5.current) {
            firstUpdate5.current = false;
        }else{
            for (var x in detail.pricelist){
                if(guest <= remainingSeat && guest >= detail.pricelist[x].min){
                    // setTotal(detail.pricelist[x].harga * 1000 * guest)
                    setTotal(detail.pricelist[x].harga * guest)
                }
            }
        }
    },[guest])

    const handleSubmit = () => {
        // callAPILogin()
        // console.log("date ID => ")
        // console.log(dateId)

        let isError=false
        var elem = document.getElementById("PSSdate");
        if(dateShown === 'Select date & time'){
            elem.className = "PSSpertanyaan flex PSSred";
            isError=true
            setval1(true)
        }else{
            elem.className = "PSSpertanyaan flex";
            setval1(false)
        }

        if(isError){
            return
        }else{
            // console.log("data submit")
            // console.log(total)
            // console.log(guest)
            // console.log(detail.voucher[0].id)
            localStorage.setItem('tanggalEvent',dateShown)
            localStorage.setItem('jam',clockSelected)
            localStorage.setItem('specialRequest', specialRequest)
            localStorage.setItem('pax', guest)
            localStorage.setItem('total',total)
            localStorage.setItem('totalAfter',totalAfter)
            localStorage.setItem('venueLocation',detail.tempat)
            // callAPIbookingEvent()
            callAPIbookingEventV2()
            // callAPISetEventSchedule()
            // setstandby(false)
        }
    }

    const callAPIbookingEvent = async () => {
        var axios = require('axios');
        var FormData = require('form-data');
        const formDataLogin = new FormData();
        formDataLogin.append("event_id", detail.id)
        formDataLogin.append("id_event_date",dateId)
        axios({
            method: 'POST',
            url: baseUrl + 'bookingEvent',
            data : formDataLogin,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('taplakToken')}` 
            },
            withCredentials:false
        })
        .then(function (response) {
            
            // console.log("response set book")
            // console.log(response.data.status)
            if(response.data.status === true){
                console.log("Berhasil")
                setstandby(false)
            } 
            // console.log(response.data.access_token)
            // localStorage.setItem('TaplakLoggedIn', 'true')
            // localStorage.setItem('username',capitalize(response.data.user.name))
            // localStorage.setItem('taplakToken',response.data.access_token)
            // window.location.reload()
            // fungsi()
            // console.log(JSON.stringify(response.data));
        })
            .catch(function (error) {
            console.log(error);
            // setValLog3(false)
        });
    }

    const callAPIbookingEventV2 = async () => {
        var axios = require('axios');
        var FormData = require('form-data');
        const formDataLogin = new FormData();
        formDataLogin.append("event_id", detail.id)
        formDataLogin.append("id_event_date",dateId)
        formDataLogin.append("total_amount",total)
        formDataLogin.append("guest",guest)
        formDataLogin.append("voucher_id",selectedVoucher === 0 ? "":selectedVoucher.id)
        axios({
            method: 'POST',
            url: baseUrl + 'bookingEvent',
            data : formDataLogin,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('taplakToken')}` 
            },
            withCredentials:false
        })
        .then(function (response) {
            
            // console.log("response set book")
            // console.log(response.data.status)
            if(response.data.status === true){
                console.log("Berhasil")
                // console.log(response.data)
                setDatabooking(response.data)
                // console.log(databooking)
                
                setstandby(false)
                // openModal()
            } else {
                alert(response.data.message);
                // setIsLogged("false")
                // callApiLogout()
            }
            // console.log(response.data.access_token)
            // localStorage.setItem('TaplakLoggedIn', 'true')
            // localStorage.setItem('username',capitalize(response.data.user.name))
            // localStorage.setItem('taplakToken',response.data.access_token)
            // window.location.reload()
            // fungsi()
            // console.log(JSON.stringify(response.data));
        })
            .catch(function (error) {
            console.log(error);
            alert("Session Login anda sudah Expired, Silahkan Login ulang");
            setIsLogged("false")
            callApiLogout()
            // setValLog3(false)
        });
    }

    const callApiLogout = async () => {
        // var axios = require('axios');
        // axios({
        //   method: 'POST',
        //   url: baseUrl + 'logoutCustomer',
        //   withCredentials:false,
        //   headers: {
        //     'Authorization': 'Bearer ' + localStorage.getItem('taplakToken')
        //   }
        // })
        // .then(function (response) {
            console.log("batasssss")
            // console.log(JSON.stringify(response.data));
            localStorage.setItem('TaplakLoggedIn', 'false');
            localStorage.removeItem("taplakToken")
            localStorage.removeItem("username")
            // window.history.back();
            // window.location.reload();
        // })
        //     .catch(function (error) {
        //     console.log(error);
        // });
      }

    const callAPISetEventSchedule = async () => {
        // var axios = require('axios');
        // var FormData = require('form-data');
        // const formDataLogin = new FormData();
        // formDataLogin.append("date_events", selectedYMDDate)
        // formDataLogin.append("start_time",selectedStartTime)
        // formDataLogin.append("end_time",selectedEndTime)
        // formDataLogin.append("max_seat",20)
        // axios({
        //     method: 'POST',
        //     url: baseUrl + 'scheduleEvent/' + detail.id,
        //     data : formDataLogin,
        //     headers: {
        //         'Authorization': `Bearer ${localStorage.getItem('taplakToken')}` 
        //     },
        //     withCredentials:false
        // })
        // .then(function (response) {
            
        //     console.log("response set book")
        //     console.log(response.data.status)
        //     if(response.data.status === true){
        //         console.log("Berhasil")
                setstandby(false)
        //     } 
            // console.log(response.data.access_token)
            // localStorage.setItem('TaplakLoggedIn', 'true')
            // localStorage.setItem('username',capitalize(response.data.user.name))
            // localStorage.setItem('taplakToken',response.data.access_token)
            // window.location.reload()
            // fungsi()
            // console.log(JSON.stringify(response.data));
        // })
        //     .catch(function (error) {
        //     console.log(error);
        //     console.log("Gagal cok")
        //     // setValLog3(false)
        // });
    }

    const [standby, setstandby] = useState(true)
    useEffect(()=>{
        
        if(standby===false){
            // console.log("data voucher>")
            // console.log(detail.foto)
            // console.log(databooking)
            // console.log(databooking.dataBooking.payment_link)
            // console.log(selectedVoucher)
            setTimeout(function() {
                history('/event/detailEvent/setSchedule/submitBooking', { state: { eventId : detail.id,  dateId: dateId, totalAmount: total,guest:guest, voucher: selectedVoucher === 0 ? "None":selectedVoucher, databooking: databooking, fotoEvent:detail.foto} });
           }, 500);
        }
    },[standby])

    const handleCloseAll = () => {
        setShowCalendar(false)
        setShowNavPopup(false)
    }

    // Helpers
    const parseTanggal = (datetime) => {
        const [year, month, date] = datetime.split("-")
        
        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ]
        return `${date} ${months[month-1].slice(0, 3)} ${year}`
    }

    const parseJam = (time) => {
        const [hour, minute, _] = time.split(":")
        const parsedJam = hour.concat(":", minute)
        return parsedJam
    }

    const handleAddVoucher = (voucherCode,detail) => {
        // console.log("ini code vouchernya")
        // console.log(voucherCode)
        // console.log(detail)

        if(detail.voucher.length !== 0){
            // console.log("ada vouchernya")
            detail.voucher.forEach(element => {
                // console.log(element.name)
                if(voucherCode === element.name){
                    setVoucherAmount(detail.voucher[0].discount)
                    const discount = (total*voucherAmount)/100
                    setTotalAfter(total-discount)
                    const detailSelectedVoucher = {
                        id: element.id,
                        name: element.name,
                        discount: element.discount,
                        quota: element.quota
                    }
                    setSelectedVoucher(detailSelectedVoucher)
                } else {
                    setVoucherAmount(0)
                    setTotalAfter(total)
                    setSelectedVoucher(0)
                }
            });
            // if(voucherCode === )
            // setVoucherAmount(detail.voucher[0].discount)
            // const discount = (total*voucherAmount)/100
            // setTotalAfter(total-discount)
            // console.log("hasil nya")
            // console.log(total)
            // console.log(discount)
            // console.log(voucherAmount)
            // console.log(totalAfter)
        }
    }


    const parseJadwal = (jadwal) => {
        jadwal = jadwal.map(el => {
            return {
                "id": el["id"],
                "tanggal": parseTanggal(el["date_events"]),
                "remaining_seats": el["remaining_seats"],
                "max_seat": el["max_seat"],
                "tanggalymd":el["date_events"],
                "jam": `${parseJam(el["start_time"])} - ${parseJam(el["end_time"])}`
            }
        })
        return jadwal
    }

    const parseVoucher = (voucher) => {
        if(voucher === "N/A") {
            return "N/A"
        }

        voucher = voucher.map(el => {
            return {
                id: el["id"],
                name: el["name"],
                discount: el["discount"],
                quota: el["quota"]
            }
        })

        return voucher
    }

    const parseScheduleEvent = (scheduleEventData) => {
        scheduleEventData =  scheduleEventData.map(el => {
            return {
                id: el["id"],
                nama: el["judul"],
                foto: el["gambar"],
                style: el["style"],
                fotoMakanan: el["menu"][0]["foto"],
                menu: el["menu"].map(el => el["nama"]),
                menuDetail: parseMenuDetail(el["menu"]),
                // pricelist: [{min:1,max:4,harga:200},{min:5,max:6,harga:150}],
                pricelist: [{min:1,max:4,harga:el["harga"][0]["amount"].substring(0, el["harga"][0]["amount"].length-3)}],
                voucher: parseVoucher(el["voucher"]),
                jadwal: parseJadwal(el["tanggal"]),
                tempat: el["tempat"]
            }
        })
        return scheduleEventData[0]
    } 

    const parseMenuDetail = (menu) => {
        menu = menu.map(el => {
            return {
                "foto": el["foto"],
                "nama": el["nama"],
            }
        })

        return menu
    }

    return (
        <div onClick={e => {e.stopPropagation();handleCloseAll()}} id="maincontainer" style={{minWidth:"100%", minHeight:"100vh", backgroundColor:"#111111", position:"relative", overflow:"unset"}}>
            <DesktopNavbar showPopup={showNavPopup} setShowPopup={setShowNavPopup}></DesktopNavbar>
            <FloatingWhatsAppCustom></FloatingWhatsAppCustom>
            <img onClick={e => {e.preventDefault();e.stopPropagation();history(-1)}} className='backmobile' alt="back" src={circleback}></img>
            {
                loading?
                <img alt='loading' className='taplakLoading' src={spinner}></img>
                :
                <CSSTransition in={standby} timeout={{appear:1000,enter:2000,exit:1000}} mountOnEnter={true} appear={true} classNames="PSStrans">
                <div className='PSScontainer'>
                    <p className='big bold white PSStitle'>Set Schedule</p>
                    <div className='flex PSSmainwrapper'>
                        <div className='PSSkontenkiri'>   
                            <p className='semibig white PSSmargin'>Event</p>
                            <div className='flex' style={{marginTop:"18px"}}>
                                <img 
                                    src={`${detail.foto}`}
                                    alt="new"
                                    className="PSSimg"
                                />
                                {/* <div className='PSSimg' style={{backgroundImage:`url(${detail.foto})`}}></div> */}
                                <div className='PSSimg2'>
                                <p className='normal medium white'>{detail.nama}</p>
                                <p className='small grey' style={{marginTop:"4px"}}>{detail.style}</p>
                                </div>
                            </div>

                            <p className="semibig white PSSmargin">Menu</p>
                            {
                                detail.menuDetail.map((post) => {
                                    return (
                                            <div className='flex' style={{marginTop:"18px"}}>
                                            <img 
                                                src={`${post.foto}`}
                                                alt="new"
                                                className="PSSimg"
                                            />
                                            {/* <div className='PSSimg' style={{backgroundImage:`url(${detail.fotoMakanan})`}}></div> */}
                                            <div className='PSSimg2 flex' style={{flexDirection:"column"}}>
                                                <p className='normal medium white'>{
                                                        <span>{post.nama}</span>
                                                    }</p>
                                            </div>
                                        </div>
                                    )
                                })
                                
                            }
                            {/* <div className='flex' style={{marginTop:"18px"}}>
                                <img 
                                    src={`${detail.fotoMakanan}`}
                                    alt="new"
                                    className="PSSimg"
                                />
                                <div className='PSSimg2 flex' style={{flexDirection:"column"}}>
                                    <p className='normal medium white'>{
                                            detail.menu.map((post) => {
                                                if(post === detail.menu[0]){
                                                    return(<span>{post}</span>)

                                                }else{
                                                    return(<span>, {post}</span>)
                                                }
                                            })
                                        }</p>
                                </div>
                            </div> */}

                            <p className="semibig white PSSmargin">Special Request</p>
                            <p className="tiny" style={{color:"#AAAAAA", marginTop:"18px"}}>*Any requests made will be reviewed by the Taplak Partner before confirming the event</p>
                            <textarea value={specialRequest} onChange={e => setSpecialRequest(e.target.value)} className='PSStextarea' rows="10" placeholder="*Any requests made will be reviewed by the Taplak Partner before confirming the event"></textarea>
                        </div>
                        
                        <div className='PSSkontenkanan'>
                            <div className='PSSmargin PSSkananwrapper1'>
                                <div className='PSSformkontainer'>
                                    <p className='medium bold'>Your Booking</p>
                                    <div className='PSSformgroup'>
                                        <div className='PSSpertanyaanflex'>
                                            <p className='small normal PSSpertanyaantext'>Location</p>
                                            <div className='flex' style={{marginTop:"12px"}}>
                                                <img alt='icon' style={{marginTop:"2px"}} src={location} className="PSSiconkiri"></img>
                                                <p className='small'>{detail.tempat}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='PSSformgroup flex'>
                                        <div className='PSSpertanyaanflex'>
                                            <p className='small normal PSSpertanyaantext'>Date & Time</p>
                                            <div onClick={e => {e.preventDefault();e.stopPropagation();showCalendar? setShowCalendar(false):handleOpenPopUp("date")}} id="PSSdate" className='PSSpertanyaan flex'>
                                                <img className='PSSiconkiri' alt='icon' src={calendar}></img>
                                                <p id="PSSdateshown" className='verytiny normal PSSpertanyaantext'>{dateShown}</p>
                                                <img id="PSSdatedown" className='PSSiconkanan' alt='icon' src={down}></img>
                                            </div>
                                        </div>
                                        {
                                        showCalendar === true && (
                                            <div className="PSSpopupkontainer2">
                                                <div style={{postion:"relative", height:"100%", width:"-webkit-fill-available",width: "-moz-available", padding:"25px"}}>
                                                    {
                                                        detail.jadwal.map((post) => (
                                                            <p onClick={e => {e.preventDefault();e.stopPropagation();handleSelectDate([post.tanggal,post.jam,post.id,post.tanggalymd,post.remaining_seats])}} className='tiny PSSopsi'>{post.tanggal} &ensp; • &ensp;  {post.jam}</p>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        )
                                        }
                                        
                                    </div>
                                    {
                                        val1 && (
                                            <p className='bold verytiny' style={{color:"#FA591D", marginTop:"8px", marginBottom:"8px", textAlign:"right"}}>Please select the date & time!</p>
                                        )
                                    }

                                    

                                    <div className='PSSformgroup'>
                                        <div className='PSSpertanyaanflex'>
                                            <p className='small normal PSSpertanyaantext'>Guest</p>
                                            <div className='PSSpertanyaan flex'>
                                                <img className='PSSiconkiri' alt='icon' src={user}></img>
                                                <p id="PSSguestshown" className='verytiny normal PSSpertanyaantext'>{guest} guest</p>
                                                <img onClick={e => {e.preventDefault();e.stopPropagation();handleCloseAll();handleGuest("minus")}} className='PSSiconkanan' alt='icon' src={minus}></img>
                                                <img onClick={e => {e.preventDefault();e.stopPropagation();handleCloseAll();handleGuest("plus")}} className='PSSiconkanan' alt='icon' style={{marginLeft:"24px"}} src={plus}></img>
                                            </div>
                                        </div>
                                    </div>

                                    <p className='small SBPmargin grey' style={{marginTop:"26px"}}>Voucher Code</p>
                                    <div className='SBPmargin2 flex'>
                                        <input type='text' value={voucher} onChange={e => setVoucher(e.target.value)} placeholder='Enter voucher code' className='SBPform'></input>
                                        <button onClick={() => handleAddVoucher(voucher,detail)}className='SBPbutton tiny bold'>Apply</button>
                                    </div>

                                    <p className='PSSpertanyaantext tiny normal' style={{marginTop:"16px"}}>Set menu price(s)</p>
                                    
                                        {
                                            detail.pricelist.map((post) => (
                                                <div className='flex' style={{marginTop:"8px"}}>
                                                    {/* <p className='PSSpertanyaantext tiny bold'>{post.min} - {post.max} guest</p> */}
                                                    <p className='PSSpertanyaantext tiny bold'>{post.min} guest</p>
                                                    <p className='PSSpertanyaantext tiny bold' style={{marginLeft:"auto"}}>{toRupiah(post.harga, {formal: false, symbol: 'IDR'}).slice(0, -3)}/Pax</p>
                                                </div>
                                            ))
                                        }
                                    <div className='flex' style={{marginTop:"32px"}}>
                                        <p className='small'>Price</p>
                                        {/* <p className='small' style={{marginLeft:"auto"}}>IDR {convertToRupiah(total)}</p> */}
                                        <p className='small' style={{marginLeft:"auto"}}>{toRupiah(total, {formal: false, symbol: 'IDR'}).slice(0, -3)}</p>
                                    </div>
                                    <div className='flex' style={{marginTop:"4px"}}>
                                        <p className='small'>Voucher Amount</p>
                                        <p className='small' style={{marginLeft:"auto"}}>{voucherAmount === 0 ? "-":voucherAmount+"%"}</p>
                                    </div>
                                    <div className='flex' style={{marginTop:"32px"}}>
                                        <p className='small'>Total</p>
                                        {/* <p className='small' style={{marginLeft:"auto"}}>IDR {convertToRupiah(total)}</p> */}
                                        <p className='small' style={{marginLeft:"auto"}}>{toRupiah(totalAfter,{formal: false, symbol: 'IDR'}).slice(0, -3)}</p>
                                    </div>
                                    
                                </div>
                                {
                                    localStorage.getItem('TaplakLoggedIn')==='true' ?
                                    // isLogged==='true' ?

                                <div className='PSSformkontainer' style={{marginTop:"32px"}}>
                                    <p className='medium bold'>Submit Your Booking</p>
                                    <button onClick={e => {e.preventDefault();e.stopPropagation();handleSubmit()}}  className='PSSbutton bold'>Submit Now</button>
                                </div>
                                :
                                <div className='PSSformkontainer' style={{marginTop:"32px"}}>
                                    <p className='medium bold'>Login or register to book</p>
                                    <button onClick={e => {e.preventDefault();e.stopPropagation();setShowLogin(true)}} className='PSSbutton bold'>Login/Register</button>
                                </div>
                                }
                            </div>
                            

                            
                        </div>
                    </div>
                </div>
                </CSSTransition>
            }
            {
                showLogin && (
                <PageLogin fungsi={() => setShowLogin(false)}></PageLogin>
                )
            }
            
        </div>
    )
  }