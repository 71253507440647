/* eslint-disable no-unused-vars */
import './index.css'
import { DesktopNavbar } from '../NavBar/Desktop/DesktopNavbar'
import {useState, useEffect, useRef} from 'react'
import "react-datepicker/dist/react-datepicker.css";
import close from '../../assets/close3.svg'
import { useNavigate } from "react-router-dom";
import downWhite from '../../assets/downwhite.svg'
import qrcode from '../../assets/qrcode.png'
import foto1 from '../../assets/foto4.png'
import { CSSTransition } from 'react-transition-group';
import { MobileNavbar } from '../NavBar/Mobile/MobileNavbar';
import spinner from '../../assets/spinner.gif'
import { MBPcard } from '../Card/MBPcard/MBPcard';
import { MBPChefcard } from '../Card/MBPChefcard/MBPChefcard';
import { baseUrl } from '../../lib/api';
import axios from 'axios';
import PaymentModal from '../Modal/PaymentModal';
import { FloatingWhatsAppCustom } from '../FloatingWhatsAppCustom/FloatingWhatsAppCustom';

export const PageMyBooking = () => {

    const [detail, setDetail] = useState()
    const [loading, setLoading] = useState(true)
    const [section, setSection] = useState(0)
    const [sectionChef, setSectionChef] = useState(0)
    const [bookingType, setBookingType] = useState(1)
    const [showQR, setShowQR] = useState(false)
    const [showHelp, setShowHelp] = useState(false)
    const [qr, setqr] = useState()
    const [chefname, setchefname] = useState()
    const [subject, setSubject] = useState('Select Subject')
    const [xenditPaymentLink, setXenditPaymentLink] = useState('')
    const [showSubject, setShowSubject] = useState(false)
    const [details, setDetails] = useState('')
    const [detailChef, setDetailChef] = useState()
    const [ordernumber, setordernumber] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showRateUs, setShowRateUs] = useState(false)
    const [showStyle, setShowStyle] = useState(false)
    const [style, setStyle] = useState('Select serving style')
    const [rate1, setrate1] = useState()
    const [rate2, setrate2] = useState()
    const [rate3, setrate3] = useState()
    const [rate4, setrate4] = useState()
    const [rate5, setrate5] = useState()
    const [rate6, setrate6] = useState()
    const [rate7, setrate7] = useState()
    const [review, setReview] = useState('')
    const [showOpsi, setShowOpsi] = useState()
    const [showNavPopup, setShowNavPopup] = useState(false)

    useEffect(() =>{
        window.scrollTo(0, 0)
        setLoading(true)
        console.log("isonawdoi => "+localStorage.getItem('isFromBookingChef'))
        setBookingType(localStorage.getItem('isFromBookingChef') ? localStorage.getItem('isFromBookingChef') === "true" ? 2:2:1)
        setStyle(localStorage.getItem('isFromBookingChef') ? localStorage.getItem('isFromBookingChef') === "true" ? "Chef Booking":"Chef Booking":"Event Booking")
        const myBookingFromDB = async () => {
            try{
                const data = await callApigetMyBooking()

                console.log("show all mybook")
                console.log(data)
                setDetail(data)
            } catch (error){
                console.log("Error categoryChefFromDB")
            }
        }

        const mybookingChefFromDb = async () => {
            try{
                const data = await callApigetMyBookingChef()
                // console.log("show all mybook Chef")
                // console.log(data)
                setDetailChef(data)
            }catch (error){
                console.log("Error bookingChefFromDB")
                console.log(error)
            }
        }
        myBookingFromDB()
        mybookingChefFromDb()
        // setDetail([
        // {ordernumber:1,qr:qrcode,done:false,nama:"Zidane Dane",bookingCode:"aezakmi1",status:"In Review",prefix:"+62",phoneNumber:"85673869402",namaChef:"Fauzan Pradana", foto:foto1,Type:"Asian",tanggal:"24-02-2022", total:"200000",address:"Apartement kuningan place, Jl. H Kavling No.15, RW.1, Menteng Atas, Kecamatan Setiabudi, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12980"},
        // {ordernumber:2,qr:qrcode,done:false,nama:"Zidane Dane",bookingCode:"aezakmi2",status:"Approve",prefix:"+62",phoneNumber:"85673869402",namaChef:"Fauzan Pradana", foto:foto1,Type:"Asian",tanggal:"24-02-2022", total:"200000",address:"Apartement kuningan place, Jl. H Kavling No.15, RW.1, Menteng Atas, Kecamatan Setiabudi, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12980"},
        // {ordernumber:3,qr:qrcode,done:false,nama:"Zidane Dane",bookingCode:"aezakmi3",status:"Rejected",prefix:"+62",phoneNumber:"85673869402",namaChef:"Fauzan Pradana", foto:foto1,Type:"Asian",tanggal:"24-02-2022", total:"200000",address:"Apartement kuningan place, Jl. H Kavling No.15, RW.1, Menteng Atas, Kecamatan Setiabudi, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12980"},
        // {ordernumber:4,qr:qrcode,done:false,nama:"Zidane Dane",bookingCode:"aezakmi3",status:"Success",prefix:"+62",phoneNumber:"85673869402",namaChef:"Fauzan Pradana", foto:foto1,Type:"Asian",tanggal:"24-02-2022", total:"200000",address:"Apartement kuningan place, Jl. H Kavling No.15, RW.1, Menteng Atas, Kecamatan Setiabudi, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12980"},
        // {ordernumber:5,qr:qrcode,done:true,nama:"Zidane Dane",bookingCode:"aezakmi3",status:"Success",prefix:"+62",phoneNumber:"85673869402",namaChef:"Fauzan Pradana", foto:foto1,Type:"Asian",tanggal:"24-02-2022", total:"200000",address:"Apartement kuningan place, Jl. H Kavling No.15, RW.1, Menteng Atas, Kecamatan Setiabudi, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12980"}])
        
        localStorage.setItem('isFromBookingChef',false)
        localStorage.setItem('nav', 'mb')
    },[])
    const [standby, setstandby] = useState(true)
    const firstUpdate = useRef(true);
    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
        }else{
            setLoading(false)
        }
    },[detail])
    const history = useNavigate()

    //call api get all book
    const callApigetMyBooking = async () => {
        try {
            const {data:res  } = await axios({
                method:'get',
                // url: `${baseUrl}category/type/chef`,
                url: `${baseUrl}listEventBooking`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('taplakToken')}` 
                }
            })
            // console.log("get myboook")
            // console.log(res.data)
            const myBookData = res.data
            const parsedMybook = parseMybook(myBookData)
            return parsedMybook
        } catch (error) {
            alert("Session Login anda sudah Expired, Silahkan Login ulang");
            callApiLogout()
            // window.history.back();
            
            console.log(error)
            console.log("error getDetailChefFromDB")
        }
    }

    const callApiLogout = async () => {
        // var axios = require('axios');
        // axios({
        //   method: 'POST',
        //   url: baseUrl + 'logoutCustomer',
        //   withCredentials:false,
        //   headers: {
        //     'Authorization': 'Bearer ' + localStorage.getItem('taplakToken')
        //   }
        // })
        // .then(function (response) {
            // console.log("batasssss")
            // console.log(JSON.stringify(response.data));
            localStorage.setItem('TaplakLoggedIn', 'false');
            localStorage.removeItem("taplakToken")
            localStorage.removeItem("username")
            history("/")
            // window.location.reload();
        // })
        //     .catch(function (error) {
        //     console.log(error);
        // });
      }

    const filterBooking = (type) =>{
        setSection(type)
        callApigetMyBookingByFilter(type)
    }

    const filterBookingChef = (type) =>{
        setSectionChef(type)
        // callApigetMyBookingByFilter(type)
    }

    const filterBookingType = (type) =>{
        setBookingType(type)
        // callApigetMyBookingChef()
    }

    //call api get Booking Chef
    const callApigetMyBookingChef = async () => {
        try {
            const {data:res  } = await axios({
                method:'get',
                // url: `${baseUrl}category/type/chef`,
                url: `${baseUrl}booking`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('taplakToken')}` 
                }
            })
            // console.log("get myboook")
            // console.log(res.data)
            const myBookData = res.data
            const parsedMybook = parseMybookChef(myBookData)
            return parsedMybook 
        } catch (error) {
            console.log(error)
            console.log("error getDetailChefFromDB")
        }
    }

    const parseMybookChef = (myBookData) => {
        myBookData = myBookData.map((el) => {
            return {
                ordernumber:1,
                qr:"qr code",
                done:el.status,
                nama:el.users_booking.name,
                bookingCode: el.booking_code,
                status:el.payment_status,
                paymentLink: el.payment_link,
                prefix:"+62",
                phoneNumber:localStorage.getItem('phoneNumber'),
                // namaChef:el.chef, 
                namaChef:el.chef_booking.name, 
                special_request:el.special_request, 
                foto:el.chef_booking.user_image_url,
                // Type:"Asian",
                Type:"Private Chef",
                tanggal:helperFormatDate(el.booking_date), 
                total:el.product_amount,
                address:el.veneu ? el.veneu.address : ""}
        })
        return myBookData
    }

    //call api get all book by filter
    const callApigetMyBookingByFilter = async (type) => {
        try {
            const {data:res  } = await axios({
                method:'get',
                // url: `${baseUrl}category/type/chef`,
                url: `${baseUrl}listEventBooking?status_booking=`+type,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('taplakToken')}` 
                }
            })
            // console.log("get myboook")
            // console.log(res.data)
            const myBookData = res.data
            const parsedMybook = parseMybook(myBookData)
            setDetail(parsedMybook) 
        } catch (error) {
            console.log(error)
            console.log("error getDetailChefFromDB")
        }
    }

    const parseMybook = (myBookData) => {
        myBookData = myBookData.map((el) => {
            return {
                ordernumber:1,
                qr:el.qrcode,
                done:el.booking_status,
                nama:el.user_booking.name,
                bookingCode: el.booking_code,
                status:el.payment_status,
                paymentLink: el.payment_link,
                prefix:"+62",
                phoneNumber:localStorage.getItem('phoneNumber'),
                // namaChef:el.chef, 
                namaChef:el.event_name, 
                foto:el.cover_img_url,
                // Type:"Asian",
                Type:el.event_description,
                tanggal:helperFormatDate(el.date_events), 
                total:el.grand_total,
                address:el.vanue_location}
        })
        return myBookData
    }

    const helperFormatDate = (date) => {
        // console.log("sdsad => "+date)
        const monthList = [
            "Jan", "Feb", "Mar",
            "Apr", "Mei", "Jun",
            "Jul", "Agu", "Sep",
            "Okt", "Nov", "Des"
        ]

        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ]

        let dateFormat = ''
        if(date !== null){
            const [year, month, _] = date.split("-")
            dateFormat = `${_} ${months[month-1].slice(0, 3)} ${year}`
        } 

        return dateFormat
    }

    //helpers parse data book
    // const parseListMyBook = (dataBook) => {
    //     databook = dataBook.map((val) => {
    //         return {
    //             ordernumber:1,
    //             qr:qrcode,
    //             done:false,
    //             nama:"Zidane Dane",
    //             bookingCode:"aezakmi1",
    //             status:"In Review",
    //             prefix:"+62",
    //             phoneNumber:"85673869402",
    //             namaChef:"Fauzan Pradana", 
    //             foto:foto1,
    //             Type:"Asian",
    //             tanggal:"24-02-2022", 
    //             total:"200000",
    //             address:"Apartement kuningan place, Jl. H Kavling No.15, RW.1, Menteng Atas, Kecamatan Setiabudi, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12980"
    //         }
    //     })
    // }

    const handleRateUs = (e) => {
        // console.log("terpanggil")
        // console.log(e)
        setShowRateUs(true)
        setordernumber(e)
        setrate1()
        setrate2()
        setrate3()
        setrate4()
        setrate5()
        setrate6()
        setrate7()
        setReview('')
    }

    const submitRateUs = () => {
        var axios = require('axios');
        var totalRate =(parseInt(rate1)+parseInt(rate2)+parseInt(rate3)+parseInt(rate4)+parseInt(rate5)+parseInt(rate6)+parseInt(rate7))/7
        var FormData = require('form-data');
        const formDataRateUs = new FormData();
        formDataRateUs.append("rate", totalRate.toFixed(2))
        formDataRateUs.append("booking_code",ordernumber)
        axios({
            method: 'POST',
            url: baseUrl + 'rateBookingEvent',
            data : formDataRateUs,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('taplakToken')}` 
            },
            withCredentials:false
        })
        .then(function (response) {
            if(response.data.status === true){
                console.log("Berhasil Rate Us")
                setShowRateUs(false)
            } 
        
        }).catch(function (error) {
            console.log(error);
            console.log("Gagal cok")
            // setValLog3(false)
        });
    }

    const handleChooseSubject = () => {
        var elem = document.getElementById("MBPdown");
        var elem2 = document.getElementById("MBPsubject");
        elem.style.transition = "transform 0.5s"
        var defaultclassname = elem2.className
        if(showSubject){
            setShowSubject(false)
            elem.style.transform = "rotate(0deg)"
            elem2.className = defaultclassname.replace("MBPyellow","")
            
        }else{
            setShowSubject(true)
            elem.style.transform = "rotate(180deg)"
            elem2.className = defaultclassname + " MBPyellow"
        }
    }

    const handleSelectSubject = (e) => {
        var elem = document.getElementById("MBPsubject");
        setSubject(e)
        elem.className = "flex black tiny MBPsubject MBPmargin3"
    }

    const handleQRchef = (e) => {
        setShowQR(true)
        setchefname(e[0])
        setqr(e[1])
    }

    const handleHelp = (e) => {
        setShowHelp(true)
        setShowSubject(false)
        setDetails('')
        setSubject('Select Subject')
        var elem = document.getElementById("MBPsubject");
        elem.className = "flex grey verytiny MBPsubject MBPmargin3"
        // console.log(e)
    }

    const handleOpenPopUp = (e) => {
        setShowStyle(true)
    }

    const handleSelectStyle = (e) => {
        if(e === 1){
            setStyle("Event Booking")
        } else {
            setStyle("Chef Booking")
        }
        var elem = document.getElementById("PSSstyleshown");
        elem.className = "tiny normal PSSpertanyaantext"
        setBookingType(e)
        setShowStyle(false)
    }

    useEffect(()=>{
        
        if(standby===false){
            setTimeout(function() {
           }, 500);
        }
    },[standby])

    const handleCloseAll = () => {
        setShowOpsi()
        setShowNavPopup(false)
    }

    const handlePayment = (paymentLink) => {
        setXenditPaymentLink(paymentLink)
        openModal()
    }

    const handlePaymentChef = (paymentLink) => {
        window.open(paymentLink, "_blank", "noreferrer");
    }

    const openModal = () => {
        setIsModalOpen(true);
      };
    
      const closeModal = () => {
        setIsModalOpen(false);
      };
      

    
    
    return (
        <div  onClick={e => {e.stopPropagation();handleCloseAll()}} id="maincontainer" style={{minWidth:"100%", minHeight:"100vh", backgroundColor:"#111111", position:"relative", overflow:"unset"}}>
            <DesktopNavbar showPopup={showNavPopup} setShowPopup={setShowNavPopup}></DesktopNavbar>
            <MobileNavbar></MobileNavbar>
            <FloatingWhatsAppCustom></FloatingWhatsAppCustom>
            {
                loading?
                <img alt='loading' className='taplakLoading' src={spinner}></img>
                :
                <CSSTransition in={standby} timeout={{appear:1000,enter:2000,exit:1000}} mountOnEnter={true} appear={true} classNames="MBPtrans">
                <div>
                    {/* <div className='MyBookWrap'>
                        <div className='MyBookHeader'>
                            <h1>Your Bookings</h1>
                            
                        </div>
                    </div> */}
                    <div className='MBPkontainer'>
                        <div className='MBPheader'>
                            <p className='white big mbp bold MBPtittle'>Your Bookings</p>
                            <div className='PSSformgroup mbp'>
                                <div className='PSSpertanyaanflex mbp'>
                                    <div onClick={e => {e.preventDefault();e.stopPropagation();showStyle? setShowStyle(false):handleOpenPopUp("style")}} id="PSSstyle" className='PSSpertanyaan mbp flex'>
                                        <p id="PSSstyleshown" className='verytiny normal PSSpertanyaantext mbp'>{style}</p>
                                        <img id="PSSstyledown" className='PSSiconkanan' alt='icon' src={downWhite}></img>
                                    </div>
                                </div>
                                {
                                    showStyle === true && (
                                        <div className="PSSpopupkontainer mbp">
                                            <div className='textPSS' onClick={e => {e.preventDefault();e.stopPropagation();handleSelectStyle(1)}}>
                                                <p className='tiny bold'>Event Booking</p>
                                            </div>
                                            <div className='textPSS' onClick={e => {e.preventDefault();e.stopPropagation();handleSelectStyle(2)}}>
                                                <p className='tiny bold'>Chef Booking</p>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            {/* {
                                bookingType===1?
                            <div className="MBPheadernavBookType flex">
                                <p onClick={()=>filterBookingType(1)} className='medium white MBPunderline' style={{marginRight:"10px", cursor:"pointer"}}>Event Booking</p>
                                <p onClick={()=>filterBookingType(2)} className='medium white' style={{marginLeft:"20px",marginRight:"20px",cursor:"pointer"}}>Chef Booking</p>
                            </div>
                            :
                            <div className="MBPheadernavBookType flex">
                                <p onClick={()=>filterBookingType(1)} className='medium white' style={{marginRight:"10px", cursor:"pointer"}}>Event Booking</p>
                                <p onClick={()=>filterBookingType(2)} className='medium white MBPunderline' style={{marginLeft:"20px",marginRight:"20px",cursor:"pointer"}}>Chef Booking</p>
                            </div>
                            } */}
                        </div>
                        {
                             bookingType === 1 ?   section===0?
                            <div className="MBPheadernav flex gap-10">
                                <p onClick={()=>filterBooking(0)} className='medium mbp MBPunderline'>Active Booking</p>
                                <p onClick={()=>filterBooking(1)} className='medium mbp'>Booking History</p>
                            </div>
                            :
                            <div className="MBPheadernav flex gap-10">
                                <p onClick={()=>filterBooking(0)} className='medium mbp'>Active Booking</p>
                                <p onClick={()=>filterBooking(1)} className='medium mbp MBPunderline'>Booking History</p>
                            </div>
                            :
                            sectionChef===0?
                            <div className="MBPheadernav flex gap-10">
                                <p onClick={()=>filterBookingChef(0)} className='medium mbp MBPunderline'>Active Booking</p>
                                <p onClick={()=>filterBookingChef(1)} className='medium mbp'>Booking History</p>
                            </div>
                            :
                            <div className="MBPheadernav flex gap-10">
                                <p onClick={()=>filterBookingChef(0)} className='medium mbp'>Active Booking</p>
                                <p onClick={()=>filterBookingChef(1)} className='medium mbp MBPunderline'>Booking History</p>
                            </div>
                            }
                        {
                        
                        bookingType === 1 ? detail ? detail.map((post,i) => (
                            <MBPcard number={i+1} showOpsi={showOpsi} setShowOpsi={e => setShowOpsi(e)} post={post} fungsi={e => handleQRchef(e)} fungsi2={e => handleHelp(e)} fungsi3={e => handleRateUs(e)} handlePayment={(e)=> handlePayment(e) } section={section}/>
                        )) :<div></div>: detailChef ? detailChef.map((post,i) => (
                            <MBPChefcard number={i+1} showOpsi={showOpsi} setShowOpsi={e => setShowOpsi(e)} post={post} fungsi={e => handleQRchef(e)} fungsi2={e => handleHelp(e)} fungsi3={e => handleRateUs(e)} handlePayment={(e)=> handlePayment(e)} sectionChef={sectionChef}/>
                        )) :<div></div>
                        
                        }
                        
                    </div>
                    
                    {showQR && (
                        <div className='MBPqrwrapper'>
                            <div onClick={() => setShowQR(false)} className='MBPqrouter'/>
                            <div className='MBPqrkonten'>
                                <p className='semibig' style={{textAlign:"center"}}>QR For Chef</p>
                                <img alt="qr" className='MBPqrimg' src={qr}></img>
                                <p className='small bold MBPqrchefname'>Chef {chefname}</p>
                                <img onClick={() => setShowQR(false)} alt='icon' className='MBPiconqr' src={close}></img>
                            </div>
                        </div>
                    )}

                    {showRateUs && (
                        <div className='MBPqrwrapper'>
                            <div onClick={() => setShowRateUs(false)} className='MBPqrouter'/>
                            <div className='MBPqrkonten'>
                                <p className='semibig' style={{textAlign:"center"}}>Rate Us</p>
                                <p className='small MBPmargin2 flex'>Order Number: <span style={{marginLeft:"auto"}}>{ordernumber}</span></p>
                                <div className='ratingwrapper MBPmargin2'>
                                <p className='medium'>Rate your booking experience with Taplak</p>
                                
                                <div className="rating">
                                    <input type="radio" name="rating" value={5} id="5" onChange={e => setrate1(e.target.value)}/><label for="5">☆</label>
                                    <input type="radio" name="rating" value={4} id="4" onChange={e => setrate1(e.target.value)}/><label for="4">☆</label>
                                    <input type="radio" name="rating" value={3} id="3" onChange={e => setrate1(e.target.value)}/><label for="3">☆</label>
                                    <input type="radio" name="rating" value={2} id="2" onChange={e => setrate1(e.target.value)}/><label for="2">☆</label>
                                    <input type="radio" name="rating" value={1} id="1" onChange={e => setrate1(e.target.value)}/><label for="1">☆</label>
                                </div>
                                <p className='medium MBPmargin2'>How do you rate the chef/private dining brand/beverage specialist</p>
                                <div className="rating">
                                    <input type="radio" name="rating2" value={5} id="52" onChange={e => setrate2(e.target.value)}/><label for="52">☆</label>
                                    <input type="radio" name="rating2" value={4}id="42" onChange={e => setrate2(e.target.value)}/><label for="42">☆</label>
                                    <input type="radio" name="rating2" value={3} id="32" onChange={e => setrate2(e.target.value)}/><label for="32">☆</label>
                                    <input type="radio" name="rating2" value={2} id="22" onChange={e => setrate2(e.target.value)}/><label for="22">☆</label>
                                    <input type="radio" name="rating2" value={1} id="12" onChange={e => setrate2(e.target.value)}/><label for="12">☆</label>
                                </div>
                                <p className='medium MBPmargin2'>How do you rate the Set Menu</p>
                                <div className="rating">
                                    <input type="radio" name="rating3" value={5} id="53" onChange={e => setrate3(e.target.value)}/><label for="53">☆</label>
                                    <input type="radio" name="rating3" value={4} id="43" onChange={e => setrate3(e.target.value)}/><label for="43">☆</label>
                                    <input type="radio" name="rating3" value={3} id="33" onChange={e => setrate3(e.target.value)}/><label for="33">☆</label>
                                    <input type="radio" name="rating3" value={2} id="23" onChange={e => setrate3(e.target.value)}/><label for="23">☆</label>
                                    <input type="radio" name="rating3" value={1} id="13" onChange={e => setrate3(e.target.value)}/><label for="13">☆</label>
                                </div>
                                <p className='medium MBPmargin2'>How do you rate the Price</p>
                                <div className="rating">
                                    <input type="radio" name="rating4" value={5} id="54" onChange={e => setrate4(e.target.value)}/><label for="54">☆</label>
                                    <input type="radio" name="rating4" value={4} id="44" onChange={e => setrate4(e.target.value)}/><label for="44">☆</label>
                                    <input type="radio" name="rating4" value={3} id="34" onChange={e => setrate4(e.target.value)}/><label for="34">☆</label>
                                    <input type="radio" name="rating4" value={2} id="24" onChange={e => setrate4(e.target.value)}/><label for="24">☆</label>
                                    <input type="radio" name="rating4" value={1} id="14" onChange={e => setrate4(e.target.value)}/><label for="14">☆</label>
                                </div>
                                <p className='medium MBPmargin2'>How do you rate the Set Menu</p>
                                <div className="rating">
                                    <input type="radio" name="rating5" value={5} id="55" onChange={e => setrate5(e.target.value)}/><label for="55">☆</label>
                                    <input type="radio" name="rating5" value={4} id="45" onChange={e => setrate5(e.target.value)}/><label for="45">☆</label>
                                    <input type="radio" name="rating5" value={3} id="35" onChange={e => setrate5(e.target.value)}/><label for="35">☆</label>
                                    <input type="radio" name="rating5" value={2} id="25" onChange={e => setrate5(e.target.value)}/><label for="25">☆</label>
                                    <input type="radio" name="rating5" value={1} id="15" onChange={e => setrate5(e.target.value)}/><label for="15">☆</label>
                                </div>
                                <p className='medium MBPmargin2'>How do you rate the Price</p>
                                <div className="rating">
                                    <input type="radio" name="rating6" value={5} id="56" onChange={e => setrate6(e.target.value)}/><label for="56">☆</label>
                                    <input type="radio" name="rating6" value={4} id="46" onChange={e => setrate6(e.target.value)}/><label for="46">☆</label>
                                    <input type="radio" name="rating6" value={3} id="36" onChange={e => setrate6(e.target.value)}/><label for="36">☆</label>
                                    <input type="radio" name="rating6" value={2} id="26" onChange={e => setrate6(e.target.value)}/><label for="26">☆</label>
                                    <input type="radio" name="rating6" value={1} id="16" onChange={e => setrate6(e.target.value)}/><label for="16">☆</label>
                                </div>
                                <p className='medium MBPmargin2'>How do you rate the Private dining experience</p>
                                <div className="rating">
                                    <input type="radio" name="rating7" value={5} id="57" onChange={e => setrate7(e.target.value)}/><label for="57">☆</label>
                                    <input type="radio" name="rating7" value={4} id="47" onChange={e => setrate7(e.target.value)}/><label for="47">☆</label>
                                    <input type="radio" name="rating7" value={3} id="37" onChange={e => setrate7(e.target.value)}/><label for="37">☆</label>
                                    <input type="radio" name="rating7" value={2} id="27" onChange={e => setrate7(e.target.value)}/><label for="27">☆</label>
                                    <input type="radio" name="rating7" value={1} id="17" onChange={e => setrate7(e.target.value)}/><label for="17">☆</label>
                                </div>
                                <p className='medium MBPmargin2'>Review</p>
                                <textarea style={{marginRight:"8px"}} id="MBPenterAddress" type='text' className='MBPform tiny MBPmargin3' rows="5"  value={review} onChange={e => setReview(e.target.value)} placeholder="Text here for the review"></textarea>
                                </div>
                                <button onClick={() => submitRateUs()} className='MBPbuttonpopup MBPmargin2 small bold'>Submit</button>
                                

                                <img onClick={() => setShowRateUs(false)} alt='icon' className='MBPiconqr' src={close}></img>
                            </div>
                        </div>
                    )}

                    {showHelp && (
                        <div className='MBPqrwrapper'>
                            <div onClick={() => setShowHelp(false)} className='MBPqrouter'/>
                            <div className='MBPqrkonten'>
                                <p className='semibig' style={{textAlign:"center"}}>Ask For Help</p>
                                <img onClick={() => setShowHelp(false)} alt='icon' className='MBPiconqr' src={close}></img>
                                <p className='medium MBPmargin2'>Subject</p>
                                <div id='MBPsubject' onClick={() => handleChooseSubject()} className='flex grey verytiny MBPsubject MBPmargin3'>
                                    <p>{subject}</p>
                                    <img id="MBPdown" alt='icon' className='MBPicon' style={{marginLeft:"auto"}} src={downWhite}></img>
                                    {
                                        showSubject && (
                                            <div className='MBPsubjectchoice'>
                                                <p onClick={() => handleSelectSubject('Cancellation')} className='tiny black' style={{marginBottom:"16px", cursor:"pointer"}}>Cancellation</p>
                                                <p onClick={() => handleSelectSubject('Change Date')} className='tiny black' style={{marginBottom:"16px", cursor:"pointer"}}>Change Date</p>
                                                <p onClick={() => handleSelectSubject('Change Venue')} className='tiny black' style={{marginBottom:"16px", cursor:"pointer"}}>Change Venue</p>
                                                <p onClick={() => handleSelectSubject('Change Pax')} className='tiny black' style={{marginBottom:"16px", cursor:"pointer"}}>Change Pax</p>
                                                <p onClick={() => handleSelectSubject('Aditional information')} className='tiny black' style={{marginBottom:"16px", cursor:"pointer"}}>Aditional information</p>
                                                <p onClick={() => handleSelectSubject('Other')} style={{cursor:"pointer"}} className='tiny black'>Other</p>
                                            </div>
                                        )
                                    }
                                </div>
                                <p className='medium MBPmargin2'>Details</p>
                                <textarea id="MBPenterAddress" type='text' className='MBPform tiny MBPmargin2' rows="5"  value={details} onChange={e => setDetails(e.target.value)} placeholder="Text here for the detail"></textarea>
                            </div>
                        </div>
                    )}  

                </div>
                </CSSTransition>
            }
            <PaymentModal isOpen={isModalOpen} onClose={closeModal} xenditPaymentLink={xenditPaymentLink}/>
            {/* <PaymentModal isOpen={isModalOpen} onClose={closeModal} xenditPaymentLink={detail.paymentLink}/> */}
        </div>
    )
  }