import './index.css';
import food from "../../assets/assets/bycategory/menus-food1.png";
import food2 from "../../assets/assets/bycategory/menus-food2.png";
import patternFood from "../../assets/assets/bycategory/menu-circle.png";
import menuBg from "../../assets/menus_bg2.png";
import { useLocation, useNavigate } from "react-router-dom";

export const MenuBanner = () => {
    const history = useNavigate();
    const handleToChefList = () =>{
        // history("/menus");
        history("/menus", { state: { dataChef : "tes", isFromMenus:true}} );
    }
    return (
        <div className='chefBannerSingle version2' onClick={()=>{handleToChefList()}}>
            <img className='img-bg' src={menuBg} draggablefalse></img>
            <div className='chefBannerText'>
                <h1>Explore Menus</h1>
                <p>Dozen of good meals are ready to give you the best taste.</p>
                <div className='btnExplore chefsBanner'>Explore Now</div>
            </div>
        </div>
        // <div className="chefBannerSingle menuBanner" onClick={()=>{handleToChefList()}}>
        //     <img className='img-1' src={food} draggable="false"></img>
        //     <img className='img-2' src={food2} draggable="false"></img>
        //     <img className='img-3' src={patternFood} draggable="false"></img>
        //     <div className='chefBannerText'>
        //         <h1>Explore Menus</h1>
        //         <p>Dozen of good meals are ready to give you the best taste.</p>
        //         <div className='btnExplore menusBanner'>Explore Now</div>
        //     </div>
        // </div>
    )
}