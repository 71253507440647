import './index.css'
import star from '../../../assets/star.svg'
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
// import { CSSTransition } from 'react-transition-group';
import toRupiah from '@develoka/angka-rupiah-js';

export const ListChefCard = ({data,isFromSearch, standby, fungsi}) => {

    const history = useNavigate()
    const [pindah, setPindah] = useState(false)

    useEffect(()=>{
        if(pindah===true){
            if(!isFromSearch){
                setTimeout(function() {
                    history("/category/chefList/detailChef", { state: { data: data, isFromSearch:isFromSearch} });
               }, 500);
            } else {
                setTimeout(function() {
                    history("/category/chefList/detailChefSearch", { state: { data: data, isFromSearch:isFromSearch} });
               }, 500);
            }
        //     setTimeout(function() {
        //         history("/category/chefList/detailChef", { state: { data: data, isFromSearch:isFromSearch} });
        //    }, 500);
        }
    },[pindah])

    return (
        <div className='listchefcardcontainer'>
            <div onClick={() => {fungsi();setPindah(true)}} className='kontenlistchefcard'>
                <div className='gambarWrap'>
                    <img alt="gambarlistchef" src={data.gambar}></img>
                </div>
                <div className='kontainerdetaillistchef'>
                    <div className='kontaineratas'>
                        <h1>{data.nama}</h1>
                        
                        <div className='kontainerkanan'>
                            <img className='iconlistchef' alt="iconrating" src={star}></img>
                            <p className='texticonlistchef'>{data.rating}</p>
                        </div>
                    </div>
                    <div className='kontainerlistcuisine'>
                        {
                            data.jenis.map((post) => {
                                if(post === data.jenis[0]){
                                    return <p>{post.name} Cuisine</p>;
                                }
                            })
                        }
                        {
                            data.jenis.length > 1 && (
                                <p className='more'>
                                    {data.jenis.length > 1 ? ` +${data.jenis.length - 1} more` : null}
                                </p>
                            )
                        }

                        {/* {data.jenis.map((post) => {
                            if(post == data.jenis[0]){
                                return (
                                    <p>{post.name} Cuisine</p>
                                    )
                            }else{
                                return (
                                    <p>, {post.name} Cuisine</p>
                                    )
                            }
                        })} */}
                    </div>   
                    <div className='kontainerarea'>
                        <p>{data.area}</p>
                    </div>
                    <div className='kontainerlistchefbawah'>
                        <p>Start from</p>
                        <h1>{toRupiah(data.harga+"000", {formal: false, symbol: 'IDR'}).slice(0, -3)}/PAX</h1>
                    </div>
                </div>
            </div>
        </div>
    )
  }