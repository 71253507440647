import './index.css'
import { DesktopNavbar } from '../NavBar/Desktop/DesktopNavbar'
import {useState, useEffect, useRef} from 'react'
import "react-datepicker/dist/react-datepicker.css";
// import makananevent from '../../assets/makananevent.png'
import calendar from '../../assets/calendar-1.svg'
import React from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import pork from '../../assets/pork.svg'
import circleback from '../../assets/circleback.svg'
import location from '../../assets/pin-1.svg'
import { CSSTransition } from 'react-transition-group';
import eventimg from '../../assets/banner.png'
import spinner from '../../assets/spinner.gif'
import axios from 'axios';
import { baseUrl } from '../../lib/api';
import toRupiah from '@develoka/angka-rupiah-js';
import { FloatingWhatsAppCustom } from '../FloatingWhatsAppCustom/FloatingWhatsAppCustom';
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();


export const PageDetailEvent = () => {
    const { state } = useLocation()
    const history = useNavigate()
    const [detail, setDetail] = useState()
    const [loading, setLoading] = useState(true)
    const [enableButton, setEnableButton] = useState(true)
    const [showNavPopup, setShowNavPopup] = useState(false)
    const handleCloseAll = () => {
        setShowNavPopup(false)
    }
    useEffect(() =>{
        const { id,isSoldOut } = state
        window.scrollTo(0, 0)
        setLoading(true)
        // console.log("is Sold Out => "+ isSoldOut)
        setEnableButton(isSoldOut)
        const detailEventFromDB = async () => {
            try {
                const data = await callApiGetDetailEvent(id)
                setDetail(data)
            } catch (error) {
                console.log("Error detailEventFromDB")
            }
        }

        detailEventFromDB(id)

        // setDetail({gambar:eventimg,judul:"Thanksgiving",style:"American style featuring Turkey and Sides",serving:"Free standing",menu:[{nama:"Yaki gyoza",foto:makananevent,bahan:["pork"]},{nama:"Yaki gyoza", foto:makananevent,bahan:["pork"]}], sisa:10, total:20, tanggal:"24 Feb", jam:"18:00 - 20:00", tempat:"Hotel Santika, BSD City", harga:300, listEvent:[{tanggal:"24 Feb",jam:"18:00 - 20:00",sisa:10,max:20},{tanggal:"27 Feb",jam:"18:00 - 20:00",sisa:3,max:20}]})
        localStorage.setItem('nav', 'e')
    },[])
    const [standby, setstandby] = useState(true)
    const firstUpdate = useRef(true);
    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
        }else{
            setLoading(false)
        }
    },[detail])

    const callApiGetDetailEvent = async (id) => {
        try {
            const { data: res } = await axios({
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'get',
                url: `${baseUrl}detailEvent/${id}`,
                // url: `${baseUrl}event/${id}`,
            })
            const detailEventData = [res.data]
            const parsedDetailEventData = parseDetailEvent(detailEventData)
            // console.log("chuaksss")
            // console.log(detailEventData)
            // console.log(parsedDetailEventData[0])
            return parsedDetailEventData[0]

        } catch (error) {
            console.log("error getDetailEventFromDB")
            console.log(error)
            // Create error handler (pop-up or something)
        }
    }

    // Helpers
    const stylesParser = (arrData) => {
        if (!arrData) {
            return "N/A"
        }
        // arrData = arrData[0].name
        arrData = arrData.map(({name}) => `${name}`).join(',');
        // arrData = arrData.join(', ').replace(/, ([^,]*)$/, ' and $1')
        return arrData
    }

    const parsePricing = (prices) => {
        if (!prices || prices.length < 1) {
            return "N/A"
        }
        
        prices = prices.map(el =>{
            return {
                id: el["id"],
                amount: el["amount"]
            }
        })
        // const parsedPrice = prices[0]["amount"]
        return prices
    }

    const parseVoucher = (vouchers) => {
        if(!vouchers || vouchers < 1){
            return "N/A"
        }

        vouchers = vouchers.map(el => {
            return {
                id: el["id"],
                name: el["voucher_name"],
                discount: el["discount"],
                quota: el["quota"]
            }
        })

        return vouchers
    }

    const parseMenu = (menu) => {
        menu = menu.map(el => {
            return {
                nama: el["menu_name"],
                foto: el["menu_img_url"],
                bahan: [el["menu_description"]]
            }
        })
        return menu
    }

    const parseTime = (startTime, endTime) => {
        let parsedTime = ["N/A", "N/A"]
        if (startTime) {
            const [hour, minute, _] = startTime.split(":")
            startTime = `${hour}:${minute}`
            parsedTime[0] = startTime
        }
        if (endTime) {
            const [hour, minute, _] = endTime.split(":")
            endTime = `${hour}:${minute}`
            parsedTime[1] = endTime
        }
        
        parsedTime = parsedTime.join(" - ")

        return parsedTime
    }

    const parseListEvent = (listEvent) => {
        

        const monthList = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ]

        listEvent = listEvent.map(el => {
            const date = el["date_events"]
            const startTime = el["start_time"]
            const endTime = el["end_time"]

            const [year, month, _] = date.split("-")

            return {
                tanggal: `${_} ${monthList[Number(month)-1].slice(0, 3)} ${year}`,
                jam: parseTime(startTime, endTime),
                max: el["max_seat"],
                remaining: el["remaining_seats"]
            }
        })
        return listEvent
    }

    const parseDetailEvent = (detailEvent) => {
        const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
        detailEvent = detailEvent.map(el => {
            const parsedListEvent = parseListEvent(el["events_date"])
            // console.log("jam => "+parsedListEvent[0])
            // console.log("jam => "+parsedListEvent[0]["jam"])
            return {
                id: el["id"],
                gambar: el["cover_img_url"],
                judul: el["event_name"],
                description: el["event_description"],
                style: stylesParser(el["event_style"]),
                serving: el["vanue_name"],
                // serving: el["state"],
                menu: parseMenu(el["events_menu"]),
                sisa: el["min_pax"],
                total: el["max_pax"],
                tanggal: el["events_date"],
                jam: parsedListEvent[0] ? parsedListEvent[0]["jam"] : "N/A",
                tempat: el["vanue_location"],
                harga: parsePricing(el["events_price"]),
                voucher: parseVoucher(el["events_voucher"]),
                listEvent: parsedListEvent
            }
        })
        return detailEvent
    }

    const handleJoinNow = () => {
        setstandby(false)
        setTimeout(function() {
            history('/event/detailEvent/setSchedule', { state: { detail } })
        }, 500);
    }

    return (
        <div onClick={e => {e.stopPropagation();handleCloseAll()}} id="maincontainer" style={{minWidth:"100%", minHeight:"100vh", backgroundColor:"#111111", position:"relative", overflow:"unset"}}>
            <DesktopNavbar showPopup={showNavPopup} setShowPopup={setShowNavPopup}></DesktopNavbar>
            <FloatingWhatsAppCustom></FloatingWhatsAppCustom>
            <img onClick={() => history(-1)} className='backmobile' alt="back" src={circleback}></img>
            {
                loading?
                <img alt='loading' className='taplakLoading' src={spinner}></img>
                :
                <CSSTransition in={standby} timeout={{appear:1000,enter:2000,exit:1000}} mountOnEnter={true} appear={true} classNames="PDEtrans">
                    <div>
                        <div className='detailEventWrapper'>
                            <div className='detEvent-maiNGrid'>
                                <div className='detEvent-image'>
                                    <img src={`${detail.gambar}`} alt="new"></img>
                                </div>
                                <div className='detEvent-secondaryGrid'>
                                    <div className='detEvent-details' data-aos="fade-up">
                                        <div className='detEvent-detailsHead'>
                                            <div>
                                                <h1>{detail.judul}</h1>
                                                {React.createElement("p", { dangerouslySetInnerHTML: { __html: detail.description } })}
                                                <p>Tags:<span className='bold-white-medium'> {detail.style}</span></p>
                                            </div>
                                            <div className='flex-col'>
                                                <p>Start From</p>
                                                <h1>{toRupiah(detail.harga[0]["amount"], {formal: false, symbol: 'IDR'}).slice(0, -3)}/PAX</h1>
                                            </div>
                                        </div>
                                        <div className={detail.menu.length == 0 ? 'detEvent-detailsBody d-none' : 'detEvent-detailsBody'}>
                                            <div className='detailsBodyHead' data-aos="fade-up">
                                                <h1>Menu</h1>
                                                <p>Dibawah berikut adalah Menu yang disediakan oleh Chef.</p>
                                            </div>
                                            {
                                                detail.menu.map((post) => (
                                                <div className='detailsBodyCard'  data-aos="fade-up">
                                                    <div className='BodyCardImage'>
                                                        <img src={`${post.foto}`}></img>
                                                    </div>
                                                    <div className='BodyCardText'>
                                                        <h1>{post.nama}</h1>
                                                        {
                                                            post.bahan.map((x) => {
                                                                return ( 
                                                                <div>
                                                                    {
                                                                        x==="pork" ? 
                                                                        <div className='bahanFlex'>
                                                                            <img alt="icon" src={pork} className="PDEicon"></img>
                                                                            <p>{x}</p>
                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            {/* <img alt="icon" src={pork} className="PDEicon"></img> */}
                                                                            <p>{x}</p>
                                                                        </div>
                                                                    }
                                                                </div> 
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className='detEvent-form' data-aos="fade-up">
                                        <div className='formEvent'>
                                            <h1>Event Date</h1>
                                            {
                                                detail.listEvent.map((post) => (
                                                    <div>
                                                        {
                                                            post===detail.listEvent[0]?
                                                            <div className='formDate'>
                                                                <img alt='icon' src={calendar}></img>
                                                                <div className='formDate-text'>
                                                                    <p>{post.tanggal} &ensp; • &ensp;  {post.jam}</p>
                                                                    { post.sisa<=3 ? 
                                                                    <p className='text-red medium-text'>{post.remaining} Seats left</p>
                                                                    :
                                                                    <p className='text-graybase medium-text'>{post.remaining} Seats left</p>
                                                                    }
                                                                    <p className='text-fade medium-text'>from {post.max}</p>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div>
                                                                <div className='separatorDate'>•</div>
                                                                <div className='formDate'>
                                                                    <img alt='icon' src={calendar}></img>
                                                                    <div className='formDate-text'>
                                                                        <p>{post.tanggal} &ensp; • &ensp;  {post.jam}</p>
                                                                        { post.sisa<=3 ? 
                                                                        <p className='text-red medium-text'>{post.remaining} Seats left</p>
                                                                        :
                                                                        <p className='text-graybase medium-text'>{post.remaining} Seats left</p>
                                                                        }
                                                                        <p className='text-fade medium-text'>from {post.max}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    
                                                ))
                                            }
                                        </div>
                                        <div className='formEvent'>
                                            <h1>Event Location</h1>
                                            <div className='formDate'>
                                                <img alt='icon' src={location}></img>
                                                <div className='formDate-text'>
                                                    <p>Location Detail</p>
                                                    <p className='text-graybase medium-text'>{detail.tempat}</p>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            enableButton ? 
                                            <button className='PDEbuttonDisable small bold white'>Sold Out</button>
                                            :
                                            <button onClick={() => handleJoinNow()} className='PDEbutton small bold'>Join Now</button>
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CSSTransition>
            }
        </div>
    )
  }