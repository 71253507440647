import './index.css'
import logo from '../../../assets/taplak.png'
import menu from '../../../assets/menu.svg'
import close from '../../../assets/close.svg'
import {useState, useEffect, useCallback} from 'react'
import { useNavigate } from "react-router-dom";
import { PageLogin } from '../../LoginPage/PageLogin';
import {baseUrl} from '../../../lib/api'

export const MobileNavbar = ({zindex}) => {
    const history = useNavigate()
    const [y, setY] = useState(window.scrollY);
    const [showMobileMenu, setShowMobileMenu] = useState(false)
    const [showLogin, setShowLogin] = useState(false)

    const handleNavigation = useCallback(
        e => {
          const window = e.currentTarget;
          setY(window.scrollY);
          if(y<=50){
            var elem = document.getElementById("diubah2");
            elem.style.transition = "background-color 0.5s";
            elem.style.backgroundColor = "transparent";
        }else{
            var elem2 = document.getElementById("diubah2");
            elem2.style.transition = "background-color 0.5s";
            elem2.style.backgroundColor = "rgba(0, 0, 0, 0.8)";
            }
        }, [y]
      );

      const callApiLogout = async () => {
        var axios = require('axios');
        axios({
          method: 'POST',
          url: baseUrl + 'logout',
          withCredentials:false,
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('taplakToken')
          }
        })
        .then(function (response) {
            // console.log("batas")
            // console.log(JSON.stringify(response.data));
            localStorage.setItem('TaplakLoggedIn', 'false');
            window.location.reload();
        })
            .catch(function (error) {
            console.log(error);
        });
      }

      const callApiLogoutV2 = async () => {
        // var axios = require('axios');
        // axios({
        //   method: 'POST',
        //   url: baseUrl + 'logoutCustomer',
        //   withCredentials:false,
        //   headers: {
        //     'Authorization': 'Bearer ' + localStorage.getItem('taplakToken')
        //   }
        // })
        // .then(function (response) {
            console.log("batasssss")
            // console.log(JSON.stringify(response.data));
            localStorage.setItem('TaplakLoggedIn', 'false');
            localStorage.removeItem("taplakToken")
            localStorage.removeItem("username")
            setShowMobileMenu(false)
            // setShowPopup(false)
            // window.location.reload();
        // })
        //     .catch(function (error) {
        //     console.log(error);
        // });
      }
      
      useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);
      
        return () => {
          window.removeEventListener("scroll", handleNavigation);
        };
      }, [handleNavigation]);

      const login = () => {
        setShowLogin(true)
        setShowMobileMenu(false)
      }


    return (
        <div>
        {
            !showMobileMenu ?
            <div className={`kontainerMobileNavbar ${zindex ? 'z-indexnone' : ''}`} id='diubah2'>
                <img onClick={() => setShowMobileMenu(true)} className="menu" alt='logoTaplak' src={menu}></img>
                <img onClick={() => history("/")} className="logo" alt='logoTaplak' src={logo}></img>
            </div>
            :
            <div>
                <div className="kontainerMobileMenu">
                    <img onClick={() => setShowMobileMenu(false)} className="menu" alt='logoTaplak' src={close}></img>
                    {
                      localStorage.getItem('TaplakLoggedIn')==='true' && (
                        <div onClick={() => history("/userSettings")} className="menutext">
                          <p className='menutext2'>{localStorage.getItem('username')}</p>
                          <p className='tiny grey'>User Settings</p>
                        </div>
                      )
                    }
                    <p onClick={() => history("/")} className="menutext">HOME</p>
                    <p onClick={() => history("/menus", { state: { dataChef : "tes", isFromMenus:true}})} className="menutext">FOOD & DRINK</p>
                    <p onClick={() => history("/event")} className="menutext">EVENT</p>
                    {
                      localStorage.getItem('TaplakLoggedIn')==='true' && (
                        <p onClick={() => history("/MyBooking")} className="menutext">MY BOOKINGS</p>
                      )
                    }
                    {
                      localStorage.getItem('TaplakLoggedIn')==='true' ?
                      <p onClick={() => {callApiLogoutV2()}} className="menutext">SIGN OUT</p>
                      :
                      <p onClick={() => login()} className="menutext">LOGIN/REGISTER</p>
                    }
                </div>
                <div className='shadow'></div>
            </div>
        }
        {
              showLogin && (
              <PageLogin fungsi={() => setShowLogin(false)}></PageLogin>
              )
          }
        </div>
    )
  }