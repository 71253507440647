// analytics.js
import ReactGA from 'react-ga4';

export const initGA = () => {
    // ReactGA.initialize('G-YXKJ1429FK');
  ReactGA.initialize('G-YXKJ1429FK'); // Replace with your GA4 Measurement ID
};

export const logPageView = () => {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
};
