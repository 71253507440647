import "../../MenuList/index.css";
import image from "../../../assets/chef_bg.avif";
import star from "../../../assets/star.svg";
import { useNavigate } from "react-router-dom";
import toRupiah from '@develoka/angka-rupiah-js';

export const ChefCards = ({data,isFromSearch, standby, fungsi, isFromMenu}) => {
    const history = useNavigate()
    const handletoDetailChefs = () =>{
        console.log("data booking list 1 => "+JSON.stringify(data.booking_available))
        history("/chefs/detail", { state : {dataChef : data, isFromMenu: isFromMenu}});
    }

    return(
        <div key={data.id} className="byCategoryCards cardEntry" onClick={() => {handletoDetailChefs()}}>
            <div className="byCategoryCardsImage">
                <img src={data.gambar} draggable="false"></img>
            </div>
            <div className="byCategoryCardsContents">

                <div className="head">
                    <h1 className="line-clamps-2">{data.nama}</h1>
                    <div className="headSeats flex align-center gap-5">
                        <img src={star} draggable="false"></img>
                        <h1>{data.rating}</h1>
                    </div>
                </div>

                <div className="body">
                    <div className="cuisines">
                        {
                            data.jenis.map((post) => {
                                if(post === data.jenis[0]){
                                    return <p className="line-clamps-1-half">{post.name} Cuisine</p>;
                                }
                            })
                        }
                        {
                            data.jenis.length > 1 && (
                                <p className='more'>
                                    {data.jenis.length > 1 ? ` +${data.jenis.length - 1} more` : null}
                                </p>
                            )
                        }
                    </div>
                    <div className="bodyChefs">
                        <h1>{data.area}</h1>
                    </div>
                </div>
                <div className="footer">
                    <div className="price">
                        <p>Start From</p>
                        <h1>{toRupiah(data.harga+"000", {formal: false, symbol: 'IDR'}).slice(0, -3)}/PAX</h1>
                    </div>
                </div>
            </div>
        </div>
    )
}