import './index.css'
import {useState, useEffect, useRef} from 'react'
import carleft from '../../../assets/carleft.svg'
import carright from '../../../assets/carright.svg'
import { useNavigate } from 'react-router-dom'

export const MenuCardDCP = ({data, chefId,chefRating, chefName,chefFoto, standby, fungsi}) => {

    const [index, setIndex] = useState(1);
    const firstUpdate = useRef(true);
    const history = useNavigate()

    const [pindah, setPindah] = useState(false)

    useEffect(()=>{
        if(pindah===true){
            setTimeout(function() {
                history('/category/chefList/detailChef/detailMenu', { state: { dataMenu: data, chefId, chefRating: chefRating, chefName:chefName, chefFoto:chefFoto} });
           }, 500);
        }
    },[pindah])

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
          } else {
            var elem = document.getElementById(data.jenis);
            elem.style.transition = "background-image 0.5s";
            elem.style.backgroundImage = `url(${data.food[index-1].gambar})`;
          }
    },[index])

    const handleSelect = (e) => {
        if(e === "left"){
            if(index === 1){
                setIndex(data.food.length)
            }else{
                setIndex(index-1)
            }
        }else{
            if(index === data.food.length){
                setIndex(1)
            }else{
                setIndex(index+1)
            }
        }
    };

    return (
        <div className='DCPmenucard'>
            <div id={data.jenis} style={{backgroundImage:`url(${data.food[0].gambar})`}} className='DCPCarousel'>
                <img className='DCParrow' onClick={() => handleSelect("left")} style={{marginLeft:"20px",height:"32px", cursor:"pointer"}} alt='icon' src={carleft}></img>
                <img className='DCParrow' onClick={() => handleSelect("right")} style={{marginLeft:"auto",marginRight:"20px",height:"32px", cursor:"pointer"}} alt='icon' src={carright}></img>
            </div>
            <div onClick={() => {fungsi();setPindah(true)}} className='DCPmenudetail'>
                <div className='DCPmenuheader'>
                    <h1>{data.jenis} Food</h1>
                    <div className='DCPcardfoodlist'>
                        {
                            data.food.map((post) => {
                                if(post == data.food[0]){
                                    return <p>{post.nama} Cuisine</p>;
                                }
                            })
                        }
                        {
                            data.food.length > 1 && (
                                <p className='more'>
                                    {data.food.length > 1 ? ` +${data.food.length - 1} more` : null}
                                </p>
                            )
                        }
                    {/* {
                        data.food.map((post) => {
                            if (post.nama === data.nama) {
                                return <p key={index}>{post.nama}</p>;
                                }
                                return null;
                            })
                    }

                    {
                        data.food.length > 1 && (
                            <p>
                                {data.food[0].nama}
                                {data.food.length > 1 ? ` +${data.food.length - 1} more` : null}
                            </p>
                        )
                    } */}
                    </div>
                    <span className='linefoodlist'></span>
                </div>
                <div className='DCPcardkontainertengah'>
                    <p> Max {data.max} Guest</p>
                </div>
                <div className='DCPcardkontainerbawah'>
                    <p>Start from</p>
                    <h1>IDR {data.harga+".000"}/Pax</h1>
                </div>
                
            </div>
        </div>
    )
  }