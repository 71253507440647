import './index.css'
import iconchef from "../../../assets/iconchef.svg";
import imgserve from "../../../assets/work_coobg.png";
import { PageDetailChef } from '../../DetailChefPage/PageDetailChef';

export const MenuCard = ({image, name,description,categoryName, serviceName,detail,detailChef}) =>{

    return(
        <div className="ChefMenuCard">
            <div className="ChefMenuCardImage">
                <img src={image} draggable="false"></img>
            </div>
            <div className="ChefMenuCardText">
                <p>{categoryName}</p>
                <h1>{name}</h1>
                <p>{serviceName}</p>
                <div className="flex gap-5 align-center">
                    {/* <img src={iconchef} draggable="false"></img> */}
                    <p className='chefname'>{description}</p>
                </div>
            </div>
        </div>
    )
}