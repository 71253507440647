/* eslint-disable react-hooks/exhaustive-deps */
import './index.css'
import { DesktopNavbar } from '../NavBar/Desktop/DesktopNavbar'
import {useState, useEffect, useCallback, useRef} from 'react'
import "react-datepicker/dist/react-datepicker.css";
import foto1 from '../../assets/foto1.png'
import foto2 from '../../assets/foto2.png'
import React from 'react';
import foto3 from '../../assets/foto3.png'
import search from '../../assets/search.svg'
import search2 from '../../assets/search2.svg' 
import back from '../../assets/back.svg' 
import pin from '../../assets/pin-1.svg' 
import sort from '../../assets/options.svg'
import { ListChefCard } from '../Card/ListChefCard/ListChefCard';
import { useLocation, useNavigate } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import spinner from '../../assets/spinner.gif'
import axios from 'axios'
import { baseUrl } from '../../lib/api'
import { FloatingWhatsAppCustom } from '../FloatingWhatsAppCustom/FloatingWhatsAppCustom';
import useDraggableScroll from 'use-draggable-scroll';
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

export const ChefListPage = () => {
    const loc = useLocation()
    const history = useNavigate()
    const { state }  = useLocation()
    const listCategory = []
    const listLocation = []
    const [listLocationFound, setListLocationFound] = useState([])
    const [listCategoryFound, setListCategoryFound] = useState([])
    const [selectedSpeciality, setSelectedSpeciality] = useState()
    const [category, setCategory] = useState([])
    const [filteredCategory, setFilteredCategory] = useState([])
    const [isFirstStart, setIsFirstStart] = useState(true)
    const [speciality, setSpeciality] = useState([])
    const [indexListSpeciality, setIndexListSpeciality] = useState()
    const [filterChef, setFilterChef] = useState('')
    const [isFromSearchData, setIsFromSearchData] = useState(loc.state?.isFromSearch)
    const [filterLocation, setFilterLocation] = useState('Location')
    const [filterListCategoryElement, setfilterListCategoryElement] = useState("")
    const [showSortBy, setShowSortBy] = useState(false)
    const [sortBy, setSortBy] = useState('Sort by')
    const [showFilterLocation, setShowFilterLocation] = useState(false)
    const [filterSearchLocation, setFilterSearchLocation] = useState('')
    const [databaseLocation, setDatabaseLocation] = useState([])
    const [showSearchMobile, setShowSearchMobile] = useState(false)
    const [showSortByMobile, setShowSortByMobile] = useState(false)
    const [standby, setstandby] = useState(true)
    const firstUpdate = useRef(true);
    const [loading, setLoading] = useState(true)
    const [showNavPopup, setShowNavPopup] = useState(false)
    

    const handleCloseAll = () => {
        setShowFilterLocation(false)
        setShowSearchMobile(false)
        setShowSortBy(false)
        setShowNavPopup(false)
        setShowSortByMobile(false)
    }

    useEffect(() =>{
        const { dataChef, isFromSearch,dataChefSearch } = state
        window.scrollTo(0, 0)
        setLoading(true) 
        const categoryChefFromDB = async (id_speciality) => {
            try {
                /*
                Params is array of object
                [
                    {
                        "location": "Lake Cel"
                    },
                    {
                        "genre": "indonesian"
                    }
                ]
                */
                const data = await callApigetCategoryChef(id_speciality)
                // Create parser before set to state
                // console.log("paage list chef data => "+JSON.stringify(data[0].service))
                setCategory(data)
                
            } catch (error) {
                console.log("Error categoryChefFromDB")
            }
        }
        const specialityChefSearchFromDB = async () => {
            try {
                /*
                Params is array of object
                [
                    {
                        "location": "Lake Cel"
                    },
                    {
                        "genre": "indonesian"
                    }
                ]
                */
                const data = await callApigetSpeciality()
                if(data.length !== 0){
                    // console.log("tes list sorting category")
                    data.sort(function(a, b){
                        if(a.name < b.name) { return -1; }
                        if(a.name > b.name) { return 1; }
                        return 0;
                    })
                }
                // Create parser before set to state
                setSpeciality(data)
                // console.log("id speciality => "+data[0].id_speciality)
                setSelectedSpeciality(data[0].id_speciality)
                localStorage.setItem('categoryList',data[0].name)
                // categoryChefFromDB(data[0].id_speciality)
            } catch (error) {
                console.log("Error categoryChefFromDB")
            }
        }

        const specialityChefFromDB = async () => {
            try {
                /*
                Params is array of object
                [
                    {
                        "location": "Lake Cel"
                    },
                    {
                        "genre": "indonesian"
                    }
                ]
                */
                const data = await callApigetSpeciality()
                if(data.length !== 0){
                    // console.log("tes list sorting category")
                    data.sort(function(a, b){
                        if(a.name < b.name) { return -1; }
                        if(a.name > b.name) { return 1; }
                        return 0;
                    })
                }
                // Create parser before set to state
                setSpeciality(data)
                // console.log("id speciality => "+data[0].id_speciality)
                setSelectedSpeciality(data[0].id_speciality)
                localStorage.setItem('categoryList',data[0].name)
                categoryChefFromDB(data[0].id_speciality)
            } catch (error) {
                console.log("Error categoryChefFromDB")
            }
        }
        // Turn on to use it
        // const params = [
        //     {
        //         "location": "Lake Cel"
        //     },
        //     {
        //         "genre": "indonesian"
        //     }
        // ]
        // categoryChefFromDB(params)
        if(isFromSearch){
            setIndexListSpeciality(0)
            specialityChefSearchFromDB()
            setIsFromSearchData(isFromSearch)
            // console.log("dari search")
            // console.log(dataChef)
            // console.log(isFromSearchData)
            // console.log(dataChefSearch)
            // console.log(isFromSearch)
            const data = parseCategoryChefFromSearch(dataChef)
            // console.log("dari search")
            setCategory(data)
        } else {
            setIndexListSpeciality(0)
            specialityChefFromDB()

        }
        
        // setCategory([{gambar:foto1,nama:"Adrian Judarta",jenis:["Asian", "Western"],area:"Jakarta",harga:150,rating:4.5},
        //             {gambar:foto2,nama:"Mufalando",jenis:["Asian", "Indonesian"],area:"Yogyakarta",harga:200,rating:4.5},
        //             {gambar:foto3,nama:"Brian",jenis:["Indian"],area:"Bandung",harga:250,rating:4.5},
        //             {gambar:foto2,nama:"Hanrichie",jenis:["Western", "Indian"],area:"Jakarta",harga:100,rating:4.5},
        //             {gambar:foto1,nama:"Adrian Judarta",jenis:["Asian", "Western"],area:"Semarang",harga:150,rating:4.5},
        //             {gambar:foto2,nama:"Mufalando",jenis:["Asian", "Indonesian"],area:"Tangerang",harga:200,rating:4.5},
        //             {gambar:foto3,nama:"Brian",jenis:["Indian"],area:"Jakarta",harga:250,rating:4.5},
        //             {gambar:foto2,nama:"Hanrichie",jenis:["Western", "Indian"],area:"Jakarta",harga:100,rating:4.5},
        //             {gambar:foto1,nama:"Adrian Judarta",jenis:["Asian", "Western"],area:"Jakarta",harga:150,rating:4.5},
        //             {gambar:foto2,nama:"Mufalando",jenis:["Asian", "Indonesian"],area:"Jakarta",harga:200,rating:4.5},
        //             {gambar:foto3,nama:"Brian",jenis:["Indian"],area:"Jakarta",harga:250,rating:4.5},
        //             {gambar:foto2,nama:"Hanrichie",jenis:["Western", "Indian"],area:"Jakarta",harga:100,rating:4.5},
        //             {gambar:foto1,nama:"Adrian Judarta",jenis:["Asian", "Western"],area:"Jakarta",harga:150,rating:4.5},
        //             {gambar:foto2,nama:"Mufalando",jenis:["Asian", "Indonesian"],area:"Jakarta",harga:200,rating:4.5},
        //             {gambar:foto3,nama:"Brian",jenis:["Indian"],area:"Jakarta",harga:250,rating:4.5},
        //             {gambar:foto2,nama:"Hanrichie",jenis:["Western", "Indian"],area:"Jakarta",harga:100,rating:4.5},
        //             {gambar:foto1,nama:"Adrian Judarta",jenis:["Asian", "Western"],area:"Jakarta",harga:150,rating:4.5},
        //             {gambar:foto2,nama:"Mufalando",jenis:["Asian", "Indonesian"],area:"Jakarta",harga:200,rating:4.5},
        //             {gambar:foto3,nama:"Brian",jenis:["Indian"],area:"Jakarta",harga:250,rating:4.5},
        //             {gambar:foto2,nama:"Hanrichie",jenis:["Western", "Indian"],area:"Jakarta",harga:100,rating:4.5}
        //             ])
        localStorage.setItem('nav', 'fnd')
        localStorage.setItem('categoryList','')
    },[])

    const parseCategoryChefFromSearch = (categoryChefData) => {
        categoryChefData = categoryChefData.map((el, idx) => {
            // if (el["slug"] == "Chef-Taplak") {
                return {
                    id: el.id,
                    gambar: el.gambar,
                    nama: el.nama,
                    email: el.email,
                    rating:"10",
                    jenis: parseServicesToJenis(el.jenis),
                    area: "Jakarta",
                    harga: el.jenis[0].converted_discounted_price,
                }
            // }
        })
        return categoryChefData.filter(el => el)
    }

    useEffect(() => {
        // Handle all filter
        const filteredData = { filterChef, filterLocation, filterListCategoryElement }
        filterCategory(filteredData)

    }, [filterChef, filterLocation, filterListCategoryElement])

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
        }else{
            setLoading(false)
        }
    },[category,speciality])

    useEffect(() =>{
        // console.log("sebelum data nya")
        // console.log(category)
        for(var x in category){
            // for(var y in category[x].jenis){
            //     if(!listCategory.includes(category[x].jenis[y].name)){
            //         listCategory.push(category[x].jenis[y].name)
            //     }
            // }
            if(!listLocation.includes(category[x].area)){
                listLocation.push(category[x].area)
            }
        }
        // console.log("sebelum data nya")
        // console.log(speciality)
        for(var k in speciality){
            if(!listCategory.includes(speciality[k].name)){
                console.log("gak ada data")
                listCategory.push({id:speciality[k].id_speciality,name:speciality[k].name})
            } else {
                console.log("ada data nya")
            }
        } 

        // if(listCategory.length !== 0){
        //     console.log("tes list sorting category")
        //     listCategory.sort(function(a, b){
        //         if(a.name < b.name) { return -1; }
        //         if(a.name > b.name) { return 1; }
        //         return 0;
        //     })
        // }

        setListLocationFound(listLocation)
        setDatabaseLocation(listLocation)
        setListCategoryFound(listCategory)
        // console.log("awwev=> "+listCategory.length)
        // console.log("awwev=> "+listCategory.length !==0 ? listCategory[0].id:"")
        
        
    },[category,speciality])

    const filterCategory = (filter) => {
        const { filterChef, filterLocation, filterListCategoryElement } = filter
        let filteredData = category
        if (category.length) {
            if (filterChef) {
                const filteredChef = (chefName, filterChef) => chefName.includes(`${filterChef.toLowerCase()}`)
                filteredData = filteredData.filter(el => filteredChef(el["nama"].toLowerCase(), filterChef))
                setIsFirstStart(false)
            }

            if (filterLocation != "Location") {
                const filteredLocation = (location, filterLocation) => location.includes(filterLocation)
                filteredData = filteredData.filter(el => filteredLocation(el["area"], filterLocation))
            
                setIsFirstStart(false)
            }

            if (filterListCategoryElement) {
                const filteredListCategory = (genres, filterListCategoryElement) => genres.includes(filterListCategoryElement)
                filteredData = filteredData.filter(el => filteredListCategory(el["jenis"], filterListCategoryElement))
            }

            setFilteredCategory(filteredData)
        }
    }

    const handleListCategory = (e) => {
        var y = document.getElementById(localStorage.getItem('categoryList'))
        // console.log("e s=> "+e.name)
        setIndexListSpeciality(0)
        localStorage.setItem('categoryList',e.name)
        var x = document.getElementById(e.name)
        if(x.className === y.className){
            x.className = "categorylist underline"
            y.className = "categorylist underline"
        } else {
            x.className = "categorylist underline"
            y.className = "categorylist"
        }
        
        // setSelectedSpeciality(e.id)
        const categoryChefFromDB = async (id_speciality) => {
            try {
                /*
                Params is array of object
                [
                    {
                        "location": "Lake Cel"
                    },
                    {
                        "genre": "indonesian"
                    }
                ]
                */
                const data = await callApigetCategoryChef(id_speciality)
                // Create parser before set to state
                setCategory(data)
                
            } catch (error) {
                console.log("Error categoryChefFromDB")
            }
        }

        categoryChefFromDB(e.id)
        setfilterListCategoryElement(e.name)
    }

    const handleSortBy = (e) => {
        // // based on dummy data, it goes like this
        // Default will be acending
        // let sortedCategory = category.sort((a, b) => a.harga - b.harga)
        let sortedCategory = category.sort((a, b) => b.harga - a.harga)
        if (e === "Lowest IDR - Highest IDR") {
            // sortedCategory = category.sort((a, b) => b.harga - a.harga)
            sortedCategory = category.sort((a, b) => a.harga - b.harga)
        }
        setCategory(sortedCategory)
        setShowSortBy(false)
        setShowSortByMobile(false)
        setSortBy(e)
    }

    const handleFilterLocation = (e) => {
        setShowFilterLocation(false)
        setFilterLocation(e)
    }

    const handleShowFilter = (e) => {
        if(e === 'location'){
            showFilterLocation? setShowFilterLocation(false):setShowFilterLocation(true)
            setShowSortBy(false)
        }else{
            showSortBy? setShowSortBy(false):setShowSortBy(true)
            setShowFilterLocation(false)
        }
    }

    const handleSearchLocation = (e) => {
        setFilterSearchLocation(e);
        const foundLocation = []
        for (var x in databaseLocation){
          if (databaseLocation[x].toLowerCase().includes(e.toLowerCase())){
            foundLocation.push(databaseLocation[x])
          }
        }
        setListLocationFound(foundLocation);
    }

    const [y, setY] = useState(window.scrollY);

    const handleOpenSearchBar = () => {
        setShowSearchMobile(true)
        setShowSortByMobile(false)
    }

    const handleNavigation = useCallback(
        e => {
          const window = e.currentTarget;
          setY(window.scrollY);
          if(y<=50){
            var elem = document.getElementById("penggantidiubah");
            if(elem){
                elem.style.transition = "background-color 0.5s";
                elem.style.backgroundColor = "transparent";
            }
            }else{
                var elem2 = document.getElementById("penggantidiubah");
                if(elem){
                    elem2.style.transition = "background-color 0.5s";
                    elem2.style.backgroundColor = "rgba(0, 0, 0, 1)";
                }
            }
        }, [y]
      );

    useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);
    
    return () => {
        window.removeEventListener("scroll", handleNavigation);
    };
    }, [handleNavigation]);

    const parseCategoryChef = (categoryChefData) => {
        const genres = [
            ["Asian", "Western"],
            ["Asian", "Indonesian"],
            ["Western", "Indian"]
        ]
        
        // console.log("chef galery awok")
        // console.log(categoryChefData[0].chef_galery.photos)
        const area = ["Uranus Tengah", "Venus", "Provinsi Pluto"]
        
        categoryChefData = categoryChefData.map((el, idx) => {
            
            // if (el["slug"] == "Chef-Taplak") {
                if(el.services.length !== 0){
                    // console.log("chef service awok")
                    return {
                        id: el.id,
                        gambar: el.user_image_url,
                        nama: el.name,
                        biography:el.chef_galery.description,
                        email: el.email,
                        rating: parseTotalRating(el.chef_rating),
                        languages: languagesStylesParser(el["proficient"]),
                        service: parseServices(el.services),
                        chefEvent: parseChefEvent(el.chef_events),
                        chefGalery : parseChefGalery(el.chef_galery.photos),
                        chefCompany : parseChefCompany(el.work_experience,el.education_experience),
                        // jenis: idx <= genres[idx] ? genres[idx] : genres[genres.length-1],
                        jenis: parseServicesToJenis(el.services),
                        area: "Jakarta",
                        harga: el.services[0].converted_discounted_price,
                        // harga: el.starting_price,
                    }
                }
                
            // }
        })
        return categoryChefData.filter(el => el)
    }

    // const handleDecodeHtml = (string) => {
    //     const renderHTML = React.createElement("div", { dangerouslySetInnerHTML: { __html: string } });
    //     return renderHTML
    //   };

    const languagesStylesParser = (proficient) => {
        let proficientList = []
        if (proficient.length == 0) {
            return "N/A"
        }
        proficient = proficient.map((el) =>{
            proficientList.push(el.proficient)
        })

        // console.log("ini proficient => "+ proficientList)
        proficientList = proficientList.join(', ').replace(/, ([^,]*)$/, ' and $1')
        return proficientList
    }

    const parseChefCompany = (company,education) => {
        let workeduList = []
        if(company.length !== 0){
            company = company.map((el) => {
                return {
                    name:el.company,
                    date: el.start_date.slice(0,-9)
                }
            })
        }
        
        if(education.length !== 0){
            education = education.map((el) => {
                return {
                    name:el.education,
                    date: el.start_date.slice(0,-9)
                }
            })
        }
        

        // company.concat(education)

        workeduList = [...company, ...education]

        // console.log("work edu => "+JSON.stringify(workeduList))

        return workeduList

        // return {
        //     id:company.id,
        //     company_name:company.company_name,
        //     company_phone: company.company_phone,
        //     address: company.address,
        //     linkedin : company.linkedin,
        //     instagram : company.instagram,
        //     facebook : company.facebook,
        // }
    }

    const parseChefGalery = (chef_galery) => {
        if(!chef_galery || chef_galery < 1 || chef_galery == null){
            return "N/A"
        }
        chef_galery = chef_galery.map(el => {
            return {
                photo: el,
            }
        })
        // console.log(chef_galery)
        return chef_galery
    }

    const parseChefEvent = (chef_events) => {
        chef_events = chef_events.map(el => {
            const { date, time_start } = dateEventParser(el.events_date)
            return {
                id: el.id,
                gambar: el.cover_img_url,
                judul: el.event_name,
                style: stylesParser(el.event_style),
                sisa: el.min_pax,
                total: el.max_pax,
                tanggal: date,
                jam: time_start,
                tempat: el.vanue_location
            }
        })
        return chef_events
    }

    const dateEventParser = (dates) => {
        if (!dates || !dates.length) {
            return {
                "date": "N/A",
                "time_start": "N/A"
            }
        }
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ]
        dates = dates[0]

        // let [_, month, date] = dates["date"]
        let [_, month, date] = dates["date_events"].split("-")
        const idxMonth = Number(month)
        month = monthNames[idxMonth].slice(0, 3)
        date = Number(date)
        const parsedDate = `${date} ${month}`

        return {
            "date": parsedDate,
            "time_start": parseTimeEvent(dates)
        }
    }

    const parseTimeEvent = (dates) => {
        // const timeStartArr = dates["time_start"].split(":")
        const timeStartArr = dates["start_time"].split(":")
        // const timeEndArr = dates["time_end"].split(":")
        const timeEndArr = dates["end_time"].split(":")
        
        const parsedTimeStart = timeStartArr[0].concat(":", timeStartArr[1]);
        const parsedTimeEnd = timeEndArr[0].concat(":", timeEndArr[1]);

        const parsedTime = `${parsedTimeStart} - ${parsedTimeEnd}`
        return parsedTime
    }

    const stylesParser = (styles) => {
        if (!styles) {
            return "N/A"
        }
        // arrData = arrData[0].name
        styles = styles.map(({name}) => `${name}`).join(',');
        // arrData = arrData.join(', ').replace(/, ([^,]*)$/, ' and $1')
        return styles
    }

    const parseServices = (services) => {
        services = services.map(el => {
            return {
                id: el.id,
                nama: el.name,
                formated_price : el.formated_price,
                formated_discounted_price : el.formated_discounted_price,
                converted_discounted_price : el.converted_discounted_price,
                max_pax : el.max_pax,
                voucher: parseVoucher(el.voucher),
                prices: parseServicePrice(el.prices),
                menu: parseServiceMenu(el.menu),
                equipmentData: parseEquipment(el.equipment_data),
                containData: parseContain(el.contain_data),
            }
        })
        return services
    }

    const parseContain = (contain) => {
        let contains = []
        // if(!equipment || equipment < 1){
        //     return "N/A"
        // }

        contain = contain.map(el => {
            if(el !== null){
                console.log("equipment yang tidak kosong => "+el.deskripsi)
                contains.push({
                    containId: el["id_dietary"],
                    deskripsi: el["deskripsi"],
                    icon: el["icon"],
                    file_url: el["file_url"]
                })
            } else {
                console.log("equipment yang kosong => ")
                return {
                    containId: null,
                    deskripsi: null,
                    icon: null,
                    file_url: null
                }
            }
            
        })

        return contains
    }

    const parseEquipment = (equipment) => {
        let eqipments = []
        // if(!equipment || equipment < 1){
        //     return "N/A"
        // }

        equipment = equipment.map(el => {
            if(el !== null){
                console.log("equipment yang tidak kosong => "+el.deskripsi)
                eqipments.push({
                    equipmentId: el["id_equipment"],
                    deskripsi: el["deskripsi"],
                    icon: el["icon"],
                    file_url: el["file_url"]
                })
            } else {
                console.log("equipment yang kosong => ")
                return {
                    equipmentId: null,
                    deskripsi: null,
                    icon: null,
                    file_url: null
                }
            }
            
        })

        return eqipments
    }

    const parseVoucher = (vouchers) => {
        if(!vouchers || vouchers < 1){
            return "N/A"
        }

        vouchers = vouchers.map(el => {
            return {
                id: el["id"],
                name: el["name"],
                discount: el["discount"],
                quota: el["quota"]
            }
        })

        return vouchers
    }

    const parseServicesToJenis = (services) => {
        services = services.map(el => {
            return {
                id : el.id,
                name : el.name,
                converted_price : el.converted_price,
                max_pax : el.max_pax,
            }
        })
        return services
    }

    const parseServiceMenu = (services_menu) => {
        services_menu = services_menu.map(el => {
            return {
                id: el.id,
                bussines_id: el.bussines_id,
                name : el.name,
                description : el.description,
                image : el.menu_img,
            }
        })
        return services_menu
    }

    const parseServicePrice = (services_price) => {
        services_price = services_price.map(el => {
            return {
                id: el.id,
                bussines_id: el.bussines_id,
                amount : el.amount,
                max : el.max,
                
            }
        })
        return services_price
    }

    const parseTotalRating = (chef_rating) => {
        let rating = 0;
        for(const element of chef_rating){
            rating+=element.rate;
        }
        return rating
    }

    const handleParamsAPI = (params) => {
        let parsedParams = {}
        for (let value of params) {
            parsedParams = {...parsedParams, ...value}
        }
        return parsedParams
    }


    const callApigetSpeciality = async () => {
        try {
            
            const { data: res } = await axios({
                method: 'get',
                // url: `${baseUrl}category/type/chef`,
                url: `${baseUrl}speciality`,
                // params: paramsToBePassed
            })
            // console.log("Speciality => "+res.data)
            const specialityChefData = res.data
            // const specialityChefData = parseSpecialityChef(specialityData)
            // console.log("Speciality => "+specialityChefData[0].id_speciality)
            const allSpeciality = {
                "id_speciality": 0,
                "name": "All",
                "status": "active",
                "created_at": "2023-10-18T21:43:17.000000Z",
                "deleted_at": null,
                "updated_at": "2023-10-19T04:24:02.000000Z"
            }
            specialityChefData.splice(0,0,allSpeciality)
            // console.log("Speciality => "+specialityChefData)
            return specialityChefData
        } catch (error) {
            console.log(error)
            console.log("error getCategoryChefFromDB")
            // Create error handler (pop-up or something)
        }
    }

    const parseSpecialityChef = async (speciality) => {
        speciality = speciality.map((el) => {
            return {
                id : el.id_speciality,
                name : el.name
            }
        })
    }

    const callApigetCategoryChef = async (id_speciality) => {
        try {
            // let paramsToBePassed = null
            // if (params) {
            //     paramsToBePassed = handleParamsAPI(params)
            // }
            // console.log("ini id speciality sebelum di hit => "+id_speciality)
            const { data: res } = await axios({
                method: 'get',
                // url: `${baseUrl}category/type/chef`,
                // url: `${baseUrl}chef`,
                url: `${baseUrl}chef?term=&speciality_id=${id_speciality === 0 ? '': id_speciality}`,
                // params: paramsToBePassed
            })
            const categoryChefData = res.data
            const parsedCategoryChef = parseCategoryChef(categoryChefData)
            return parsedCategoryChef
        } catch (error) {
            console.log(error)
            console.log("error getCategoryChefFromDB")
            // Create error handler (pop-up or something)
        }
    }
    const ref = useRef(null);
    const {onMouseDown} = useDraggableScroll(ref);
    return (
        <div onClick={e => {e.stopPropagation();handleCloseAll()}} id="maincontainer" style={{minWidth:"100%", minHeight:"100vh", backgroundColor:"#111111", position:"relative", overflow:"unset"}}>
            <DesktopNavbar showPopup={showNavPopup} setShowPopup={setShowNavPopup}></DesktopNavbar>
            <FloatingWhatsAppCustom></FloatingWhatsAppCustom>
            <div className='kontainerPenggantiNavbar' id="penggantidiubah">
            {
                showSearchMobile ?
                <div onClick={e => {e.preventDefault();e.stopPropagation();}} className='searchbarmobilekontainer'>
                    <div className='searchbarmobile'>
                        <img src={search2} alt="iconback" className='iconKiriSerMo'></img>
                        <input value={filterChef} onChange={e => setFilterChef(e.target.value)} className="fieldchef" type='text' placeholder='Search by Chef or Cuisine' style={{marginLeft:"20px", marginRight:"20px"}}/>
                        <img onClick={e => {e.preventDefault();e.stopPropagation();setShowSearchMobile(false)}} src={back} alt="iconback" className='iconKananSerMo'></img>
                    </div>
                </div>
                :
                <div className='PenggantiNavbar'>
                    <img onClick={e => {e.preventDefault();e.stopPropagation();history(-1)}} src={back} alt="iconback" className='iconKiriNavMo'></img>
                    <img onClick={e => {e.preventDefault();e.stopPropagation();handleOpenSearchBar()}} src={search2} alt="iconback" className='iconKananNavMo'></img>
                    <img onClick={e => {e.preventDefault();e.stopPropagation();showSortByMobile? setShowSortByMobile(false):setShowSortByMobile(true)}} src={sort} alt="iconback" className='iconKiriNavMo'></img>
                    {
                        showSortByMobile === true && (
                            <div className="popupkontainerlistchefmobile">
                                <div style={{postion:"relative", height:"100%", width:"100%"}}>
                                    <p onClick={e => {e.preventDefault();e.stopPropagation();handleSortBy('Highest IDR - Lowest IDR')}} className="popupfontlistchef">Highest IDR - Lowest IDR</p>
                                    <p className="popupfontlistchef" onClick={e => {e.preventDefault();e.stopPropagation();handleSortBy('Lowest IDR - Highest IDR')}}>Lowest IDR - Highest IDR</p>
                                </div>
                            </div>
                        )
                    }
                </div>  
            }
            </div>

            {
                loading?
                <img alt='loading' className='taplakLoading' src={spinner}></img>
                :
                <CSSTransition in={standby} timeout={{appear:1000,enter:2000,exit:1000}} mountOnEnter={true} appear={true} classNames="CLtrans">
                <div>
                    <div className='chefListWrapper'>
                        <div className='headerkontainer'>
                            <div className='cuisinefilter'>
                                <div className='kontainercategorycuisine' ref={ref} onMouseDown={onMouseDown}>
                                {listCategoryFound.map((post) => {
                                    if(indexListSpeciality === undefined){
                                        console.log("speciality => undifined cok")
                                        return (
                                            <p onClick={e => {handleListCategory(post)}} style={{paddingTop:"0"}} id={post.name} className='categorylist'>{post.name}</p>
                                            )
                                    } else {
                                        console.log("speciality => ada cuy")
                                        if(post === listCategoryFound[0]){
                                            return (
                                                <p onClick={e => {handleListCategory(post)}} style={{paddingTop:"0"}} id={post.name} className='categorylist underline'>{post.name}</p>
                                                )
                                        }else{
                                            return (
                                                <p onClick={e => {handleListCategory(post)}} style={{paddingTop:"0"}} id={post.name} className='categorylist'>{post.name}</p>
                                                )
                                        }
                                    }

                                    
                                })}
                                </div>
                            </div>

                            <div className='kontainerfiltercuisine'>
                                <div onClick={e => {e.preventDefault();e.stopPropagation();handleCloseAll()}} id="wrappernya" className="findchef">
                                    <img src={search} alt='icon' className="iconsearch"></img>
                                    <input id="fieldnya" value={filterChef} onChange={e => setFilterChef(e.target.value)} className="fieldchef" type='text' placeholder='Search by Chef or Cuisine'/>
                                </div>

                                <div className='containerfilterchef'>
                                    {
                                        showFilterLocation?
                                        <div onClick={e => {e.preventDefault();e.stopPropagation();handleShowFilter('location')}} className="filterchef LCyellow">
                                            <img src={pin} alt='icon' className="iconsearch"></img>
                                            <p className='filterchoice'>{filterLocation}</p>
                                        </div>
                                        :
                                        <div onClick={e => {e.preventDefault();e.stopPropagation();handleShowFilter('location')}} className="filterchef">
                                            <img src={pin} alt='icon' className="iconsearch"></img>
                                            <p className='filterchoice'>{filterLocation}</p>
                                        </div>
                                    }

                                    {
                                        showFilterLocation === true && (
                                            <div className="popupkontainerlistchef" style={{marginRight:"0"}}>
                                                <div style={{postion:"relative", height:"100%", width:"100%"}}>
                                                    <div onClick={e => {e.preventDefault();e.stopPropagation();}} className="inputfind">
                                                        <img src={search} alt='icon' className="iconsearch"></img>
                                                        <input value={filterSearchLocation} onChange={e => handleSearchLocation(e.target.value)} className="fieldlocation" type='text' placeholder='Search Location'/>
                                                    </div>
                                                    {listLocationFound.map((post) => (
                                                        <p className="popupfontlistchef" onClick={e => {e.preventDefault();e.stopPropagation();handleFilterLocation(post)}}>{post}</p>
                                                    ))}
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                                
                                <div className='containerfilterchef'>
                                    {
                                        showSortBy?
                                        <div onClick={e => {e.preventDefault();e.stopPropagation();handleShowFilter('sortBy')}} className="filterchef LCyellow">
                                            <img src={sort} alt='icon' className="iconsearch"></img>
                                            <p className='filterchoice2'>{sortBy}</p>
                                        </div>
                                        :
                                        <div onClick={e => {e.preventDefault();e.stopPropagation();handleShowFilter('sortBy')}} className="filterchef">
                                            <img src={sort} alt='icon' className="iconsearch"></img>
                                            <p className='filterchoice2'>{sortBy}</p>
                                        </div>
                                    }
                                    
                                    {
                                        showSortBy === true && (
                                            <div className="popupkontainerlistchef">
                                                <div style={{postion:"relative", height:"100%", width:"100%"}}>
                                                    <p onClick={e => {e.preventDefault();e.stopPropagation();handleSortBy('Highest IDR - Lowest IDR')}} className="popupfontlistchef">Highest IDR - Lowest IDR</p>
                                                    <p className="popupfontlistchef" onClick={e => {e.preventDefault();e.stopPropagation();handleSortBy('Lowest IDR - Highest IDR')}}>Lowest IDR - Highest IDR</p>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={category.length > 2 ? 'ListChefPageCardContainer  space-between' : 'ListChefPageCardContainer gap-40'} data-aos="fade-up" data-aos-delay="300">
                            {/* {console.log("data chef")}
                            {console.log(isFirstStart)}
                            {console.log(category)} */}
                            {
                                isFirstStart ?
                                category.map((post) => (
                                    <ListChefCard
                                        data={post} isFromSearch={isFromSearchData} standby={standby} fungsi={()=>setstandby(false)}
                                    />
                                )) :
                                
                                filteredCategory.map((post) => (
                                    <ListChefCard
                                        data={post} isFromSearch={isFromSearchData} standby={standby} fungsi={()=>setstandby(false)}
                                    />
                                ))
                            }
                        </div>
                    </div>
                </div>
                </CSSTransition>
        }
        </div>
    )
  }

  